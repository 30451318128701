import { FieldRenderProps, FieldProps } from 'react-final-form';
import { ISelectOption } from '../../../../models/SelectOptions';

export interface IStartDatePickerProps extends FieldRenderProps<string> {
  name: string;
  hasUnpaidInvoices: boolean;
  disableScheduleLoaAfterXDays: number;
  maxPossibleRemainingLoa: number;
  validate: any;
}

export interface IReturnDatePickerProps extends FieldRenderProps<string> {
  maxPossibleRemainingLoa: number;
  disableScheduleLoaAfterXDays: number;
  name: string;
}

export interface IDatePickerProps extends FieldRenderProps<string> {
  name: string;
  selected: string;
  maxDate: string;
  minDate: string;
  showMonthYearPicker: boolean;
  dateFormat: string;
  timeFormat: string;
  deferred?: boolean;
  locale?: string;
  showCurrentTime?: boolean;
}

export interface ISelectField extends FieldRenderProps<ISelectOption> {
  fieldName: ProgramStatusSelectFieldType;
  name: string;
}

export enum ProgramStatusSelectFieldType {
  Status = 'status',
  SubStatus = 'subStatus',
  Reason = 'reason',
}

export interface ITextField extends FieldRenderProps<any> {
  name: string;
  label: string;
  displayError: boolean;
  value?: any;
}

export interface IConditionalField extends FieldProps<any, any> {
  name: string;
  changeValue: (name: string, emptyValue: any) => void;
  fieldType?: string;
  emptyValue?: any;
  options?: ISelectOption[];
}

export interface ICheckBoxField extends FieldRenderProps<string> {
  name: string;
  label: string;
}

export interface IRemainingLoa extends FieldRenderProps<string> {
  remainingLOA: number;
  countRemaining: boolean;
  maxPossibleRemainingLoa: number;
}

export interface IFileUpload extends Omit<FieldProps<any[], any>, 'children'> {
  name: string;
  changeFileNameValue?: (name: string, value: string) => void;
}

export interface IFieldError {
  name: string;
  multipleErrors?: boolean;
}
