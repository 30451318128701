import { useMemo } from 'react';
import { useProducts } from './use-products';
import { Select } from '../../ui/form/select/select';
import { PRODUCT_CODE, PRODUCT_TYPE } from '../../admissions/admissions-form/admissions-form.constants';
import { useField, useForm } from 'react-final-form';
import styles from './approve-admission-decision-form.module.scss';

export const ProductTypeSelect = () => {
  const form = useForm();
  const { data: products, isLoading } = useProducts();
  const productType = useField<string>(PRODUCT_TYPE);

  const productTypes = useMemo(
    () =>
      Array.from(
        products.reduce((prev, curr) => {
          prev.add(curr.type);

          return prev;
        }, new Set<string>()),
      ).map((value) => ({
        label: value,
        value,
      })),
    [products],
  );

  return (
    <Select
      name={PRODUCT_TYPE}
      options={productTypes}
      label="Product Type"
      isLoading={isLoading}
      onChange={(newValue) => {
        if (newValue === productType.input.value) return;
        form.change(PRODUCT_CODE, undefined);
      }}
      className={styles.element}
    />
  );
};
