import React, { useEffect, useState } from 'react';

import { Field, useField } from 'react-final-form';
import { FormControl } from 'react-bootstrap';
import moment from 'moment';
import { RedLabel, InputStyles } from '../styled-components';
import {
  IStartDatePickerProps,
  IReturnDatePickerProps,
  ISelectField,
  IConditionalField,
  ITextField,
  ICheckBoxField,
  IRemainingLoa,
  IFileUpload,
  IFieldError,
  ProgramStatusSelectFieldType,
} from './form-filed.models';
import { BoldColumn } from '../../../learner-info/styled-components';
import { ISelectOption, IOption } from '../../../../models/SelectOptions';
import { CHECKBOX } from '../../../constants/common-constants';
import { REASON } from '../../../learner-info/program-status/program-status-editing-modal';
import { BaseSelect } from '../../../ui/form/select/select';
import DatePicker from 'react-datepicker';

interface IAdmissionDataOption {
  Label: string;
  Value: string;
}

export const SUB_STATUS = 'subStatus';
export const START_DATE = 'startDate';
export const RETURN_DATE = 'returnDate';

export const formatDate = (date: string | Date, format: string) => moment(date).format(format);

export const isCurrentDate = (date: Date | string) => moment(date).isSame(new Date(), 'day');

interface IEmptySelectOption {
  value: '-';
  label: '-';
}

export const emptySelectOption: IEmptySelectOption = {
  value: '-',
  label: '-',
};

export const ConditionalField: React.FC<IConditionalField> = ({
  fieldType,
  name,
  changeValue,
  emptyValue,
  ...rest
}: IConditionalField): JSX.Element => {
  useEffect(
    () => () => {
      switch (fieldType) {
        case 'select':
          changeValue(name, emptySelectOption);
          break;
        case 'checkbox':
          changeValue(name, false);
          break;
        case 'radio':
          changeValue(name, null);
          break;
        case 'admissionForm':
          changeValue(name, emptyValue);
          break;
        case 'keepValue':
          break;
        default:
          changeValue(name, null);
          break;
      }
    },
    [],
  );
  return (
    <>
      <Field name={name} {...rest} />
    </>
  );
};

export const renderSelectField: React.FC<ISelectField> = ({
  input,
  meta,
  disabled,
  testID,
  ...rest
}: ISelectField): JSX.Element => (
  <BaseSelect
    testId={testID}
    error={meta.error}
    {...input}
    {...rest}
    onChange={(e) => {
      input.onChange(e);
    }}
    isDisabled={disabled}
  />
);

export const RenderSelectFieldProgramStatusForm: React.FC<ISelectField> = ({
  input,
  meta,
  fieldName,
  ...rest
}: ISelectField) => {
  const subStatusField = useField(SUB_STATUS);
  const reasonField = useField(REASON);

  return (
    <BaseSelect
      error={meta.error}
      {...input}
      {...rest}
      onChange={(e) => {
        input.onChange(e);
        // clear subStatus filed if programStatus filed changed
        if (fieldName === ProgramStatusSelectFieldType.Status) {
          subStatusField.input.onChange(emptySelectOption);
          reasonField.input.onChange(emptySelectOption);
        }
        if (fieldName === ProgramStatusSelectFieldType.SubStatus) {
          reasonField.input.onChange(emptySelectOption);
        }
      }}
    />
  );
};

export const returnNearestValidStartDate = (disableStartDateAfterXDays: number): Date => {
  const today = moment();
  const currentMonthFistDay = moment().startOf('month');
  const nextMonthFistDay = moment().startOf('month').add(1, 'months');
  const XDayAfter = moment().startOf('month').add(disableStartDateAfterXDays, 'days').toDate();
  return today.isSameOrBefore(XDayAfter) ? currentMonthFistDay.toDate() : nextMonthFistDay.toDate();
};

export const isStartDateSelectorDisabled = (
  startDate: string | null,
  hasUnpaidInvoices: boolean,
  disableStartDateAfterXDays: number,
): boolean => {
  const today = moment();
  if (startDate && hasUnpaidInvoices) {
    return true;
  }
  if (startDate) {
    const isLaterThen8DaysAfterStartDate = moment(startDate).add(disableStartDateAfterXDays, 'days');
    return today.isAfter(isLaterThen8DaysAfterStartDate);
  }
  return false;
};

export const StartDatePicker: React.FC<IStartDatePickerProps> = ({
  input,
  hasUnpaidInvoices,
  disableScheduleLoaAfterXDays,
}: IStartDatePickerProps) => {
  const preventTypingInDataSelectInput = (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined,
  ) => {
    e?.preventDefault();
  };

  const returnDateField = useField(RETURN_DATE);
  const returnDateFieldValue = returnDateField.input.value;
  const startDateValue = input.value ? moment(input.value).toDate() : undefined;
  const returnDateFieldValueDate = returnDateFieldValue ? moment(returnDateFieldValue).toDate() : undefined;
  const maxDate = returnDateFieldValue ? moment(returnDateFieldValue).subtract(1, 'months').toDate() : undefined;

  return (
    <InputStyles>
      <DatePicker
        selected={startDateValue}
        onChange={(e: Date | null) => {
          input.onChange(e);
        }}
        disabled={isStartDateSelectorDisabled(input.value, hasUnpaidInvoices, disableScheduleLoaAfterXDays)}
        onChangeRaw={preventTypingInDataSelectInput}
        startDate={startDateValue}
        endDate={returnDateFieldValueDate}
        minDate={returnNearestValidStartDate(disableScheduleLoaAfterXDays)}
        maxDate={maxDate}
        dateFormat="dd MMM yyyy"
        showMonthYearPicker
        selectsStart
      />
    </InputStyles>
  );
};

const getReturnDataPickerMinDate = (startDateFieldValue: string | null, disableScheduleLoaAfterXDays: number) => {
  const nearestValidStartDate = returnNearestValidStartDate(disableScheduleLoaAfterXDays);

  const startDateIsInThePast = startDateFieldValue && moment(startDateFieldValue).isBefore(nearestValidStartDate);

  const startDateSelectorDisabled = isStartDateSelectorDisabled(
    startDateFieldValue,
    false,
    disableScheduleLoaAfterXDays,
  );

  if (startDateIsInThePast && startDateSelectorDisabled) {
    return nearestValidStartDate;
  }
  if (startDateFieldValue) {
    return moment(startDateFieldValue).add(1, 'month').toDate();
  }
  return moment(nearestValidStartDate).add(1, 'month').toDate();
};

const getReturnDataPickerMaxDate = (
  startDateFieldValue: string | null,
  maxPossibleRemainingLoa: number,
  disableScheduleLoaAfterXDays: number,
) => {
  const startDateSelectorDisabled = isStartDateSelectorDisabled(
    startDateFieldValue,
    false,
    disableScheduleLoaAfterXDays,
  );

  return startDateFieldValue && startDateSelectorDisabled
    ? moment(startDateFieldValue).add(maxPossibleRemainingLoa, 'month').toDate()
    : undefined;
};

export const ReturnDatePicker: React.FC<IReturnDatePickerProps> = ({
  input,
  maxPossibleRemainingLoa,
  disableScheduleLoaAfterXDays,
}: IReturnDatePickerProps) => {
  const preventTypingInDataSelectInput = (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined,
  ) => {
    e?.preventDefault();
  };

  const startDateField = useField(START_DATE);
  const startDateFieldValue = startDateField.input.value;
  const returnDate = input.value ? moment(input.value).toDate() : undefined;
  const startDateFieldValueDate = startDateFieldValue ? moment(startDateFieldValue).toDate() : undefined;

  const minDate = getReturnDataPickerMinDate(startDateFieldValue, disableScheduleLoaAfterXDays);
  const maxDate = getReturnDataPickerMaxDate(
    startDateFieldValue,
    maxPossibleRemainingLoa,
    disableScheduleLoaAfterXDays,
  );
  return (
    <InputStyles>
      <DatePicker
        selected={returnDate}
        onChange={(e: Date | null) => {
          input.onChange(e);
        }}
        selectsEnd
        onChangeRaw={preventTypingInDataSelectInput}
        startDate={startDateFieldValueDate}
        endDate={returnDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="dd MMM yyyy"
        showMonthYearPicker
      />
    </InputStyles>
  );
};

export const RemainingLoaField: React.FC<IRemainingLoa> = ({
  maxPossibleRemainingLoa,
  countRemaining,
  remainingLOA,
}: IRemainingLoa): JSX.Element => {
  const startDateField = useField(START_DATE);
  const returnDateField = useField(RETURN_DATE);
  const startDateFieldValue = startDateField.input.value;
  const returnDateFieldValue = returnDateField.input.value;
  const startDateFieldValueDate = startDateFieldValue ? moment(startDateFieldValue).toDate() : null;
  const returnDateFieldValueDate = returnDateFieldValue ? moment(returnDateFieldValue).toDate() : null;
  if (startDateFieldValueDate && returnDateFieldValueDate && countRemaining) {
    const timeScheduled = moment(returnDateFieldValueDate).diff(startDateFieldValueDate, 'months', true);
    const timeLeft = maxPossibleRemainingLoa - timeScheduled;
    return (
      <>
        <BoldColumn>
          {timeLeft >= 0 ? (
            `${timeLeft} months`
          ) : (
            <>
              <RedLabel>{`${timeLeft} months`}</RedLabel>
            </>
          )}
        </BoldColumn>
      </>
    );
  }
  return (
    <>
      <BoldColumn>{`${countRemaining ? maxPossibleRemainingLoa : remainingLOA} months`}</BoldColumn>
    </>
  );
};

export const FileUpload: React.FC<IFileUpload> = ({ name, changeFileNameValue, ...props }): JSX.Element => {
  const [fileError, setFileError] = useState(false);

  return (
    <Field name={name}>
      {({
        input: {
          // eslint-disable-next-line
          value,
          onChange,
          ...input
        },
      }) => {
        const { accept } = props;
        const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          const filesList = target.files;
          onChange(filesList); // instead of the default target.value
          if (filesList && filesList.length > 0) {
            const fileFormat = filesList[0].type;
            setFileError(fileFormat !== 'application/pdf');
          }
          if (changeFileNameValue) {
            const fileNameValue = filesList && filesList.length > 0 ? filesList[0].name.split('.')[0] : '';
            // automaticaly changes value of file name field if file uploaded
            changeFileNameValue('fileName', fileNameValue);
          }
        };

        return (
          <div>
            <input {...input} className="file-submit" type="file" onChange={handleChange} {...props} />
            {accept && accept === '.pdf' && fileError && (
              <div className="format-error">The allowed file type is .pdf</div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export const renderCheckbox: React.FC<ICheckBoxField> = ({
  input: { checked, onChange, value, type },
  label,
  ...custom
}: ICheckBoxField): JSX.Element => (
  <>
    <label style={{ display: type === CHECKBOX ? 'block' : 'inline-block' }}>
      <input type={type} value={value} checked={Boolean(checked)} onChange={onChange} {...custom} /> {label}
    </label>
  </>
);

export const renderTextField: React.FC<ITextField> = ({
  input,
  meta,
  displayError,
  ...custom
}: ITextField): JSX.Element => (
  <>
    <FormControl as="textarea" rows={3} {...input} {...custom} />
    {displayError && meta.error && <RedLabel>{meta.error}</RedLabel>}
  </>
);

export const renderTextInput: React.FC<ITextField> = ({ input, ...custom }: ITextField): JSX.Element => (
  <>
    <FormControl as="textarea" rows={3} {...input} {...custom} />
  </>
);

export const renderAdmissionCheckBoxFiled = (
  dataObject: IAdmissionDataOption | ISelectOption,
  changeValue: () => void,
  name: string,
  type: string,
  fieldType: string,
): JSX.Element => (
  <ConditionalField
    key={
      (dataObject as IAdmissionDataOption).Value
        ? (dataObject as IAdmissionDataOption).Value
        : (dataObject as ISelectOption).value
    }
    name={name}
    changeValue={changeValue}
    render={renderCheckbox}
    type={type}
    fieldType={fieldType}
    label={
      (dataObject as IAdmissionDataOption).Label
        ? (dataObject as IAdmissionDataOption).Label
        : (dataObject as ISelectOption).label
    }
    value={
      (dataObject as IAdmissionDataOption).Value
        ? (dataObject as IAdmissionDataOption).Value
        : (dataObject as ISelectOption).value
    }
  />
);

export const Error: React.FC<IFieldError> = ({
  name,
  multipleErrors,
}: {
  name: string;
  multipleErrors?: boolean;
}): JSX.Element | null => {
  const {
    meta: { error },
  } = useField(name, { subscription: { touched: true, error: true } });
  if (multipleErrors) {
    return error && error.length > 0
      ? error.map((errorMessage: string) => <RedLabel key={errorMessage}>{errorMessage}</RedLabel>)
      : null;
  }
  return error ? <RedLabel>{error}</RedLabel> : null;
};

export const makeFirstLetterLowerCase = (word: string | null) => {
  if (typeof word !== 'string') return '';
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const capitalize = (word: string | null) => {
  if (typeof word !== 'string') return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const removeWhiteSpaces = (word: string) => {
  if (typeof word !== 'string') return '';
  return word.replace(/\s/g, '');
};

export const createOptions = (options: IOption[]): ISelectOption[] =>
  options.map((type: IOption): ISelectOption => ({ label: type.name, value: type.id }));
