import React from 'react';
import { useParams } from 'react-router-dom';
import LearnerDashboardContent from './learner-dashboard-content';
import { LearnerContainer } from '../learner-container/learner-container';

export const LearnerDashboard = () => {
  const { learnerId } = useParams<{ learnerId: string }>();

  return (
    <LearnerContainer>
      <LearnerDashboardContent learnerId={learnerId} />
    </LearnerContainer>
  );
};
