import React from 'react';

interface IProps {
  days: number | null;
}

const AcademicStandingStatusAge: React.FunctionComponent<IProps> = ({ days }: IProps) => {
  if (days === null) {
    return <></>;
  }

  const pluralDays = days === 1 ? 'day' : 'days';
  return (
    <span>
      ({days} {pluralDays})
    </span>
  );
};

export default AcademicStandingStatusAge;
