import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import { PropValue } from './utils/event-prop';

export const achievementApprovedSelector: (event: IEvent) => IEventDetails | null = (event: IEvent) => {
  if (event.achievementType !== 'credential') {
    return null;
  }

  return {
    metadata: {
      type: {
        category: 'Credential',
        eventName: 'Approved',
      },
      date: event.updatedDateTime,
      editor: event.approvedBy,
    },
    element: <Event event={event} />,
  };
};

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <>
      <Row>
        <PropValue value={`${event.productType} ${event.productCode}`} primary />
      </Row>
      <Row>{event.name}</Row>
    </>
  );
};
