import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const StyledApplicantEditableInfo = styled.div`
  .row {
    margin-bottom: 2px;
  }
`;

export const EditingCol = styled(Col)`
  padding-right: 15px;
`;
