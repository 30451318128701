import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import {
  learnerInfoEditModalTypes,
  IDropdownSelectedOptions,
  IProgramStatusOption,
} from '../reducers/learner-info-editing-modal/learning-info-editing-modal.model';
import { IOption } from '../../models/SelectOptions';

export const getLearnerInfoEditState = (state: IRootState): learnerInfoEditModalTypes =>
  state.learnerInfoEditModalState;

export const getProgramStatusSelectOptions = createSelector(
  [getLearnerInfoEditState],
  (learnerInfoEditModalState: learnerInfoEditModalTypes): IOption[] => learnerInfoEditModalState.programStatusOptions,
);
export const getReasonSelectOptions = createSelector(
  [getLearnerInfoEditState],
  (learnerInfoEditModalState: learnerInfoEditModalTypes): IOption[] => learnerInfoEditModalState.reasons,
);
export const getProgramStatusRestrictions = createSelector(
  [getLearnerInfoEditState],
  (learnerInfoEditModalState: learnerInfoEditModalTypes): IProgramStatusOption[] =>
    learnerInfoEditModalState.restrictions,
);

export const getSelectedOptionsList = createSelector(
  [getLearnerInfoEditState],
  (learnerInfoEditModalState: learnerInfoEditModalTypes): IDropdownSelectedOptions =>
    learnerInfoEditModalState.dropdownSelectedOptions,
);

export const getProgramStatusLabel = createSelector(
  [getLearnerInfoEditState],
  (learnerInfoEditModalState: learnerInfoEditModalTypes): string =>
    learnerInfoEditModalState.dropdownSelectedOptions.programStatus.label,
);
