type Options = {
  onUploadProgress: (progress: ProgressEvent) => void;
  headers: Record<string, string>;
};

export const uploadProgressiveFile = async <D>(url: string, payload: any, options: Options): Promise<D> => {
  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('progress', (event) => {
      options.onUploadProgress(event);
    });

    xhr.addEventListener('loadend', () => {
      if (xhr.readyState !== 4) return;

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(new Error('File upload failed'));
      }
    });
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    Object.entries(options.headers).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value);
    });
    xhr.send(payload);
  });
};
