import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../api/adminUI.api';
import IError from '../../models/Error';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  GET_AEP_DEADLINE_ERROR,
  GET_AEP_DEADLINE_SUCCESS,
  EXTEND_AEP_DEADLINE,
  GET_AEP_DEADLINE_PENDING,
} from '../reducers/aep-deadline/aep-deadline.constants';
import { IAEPDeadline } from '../reducers/aep-deadline/aep-deadline.model';
import { IExtendAepDeadlineRequest } from '../../components/learner-info/aep-deadline/aep-deadline.model';

export const getAepDeadlineObjectPending = (): AnyAction => action(GET_AEP_DEADLINE_PENDING);
export const getAepDeadlineObjectSUCCES = (aepDeadline: IAEPDeadline): AnyAction =>
  action(GET_AEP_DEADLINE_SUCCESS, aepDeadline);
export const getAepDeadlineObjectFailure = (error: IError): AnyAction => action(GET_AEP_DEADLINE_ERROR, error);

export const getLearnerAepDeadlineInfo = (learnerId: string) => async (dispatch: Dispatch) => {
  dispatch(getAepDeadlineObjectPending());
  try {
    const response = await api.aepDeadline.learnerAepDeadline(learnerId);
    if (!response.ok) throw await response;
    const data = (await response.json()) as IAEPDeadline;
    dispatch(getAepDeadlineObjectSUCCES(data));
  } catch (error: any) {
    dispatch(getAepDeadlineObjectFailure(error));
    errorHandling({ error });
  }
};

export const extendLearnerAepDeadlineInfo =
  (
    learnerId: string,

    aepDeadlineRequest: IExtendAepDeadlineRequest,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await api.aepDeadline.extendLearnerAepDeadline(learnerId, aepDeadlineRequest);
      if (!response.ok) throw await response;
      dispatch({ type: EXTEND_AEP_DEADLINE, payload: aepDeadlineRequest });
    } catch (error: any) {
      errorHandling({ error });
    }
  };
