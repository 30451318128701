import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';

export const StyledAchievementsContainer = styled(Container)`
  font-family: 'Open Sans';
  text-align: left;
  margin-bottom: 20px;
  @media (min-width: 1200px) {
    max-width: 1440px;
  }
`;

export const WaitingBlock = styled.div`
  text-align: center;
  font-size: 13px;
`;

export const StyledModalButtons = styled.div`
  display: flex;
  justify-content: center;
  && {
    .btn {
      width: 180px;
      height: 38px;
    }
    .btn-success {
      margin-right: 25px;
    }
  }
`;

export const StyledAchievementsWrapper = styled.div`
  margin: 45px 0;
  && {
    td,
    th {
      vertical-align: middle !important;
    }
    .table-bordered thead th {
      padding-left: 5px;
      font-size: 15px;
    }
    tr {
      padding-left: 5px;
      font-size: 14px;
    }
  }
`;
export const TitleRow = styled(Row)`
  margin: 5px 0 30px 0;
  font-size: larger;
`;

export const CommonTd = styled.td`
  white-space: nowrap;
`;
export const CenteredTd = styled.td`
  text-align: center;
  white-space: nowrap;
`;

export const CommonTh = styled.th`
  white-space: nowrap;
`;

export const CommentsTh = styled.th`
  min-width: 300px;
`;

export const CommentsTd = styled.td`
  word-break: break-word;
`;
export const MainInfoTd = styled.td`
  white-space: nowrap;
  max-width: 250px;
`;

export const StyledAchievements = styled.div``;
