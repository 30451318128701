import React from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FaPlus, FaTrashAlt, FaMinus } from 'react-icons/fa';
import api from '../../../shared/api/adminUI.api';
import {
  ITransferCreditsObject,
  ITransferCreditsState,
  TransferableCoursesFormValues,
  TransferableCoursesObject,
} from '../learner-info.model';
import errorHandling from '../../helper-components/alert-component.component';
import { ExternalTransferWrapper, DeleteButton } from './styled-components';
import {
  renderTextInput,
  Error,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import ProductSelect from './product-select';
import CourseSelect from './course-select';
import ApprovedTransfers from './approved-transfers';
import { composeValidators, minLength, required } from '../../../utils/validate.utils';
import {
  EXTERNAL_COURSE_CODE_MAX_LENGTH,
  EXTERNAL_COURSE_CODE_MIN_LENGTH,
  EXTERNAL_COURSE_NAME_MAX_LENGTH,
  EXTERNAL_COURSE_NAME_MIN_LENGTH,
  EXTERNAL_INSTITUTION_NAME_MAX_LENGTH,
  EXTERNAL_INSTITUTION_NAME_MIN_LENGTH,
} from './transfer-credits.constants';

class TransferCredits extends React.PureComponent<ITransferCreditsObject, ITransferCreditsState> {
  constructor(props: ITransferCreditsObject) {
    super(props);
    this.state = {
      transferableProducts: [],
      transferableProductsPending: false,
      transferableCourses: [],
      activeProductCode: '',
      activeProductType: '',
      transferableCoursesPending: false,
      pendingCourses: [],
      savePending: false,
      approvePending: false,
      formValues: {
        courseTransfers: [],
      },
      productListDefault: '',
    };
  }

  public componentDidMount(): void {
    const { canEditExternalTransfer } = this.props;
    if (canEditExternalTransfer) {
      this.getListOfTransferableProducts();
    }
    this.forceUpdate();
    const { formValues } = this.state;

    const { externalTransfers } = this.props;
    externalTransfers.forEach((course) => {
      if (course.status === 'pending') {
        formValues.courseTransfers.push({
          productCode: course.productCode,
          productType: course.productType,
          externalCourses: course.externalCourses,
          courseCode: course.courseCode,
          courseName: course.courseName,
          isTransferable: false,
        });
      }
    });

    this.setState({ formValues });
  }

  private readonly getListOfTransferableProducts = async (): Promise<void> => {
    try {
      const { learnerProgramEnrollments } = this.props;
      const activePE = learnerProgramEnrollments.find((pe) => pe.programStatusId === 'enrolled');

      this.setState({
        transferableProductsPending: true,
      });
      const response = await api.transferCredits.getListOfTransferableProducts();
      if (!response.ok) throw await response;

      const transferableProducts = await response.json();

      if (transferableProducts && transferableProducts.length) {
        if (activePE && activePE.productType !== 'Course') {
          const currentPE = transferableProducts.find(
            // @ts-ignore
            (product) => product.productCode === activePE.productCode,
          );

          this.setState({
            productListDefault: `${currentPE.productName} - ${currentPE.productCode}`,
          });
          this.handleSelectTransferableCourse({
            label: `${currentPE.productName} - ${currentPE.productCode}`,
            value: currentPE.productCode,
            productType: currentPE.productType,
          });
        }
        this.setState({ transferableProducts });
      }
      this.setState({
        transferableProductsPending: false,
      });
    } catch (error: any) {
      errorHandling({ error: error as any });
      this.setState({
        transferableProductsPending: false,
      });
    }
  };

  private readonly getListOfProductsTransferableCourses = async (
    productType: string,
    productCode: string,
  ): Promise<void> => {
    try {
      this.setState({
        transferableCoursesPending: true,
      });
      const { learnerId } = this.props;
      const response = await api.transferCredits.getListOfProductsTransferableCourses(
        learnerId,
        productType,
        productCode,
      );
      if (!response.ok) throw await response;

      const transferableCourses = await response.json();
      if (transferableCourses && transferableCourses.length) {
        transferableCourses.forEach((course: TransferableCoursesObject) => {
          course.productCode = productCode;
        });
        this.setState({ transferableCourses });
      }
      this.setState({
        transferableCoursesPending: false,
      });
    } catch (error: any) {
      errorHandling({ error: error as any });
      this.setState({
        transferableCoursesPending: false,
      });
    }
  };

  private savePendingCourses = async (values: TransferableCoursesFormValues): Promise<void> => {
    try {
      this.setState({
        savePending: true,
      });
      const { learnerId } = this.props;
      const response = await api.transferCredits.saveLearnerPendingExternalTransfers(learnerId, values);
      if (!response.ok) throw await response;

      this.setState({
        savePending: false,
      });
    } catch (error: any) {
      errorHandling({ error: error as any });
      this.setState({
        savePending: false,
      });
    }
  };

  private approvePendingCourses = async (values: TransferableCoursesFormValues): Promise<void> => {
    try {
      this.setState({
        approvePending: true,
      });
      const { learnerId } = this.props;
      const response = await api.transferCredits.approveLearnerPendingExternalTransfers(learnerId, values);
      if (!response.ok) throw await response;

      this.setState({
        approvePending: false,
      });
    } catch (error: any) {
      errorHandling({ error: error as any });
      this.setState({
        approvePending: false,
      });
    }
  };

  private getPrepopulatedProvider = (pendingCoursesToTransfer: TransferableCoursesObject[]): string => {
    if (pendingCoursesToTransfer.length === 0) {
      return '';
    }

    const lastEnteredCourse = pendingCoursesToTransfer[pendingCoursesToTransfer.length - 1];
    const lastEnteredExternalCourse = lastEnteredCourse.externalCourses[lastEnteredCourse.externalCourses.length - 1];

    return lastEnteredExternalCourse.provider;
  };

  private handleAddTransferredCourse = (fields: any, course: TransferableCoursesObject): void => {
    const { pendingCourses, activeProductCode, activeProductType, formValues } = this.state;
    course.productCode = activeProductCode;
    course.productType = activeProductType;

    const defaultProvider = this.getPrepopulatedProvider(fields.value);

    course.externalCourses = [
      {
        externalCourseCode: '',
        externalCourseName: '',
        provider: defaultProvider,
      },
    ];
    if (fields) {
      fields.push(course);
    }
    const joined = pendingCourses.concat(course);
    formValues.courseTransfers = joined;

    this.setState({ formValues, pendingCourses: joined });
  };

  private handleSave = async (values: TransferableCoursesFormValues): Promise<void> => {
    this.savePendingCourses(values);
    const { onSubmit } = this.props;
    await onSubmit();
  };

  private clearPendingCoursesList = () => {
    this.setState({ pendingCourses: [], formValues: { courseTransfers: [] } });
  };

  private handleRemove = (course: any): void => {
    const { pendingCourses } = this.state;

    this.setState({
      pendingCourses: pendingCourses.filter((pendingCourse) => pendingCourse.courseCode !== course.courseCode),
    });
  };

  private handleApprove = async (values: TransferableCoursesFormValues): Promise<void> => {
    await this.approvePendingCourses(values);
    const { onSubmit } = this.props;
    await onSubmit();
    this.clearPendingCoursesList();
  };

  private handleSelectTransferableCourse = (option: any) => {
    this.setState({
      activeProductCode: option.value,
      activeProductType: option.productType,
    });
    this.getListOfProductsTransferableCourses(option.productType, option.value);
  };

  render(): JSX.Element {
    const { externalTransfers, canEditExternalTransfer, canApproveExternalTransfer } = this.props;

    const {
      transferableProducts,
      transferableProductsPending,
      transferableCourses,
      transferableCoursesPending,
      pendingCourses,
      formValues,
      savePending,
      approvePending,
      productListDefault,
    } = this.state;

    const transferableProductsOptions: any = [];
    const initialFormValues = formValues;
    const submitPending = savePending || approvePending;
    let transferableCoursesFiltered = transferableCourses;

    if (transferableProducts.length) {
      transferableProducts.map((option) => {
        transferableProductsOptions.push({
          label: `${option.productName} - ${option.productCode}`,
          value: option.productCode,
          productType: option.productType,
          approved: option.approved,
        });
      });
    }

    if (pendingCourses.length || externalTransfers.length) {
      transferableCoursesFiltered = transferableCourses.filter(
        (prod) =>
          pendingCourses.findIndex(
            (course) => course.courseCode === prod.courseCode && course.productCode === prod.productCode,
          ) === -1 &&
          externalTransfers.findIndex(
            (course) => course.courseCode === prod.courseCode && course.productCode === prod.productCode,
          ) === -1,
      );
    }

    const requiredArray = (value: any) => (value && value.length > 0 ? undefined : 'Required');
    return (
      <div>
        <Col xs={10} className="mx-auto">
          {canEditExternalTransfer && (
            <ProductSelect
              transferableProductsPending={transferableProductsPending}
              transferableProductsOptions={transferableProductsOptions}
              handleSelectTransferableCourse={this.handleSelectTransferableCourse}
              productListDefault={productListDefault}
            />
          )}
        </Col>
        {(formValues.courseTransfers.length > 0 || transferableCourses.length > 0) && (
          <Col xs={10} className="mx-auto">
            <Form
              onSubmit={this.handleApprove}
              initialValues={initialFormValues}
              mutators={{
                ...arrayMutators,
              }}
              render={({ handleSubmit, valid, form: { getState } }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <ExternalTransferWrapper>
                    <FieldArray name="courseTransfers" validate={requiredArray}>
                      {({ fields }) => (
                        <div>
                          {canEditExternalTransfer && (
                            <CourseSelect
                              fields={fields}
                              transferableCourses={transferableCoursesFiltered}
                              transferableCoursesPending={transferableCoursesPending}
                              handleAddTransferredCourse={this.handleAddTransferredCourse}
                            />
                          )}
                          {fields.map((courseTransfer, index) => (
                            <Row key={`form-row-${index}`} className="single-course-block">
                              <Col xs={1}>
                                {canEditExternalTransfer && (
                                  <DeleteButton
                                    onClick={() => {
                                      fields.remove(index);
                                      this.handleRemove(fields.value[index]);
                                    }}
                                  >
                                    <FaTrashAlt style={{ color: 'red' }} />
                                  </DeleteButton>
                                )}
                              </Col>
                              <Col xs={3}>
                                <Row>
                                  <Field name={`${courseTransfer}.courseCode`}>
                                    {(props) => <span className="course-label">{`${props.input.value} `}</span>}
                                  </Field>
                                  <span>&nbsp;</span>
                                  <Field name={`${courseTransfer}.courseName`}>
                                    {(props) => <span className="course-label">{props.input.value}</span>}
                                  </Field>
                                </Row>
                                <Row>
                                  <Field name={`${courseTransfer}.productCode`}>
                                    {(props) => <span>{props.input.value}</span>}
                                  </Field>
                                </Row>
                              </Col>
                              <Col xs={2}>
                                <div>Pending</div>
                              </Col>
                              <Col xs={5}>
                                <FieldArray va name={`${courseTransfer}.externalCourses`}>
                                  {({ fields: externalCourses }) => (
                                    <div>
                                      {externalCourses.map(
                                        (course, ind): JSX.Element => (
                                          <div className="single-external-transfer" key={course}>
                                            <Row>
                                              <Col xs={11}>
                                                <Row className="row-codes">
                                                  <Col xs={5}>
                                                    <Field
                                                      name={`${course}.externalCourseCode`}
                                                      displayError
                                                      disabled={!canEditExternalTransfer}
                                                      placeholder="Course Code"
                                                      render={renderTextInput}
                                                      rows={1}
                                                      maxLength={EXTERNAL_COURSE_CODE_MAX_LENGTH}
                                                      validate={composeValidators(
                                                        required,
                                                        minLength(EXTERNAL_COURSE_CODE_MIN_LENGTH),
                                                      )}
                                                    />
                                                    <div className="field-error">
                                                      <Error name={`${course}.externalCourseCode`} />
                                                    </div>
                                                  </Col>
                                                  <Col xs={7}>
                                                    <Field
                                                      name={`${course}.externalCourseName`}
                                                      displayError
                                                      disabled={!canEditExternalTransfer}
                                                      placeholder="Course Name"
                                                      render={renderTextInput}
                                                      rows={1}
                                                      maxLength={EXTERNAL_COURSE_NAME_MAX_LENGTH}
                                                      validate={composeValidators(
                                                        required,
                                                        minLength(EXTERNAL_COURSE_NAME_MIN_LENGTH),
                                                      )}
                                                    />
                                                    <div className="field-error">
                                                      <Error name={`${course}.externalCourseName`} />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col xs={12}>
                                                    <Field
                                                      name={`${course}.provider`}
                                                      placeholder="Institution Name"
                                                      disabled={!canEditExternalTransfer}
                                                      render={renderTextInput}
                                                      rows={1}
                                                      maxLength={EXTERNAL_INSTITUTION_NAME_MAX_LENGTH}
                                                      validate={composeValidators(
                                                        required,

                                                        minLength(EXTERNAL_INSTITUTION_NAME_MIN_LENGTH),
                                                      )}
                                                    />
                                                    <div className="field-error">
                                                      <Error name={`${course}.provider`} />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col xs={1}>
                                                {canEditExternalTransfer && (
                                                  <>
                                                    {ind !== 0 ? (
                                                      <DeleteButton onClick={() => externalCourses.remove(ind)}>
                                                        <FaMinus
                                                          style={{
                                                            color: 'black',
                                                          }}
                                                        />
                                                      </DeleteButton>
                                                    ) : (
                                                      <DeleteButton
                                                        disabled={externalCourses.length && externalCourses.length > 3}
                                                        onClick={() =>
                                                          externalCourses.push({
                                                            externalCourseCode: '',
                                                            externalCourseName: '',
                                                            provider: externalCourses.length
                                                              ? externalCourses.value[externalCourses.length - 1]
                                                                  .provider
                                                              : '',
                                                          })
                                                        }
                                                      >
                                                        <FaPlus
                                                          style={{
                                                            color: 'black',
                                                          }}
                                                        />
                                                      </DeleteButton>
                                                    )}
                                                  </>
                                                )}
                                              </Col>
                                            </Row>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  )}
                                </FieldArray>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                    {canEditExternalTransfer && (
                      <div className="transfer-buttons">
                        <Button
                          disabled={submitPending}
                          variant="primary"
                          onClick={() => {
                            this.handleSave(getState().values);
                          }}
                        >
                          <span>Save</span>
                          {savePending && <Spinner size="sm" animation="border" />}
                        </Button>
                        {canApproveExternalTransfer && (
                          <Button
                            disabled={!valid || submitPending}
                            variant="success"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Approve
                            {approvePending && <Spinner size="sm" animation="border" />}
                          </Button>
                        )}
                      </div>
                    )}
                  </ExternalTransferWrapper>
                </form>
              )}
            />
          </Col>
        )}
        <Col xs={10} className="mx-auto">
          <ApprovedTransfers externalTransfers={externalTransfers} />
        </Col>
      </div>
    );
  }
}

export default TransferCredits;
