import React from 'react';
import styles from './partner-form-field.module.scss';
import { FieldProps, FormField } from '../../form-field/form-field';
import { FormFileField } from '../../form-field/form-file-field';

type Props<T> = FieldProps<T>;

export const PartnerFormField = <T = string,>(props: Props<T>) => (
  <FormField className={styles.field} data-testid={props.name} {...props} />
);

export const PartnerFormFileField = (props: Props<FileList>) => <FormFileField className={styles.field} {...props} />;
