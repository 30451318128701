import React from 'react';
import { FormControl, Button } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import { ProgramStatusProps, ICommonLearanerStatusState, IProps } from './program-status.model';
import ProgramsStatusEditingModal from './program-status-editing-modal/program-status-editing-modal';
import withPermission from '../../helper-components/view-permission.component';
import withLoading from '../../helper-components/loading.component';
import { EditingInput } from './styled-components';

class ProgramStatus extends React.PureComponent<IProps, ICommonLearanerStatusState> {
  constructor(props: ProgramStatusProps) {
    super(props);

    this.state = {
      show: false,
    };
  }

  private handleModal(show: boolean) {
    this.setState({ show });
  }

  public render(): JSX.Element {
    const { programStatus, canEditProgramStatus, programEnrollmentId, learnerId } = this.props;
    const { show } = this.state;

    return (
      <>
        <EditingInput className="xs-6" size="sm">
          <FormControl readOnly aria-describedby="basic-addon2" value={programStatus} size="sm" />
          {canEditProgramStatus && (
            <Button variant="outline-secondary" size="sm" onClick={() => this.handleModal(true)}>
              <FiEdit />
            </Button>
          )}
        </EditingInput>

        <ProgramsStatusEditingModal
          programEnrollmentId={programEnrollmentId}
          learnerId={learnerId}
          show={show}
          handleClose={() => this.handleModal(false)}
        />
      </>
    );
  }
}

export default withLoading(withPermission(connect()(ProgramStatus)));
