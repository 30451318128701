import cn from 'classnames';
import React from 'react';
import styles from './form-element.module.scss';

type Props = {
  /**
   * @default "horizontal"
   *  */
  direction?: 'horizontal' | 'vertical';
  children: React.ReactNode;
  className?: string;
};

export const FormElement = ({ direction = 'horizontal', children, className }: Props) => (
  <div className={cn(styles.container, direction === 'horizontal' ? styles.horizontal : styles.vertical, className)}>
    {children}
  </div>
);

type LabelProps = {
  children: React.ReactNode;
  className?: string;
};

FormElement.Label = ({ children, className }: LabelProps) => (
  <div className={cn(styles.label, className)}>{children}</div>
);

FormElement.Content = ({ children }: { children: React.ReactNode }) => <div className={styles.content}>{children}</div>;
