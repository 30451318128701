import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import api from '../../shared/api/adminUI.api';
import {
  AdmittanceResponse,
  AdmittanceStatusEnum,
  EnglishProficiency,
  RequirementStatus,
  RequirementType,
} from '../../models/LearnerRegistration';
import IError from '../../models/Error';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';

import {
  AdmissionDecisionBlock,
  LearnerRegistrationApplicantBlock,
  LearnerRegistrationHeading,
  LearnerRegistrationStatusBlock,
} from './styled.components';
import IdentityInfo from '../../shared/models/identity-info';
import PersonalDetailsComponent from './personal-details/personal-details';
import PreviousEducationComponent from './previous-education/previous-education';
import VerificationSteps from './verification-steps/verification-steps';
import AdmittanceStatusModal from './admittance-status-modal/admittance-status-modal';
import UndoConfirmationModal from './undo-confirmation-modal/undo-confirmation-modal';
import ApplicantFilesComponent from './applicant-files/applicant-files';
import RegistrationsListComponent from './registrations-list/registrations-list';
import PartnerDetailsComponent from './partner-details/partner-details';
import { useActiveRegistrations } from './hooks/use-active-registrations';
import { RejectAdmissionDecisionModal } from './reject-admission-decision-modal';
import { ApproveAdmissionDecisionForm } from './approve-admission-decision-form/approve-admission-decision-form';
import { convertLocalDateToUniversityDate } from '../../utils/date.utils';

type RequestStateType = 'initial' | 'loading' | 'success' | 'error';

const LearnerRegistration = () => {
  const { applicantId } = useParams<{ applicantId: string }>();

  const [registrationIsEditable, setRegistrationIsEditable] = useState(false);
  const {
    data: activeRegistrations,
    error: activeRegistrationError,
    refetch: refetchActiveRegistration,
    status: activeRegistrationState,
  } = useActiveRegistrations({
    onSuccess(registrations) {
      const admissionDecisionIndex = registrations[0].requirements
        .map((e) => e.requirement)
        .indexOf(RequirementType.AdmissionDecision);
      setRegistrationIsEditable(
        registrations[0].requirements[admissionDecisionIndex - 1]?.status === 'Fulfilled' &&
          registrations[0].status !== 'Completed' &&
          registrations[0].status !== 'Rejected',
      );
    },
  });

  const [verificationComplete, setVerificationComplete] = useState(false);
  const [selectedRegistrationIndex, setSelectedRegistrationIndex] = useState<number>(0);

  const [admittanceStatusState, setAdmittanceStatusState] = useState<RequestStateType>('initial');
  const [admittanceStatus, setAdmittanceStatus] = useState<AdmittanceResponse>();
  const [admittanceStatusError, setAdmittanceStatusError] = useState<IError | null>(null);
  const [admittanceUpdateInProgress, setAdmittanceUpdateInProgress] = useState(false);
  const [admissionDecisionChoice, setAdmissionDecisionChoice] = useState<'approve' | 'reject' | undefined>();
  const [admittanceViewOnly, setAdmittanceViewOnly] = useState(false);

  const [changedProductOnAccept, setChangedProductOnAccept] = useState<string>();

  const [hasEngProficiencyReq, setHasEngProficiencyReq] = useState<boolean | undefined>(false);
  const [engProficiencyState, setEngProficiencyState] = useState<RequestStateType>('initial');
  const [engProficiency, setEngProficiency] = useState<EnglishProficiency>();
  const [engProficiencyError, setEngProficiencyError] = useState<IError | null>(null);

  const [hasLearnerIdentityReq, setHasLearnerIdentityReq] = useState<boolean | undefined>(false);
  const [learnerIdentityState, setLearnerIdentityState] = useState<RequestStateType>('initial');
  const [learnerIdentity, setLearnerIdentity] = useState<IdentityInfo>();
  const [learnerIdentityError, setLearnerIdentityError] = useState<IError | null>(null);

  const fetchAdmittanceState = async (id: string, regId: string, isViewOnly?: boolean) => {
    if (!id || !regId) return;
    try {
      setAdmittanceStatusState('loading');
      const response = await api.learnerRegistration.getAdmittanceStatus(id, regId);
      if (!response.ok) throw await response;
      if (response.status === 204) setAdmittanceStatus(undefined);
      else {
        const parsedResponse = (await response.json()) as AdmittanceResponse;

        if (parsedResponse && parsedResponse.admittanceLearnerData) {
          // admittance returns the same keys as learner-profile API but with different capitalisation
          // Modifying the keys on admittance learner data to align with the other responses
          {
            Object.entries(parsedResponse.admittanceLearnerData).forEach(([key, value]) => {
              const newKey = key.charAt(0).toUpperCase() + key.slice(1);
              // @ts-ignore
              parsedResponse.admittanceLearnerData[newKey] = value;
              // @ts-ignore
              delete parsedResponse.admittanceLearnerData[key];
            });
          }
        }
        setAdmittanceViewOnly(isViewOnly || false);
        setAdmittanceStatus(parsedResponse);
      }
      setAdmittanceStatusState('success');
    } catch (err) {
      setAdmittanceStatusState('error');
      setAdmittanceStatusError(err as IError);
    }
  };

  const fetchApplicantIdentity = async (id: string) => {
    try {
      setLearnerIdentityState('loading');
      const response = await api.learnerRegistration.getIdentityInfo(id);
      if (!response.ok) throw await response;
      if (response.status === 204) {
        setLearnerIdentity(undefined);
        setVerificationComplete(false);
      } else {
        const parsedResult = (await response.json()) as IdentityInfo;
        setLearnerIdentity(parsedResult);
        setVerificationComplete(parsedResult.process.processComplete);
      }
      setLearnerIdentityState('success');
    } catch (err) {
      setLearnerIdentityState('error');
      setLearnerIdentityError(err as IError);
    }
  };

  const fetchEnglishProficiency = async (id: string) => {
    try {
      setEngProficiencyState('loading');
      const response = await api.learnerRegistration.getEnglishProficiency(id);
      if (!response.ok) throw await response;
      if (response.status === 204) setEngProficiency(undefined);
      else setEngProficiency((await response.json()) as EnglishProficiency);
      setEngProficiencyState('success');
    } catch (err) {
      setEngProficiencyState('error');
      setEngProficiencyError(err as IError);
    }
  };

  useEffect(() => {
    const loadApplicantIdentity = async (): Promise<void> => {
      if (applicantId && activeRegistrations?.length) {
        const currentRequirements = activeRegistrations[selectedRegistrationIndex];

        await fetchAdmittanceState(
          applicantId,
          currentRequirements.supersederRegistrationId || currentRequirements.registrationId,
          !!currentRequirements.supersededByRegistrationId || selectedRegistrationIndex > 1,
        );

        const hasIdentityReq = currentRequirements?.requirements.find(
          (r) => r.requirement === RequirementType.GovIdentity,
        );
        setHasLearnerIdentityReq(!!hasIdentityReq);
        if (hasIdentityReq) await fetchApplicantIdentity(applicantId);

        const hasEnglishProficiencyReq = currentRequirements?.requirements.find(
          (r) => r.requirement === RequirementType.EnglishProficiency,
        );
        setHasEngProficiencyReq(!!hasEnglishProficiencyReq);
        if (hasEnglishProficiencyReq) await fetchEnglishProficiency(applicantId);
      }
    };
    loadApplicantIdentity();
  }, [applicantId, activeRegistrations, selectedRegistrationIndex]);

  const submitAdmittanceUndo = async (): Promise<void> => {
    if (!activeRegistrations?.[selectedRegistrationIndex] || !applicantId) return;
    setAdmittanceUpdateInProgress(true);
    const regId =
      activeRegistrations?.[selectedRegistrationIndex].supersederRegistrationId ||
      activeRegistrations?.[selectedRegistrationIndex].registrationId;
    try {
      const response = await api.learnerRegistration.undoAdmittance(applicantId, regId);
      if (!response.ok) throw await response;
      else {
        await refetchActiveRegistration();
        const currentRequirements = activeRegistrations[selectedRegistrationIndex];
        await fetchAdmittanceState(
          applicantId,
          currentRequirements.supersederRegistrationId || currentRequirements.registrationId,
          !!currentRequirements.supersededByRegistrationId,
        );
      }
      setAdmittanceUpdateInProgress(false);
    } catch (err) {
      setAdmittanceUpdateInProgress(false);
      setAdmittanceStatusError(err as IError);
    }
  };

  const handleRejectAdmission = async () => {
    await fetchAdmittanceState(applicantId, activeRegistration!.registrationId);
    setAdmissionDecisionChoice(undefined);
  };

  const handleApproveAdmission = async (newProductCode: string, isCodeChanged: boolean) => {
    await fetchAdmittanceState(applicantId, activeRegistration!.registrationId);
    if (isCodeChanged) {
      setChangedProductOnAccept(newProductCode);
    }
  };

  const RegistrationStatusMsg = () => {
    if (activeRegistrationState === 'loading' || activeRegistrationState === 'error') return null;
    if (admittanceStatusState === 'initial' || admittanceStatusState === 'loading') return null;
    if (!activeRegistrations?.[selectedRegistrationIndex])
      return <p>This applicant doesn&apos;t have any associated registrations</p>;

    const currentRequirements = activeRegistrations[selectedRegistrationIndex];
    const { productCode } = activeRegistrations[selectedRegistrationIndex];

    const admissionDecision = currentRequirements?.requirements.find(
      (r) => r.requirement === RequirementType.AdmissionDecision,
    );
    const admissionDecisionIndex = currentRequirements?.requirements
      .map((e) => e.requirement)
      .indexOf(RequirementType.AdmissionDecision);
    const admissionStatus = admittanceStatus?.status;

    if (changedProductOnAccept) {
      return (
        <p>
          {/* eslint-disable-next-line max-len */}
          Registration for <strong>{productCode}</strong> has been switched to <strong>{changedProductOnAccept}</strong>{' '}
          and accepted
        </p>
      );
    }

    if (currentRequirements.status === 'Superseded') {
      const replacementProduct = activeRegistrations?.find(
        (i) => i.registrationId === currentRequirements.supersededByRegistrationId,
      );
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been switched to{' '}
          <strong>{replacementProduct?.productCode}</strong>
        </p>
      );
    }

    // rejection has priority over pending
    if (admissionStatus === AdmittanceStatusEnum.Rejected) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been rejected
        </p>
      );
    }

    const hasPending = currentRequirements?.requirements.some(
      (r, i) => r.status === RequirementStatus.Pending && i < admissionDecisionIndex,
    );

    if (hasPending) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> is still in progress and doesn&apos;t require any Admission
          Decision to be made
        </p>
      );
    }

    if (admissionStatus === AdmittanceStatusEnum.Accepted) {
      if (currentRequirements.supersederRegistrationId) {
        const replacedProduct = activeRegistrations?.find(
          (i) => i.registrationId === currentRequirements.supersederRegistrationId,
        );
        return (
          <p>
            Registration for <strong>{productCode}</strong> has been accepted and replaced{' '}
            <strong>{replacedProduct?.productCode}</strong>
          </p>
        );
      }
      return (
        <p>
          Registration for <strong>{productCode}</strong> has been accepted
        </p>
      );
    }

    if (!admissionDecision || (admissionDecision && !admissionStatus)) {
      return (
        <p>
          This registration for <strong>{productCode}</strong> doesn&apos;t require any further Admission Decision to be
          made
        </p>
      );
    }

    const identityProcessComplete = learnerIdentity?.process?.processComplete;

    if (!identityProcessComplete) {
      return (
        <p>
          Registration for <strong>{productCode}</strong> requires Identity Checks to be complete before an Admission
          Decision can be taken
        </p>
      );
    }

    return (
      <p>
        Registration for <strong>{productCode}</strong> is Ready for Review
      </p>
    );
  };

  const UndoAdmittanceCta = () => {
    if (
      admittanceViewOnly ||
      (admittanceStatus?.status !== AdmittanceStatusEnum.Accepted &&
        admittanceStatus?.status !== AdmittanceStatusEnum.Rejected)
    )
      return null;
    const currentRequirements = activeRegistrations?.[selectedRegistrationIndex];
    const paymentComplete = currentRequirements?.requirements.find((r) => r.requirement === RequirementType.TuitionFee);
    if (currentRequirements?.status === 'Completed' || paymentComplete?.status === 'Fulfilled') return null;

    return (
      <div className="d-flex justify-content-center">
        <UndoConfirmationModal
          admittanceStatus={admittanceStatus!}
          disabled={admittanceUpdateInProgress}
          undoEvent={submitAdmittanceUndo}
        />
      </div>
    );
  };

  const activeRegistration = activeRegistrations?.[selectedRegistrationIndex];

  const RegistrationItemBlock = () => (
    <LearnerRegistrationStatusBlock>
      <h3>Registration Status</h3>
      <WithLoading
        loading={activeRegistrationState === 'loading'}
        loadingText="Loading registration list for applicant"
      >
        <WithErrorHandling small error={activeRegistrationError as IError}>
          <WithLoading
            loading={admittanceStatusState === 'initial' || admittanceStatusState === 'loading'}
            loadingText="Loading admittance status for registration"
          >
            <RegistrationStatusMsg />
            <WithErrorHandling small error={admittanceStatusError}>
              <UndoAdmittanceCta />
              {verificationComplete && admittanceStatus?.status === AdmittanceStatusEnum.Initiated && (
                <AdmissionDecisionBlock>
                  <ButtonGroup className="mb-2">
                    <ToggleButton
                      key="1"
                      id="radio-1"
                      type="radio"
                      variant={admissionDecisionChoice === 'approve' ? 'success' : 'secondary'}
                      name="radio"
                      value="approve"
                      checked={admissionDecisionChoice === 'approve'}
                      onChange={(e: any) => {
                        setAdmissionDecisionChoice(e.currentTarget.value);
                      }}
                    >
                      Approve
                    </ToggleButton>
                    <ToggleButton
                      key="2"
                      id="radio-2"
                      type="radio"
                      variant={admissionDecisionChoice === 'reject' ? 'success' : 'secondary'}
                      name="radio"
                      value="reject"
                      checked={admissionDecisionChoice === 'reject'}
                      onChange={(e: any) => {
                        setAdmissionDecisionChoice(e.currentTarget.value);
                        setRegistrationIsEditable(false);
                      }}
                    >
                      Reject
                    </ToggleButton>
                  </ButtonGroup>
                  {!admissionDecisionChoice && <p>Select whether to accept or reject the application</p>}
                  <RejectAdmissionDecisionModal
                    applicantId={applicantId}
                    show={admissionDecisionChoice === 'reject'}
                    activeRegistration={activeRegistration!}
                    onHide={() => setAdmissionDecisionChoice(undefined)}
                    onSuccess={handleRejectAdmission}
                  />
                  {admissionDecisionChoice === 'approve' && (
                    <ApproveAdmissionDecisionForm
                      activeRegistration={activeRegistration!}
                      applicantId={applicantId}
                      onSuccess={handleApproveAdmission}
                    />
                  )}
                </AdmissionDecisionBlock>
              )}
            </WithErrorHandling>
            <PartnerDetailsComponent
              partnerId={activeRegistrations && activeRegistrations[selectedRegistrationIndex]?.partnerId}
            />
            {!!admittanceStatus && (
              <LearnerRegistrationApplicantBlock>
                <h3>Admission Decision</h3>
                <ul>
                  {!!admittanceStatus.admittanceLearnerData && (
                    <li>
                      <span>Registration Details:</span> <AdmittanceStatusModal admittanceStatus={admittanceStatus} />
                    </li>
                  )}
                  <li>
                    <span>Hubspot Ticket:</span>
                    {admittanceStatus?.decisionTicketUrl ? (
                      <a href={admittanceStatus.decisionTicketUrl} target="_blank" rel="noopener noreferrer">
                        {admittanceStatus.decisionTicketUrl}
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </li>
                  {(admittanceStatus.status === AdmittanceStatusEnum.Accepted ||
                    admittanceStatus.status === AdmittanceStatusEnum.Rejected) && (
                    <li>
                      <span>Reviewed:</span> {admittanceStatus?.decisionMadeBy} (
                      <>
                        {convertLocalDateToUniversityDate(admittanceStatus?.decisionDate)?.format(
                          'DD MMM YYYY hh:mm A',
                        )}
                      </>
                      )
                    </li>
                  )}
                  {admittanceStatus.status === AdmittanceStatusEnum.Rejected && !!admittanceStatus.comment?.length && (
                    <li>
                      <span>Comment:</span> {admittanceStatus?.comment}
                    </li>
                  )}
                  {admittanceStatus.status === AdmittanceStatusEnum.Accepted &&
                    !!admittanceStatus.missingAepDocuments && (
                      <li>
                        <span>AEP:</span> {admittanceStatus.missingAepDocuments?.length > 0 ? 'Pending' : 'Completed'} (
                        <>
                          {convertLocalDateToUniversityDate(admittanceStatus?.aepDocumentUpdatedDate!)?.format(
                            'DD MMM YYYY hh:mm A',
                          )}
                        </>
                        )
                      </li>
                    )}
                  {admittanceStatus.status === AdmittanceStatusEnum.Accepted &&
                    !!admittanceStatus.aepDocuments?.length && (
                      <>
                        {admittanceStatus.aepDocuments.map((item, index) => (
                          <li key={index}>
                            <span>{item.name}:</span>
                            {item.status}
                          </li>
                        ))}
                      </>
                    )}
                </ul>
              </LearnerRegistrationApplicantBlock>
            )}
          </WithLoading>
        </WithErrorHandling>
        {hasLearnerIdentityReq && (
          <LearnerRegistrationApplicantBlock>
            <h3>Verification Steps</h3>
            <WithLoading
              loading={learnerIdentityState === 'initial' || learnerIdentityState === 'loading'}
              loadingText="Loading Verification steps for applicant"
            >
              <WithErrorHandling small error={learnerIdentityError}>
                {learnerIdentity && (
                  <VerificationSteps
                    refreshVerificationSteps={fetchApplicantIdentity}
                    applicantId={applicantId || ''}
                    canEditDocuments={registrationIsEditable}
                    learnerIdentity={learnerIdentity}
                  />
                )}
              </WithErrorHandling>
            </WithLoading>
          </LearnerRegistrationApplicantBlock>
        )}

        {hasEngProficiencyReq && (
          <LearnerRegistrationApplicantBlock>
            <h3>English Proficiency</h3>
            <WithLoading
              loading={engProficiencyState === 'initial' || engProficiencyState === 'loading'}
              loadingText="Loading English Proficiency for applicant"
            >
              <WithErrorHandling small error={engProficiencyError}>
                {!engProficiency && <p>Applicant hasn&apos;t submitted their english proficiency yet</p>}
                {engProficiency && (
                  <ul>
                    <li>
                      <span>Native English Speaker:</span> {engProficiency?.IsNativeEnglishSpeaker ? 'Yes' : 'No'}
                    </li>
                    {!engProficiency?.IsNativeEnglishSpeaker && (
                      <li>
                        <span>Primary Education was in English:</span>{' '}
                        {engProficiency?.IsEnglishPrimaryEducationLanguage ? 'Yes' : 'No'}
                      </li>
                    )}
                  </ul>
                )}
              </WithErrorHandling>
            </WithLoading>
          </LearnerRegistrationApplicantBlock>
        )}
      </WithLoading>
    </LearnerRegistrationStatusBlock>
  );

  return (
    <div>
      <LearnerRegistrationHeading>
        <h1>Learner Registration</h1>
        <Button as={Link as any} to={`/learners/${applicantId}`} variant="success">
          Learner Record
        </Button>
      </LearnerRegistrationHeading>
      <RegistrationsListComponent
        activeRegistrations={activeRegistrations}
        activeRegistrationState={activeRegistrationState}
        selectedRegistrationIndex={selectedRegistrationIndex}
        setSelectedRegistrationIndex={setSelectedRegistrationIndex}
      />
      <RegistrationItemBlock />
      <PersonalDetailsComponent canEditPersonal={registrationIsEditable} />
      <PreviousEducationComponent canEditEducation={registrationIsEditable} />
      <ApplicantFilesComponent />
    </div>
  );
};

export default LearnerRegistration;
