import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Limits, MaxCourseNumberContainer, MaxCourseNumberLabel, ValidationError } from './styled-components';
import errorHandling from '../../helper-components/alert-component.component';
import IError from '../../../models/Error';
import { request } from 'shared/api/fetch';

export interface MaxCoursesModel {
  LearnerId: string;
  MaxSimultaneousCourses: number;
  UnlimitedSimultaneousCourses: number;
  LimitText: string;
  LimitedBy: string[];
}

export interface MaxCoursesPermissionObject {
  canEditLearnerMaxSimultaneousCourses: boolean;
}

interface Props {
  learnerId: string;
}

const MaxCourseNumber: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validationError, setValidationError] = useState(false);
  const [newMaxSimultaneousCourses, setNewMaxSimultaneousCourses] = useState(0);
  const [canEdit, setCanEdit] = useState(true);
  const [model, setModel] = useState<MaxCoursesModel>({
    LearnerId: '',
    MaxSimultaneousCourses: 0,
    UnlimitedSimultaneousCourses: 0,
    LimitText: '',
    LimitedBy: [],
  });

  useEffect(() => {
    async function getMaxCourses(learnerId: string) {
      try {
        const data = await request.get<MaxCoursesModel>(
          `/api/course-load/learners/${learnerId}/max-simultaneous-courses`,
        );
        setModel(data);
        setLoading(false);
      } catch (error: any) {
        errorHandling({ error: error as IError });
      }
    }

    async function getPermissions() {
      try {
        const permissionObject = await request.get<MaxCoursesPermissionObject>('/api/course-load/permissions');
        setCanEdit(permissionObject.canEditLearnerMaxSimultaneousCourses);
      } catch (error: any) {
        errorHandling({ error: error as IError });
      }
    }

    async function getData(learnerId: string) {
      getMaxCourses(learnerId);
      getPermissions();
    }

    getData(props.learnerId);
  }, [props.learnerId]);

  const edit = () => {
    setNewMaxSimultaneousCourses(model.UnlimitedSimultaneousCourses);
    setEditing(true);
  };

  const cancel = () => {
    setEditing(false);
    setValidationError(false);
  };

  const save = async () => {
    const validation = new RegExp('^[0-9]$');
    if (!validation.test(newMaxSimultaneousCourses.toString())) {
      setValidationError(true);
      return;
    }
    cancel();

    try {
      const { learnerId } = props;
      const data = await request.put<MaxCoursesModel>(
        `/api/course-load/learners/${learnerId}/max-simultaneous-courses`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            MaxSimultaneousCourses: newMaxSimultaneousCourses,
          }),
        },
      );
      setModel(data);
    } catch (error: any) {
      errorHandling({ error: error as IError });
    }
  };

  const onNewMaxSimultaneousCoursesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    setNewMaxSimultaneousCourses(newValue);
  };

  if (editing) {
    return (
      <MaxCourseNumberContainer>
        <MaxCourseNumberLabel>Max courses:</MaxCourseNumberLabel>
        <input
          type="number"
          required
          min="1"
          max="9"
          value={newMaxSimultaneousCourses}
          onChange={onNewMaxSimultaneousCoursesChange}
        />
        <Button variant="success" onClick={save}>
          Save
        </Button>
        <Button variant="warning" onClick={cancel}>
          Cancel
        </Button>
        {model.LimitText && <Limits>{model.LimitText}</Limits>}
        {validationError && <ValidationError>Range is 1 to 9</ValidationError>}
      </MaxCourseNumberContainer>
    );
  }

  const maxCourses = loading ? '...' : model.UnlimitedSimultaneousCourses;

  return (
    <MaxCourseNumberContainer>
      <MaxCourseNumberLabel>{`Max courses: ${maxCourses}`}</MaxCourseNumberLabel>
      {!loading && canEdit && (
        <Button variant="info" onClick={edit}>
          {' '}
          Edit{' '}
        </Button>
      )}
      {model.LimitText && <Limits>{model.LimitText}</Limits>}
    </MaxCourseNumberContainer>
  );
};

export default MaxCourseNumber;
