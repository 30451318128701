import moment from 'moment';
import { find } from 'lodash';
import { isEmptyValue } from '../../../../utils/validate.utils';
import { createOptions } from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import { ISelectOption, IOption } from '../../../../models/SelectOptions';
import { DEFERRED_STATUS, LOA_STATUS } from './program-status-editing-modal.constants';
import { ProgramStatusValue } from './programs-status-editing-modal.model';
import { IProgramStatusOption } from '../../../../shared/reducers/learner-info-editing-modal';

const TODAYS_DAY = moment().toDate();

export const isDeferredOrEmpty = (option: ISelectOption) => !isEmptyValue(option) || option.value === DEFERRED_STATUS;

export const createReasonOptions = (reasonSelectOptions: IOption[]) =>
  createOptions(reasonSelectOptions.filter((option) => option.available));

export const isOutdated = (returnDate: string | Date) => moment(returnDate).isBefore(TODAYS_DAY);

export const createSubStatusOptions = (programStatus: ISelectOption, restrictions: IProgramStatusOption[]) => {
  const currentRestriction = find(
    restrictions,
    (restriction: IProgramStatusOption) => restriction.programStatus.id === programStatus.value,
  );
  if (currentRestriction) {
    return currentRestriction.subStatuses;
  }
  return [];
};

export const createProgramStatusOptions = (options: IOption[], currentProgramStatus: ISelectOption) => {
  if (currentProgramStatus.value !== ProgramStatusValue.Enrolled) {
    const optionsWithoutEnrooledStatus = options.filter((option: IOption) => option.id !== ProgramStatusValue.Enrolled);
    return createOptions(optionsWithoutEnrooledStatus);
  }
  return createOptions(options);
};

export const dateDropdownColourStyles = {
  option: (styles: React.CSSProperties) => ({
    ...styles,
    color: 'black!important',
  }),
};

export const showStartDateField = (programStatusValue: string) =>
  programStatusValue === DEFERRED_STATUS || programStatusValue === LOA_STATUS;
