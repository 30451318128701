import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';
import EventPropComment from './utils/event-prop-comment';
import EventPropStartDate from './utils/event-prop-start-date';

export const unenrolledFromCourseSelector: (event: IEvent) => IEventDetails = (event: IEvent) => ({
  metadata: {
    type: {
      category: 'Course Enrollment',
      eventName: 'Unenrolled from Course',
    },
    date: event.updatedDateTime,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventProp name="Course Code" value={event.courseCode} primary />
      <EventPropStartDate value={event.startDate} />
      <EventProp name="Status" value={event.status} />
      <EventProp name="Substatus" value={event.subStatus} />
      <EventPropComment value={event.comment} />
    </Row>
  );
};
