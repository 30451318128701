import React from 'react';
import { ICustomEventProps, IEvent, IEventDetails } from '../learner-history-model';

const toFriendly: (eventKeyPart: string) => string = (eventKeyPart: string) => {
  const splitString = eventKeyPart.split(/(?=[A-Z])/);
  if (splitString[splitString.length - 1] === 'Event') {
    splitString.pop();
  }

  return splitString.length === eventKeyPart.length ? eventKeyPart : splitString.join(' ');
};

export const genericEventSelector: (event: IEvent) => IEventDetails = (event: IEvent) => {
  const eventKeySplit = event.eventKey.split('.');
  const category = toFriendly(eventKeySplit[0]);
  const eventName = eventKeySplit.length > 1 ? toFriendly(eventKeySplit[1]) : null;

  return {
    metadata: {
      type: { category, eventName },
      date: event.updatedDateTime,
    },
    element: <GenericEvent event={event} />,
  };
};

const GenericEvent: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return <>{event.eventKey}</>;
};
