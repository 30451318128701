import React from 'react';
import { FormApi } from 'final-form';
import { Button, Modal } from 'react-bootstrap';
import { CLOSE, SAVE_CHANGES } from '../../constants/common-constants';

interface ISaveChangesDialog {
  show: boolean;
  form: FormApi<any>;
  formValid: boolean;
  text: string;
  formResetNeeded: boolean;
  closeModals: (form: FormApi<any>, formResetNeeded: boolean) => void;
  handleSubmit: () => void;
}

const SaveChangesDialog: React.FunctionComponent<ISaveChangesDialog> = (props: ISaveChangesDialog) => {
  const { show, closeModals, handleSubmit, form, formValid, text, formResetNeeded } = props;
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Save changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>{`${text}`}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModals(form, formResetNeeded)}>
          {CLOSE}
        </Button>
        <Button variant="primary" onClick={() => handleSubmit()} disabled={!formValid}>
          {SAVE_CHANGES}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SaveChangesDialog;
