import { useQuery } from '@tanstack/react-query';
import { usePartnerAgreementsParams } from './use-params';
import { getLatestAgreement } from 'shared/api/partner';

export const useLatestAgreement = () => {
  const { partnerId } = usePartnerAgreementsParams();

  return useQuery({
    queryKey: ['partner', partnerId, 'latest-agreement'],
    queryFn: () => getLatestAgreement(partnerId),
    suspense: true,
  });
};
