import { PublicClientApplication } from '@azure/msal-browser';

export const msal = new PublicClientApplication({
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTHENTICATION_TENANT_ID}`,
    clientId: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID!,
    redirectUri: `${window.location.origin}/`,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
  },
});

export const getBearerToken = async () => {
  const loginRequest = {
    scopes: [process.env.REACT_APP_AUTHENTICATION_ADMIN_API_SCOPE!],
  };

  const accounts = msal!.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const account = await msal!.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return account.accessToken;
    } catch (error: any) {
      await msal!.acquireTokenRedirect({
        scopes: [process.env.REACT_APP_AUTHENTICATION_ADMIN_API_SCOPE!],
      });
      throw error;
    }
  }
  throw new Error('Not Authenticated');
};
