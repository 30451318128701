import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Row, Col, Spinner, Card } from 'react-bootstrap';
import { AnyAction } from 'redux';
import { Link } from 'react-router-dom';
import { IRootState } from '../../shared/reducers';
import {
  IDispatchProps,
  IAdmissionsState,
  ISelectedAdmissionOption,
  IPermissionObject,
  AdminSubmitIdentityId,
  IVoucherBlock,
  ICommonProps,
  INewFieldValue,
  IAdmissionsStateProps,
  AEPDocument,
  AdminSubmitIdentityIdV2,
  IIdentityInfo,
  DocumentInfo,
} from './admissions.model';
import ApplicantFiles from '../learner-files/learner-files';
import {
  AdmissionsContainer,
  PersonalDetailsCard,
  StyledWrapper,
  StyledLink,
  StyledLoadingBlock,
  StyledLoadingText,
  StyledApplicantFiles,
  AdmissionBlock,
} from './styled-components';
import { WithLoading } from '../helper-components/loading.component';
import { WithPermissions } from '../helper-components/view-permission.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import { formatDate } from '../helper-components/form-components/form-filed-components/form-filed.components';
import { getApplicantInfo } from './admissions-functions';
import errorHandling from '../helper-components/alert-component.component';
import api from '../../shared/api/adminUI.api';

import {
  updateApplicationVoucherCodeFieldValue,
  updateTuitionVoucherCodeFieldValue,
  getVoucherCodeInfo,
} from '../../shared/actions/voucher-code.action';
import AdmissionsForm from './admissions-form/admissions-form';
import ApplicationInfo from './admissions-form/application-info';
import { BoldColumn } from '../learner-info/styled-components';
import FieldEditor from './editing-component/editing-component';
import { getVoucherData, getVoucherDataError } from '../../shared/selectors/voucher-code.selector';
import { getApplicantEditableInfoState } from '../../shared/selectors/applicant-editable-info.selector';
import {
  getApplicantEditableInfo,
  updateApplicantProfileFieldValue,
} from '../../shared/actions/applicant-editable-info.action';
import PersonalDataBasedOnProgramEditable from './applicant-editable-info/applicant-editable-info';

import { APPLICANT_STATUS } from '../constants/common-constants';
import { ACCESS_DENIED_TEXT } from '../learning-path/learning-path.constants';
import { toLowerCaseProps } from '../../utils/json';
import { StyledCard, StyledResult } from './admissions-form/styled-components';

const Program: React.FC<{
  productCode: string;
  productType: string;
}> = (props: { productCode: string; productType: string }): JSX.Element => {
  const { productCode, productType } = props;
  return (
    <StyledWrapper>
      <h3>Program</h3>
      <span>
        {productCode} {productType}
      </span>
    </StyledWrapper>
  );
};

const EnrollmentAgreement: React.FC<{ status: string }> = (props: { status: string }): JSX.Element => {
  const { status } = props;
  return status !== 'Enrolled' ? (
    <></>
  ) : (
    <>
      <h3>Enrollment</h3>
      <PersonalDetailsCard>
        <Row>
          <Col xs={6}>
            <span>Agreement:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>✔ Signed</span>
          </BoldColumn>
        </Row>
      </PersonalDetailsCard>
    </>
  );
};

const AdmissionDecision: React.FC<{
  reviewedBy: string;
  reviewedTime: Date | null;
  comments: string;
  AEP: boolean | null;
  aepDocuments: AEPDocument[];
  acceptanceType: string;
  foundationCoursesCsv: string;
}> = (props: {
  reviewedBy: string;
  reviewedTime: Date | null;
  comments: string;
  AEP: boolean | null;
  aepDocuments: AEPDocument[];
  acceptanceType: string;
  foundationCoursesCsv: string;
}): JSX.Element => {
  const { reviewedBy, reviewedTime, comments, AEP, aepDocuments, acceptanceType, foundationCoursesCsv } = props;
  return !acceptanceType ? (
    <></>
  ) : (
    <StyledWrapper>
      <h3>Admission Decision</h3>
      <AdmissionBlock>
        <PersonalDetailsCard>
          <Row>
            <Col xs={6}>
              <span>AcceptanceType:</span>
            </Col>
            <BoldColumn xs={6}>
              <span>{acceptanceType}</span>
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Comments:</span>
            </Col>
            <BoldColumn xs={6}>
              <span>{comments}</span>
            </BoldColumn>
          </Row>
          <Row>
            <Col xs={6}>
              <span>Reviewed:</span>
            </Col>
            <BoldColumn xs={6}>
              <span>
                {reviewedBy} {reviewedTime && formatDate(reviewedTime, 'DD MMM YYYY hh:mm A')}
              </span>
            </BoldColumn>
          </Row>
          {foundationCoursesCsv && (
            <Row>
              <Col xs={6}>
                <span>Foundation Courses:</span>
              </Col>
              <BoldColumn xs={6}>
                <span>{foundationCoursesCsv}</span>
              </BoldColumn>
            </Row>
          )}
          {AEP === true && (
            <Row>
              <Col xs={6}>
                <span>AEP:</span>
              </Col>
              <BoldColumn xs={6}>
                <span>AEP</span>
              </BoldColumn>
            </Row>
          )}
          {AEP &&
            aepDocuments &&
            aepDocuments.map((document) => (
              <Row key={document.name}>
                <Col xs={6}>
                  <span>{document.name}:</span>
                </Col>
                <BoldColumn xs={6}>
                  <span>{document.status}</span>
                </BoldColumn>
              </Row>
            ))}
        </PersonalDetailsCard>
      </AdmissionBlock>
    </StyledWrapper>
  );
};

const AdmissionDecisionTicket: React.FC<{ url: string }> = (props: { url: string }): JSX.Element => {
  const { url } = props;
  return !url ? (
    <></>
  ) : (
    <StyledWrapper>
      <h3>Admission Decision Ticket</h3>
      <PersonalDetailsCard>
        <Row>
          <Col xs={6}>
            <span>Hubspot Ticket:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </span>
          </BoldColumn>
        </Row>
      </PersonalDetailsCard>
    </StyledWrapper>
  );
};

const AdmissionVoucherBlock: React.FC<IVoucherBlock> = (props: IVoucherBlock): JSX.Element => {
  const {
    canEditVoucherCodes,
    voucherData: { TuitionFeeVoucherCode, ApplicationFeeVoucherCode },
    handleApplicationFieldValueChange,
    handleTuitionFieldValueChange,
  } = props;
  // @ts-ignore

  return !canEditVoucherCodes ? (
    <></>
  ) : (
    <>
      <h3>Voucher Codes </h3>
      <PersonalDetailsCard>
        <Row>
          <Col xs={6}>
            <span>Application Fee:</span>
          </Col>
          <BoldColumn xs={6}>
            <FieldEditor
              oldValue={ApplicationFeeVoucherCode.Value}
              propertyName="ApplicationFeeVoucherCode"
              changedFieldUrl="application-fee"
              canEdit={ApplicationFeeVoucherCode.IsEditable}
              handleNewValue={handleApplicationFieldValueChange}
              fieldType="input"
            />
          </BoldColumn>
        </Row>

        <Row>
          <Col xs={6}>
            <span>Tuition Fee:</span>
          </Col>
          <BoldColumn xs={6}>
            <FieldEditor
              oldValue={TuitionFeeVoucherCode.Value}
              propertyName="TuitionFeeVoucherCode"
              changedFieldUrl="tuition-fee"
              canEdit={TuitionFeeVoucherCode.IsEditable}
              handleNewValue={handleTuitionFieldValueChange}
              fieldType="input"
            />
          </BoldColumn>
        </Row>
      </PersonalDetailsCard>
    </>
  );
};

class Admissions extends React.Component<ICommonProps, IAdmissionsState> {
  constructor(props: ICommonProps) {
    super(props);
    this.state = {
      permissionObject: {
        readApplications: false,
        makeApplicationDecisions: false,
        canEditVoucherCodes: false,
        canAddFxRate: false,
      },
      admissionDataPending: true,
      error: null,
      applicantInfo: {
        AEPDocuments: [],
        ProductType: '',
        ProductCode: '-',
        Status: '',
        AdmissionTicketUrl: '',
        ReviewedBy: '',
        ReviewedTime: null,
        Comments: '',
        AEP: null,
        AEPEnglishProficiencyStatus: '',
        AEPTranscriptStatus: '',
        AcceptanceType: '',
        FoundationCoursesCsv: '',
        PriorEducationDoc1: '',
        PriorEducationDoc2: '',
        EnglishEvidenceOption: '',
        EnglishCertificate: '',
        EnglishIndividualEvaluation: '',
        EfsetScore: null,
        ApplicationSource: null,
      },
      admissionData: {
        ApplicationId: '',
        LearnerId: '',
        ProductType: '',
        ProductCode: '',
        ProductList: [],
        FoundationCourses: {
          MBA: [],
        },
      },
      identityInfo: null,
      aepDocumentsList: [],
    };
  }

  public componentDidMount() {
    const { match, getApplicantEditableInfoData } = this.props;
    const { applicantId } = match.params;
    this.getAdmissionPermission(applicantId);
    getApplicantEditableInfoData(applicantId);
  }

  private handleApplicantProfileValueChange = async (
    newValue: INewFieldValue,
    changedFieldUrl: string,
  ): Promise<void> => {
    const { setApplicantProfileFieldValue, match } = this.props;
    const { applicantId } = match.params;
    // const { setNewLearnerProfileFieldValue } = this.props;
    await setApplicantProfileFieldValue(applicantId, newValue, changedFieldUrl);
  };

  private handleApplicationFieldValueChange = async (newValue: INewFieldValue): Promise<void> => {
    try {
      const { setApplicationVoucherCodeFieldValue, match } = this.props;
      const { applicantId } = match.params;
      await setApplicationVoucherCodeFieldValue(applicantId, newValue);
    } catch (error: any) {
      errorHandling({ error });
    }
  };

  private handleTuitionFieldValueChange = async (newValue: INewFieldValue): Promise<void> => {
    try {
      const { setTuitionVoucherCodeFieldValue, match } = this.props;
      const { applicantId } = match.params;
      await setTuitionVoucherCodeFieldValue(applicantId, newValue);
    } catch (error: any) {
      errorHandling({ error });
    }
  };

  private readonly getAdmissionPermission = async (applicantId: string): Promise<void> => {
    try {
      const { getVoucherCodeData } = this.props;
      const response = await api.admissions.getAdmissionPermission();
      if (!response.ok) throw await response;

      const permissionObject = (await response.json()) as IPermissionObject;

      this.setState({ permissionObject });
      if (permissionObject.readApplications) {
        this.loadAdmissionData(applicantId);
      } else {
        this.setState({
          admissionDataPending: false,
        });
      }

      if (permissionObject.canEditVoucherCodes) {
        getVoucherCodeData(applicantId);
      }
    } catch (error: any) {
      errorHandling({ error });
      this.setState({
        error,
        admissionDataPending: false,
      });
    }
  };

  private readonly loadAdmissionData = async (applicantId: string): Promise<void> => {
    const { permissionObject } = this.state;
    let admissionDataResponsePromise: Promise<Response> | null = null;
    let aepDocumentOptionsResponsePromise: Promise<Response> | null = null;

    try {
      this.setState({
        admissionDataPending: true,
      });

      if (permissionObject.makeApplicationDecisions) {
        admissionDataResponsePromise = api.admissions.getAdmissionData(applicantId);
        aepDocumentOptionsResponsePromise = api.aepDocuments.aepDocumentOptions();
      }

      const applicantInfo = await getApplicantInfo(applicantId);

      const identityInfoResponse = await api.learnerIdentity.getIdentityInfo(applicantId);
      const { status } = identityInfoResponse;
      if (status !== 200 && status !== 404) throw await identityInfoResponse;
      const identityInfo = status === 200 ? toLowerCaseProps(await identityInfoResponse.json()) : null;
      if (admissionDataResponsePromise) {
        const admissionDataResponse = await admissionDataResponsePromise;
        if (!admissionDataResponse.ok) throw await admissionDataResponse;
        const admissionData = await admissionDataResponse.json();
        this.setState({ admissionData });
      }

      if (aepDocumentOptionsResponsePromise) {
        const aepDocumentOptionsResponse = await aepDocumentOptionsResponsePromise;
        if (!aepDocumentOptionsResponse.ok) throw await aepDocumentOptionsResponse;
        const aepDocumentsList = await aepDocumentOptionsResponse.json();
        this.setState({ aepDocumentsList });
      }

      this.setState({
        admissionDataPending: false,
        applicantInfo,
        identityInfo,
      });
    } catch (error: any) {
      errorHandling({ error });
      const admissionError = error.status === 404 ? { status: 404, statusText: 'Applicant not found' } : error;
      this.setState({
        admissionDataPending: false,
        error: admissionError,
      });
    }
  };

  private readonly saveIdentityId = async (applicantId: string, identityId: AdminSubmitIdentityId): Promise<void> => {
    const identityInfo = await api.admissions.SubmitIdentityId(applicantId, identityId);
    if (!identityInfo.ok) throw await identityInfo.json();
    this.setState({
      identityInfo: toLowerCaseProps(await identityInfo.json()) as IIdentityInfo,
    });
  };

  private readonly saveIdentityIdV2 = async (
    applicantId: string,
    identityData: AdminSubmitIdentityIdV2,
  ): Promise<void> => {
    const identityInfo = await api.learnerIdentity.SubmitIdentityInfo(applicantId, identityData);
    if (!identityInfo.ok) throw await identityInfo.json();
    this.setState({
      identityInfo: toLowerCaseProps(await identityInfo.json()) as IIdentityInfo,
    });
  };

  private readonly saveIdentityPhoto = async (applicantId: string, photoDocument: DocumentInfo): Promise<void> => {
    const identityInfo = await api.learnerIdentity.SubmitIdentityPhoto(applicantId, {
      UploadedFileName: photoDocument.uploadedFileName,
      OriginalFileName: photoDocument.originalFileName,
    });
    if (!identityInfo.ok) throw await identityInfo.json();
    this.setState({
      identityInfo: toLowerCaseProps(await identityInfo.json()) as IIdentityInfo,
    });
  };

  render(): JSX.Element {
    const {
      admissionDataPending,
      error,
      admissionData,
      permissionObject,
      applicantInfo,
      identityInfo,
      aepDocumentsList,
    } = this.state;
    const { match, voucherData, applicantEditableInfo } = this.props;
    const { applicantId } = match.params;
    const mbaCourses = admissionData.FoundationCourses && admissionData.FoundationCourses.MBA;
    const initialFoundationCoursesValues = mbaCourses
      ? admissionData.FoundationCourses.MBA.map((course: ISelectedAdmissionOption) => course.Value)
      : [];

    return (
      <AdmissionsContainer>
        {applicantInfo.ApplicationSource === 'apply-nxu' && (
          <StyledCard>
            <Card.Title>
              <StyledResult>
                <span>
                  Application source is ApplyNXU
                  <br />
                  Admission should be managed from{' '}
                  <Link to={`/learner-registration/${applicantId}`}>Learner Registration</Link>
                </span>
              </StyledResult>
            </Card.Title>
          </StyledCard>
        )}
        <AdmissionsForm
          error={error}
          loading={admissionDataPending}
          styleNoAccesText={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.5em',
          }}
          permission={permissionObject.readApplications}
          spinner
          loadingText="Loading admission form..."
          noAccesText="You do not have access to admission form"
          applicantInfo={applicantInfo}
          identityInfo={identityInfo}
          initialFoundationCoursesValues={initialFoundationCoursesValues}
          makeApplicationDecisions={permissionObject.makeApplicationDecisions}
          admissionData={admissionData}
          applicantId={applicantId}
          aepDocuments={aepDocumentsList}
          country={applicantEditableInfo.applicantEditableInfo.Country}
          state={applicantEditableInfo.applicantEditableInfo.State}
        />
        <AdmissionVoucherBlock
          canEditVoucherCodes={permissionObject.canEditVoucherCodes}
          voucherData={voucherData}
          handleApplicationFieldValueChange={this.handleApplicationFieldValueChange}
          handleTuitionFieldValueChange={this.handleTuitionFieldValueChange}
        />
        <AdmissionDecision
          acceptanceType={applicantInfo.AcceptanceType}
          AEP={applicantInfo.AEP}
          reviewedBy={applicantInfo.ReviewedBy}
          reviewedTime={applicantInfo.ReviewedTime}
          comments={applicantInfo.Comments}
          aepDocuments={applicantInfo.AEPDocuments}
          foundationCoursesCsv={applicantInfo.FoundationCoursesCsv}
        />
        <AdmissionDecisionTicket url={applicantInfo.AdmissionTicketUrl} />
        <ApplicationInfo
          applicantId={applicantId}
          applicantInfo={applicantInfo}
          identityInfo={identityInfo}
          loading={admissionDataPending}
          saveIdentityIdV2={this.saveIdentityIdV2}
          saveIdentityId={this.saveIdentityId}
          saveIdentityPhoto={this.saveIdentityPhoto}
        />
        <Program productCode={applicantInfo.ProductCode} productType={applicantInfo.ProductType} />
        {applicantEditableInfo && applicantEditableInfo.optionsPendingApplicantEditableInfo ? (
          <StyledLoadingBlock>
            <Spinner animation="border" size="sm" />
            <StyledLoadingText>Loading applicant information...</StyledLoadingText>
          </StyledLoadingBlock>
        ) : (
          <PersonalDataBasedOnProgramEditable
            handleApplicantProfileValueChange={this.handleApplicantProfileValueChange}
            applicantEditableInfo={applicantEditableInfo.applicantEditableInfo}
          />
        )}

        {((applicantEditableInfo &&
          applicantEditableInfo.applicantEditableInfo.ApplicationStatus === APPLICANT_STATUS.ENROLLING) ||
          (applicantEditableInfo &&
            applicantEditableInfo.applicantEditableInfo.ApplicationStatus === APPLICANT_STATUS.SUBMITTED) ||
          (applicantEditableInfo &&
            applicantEditableInfo.applicantEditableInfo.ApplicationStatus === APPLICANT_STATUS.ENROLLED)) && (
          <StyledWrapper>
            <PersonalDetailsCard>
              <StyledLink to={`/applicants/original-info/${applicantId}`} target="_blank">
                View original application information
              </StyledLink>
            </PersonalDetailsCard>
          </StyledWrapper>
        )}

        <EnrollmentAgreement status={applicantInfo.Status} />
        <WithLoading loading={false} loadingText="Loading access permissions...">
          <WithErrorHandling error={null}>
            <WithPermissions permission noAccesText={ACCESS_DENIED_TEXT}>
              <StyledApplicantFiles>
                <h3> Applicant Files </h3>
                <ApplicantFiles
                  editAepOnUpload={false}
                  learnerId={applicantId}
                  learnerStatus={applicantInfo.Status}
                  isAdmission
                />
              </StyledApplicantFiles>
            </WithPermissions>
          </WithErrorHandling>
        </WithLoading>
      </AdmissionsContainer>
    );
  }
}

const mapStateToProps = (state: IRootState): IAdmissionsStateProps => ({
  voucherData: getVoucherData(state),
  applicantEditableInfo: getApplicantEditableInfoState(state),
  error: getVoucherDataError(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>): IDispatchProps => ({
  getVoucherCodeData: (applicantId: string): void => {
    dispatch(getVoucherCodeInfo(applicantId));
  },
  getApplicantEditableInfoData: (applicantId: string): void => {
    dispatch(getApplicantEditableInfo(applicantId));
  },
  setApplicationVoucherCodeFieldValue: (applicantId: string, newValue: INewFieldValue): void => {
    dispatch(updateApplicationVoucherCodeFieldValue(applicantId, newValue));
  },
  setApplicantProfileFieldValue: (applicantId: string, newValue: INewFieldValue, changedFieldUrl: string): void => {
    dispatch(updateApplicantProfileFieldValue(applicantId, newValue, changedFieldUrl));
  },

  setTuitionVoucherCodeFieldValue: (applicantId: string, newValue: INewFieldValue): void => {
    dispatch(updateTuitionVoucherCodeFieldValue(applicantId, newValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Admissions);
