/* eslint react/prop-types: 0 */

import React, { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { AdmittanceResponse } from '../../../models/LearnerRegistration';

export interface UndoConfirmationModalProps {
  admittanceStatus: AdmittanceResponse;
  undoEvent: () => void;
  disabled: boolean;
}

const UndoConfirmationModal = (props: UndoConfirmationModalProps) => {
  const { admittanceStatus, undoEvent, disabled } = props;
  const { productCode, productType } = admittanceStatus;

  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    setShowModal(false);
    undoEvent();
  };

  const ModalPanel = () => (
    <Modal size="lg" show={showModal} onHide={handleClose} backdrop="static" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Decision </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are undoing the admission decision for <strong>{`${productCode} ${productType}`}</strong>
          <br />
          Please confirm that you want to proceed?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <Button variant="danger" onClick={handleOpen} disabled={disabled}>
        Undo
      </Button>
      <ModalPanel />
    </div>
  );
};

export default UndoConfirmationModal;
