import { ISelectOption } from 'models/SelectOptions';
import { useMemo } from 'react';
import { useAepDocumentOptions } from 'shared/api/aep-documents';

export const useAepDocumentOptionsField = () => {
  const { data: aepDocumentOptions } = useAepDocumentOptions();

  const aepOptionsList = useMemo<ISelectOption[]>(
    () =>
      aepDocumentOptions?.map((type) => ({
        label: type.Name,
        value: type.Name,
      })) || [],
    [aepDocumentOptions],
  );
  const defaultAepDocuments = useMemo(() => aepDocumentOptions?.filter((ad) => ad.IsDefault), [aepDocumentOptions]);

  return { aepOptionsList, defaultAepDocuments };
};
