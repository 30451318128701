import React from 'react';
import { Button, Modal, Col, Spinner } from 'react-bootstrap';
import { Form, FormRenderProps, AnyObject, Field } from 'react-final-form';
import { FormState } from 'final-form';
import {
  FileUpload,
  Error,
  renderTextField,
  renderSelectField,
  renderTextInput,
  emptySelectOption,
  ConditionalField,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { selectRequired } from '../../../utils/validate.utils';
import getFileValidationErrors from './file-validation-functions';
import { CLOSE, UPLOAD, COMMENT, COMMENT_ID } from '../../constants/common-constants';
import { FormRow } from '../styled-component';
import { ISelectOption } from '../../../models/SelectOptions';
import { DatePicker } from 'components/helper-components/form-components/form-filed-components/date-picker';

const UPLOAD_FIELD = 'uploadFile';
const FILE_NAME = 'fileName';
const UPLOAD_DATE = 'uploadDate';
const CATEGORY = 'categorySelect';
const SUBCATEGORY = 'subcategory';
const OTHER_DOCUMENTS = 'other';

interface IInitialFormValues {
  [UPLOAD_FIELD]: null | FileList;
  [FILE_NAME]: string;
  [CATEGORY]: ISelectOption;
  [SUBCATEGORY]: string | null;
  [UPLOAD_DATE]: Date;
  [COMMENT_ID]: string;
}

interface IFileUploadFormProps {
  handleSave: (values: AnyObject) => void;
  handleClose: (showUploadModal: boolean) => void;
  categoryOptions: ISelectOption[];
  showUploadModal: boolean;
  categoryOptionsPending: boolean;
  fileUploadPending: boolean;
}

const FileUploadForm: React.FunctionComponent<IFileUploadFormProps> = (props: IFileUploadFormProps) => {
  const { handleSave, handleClose, showUploadModal, categoryOptions, categoryOptionsPending, fileUploadPending } =
    props;

  const initialFormValues: IInitialFormValues = {
    [UPLOAD_FIELD]: null,
    [FILE_NAME]: '',
    [CATEGORY]: emptySelectOption,
    [SUBCATEGORY]: null,
    [UPLOAD_DATE]: new Date(),
    [COMMENT_ID]: '',
  };

  return (
    <>
      <Modal show={showUploadModal}>
        <Modal.Header>
          <Modal.Title>Add Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSave}
            keepDirtyOnReinitialize
            initialValues={initialFormValues}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => {
              const errors: { [key: string]: string | string[] } = {};
              const file = values[UPLOAD_FIELD] ? values[UPLOAD_FIELD]![0] : null;
              const categoryValue = values[CATEGORY].value;

              if (getFileValidationErrors(file).length > 0) {
                errors[UPLOAD_FIELD] = getFileValidationErrors(file);
              }
              if (!values[UPLOAD_DATE]) {
                errors[UPLOAD_DATE] = 'Please, select upload date';
              }
              if (!values[FILE_NAME]) {
                errors[FILE_NAME] = 'Please, provide file name';
              }
              if (!values[SUBCATEGORY] && categoryValue === OTHER_DOCUMENTS) {
                errors[SUBCATEGORY] = 'Please, provide sub-category';
              }

              return errors;
            }}
            render={<T extends object>({
              handleSubmit,
              pristine,
              invalid,
              values,
              errors,
              form,
            }: FormRenderProps<any> & FormState<T>): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <Col xs={4}>Upload File</Col>
                  <Col xs={8}>
                    <FileUpload name={UPLOAD_FIELD} changeFileNameValue={form.mutators.setValue} />
                    <Error name={UPLOAD_FIELD} multipleErrors />
                  </Col>
                </FormRow>
                {values[UPLOAD_FIELD] && !errors?.[UPLOAD_FIELD] && (
                  <>
                    <FormRow>
                      <Col xs={4}>File Name</Col>
                      <Col xs={8}>
                        <Field name={FILE_NAME} render={renderTextInput} maxLength={50} />
                        <Error name={FILE_NAME} />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col xs={4}>Upload Date</Col>
                      <Col xs={8}>
                        <Field
                          name={UPLOAD_DATE}
                          showMonthYearPicker={false}
                          render={DatePicker}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          showCurrentTime
                        />
                        <Error name={UPLOAD_DATE} />
                      </Col>
                    </FormRow>
                    <FormRow>
                      <Col xs={4}>Category</Col>
                      <Col xs={8}>
                        {categoryOptionsPending ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <Field
                            name={CATEGORY}
                            render={renderSelectField}
                            options={categoryOptions}
                            validate={selectRequired}
                          />
                        )}
                      </Col>
                    </FormRow>
                    {values[CATEGORY].value === OTHER_DOCUMENTS && (
                      <FormRow>
                        <Col xs={4}>
                          <span>Sub-category</span>
                        </Col>
                        <Col xs={8}>
                          <ConditionalField
                            name={SUBCATEGORY}
                            changeValue={form.mutators.setValue}
                            render={renderTextInput}
                            maxLength={50}
                          />
                          <Error name={SUBCATEGORY} />
                        </Col>
                      </FormRow>
                    )}
                    <FormRow>
                      <Col xs={4}>{COMMENT}</Col>
                      <Col xs={8}>
                        <Field name={COMMENT_ID} render={renderTextField} maxLength={500} />
                      </Col>
                    </FormRow>
                  </>
                )}

                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={pristine || invalid || fileUploadPending}
                  >
                    {UPLOAD}
                    {fileUploadPending && <Spinner size="sm" animation="border" />}
                  </Button>
                  <Button variant="secondary" onClick={() => handleClose(false)}>
                    {CLOSE}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileUploadForm;
