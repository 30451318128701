import * as React from 'react';
import { StepperContent } from '../../../ui/stepper';
import api from '../../../../shared/api/adminUI.api';
import SubmitButton from '../../submit-button-component/submit-button-component';
import CourseList from '../../courses-render-function/courses-checkbox-list';
import errorHandling from '../../../helper-components/alert-component.component';
import { WithLoading } from '../../../helper-components/loading.component';
import { LearningPathContainer } from '../../styled-components';
import {
  ISpecializationCoursesProps,
  ISpecializationCoursesState,
  ISpecializationCourse,
} from './specializations-courses.model';

export const STEP1 = 'step-one';

class SpecializationsCourses extends React.PureComponent<ISpecializationCoursesProps, ISpecializationCoursesState> {
  constructor(props: ISpecializationCoursesProps) {
    super(props);
    this.state = {
      specializationsCoursesData: [],
      checkedItems: new Map(),
      pending: true,
      pendingSave: false,
    };
  }

  public componentDidMount() {
    const { learnerId, programEnrollmentId } = this.props;
    this.loadSpecializationData(learnerId, programEnrollmentId);
  }

  private setCheckedItems = (specializationsCoursesData: ISpecializationCourse[]) => {
    const checkBoxesData = specializationsCoursesData.map((course: ISpecializationCourse) => ({
      key: course.id,
      value: course.selected,
    }));
    return new Map(checkBoxesData.map((i) => [i.key, i.value]));
  };

  private processSpecializationDataBeforeSave = (checkedItems: Map<string, boolean>): string[] =>
    checkedItems.size > 0
      ? Object.keys(Object.fromEntries(checkedItems))
          .filter((key) => Object.fromEntries(checkedItems)[key])
          .map((key) => key)
      : [];

  private onSave = async (
    e: React.FormEvent,
    checkedItems: Map<string, boolean>,
    resolve: (data: string) => void,
    learnerId: string,
    programEnrollmentId: string,
  ): Promise<void> => {
    e.preventDefault();
    this.setState({ pendingSave: true });
    const specializationData = this.processSpecializationDataBeforeSave(checkedItems);
    try {
      const specializationsResponse = await api.learningPathPlanner.specializtions.setSelectedSpecializtions(
        learnerId,
        programEnrollmentId,
        specializationData,
      );
      if (!specializationsResponse.ok) throw await specializationsResponse.json();
      this.setState({ pendingSave: false });
      resolve('');
    } catch (error: any) {
      errorHandling({ error });
      this.setState({ pendingSave: false });
    }
  };

  private handleCheckboxChange = (e: React.BaseSyntheticEvent) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    const { checkedItems } = this.state;
    this.setState({ checkedItems: new Map(checkedItems).set(item, isChecked) });
  };

  private readonly loadSpecializationData = async (learnerId: string, programEnrollmentId: string): Promise<void> => {
    try {
      const specializationsResponse = await api.learningPathPlanner.specializtions.getSpecializtionsData(
        learnerId,
        programEnrollmentId,
      );
      if (!specializationsResponse.ok) throw await specializationsResponse.json();
      const specializationsCoursesData = (await specializationsResponse.json()) as ISpecializationCourse[];
      this.setState({
        pending: false,
        specializationsCoursesData,
        checkedItems: this.setCheckedItems(specializationsCoursesData),
      });
    } catch (error: any) {
      errorHandling({ error });
      this.setState({
        pending: false,
      });
    }
  };

  render(): JSX.Element {
    const { pending, specializationsCoursesData, checkedItems, pendingSave } = this.state;
    const { submitButtonPermission, learnerProductCode, learnerId, programEnrollmentId } = this.props;

    return (
      <WithLoading loading={pending} loadingText="Loading specialisations ..." spinner>
        <LearningPathContainer>
          <StepperContent
            actions={
              <>
                {submitButtonPermission && (
                  <SubmitButton
                    isDisabled={pendingSave}
                    pendingSave={pendingSave}
                    checkedItems={checkedItems}
                    onSubmit={this.onSave}
                    learnerId={learnerId}
                    programEnrollmentId={programEnrollmentId}
                  />
                )}
              </>
            }
          >
            <CourseList
              courseData={specializationsCoursesData}
              checkedItems={checkedItems}
              handleChange={this.handleCheckboxChange}
              learnerProductCode={learnerProductCode}
              specialisations
            />
          </StepperContent>
        </LearningPathContainer>
      </WithLoading>
    );
  }
}

export default SpecializationsCourses;
