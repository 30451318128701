import React from 'react';
import { INewFieldValue } from '../admissions.model';
import { IApplicantPersonalInfoEditable } from './applicant-editable-info.model';
import ApplicantPersonalDetailsEditable from './applicant-personal-details/applicant-personal-details';
import { useNationalityOptionsContext } from '../../../shared/providers/nationalityOptionsProvider';

export interface IProps {
  applicantEditableInfo: IApplicantPersonalInfoEditable | null;
  handleApplicantProfileValueChange: (newValue: INewFieldValue, changedFieldName: string) => Promise<void>;
}

const PersonalDataBasedOnProgramEditable: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { applicantEditableInfo, handleApplicantProfileValueChange } = props;

  const {
    countryOptions,
    stateOptions,
    nationalityOptions,
    loading: nationalityOptionsLoading,
  } = useNationalityOptionsContext();

  return (
    <>
      {applicantEditableInfo && !nationalityOptionsLoading && (
        <ApplicantPersonalDetailsEditable
          countriesOptions={countryOptions}
          stateOptions={stateOptions}
          nationalitiesOptions={nationalityOptions}
          handleApplicantProfileValueChange={handleApplicantProfileValueChange}
          personalInfoEditable={applicantEditableInfo}
        />
      )}
    </>
  );
};

export default PersonalDataBasedOnProgramEditable;
