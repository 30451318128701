import { ExternalTransferObject } from '../learner-info.model';
import {
  EXTERNAL_COURSE_CODE_MIN_LENGTH,
  EXTERNAL_COURSE_NAME_MIN_LENGTH,
  EXTERNAL_INSTITUTION_NAME_MIN_LENGTH,
} from './transfer-credits.constants';

export const isApprovalPending = (externalTransfers: ExternalTransferObject[]): boolean => {
  if (!externalTransfers) return false;
  if (!externalTransfers.some((x) => x.status === 'pending')) return false;

  const notReadyForApproval = externalTransfers.some(
    (x) =>
      x.status === 'pending' &&
      x.externalCourses.some(
        (ec) =>
          ec.externalCourseCode.length < EXTERNAL_COURSE_CODE_MIN_LENGTH ||
          ec.externalCourseName.length < EXTERNAL_COURSE_NAME_MIN_LENGTH ||
          ec.provider.length < EXTERNAL_INSTITUTION_NAME_MIN_LENGTH,
      ),
  );
  return !notReadyForApproval;
};
