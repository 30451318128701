import React from 'react';
import { Row, Button, Modal, Col } from 'react-bootstrap';
import { LearnerAcademicStanding } from '../../../shared/reducers/academic-standing/academic-standing.model';
import GradeGPA from '../../learner-grades/components/grade-gpa';
import UniversityDate from '../../university-date';
import AcademicStandingStatusAge from './academic-standing-status-age';
import AcademicStandingStatusIcon from './academic-standing-status-icon';

interface IProps {
  readonly status: LearnerAcademicStanding;
  readonly age: number | null;
  readonly academicStandingCode: string;
  readonly handleClose: () => void;
}

class AcademicStandingDetails extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { handleClose, status, academicStandingCode: asCode, age } = this.props;

    if (status.parameterStatus === null) return <></>;
    const academicStanding = status.parameterStatus.find((ps) => ps.parameterCode === asCode);
    if (academicStanding == null) return <></>;

    return (
      <Modal show backdrop="static" size="lg">
        <Modal.Header>
          <Modal.Title>
            <>Academic Standing: </>
            <AcademicStandingStatusIcon value={academicStanding.status} />
            <> {academicStanding.statusText} </>
            <UniversityDate value={academicStanding.lastStatusChangeDate} />
            <AcademicStandingStatusAge days={age} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status.parameterStatus
            .filter((ps) => ps.parameterCode !== asCode)
            .map((ps) => (
              <Row key={ps.parameterCode} style={{ paddingBottom: 20 }}>
                <Col xs={3}>{ps.parameterName}</Col>
                <Col xs={1}>
                  <AcademicStandingStatusIcon value={ps.status} />
                </Col>
                <Col xs={5}>{ps.parameterCode.includes('gpa') ? <GradeGPA gpa={ps.statusText} /> : ps.statusText}</Col>
                <Col xs={3}>
                  <UniversityDate value={ps.lastStatusChangeDate} />
                </Col>
              </Row>
            ))}
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AcademicStandingDetails;
