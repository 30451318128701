import {
  ACCEPT,
  ACCEPTANCE_PATH,
  ADMISSION_DECISION,
  AEP_DOCUMENTS,
  OK,
  PARTNER,
  PRODUCT_CODE,
  PRODUCT_CODE_ERROR,
  PRODUCT_TYPE,
  UNITED_STATES_ISO,
} from 'components/admissions/admissions-form/admissions-form.constants';
import { ILearnerAEPDocument } from 'components/learner-files/learner-files.model';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { emptySelectOption, renderCheckbox } from 'components/helper-components/form-components/form-filed-components';
import { acceptAdmittance, usePersonalDetails } from 'shared/api/learner-registration';
import { CountryMessage } from 'components/admissions/admissions-form/styled-components';
import { ProductTypeSelect } from './product-type-select';
import { ProductCodeSelect } from './product-code-select';
import { Button, Col, Row } from 'react-bootstrap';
import { RegistrationItem } from 'models/LearnerRegistration';
import { toast } from 'react-toastify';
import { AppError } from 'shared/api/app-error';
import { IFormErrors } from 'components/admissions/admissions-form/admissions-form.model';
import { useAepDocumentOptionsField } from './acceptance-path/use-aep-document-options-field';
import { AcceptancePath } from './acceptance-path/acceptance-path';
import { ApproveAdmissionFieldValues } from './types';

type Props = {
  applicantId: string;
  activeRegistration: RegistrationItem;
  onSuccess: (code: string, isCodeChanged: boolean) => Promise<any>;
};

export const ApproveAdmissionDecisionForm = ({ applicantId, activeRegistration, onSuccess }: Props) => {
  const { data: personalDetails } = usePersonalDetails(applicantId);
  const { defaultAepDocuments } = useAepDocumentOptionsField();

  const handleSubmit = async (values: ApproveAdmissionFieldValues) => {
    const originalCode = activeRegistration.productCode;
    let payload: any = null;

    if (originalCode !== values.productCode || values.acceptancePath || values[PARTNER]) {
      payload = {
        productType: values.productType,
        productCode: values.productCode,
        aepDocuments: values.acceptancePath ? values.aepDocuments : null,
        partnerId: values[PARTNER],
      };
    }

    try {
      await acceptAdmittance(applicantId, activeRegistration.registrationId, payload);
      await onSuccess(values[PRODUCT_CODE]!, originalCode !== values[PRODUCT_CODE]);
    } catch (err) {
      toast.error(
        err instanceof AppError && err.data?.code !== 'UNKNOWN'
          ? err.message
          : 'Failed to accept the admission decision',
      );
    }
  };

  return (
    <Form<ApproveAdmissionFieldValues>
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      initialValues={{
        [PARTNER]: activeRegistration?.partnerId,
        [PRODUCT_CODE]: activeRegistration?.productCode,
        [PRODUCT_TYPE]: activeRegistration?.productType,
        [ADMISSION_DECISION]: '',
        [ACCEPTANCE_PATH]: false,
        [AEP_DOCUMENTS]: defaultAepDocuments?.map(
          (ad) =>
            ({
              name: ad.Name,
              status: OK,
            }) as ILearnerAEPDocument,
        ),
      }}
      mutators={{
        ...arrayMutators,
      }}
      validate={(values) => {
        const errors: IFormErrors = {};

        if (
          values[ADMISSION_DECISION] === ACCEPT &&
          (!values[PRODUCT_CODE] || values[PRODUCT_CODE] === emptySelectOption.value)
        ) {
          errors[PRODUCT_CODE] = PRODUCT_CODE_ERROR;
        }
        return errors;
      }}
      render={({ invalid, submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-center align-items-center">
            {personalDetails?.country === UNITED_STATES_ISO && (
              <CountryMessage data-testid="country-message">
                This is a US applicant from {personalDetails.state} state.
              </CountryMessage>
            )}
          </div>
          <ProductTypeSelect />
          <ProductCodeSelect />
          <Row>
            <Col xs={4}>
              <span>Acceptance path</span>
            </Col>
            <Col xs={8}>
              <Field
                fieldType="checkbox"
                name={ACCEPTANCE_PATH}
                render={renderCheckbox}
                type="checkbox"
                label="Accepted Evaluation Pending"
              />
            </Col>
          </Row>
          <AcceptancePath />
          <Row className="justify-content-center">
            <Button disabled={invalid || submitting} type="submit">
              <span>Accept</span>
            </Button>
          </Row>
        </form>
      )}
    />
  );
};
