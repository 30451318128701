import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styles from './add-partner-button.module.scss';
import { CreatePartnerPayload } from '../../../../../models/Partner';
import { PARTNERS_QUERY_KEY } from '../../../hooks/use-partners';
import { PartnerFormValues } from '../../../components/partner-form/partner-form';
import { PartnerFormModal } from '../../../components/partner-form-modal/partner-form-modal';
import { AppError } from '../../../../../shared/api/app-error';
import { createPartner } from 'shared/api/partner';

export const AddPartnerButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync, error, reset } = useMutation<unknown, AppError, CreatePartnerPayload>({
    mutationFn: createPartner,
  });

  const handleCreatePartner = async (data: PartnerFormValues) => {
    try {
      await mutateAsync({
        email: data['partner-email'],
        accountName: data['partner-name'],
        contactName: data['partner-contact-name'],
        logo: data['partner-logo']?.item(0),
      });

      toast.success('Partner added successfully');
      setIsModalOpen(false);
      queryClient.invalidateQueries(PARTNERS_QUERY_KEY);
    } catch (err) {
      console.error('Error creating partner', err);
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      reset();
    }
  }, [isModalOpen, reset]);

  return (
    <div className={styles.container}>
      <Button variant="dark" onClick={() => setIsModalOpen(true)}>
        + ADD PARTNERS
      </Button>
      <PartnerFormModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        onSubmit={handleCreatePartner}
        error={error}
        title="Add Partner"
      />
    </div>
  );
};
