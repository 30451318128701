import { Field, FieldProps as FormFieldProps, FieldRenderProps, useField } from 'react-final-form';
import cn from 'classnames';
import { FaAsterisk } from 'react-icons/fa';
import styles from './form-field.module.scss';
import { HelperText } from 'components/ui/helper-text/helper-text';

type BaseFieldProps<T = string> = FormFieldProps<T, FieldRenderProps<T, HTMLElement>, HTMLElement>;

export type FieldProps<T = string> = {
  label?: string;
  helperText?: string;
  className?: string;
  required?: boolean;
  reverse?: boolean;
  contentClassName?: string;
} & BaseFieldProps<T>;

export const FormField = <T = string,>({
  name,
  label,
  helperText,
  className,
  required,
  reverse,
  contentClassName,
  ...rest
}: FieldProps<T>) => {
  const { meta } = useField(name);

  const hasError = meta.error && meta.invalid && (meta.submitFailed || meta.submitSucceeded);

  const conditionalProps: Omit<BaseFieldProps<T>, 'name'> = rest.render
    ? {}
    : {
        component: 'input',
        type: 'text',
      };

  return (
    <div className={cn(styles.field, reverse && styles.reverse, className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {required ? <FaAsterisk className={styles.asterisk} size={5} /> : <span />}
          <span>{label}</span>
        </label>
      )}
      <div className={cn(styles.content, contentClassName)}>
        <Field name={name} data-testid={name} {...conditionalProps} {...rest} />
        {helperText && <span className={styles.description}>{helperText}</span>}

        {hasError && (
          <HelperText variant="error" data-testid={`${name}-error`}>
            {hasError ? meta.error : ''}
          </HelperText>
        )}
      </div>
    </div>
  );
};
