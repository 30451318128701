import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LearnerName, LearnerId } from '../styled-components';
import { IRootState } from '../../../shared/reducers';
import { getLearnerName } from '../../../shared/selectors/learner-name.selector';

interface LearnerNameIdProps extends StateProps {
  learnerName?: string;
}

function LearnerNameId(props: LearnerNameIdProps) {
  const { learnerName } = props;
  const { learnerId } = useParams<{ learnerId: string }>();

  return (
    <>
      <LearnerName>
        <a href={`/learners/${learnerId}/transcript`} target="blank">
          {learnerName}
        </a>
      </LearnerName>
      <LearnerId>{learnerId}</LearnerId>
    </>
  );
}

const mapStateToProps = (learnerProfileState: IRootState): StateProps => ({
  learnerName: getLearnerName(learnerProfileState),
});

interface StateProps {
  learnerName?: string;
}

export default connect(mapStateToProps)(LearnerNameId);
