import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import errorHandling from '../../components/helper-components/alert-component.component';

import api from '../api/adminUI.api';
import IError from '../../models/Error';
import {
  GET_CONFIGURATION_ERROR,
  GET_CONFIGURATION_PENDING,
  GET_CONFIGURATION_SUCCESS,
} from '../reducers/configuration/configuration.constants';
import { IConfiguration } from '../reducers/configuration/configuration.model';

export const getConfigurationPending = (): AnyAction => action(GET_CONFIGURATION_PENDING);
export const getConfigurationSuccess = (configuration: IConfiguration): AnyAction =>
  action(GET_CONFIGURATION_SUCCESS, configuration);
export const getConfigurationFailure = (error: IError): AnyAction => action(GET_CONFIGURATION_ERROR, error);

export const getConfigurationObject = () => async (dispatch: Dispatch) => {
  dispatch(getConfigurationPending());

  try {
    const response = await api.program.configuration();

    if (!response.ok) throw await response;

    const configuration = (await response.json()) as IConfiguration;
    dispatch(getConfigurationSuccess(configuration));
  } catch (error: any) {
    dispatch(getConfigurationFailure(error));
    errorHandling({ error });
  }
};
