import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';

export const learnerOfficialNameUpdatedEventSelector: (event: IEvent) => IEventDetails = (event: IEvent) => ({
  metadata: {
    type: {
      category: 'Learner Profile',
      eventName: 'Official Name Updated',
    },
    date: event.updatedDateTime,
    editor: event.actor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventProp name="Official Name" value={event.officialName} primary />
    </Row>
  );
};
