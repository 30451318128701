import React from 'react';
import { Container } from 'react-bootstrap';
import { PermissionContainer } from '../../permissions/permission-container';
import { Permission } from '../../../shared/models/permission';
import styles from './partners-page.module.scss';
import { AddPartnerButton } from './components/add-partner-button/add-partner-button';
import { PartnersTable } from './components/partners-table/partners-table';
import { SuspenseContainer } from '../../suspense-container/suspense-container';
import { Breadcrumb } from '../../breadcrumb/breadcrumb';

export const PartnerPage = () => (
  <Container>
    <PermissionContainer permissions={[Permission.PartnersViewer]}>
      <Breadcrumb>
        <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Partners</Breadcrumb.Item>
      </Breadcrumb>

      <div className={styles.container}>
        <h4 className={styles.headline}>Partners</h4>
        <SuspenseContainer loadingText="Loading Partner..." errorText="Failed to load partner information">
          <AddPartnerButton />
          <PartnersTable />
        </SuspenseContainer>
      </div>
    </PermissionContainer>
  </Container>
);
