import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { ILearnerProfileState } from '../reducers/learner-profile/learner-profile.reducer';

export const getLearnerProfileState = (state: IRootState): ILearnerProfileState => state.learnerProfileState;

export const getLearnerName = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): string => learnerProfileState.learnerProfile.Name,
);
