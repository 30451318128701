import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const PrivacySettings = styled(Container)`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #f7f7f7;
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  border-color: #787878;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

// duplicating style due to this bug: https://github.com/microsoft/TypeScript/issues/37597
export const PrivacySettingsHistory = styled(Container)`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #f7f7f7;
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  border-color: #e0e0e0;
  margin-bottom: 15px;
  font-size: 0.9em;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

export const Contacts = styled(Container)`
  font-size: 0.8em;
  padding: 0px;
`;

export const RowTitle = styled.h2`
  font-weight: 800;
  font-size: 2em;
  text-align: left;
  padding: 15px;
  flex: 1 0 100%;
`;

export const TopRow = styled(Row)`
  margin-top: 15px;
`;

export const LockIconContainer = styled(Container)`
  position: relative;
  bottom: 2px;
  display: inline;
  margin-left: 5px;
  padding-left: 0px;
  &:nth-child(2) {
    margin-left: 20px;
  }
`;
