import { Row, Container, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const CenteredRow = styled(Row)`
  display: flex;
  align-items: baseline;
`;

export const PersonalInfo = styled(Container)`
  padding: 15px;
  background-color: #f7f7f7;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

export const BoldColumn = styled(Col)`
  font-weight: bold !important;
`;

export const LearnerProfileRow = styled(Row)`
  min-height: 40px;
  padding-bottom: 6px;
`;

export const TransferRow = styled(Row)`
  min-height: 40px;
`;

export const LongTextColumn = styled(Col)`
  font-weight: bold !important;
  word-break: break-all;
`;

export const InputColumn = styled(Col)`
  font-weight: bold;
  margin-top: 0.3em;
`;

export const RedLabel = styled.span`
  color: red;
`;

export const OrangeLabel = styled.span`
  color: #ff8c00;
`;

export const GreenLabel = styled.span`
  color: seagreen;
`;

export const StyledTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
`;

export const StylesForProgramEnrollments = styled.div`
  .program-enrollments-wrapper {
    margin: 30px 0 15px;
    .program-enrollments-inner {
      position: relative;
      .add-button {
        background: transparent;
        border: 0;
        box-shadow: none;
        display: block;
        margin-bottom: -45px;
        margin-left: 15px;
        outline: 0;
        padding: 0;
        img {
          height: 30px;
          width: 30px;
        }
        &.pending-button {
          background: #000;
          border-radius: 7px;
          margin-bottom: -50px;
          padding: 5px 7px;
          img {
            background: #fff;
            border-radius: 50%;
            margin-right: 5px;
          }
          .product-code {
            color: #fff;
          }
        }
      }
      .nav-tabs .nav-item:first-child {
        margin-left: 60px;
      }
      .tab-content {
        margin-top: 24px;
      }
      &.program-enrollments-hidden {
        .nav-tabs .nav-item:first-child {
          margin-left: 0;
        }
      }
    }
    &.pe-error {
      .pe-error-text {
        color: red;
        pre {
          color: red;
        }
      }
      .program-enrollments-inner {
        .tab-content {
          padding: 0;
        }
      }
    }
  }
  .tab-content {
    background: #f7f7f7;
    padding: 10px;
  }
  .nav-link {
    padding: 0;
  }
  .enrollment-tab {
    height: 100%;
    padding: 8px 16px;
    text-align: center;
    .enrollment-type {
      color: #000;
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
    }
    .enrollment-status {
      color: grey;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
`;

export const StylesForCollapsePanel = styled.div`
  .Collapsible {
    background-color: #f7f7f7;
  }
  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid $lightGrey;
    border-top: 0;

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    display: block;
    font-weight: 800;
    text-decoration: none;
    font-size: 21px;
    position: relative;
    padding: 8px 0 0;
    background: $cyan;
    color: black;

    &:after {
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      color: #4e5861;
      content: '\f0d7';
      font-size: 30px;
      position: absolute;
      right: 10px;
      top: -2px;
      display: block;
      transition: transform 300ms;
      cursor: pointer;
    }

    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }
`;
export const FormWarning = styled.div`
  font-size: large;
  margin: 20px;
`;
export const ButtonColumn = styled(Col)`
  padding: 0;
  top: -5px;
`;

export const LongText = styled.div`
  word-break: break-all;
  font-size: 13px;
  font-weight: 400;
  color: gray;
`;

export const CoursesSearch = styled.div`
  position: relative;
  .search-input-wrapper {
    position: relative;
    .search-icon {
      height: 20px;
      left: 11px;
      position: absolute;
      top: 12px;
      width: 17px;
    }
    .search-input {
      box-shadow: none;
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 8px;
      color: #000;
      display: block;
      font-size: 15px;
      margin: 10px auto 5px;
      outline: none;
      padding: 10px 10px 10px 40px;
      width: 100%;
      &:focus,
      &:active {
        border-color: #2684ff;
      }
    }
  }

  .search-list {
    background: #fff;
    border-radius: 4px;
    left: 0;
    list-style: none;
    max-height: 275px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: left;
    z-index: 2;
    li {
      display: block;
      color: #000;
      cursor: pointer;
      margin-bottom: 1px;
      padding: 10px;
      position: relative;
      transition: 0.3s;
      &:not(:last-child) {
        border-bottom: 1px solid #f7f7f7;
      }
    }
  }
`;

export const ProductSelectWrapper = styled.div`
  .product-select {
    div[class*='control'] {
      border-radius: 8px;
      div:first-child {
        padding: 3px 4px;
      }
    }
  }
`;

export const CourseBlock = styled.div`
  .single-course {
    align-items: center;
    display: flex;
    .course-info {
      flex: 0 0 50%;
    }
    .external-course-info {
      flex: 0 0 50%;
    }
  }
`;

export const ApprovedTransfersWrapper = styled.div`
  font-weight: bold;
  .single-course-section {
    display: flex;
    margin: 15px 0;
    .course-left {
      display: flex;
      flex: 0 0 50%;
      .course-info {
        flex: 0 0 60%;
        .product-code {
          font-weight: 400;
        }
      }
      .course-status {
        flex: 0 0 40%;
        .date {
          font-size: 12px;
        }
      }
    }
    .course-right {
      flex: 0 0 50%;
      .external-transfers-info {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;
        .course-code {
          flex: 0 0 50%;
          .provider-name {
            font-size: 12px;
          }
        }
        .course-name {
          flex: 0 0 50%;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #dee2e6;
        }
      }
    }
    &:first-child {
      margin-top: 40px;
    }
  }
`;
