import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';

export const learnerPrivacySettingUpdatedEventSelector: (event: IEvent) => IEventDetails = (event: IEvent) => ({
  metadata: {
    type: {
      category: 'Learner Profile',
      eventName: 'Learner Privacy Setting Updated',
    },
    date: event.updatedDateTime,
    editor: event.actor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <Row>
        <EventProp name="Restricted" value={event.restricted ? 'Yes' : 'No'} primary />
        <EventProp name="Release Academic Information" value={event.releaseAcademicInfo ? 'Yes' : 'No'} />
        <EventProp name="Release Account Information" value={event.releaseAccountInfo ? 'Yes' : 'No'} />
        <EventProp name="Partner" value={event.partnerName ? event.partnerName : 'None'} primary />
      </Row>
      <Row>
        <EventProp
          name="Authorized Contacts"
          value={
            event.authorizedContacts && Array.isArray(event.authorizedContacts) && event.authorizedContacts.length
              ? (event.authorizedContacts || [])
                  .map(
                    (c: any) =>
                      `${c.contactName ? c.contactName : ''} / ${c.contactInformation ? c.contactInformation : ''}`,
                  )
                  .join(', ')
              : 'None'
          }
        />
      </Row>
    </Row>
  );
};
