import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

interface IErrorNotificationProps {
  learnerId: string;
}

const StyledLink = styled(Link)`
  color: #ffffff;
  text-decoration: underline;
  &:hover {
    color: #f5f5f5;
    text-decoration: none;
  }
`;
export const Message = (learnerId: string) => {
  const link = <StyledLink to={`/learners/${learnerId}`}>Please upload the document to the Learner Record.</StyledLink>;
  return (
    <div>
      <p> This application is now archived. {link} </p>
    </div>
  );
};

const AddDocumentErrorNotification = (props: IErrorNotificationProps): void => {
  const { learnerId } = props;
  toast.error(() => Message(learnerId), {
    position: 'bottom-right',
    closeButton: false,
    autoClose: 6000,
    hideProgressBar: true,
  });
};
export default AddDocumentErrorNotification;
