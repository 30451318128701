import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button } from 'react-bootstrap';
import './wait-button.scss';

interface WaitButtonProps {
  waiting: boolean;
  size?: 'sm' | 'lg';
  variant: any;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => Promise<boolean>;
  children?: React.ReactNode;
}

interface WaitButtonState {
  waiting: boolean;
  success: boolean;
  failure: boolean;
}

class WaitButton extends React.Component<WaitButtonProps, WaitButtonState> {
  constructor(props: WaitButtonProps) {
    super(props);
    this.state = {
      waiting: props.waiting,
      success: false,
      failure: false,
    };
  }

  async clicked(): Promise<void> {
    const { onClick } = this.props;
    const { waiting } = this.state;
    if (!onClick || waiting) {
      return;
    }

    this.setState({ waiting: true, success: false, failure: false });
    try {
      const result = await onClick();
      this.setState({ waiting: false, success: result, failure: !result });
      this.removeStatus();
    } catch (e) {
      this.setState({ waiting: false, success: false, failure: true });
      this.removeStatus();
      throw e;
    }
  }

  private removeStatus() {
    window.setTimeout(() => {
      this.setState({ success: false, failure: false });
    }, 500);
  }

  render(): JSX.Element {
    const { size, variant, type, children } = this.props;
    const { waiting, success, failure } = this.state;
    let { disabled } = this.props;

    if (waiting) {
      disabled = true;
    }

    const waitElementClasses = ['fas wait-button-centered'];
    const successElementClasses = ['fas wait-button-centered'];
    const failureElementClasses = ['fas wait-button-centered'];
    const buttonClasses = [];
    if (waiting) {
      waitElementClasses.push('show');
      buttonClasses.push('button-container-fade');
    }
    if (success) {
      successElementClasses.push('show');
      buttonClasses.push('button-container-fade');
    }
    if (failure) {
      failureElementClasses.push('show');
      buttonClasses.push('button-container-fade');
    }

    return (
      <span style={{ position: 'relative' }}>
        <Button
          size={size}
          variant={variant}
          disabled={disabled}
          type={type}
          className={buttonClasses.join(' ')}
          onClick={() => this.clicked()}
        >
          {children}
        </Button>
        <FontAwesomeIcon icon={faSpinner} className={waitElementClasses.join(' ')} />
        <FontAwesomeIcon icon={faCheck} className={successElementClasses.join(' ')} />
        <FontAwesomeIcon icon={faTimes} className={failureElementClasses.join(' ')} />
      </span>
    );
  }
}

export default WaitButton;
