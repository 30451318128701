import React from 'react';
import Select from 'react-select';
import { IProductSelectObject } from '../learner-info.model';
import { ProductSelectWrapper } from '../styled-components';

class ProductSelect extends React.PureComponent<IProductSelectObject> {
  render(): JSX.Element {
    const {
      transferableProductsPending,
      transferableProductsOptions,
      handleSelectTransferableCourse,
      productListDefault,
    } = this.props;

    return (
      <ProductSelectWrapper>
        {transferableProductsPending ? (
          <div className="readmission-loader" />
        ) : (
          <Select
            options={transferableProductsOptions}
            placeholder="Choose the program to initiate a transfer credit for"
            onChange={(option: any) => {
              handleSelectTransferableCourse(option);
            }}
            className="product-select"
            defaultValue={
              productListDefault && productListDefault.length
                ? { label: productListDefault, value: productListDefault }
                : null
            }
          />
        )}
      </ProductSelectWrapper>
    );
  }
}

export default ProductSelect;
