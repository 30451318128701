import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import styles from './tooltip.module.scss';

type ShowOptions = {
  variant: 'success' | 'danger' | 'warning' | 'info';
  placement: TooltipProps['placement'];
  duration: number;
};

export type OverlayTooltipRef = {
  show: (text: string, options?: Partial<ShowOptions>) => void;
};

type OverlayTooltipProps = {
  children: React.ReactElement;
};

export const OverlayTooltip = forwardRef<OverlayTooltipRef, OverlayTooltipProps>(({ children }, ref) => {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState<Partial<ShowOptions> | undefined>(undefined);
  const [text, setText] = useState('');
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleShow = (newText: string, options?: Partial<ShowOptions>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setOptions(options);
    setText(newText);
    setShow(true);

    timerRef.current = setTimeout(() => setShow(false), options?.duration || 2000);
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
  }));

  return (
    <OverlayTrigger
      show={show}
      overlay={
        <Tooltip className={options?.variant && styles[options.variant]} placement={options?.placement || 'top'}>
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
});
