import React from 'react';
import { usePartner } from '../../hooks/use-partner';
import { usePartnerAgreementsParams } from '../hooks/use-params';
import { Breadcrumb } from '../../../breadcrumb/breadcrumb';

export const PartnerAgreementsBreadcrumbs = () => {
  const { partnerId } = usePartnerAgreementsParams();

  const { data: partner } = usePartner(partnerId, { suspense: true });

  return (
    <Breadcrumb>
      <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item to="/partners">Partners</Breadcrumb.Item>
      <Breadcrumb.Item to={`/partners/${partnerId}`}>{partner?.accountName}</Breadcrumb.Item>
      <Breadcrumb.Item active>Agreements</Breadcrumb.Item>
    </Breadcrumb>
  );
};
