import { AnyAction } from 'redux';
import { AcademicStandingModalTypes } from './academic-standing.model';
import { GET_SAP_ERROR, GET_SAP_PENDING, GET_SAP_SUCCESS } from './academic-standing.constants';

const initialState: AcademicStandingModalTypes = {
  academicStanding: {
    learnerId: '',
    parameterStatus: null,
    academicStanding: null,
    daysSinceAcademicStatusUpdate: null,
  },
  optionsPending: false,
  error: null,
};

const sapModalState = (
  state: AcademicStandingModalTypes = initialState,
  { type, payload }: AnyAction,
): AcademicStandingModalTypes => {
  switch (type) {
    case GET_SAP_PENDING:
      return {
        ...state,
        optionsPending: true,
      };
    case GET_SAP_SUCCESS:
      return {
        ...state,
        academicStanding: payload,
        optionsPending: false,
      };
    case GET_SAP_ERROR:
      return {
        ...state,
        error: payload,
        academicStanding: initialState.academicStanding,
        optionsPending: false,
      };
    default:
      return state;
  }
};

export default sapModalState;
