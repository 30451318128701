import React from 'react';
import { CommunicationItem, CommunicationsList, CommunicationsWrapper } from './communications-styled-component';
import errorHandling from '../helper-components/alert-component.component';
import { EmailBodyDialog, LearnerEmail } from './communications-common';
import UniversityDate from '../university-date';
import { request } from 'shared/api/fetch';

export interface ICommunicationsProps {
  readonly learnerId: string;
}

interface ICommunicationsState {
  emails: LearnerEmail[] | null;
  showEmail: LearnerEmail | null;
}

const IgnoreList: string[] = [
  'affilate@nexford.org',
  'afranklin@nexford.org',
  'cibdiscount@nexford.org',
  'alumni@nexford.org',
  'fadl.altarzi@nexford.org',
  'getback@nexford.org',
  'help@nexford.org',
  'nigeria@nexford.org',
  'nxulearnerrefund@nexford.org',
  'nxurefer@nexford.org',
  'omajek@nexford.org',
  'scholarships@nexford.org',
  'webinars@nexford.org',
  'younext@nexford.org',
  'aomidiran@nexford.org',
  'Faraltarzi@nexford.org',
  'hilufoye@nexford.org',
  'melkelish@nexford.org',
  'sokogho@nexford.org',
  'yelrayes@nexford.org',
];

class Communications extends React.Component<ICommunicationsProps, ICommunicationsState> {
  constructor(props: ICommunicationsProps) {
    super(props);
    this.state = {
      emails: null,
      showEmail: null,
    };
  }

  componentDidMount(): void {
    const { learnerId } = this.props;
    this.loadEmails(learnerId);
  }

  private readonly includeEmailAddress = (from: string, to: string): boolean =>
    !IgnoreList.includes(from) && !IgnoreList.includes(to);

  private readonly loadEmails = async (learnerId: string): Promise<void> => {
    try {
      const emails = await request.get<LearnerEmail[]>(`/api/email-sync/learners/${learnerId}/emails`);
      this.setState({ emails });
    } catch (error: any) {
      errorHandling({ error });
    }
  };

  render(): JSX.Element {
    const { emails, showEmail } = this.state;
    if (emails == null) return <>Loading...</>;

    const filteredEmails = emails.filter((e) => this.includeEmailAddress(e.from, e.to));

    return (
      <CommunicationsWrapper>
        {showEmail && <EmailBodyDialog email={showEmail} close={() => this.setState({ showEmail: null })} />}
        <CommunicationsList>
          {filteredEmails.map((email) => (
            <EmailRow showEmail={() => this.setState({ showEmail: email })} email={email} key={email.emailId} />
          ))}
        </CommunicationsList>
      </CommunicationsWrapper>
    );
  }
}

interface IEmailRowProps {
  email: LearnerEmail;
  showEmail: () => void;
}
const EmailRow = ({ email, showEmail }: IEmailRowProps): JSX.Element => (
  <CommunicationItem onClick={showEmail}>
    <div className="table-cell" style={{ width: '10%' }} title={`From ${email.from} to ${email.to}`}>
      <span>{email.fromNexford ? 'From NXU' : 'To NXU'}</span>
    </div>
    <div className="table-cell" style={{ width: '65%' }}>
      <h5>{email.subject}</h5>
    </div>
    <div className="table-cell" style={{ width: '25%' }}>
      <UniversityDate value={email.timestamp} withTime />
    </div>
    <div className="table-call" style={{ width: '100%' }}>
      <span>{email.preview}</span>
    </div>
  </CommunicationItem>
);

export default Communications;
