import { Container, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const AdmissionsContainer = styled(Container)`
  font-family: 'Open Sans';
  text-align: left;
  margin-bottom: 20px;
  padding: 60px 30px 20px 30px;
  @media (min-width: 1200px) {
    max-width: 1240px;
  }
`;
export const StyledWrapper = styled.div`
  margin-bottom: 45px;
  margin-top: 40px;
`;
export const StyledApplicantFiles = styled.div`
  margin-top: 25px;
`;
export const StyledLoadingBlock = styled.div`
  text-align: center;
`;
export const StyledLoadingText = styled.span`
  text-align: center;
  font-size: 16px;
  padding-left: 10px;
  color: #383737;
`;

export const StyledLink = styled(Link)`
  color: #212529;
  text-decoration: underline;
  &:focus,
  &:visited,
  &:active {
    color: #212529;
  }
  &:hover {
    color: #212529;
    text-decoration: none;
  }
`;

export const PersonalDetailsCard = styled(Card)`
  width: 80%;
  border: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
`;

export const AdmissionBlock = styled.div`
  .row {
    padding-bottom: 4px;
  }
`;

export const StyledOriginalInfo = styled.div`
  .row {
    padding-bottom: 6px;
  }
`;
