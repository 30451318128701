import React, { useContext, useEffect, useState } from 'react';
import { ISelectOption } from '../../models/SelectOptions';
import api from '../api/adminUI.api';
import { CountriesModel, NationalititesModel, StatesModel } from '../models/nationality-result';

export interface INationalityOptionsContextState {
  countryOptions: ISelectOption[];
  nationalityOptions: ISelectOption[];
  stateOptions: ISelectOption[];
  loading: boolean;
}

export const NationalityOptionsContext = React.createContext<INationalityOptionsContextState>(undefined!);

export const NationalityOptionsConsumer = NationalityOptionsContext.Consumer;

export const useNationalityOptionsContext = () => useContext(NationalityOptionsContext);

interface NationalityOptionsContextProps {
  children?: React.ReactNode;
}

export function NationalityOptionsProvider({ children }: NationalityOptionsContextProps) {
  const [loading, setLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState<ISelectOption[]>([]);
  const [nationalityOptions, setNationalityOptions] = useState<ISelectOption[]>([]);
  const [stateOptions, setStateOptions] = useState<ISelectOption[]>([]);

  const getCountries = async () => {
    const countriesResponse = await api.admissions.getCountries();

    if (!countriesResponse.ok) throw await countriesResponse.json();
    const countriesData = (await countriesResponse.json()) as CountriesModel;
    setCountryOptions(countriesData.Countries);
  };

  const getStates = async () => {
    const statesResponse = await api.admissions.getStates();

    if (!statesResponse.ok) throw await statesResponse.json();
    const statesData = (await statesResponse.json()) as StatesModel;
    setStateOptions(statesData.States);
  };

  const getNationalities = async () => {
    const nationalitiesResponse = await api.admissions.getNationalities();

    if (!nationalitiesResponse.ok) throw await nationalitiesResponse.json();
    const nationalitiesData = (await nationalitiesResponse.json()) as NationalititesModel;
    setNationalityOptions(nationalitiesData.Nationalities);
  };

  useEffect(() => {
    setLoading(true);
    if (countryOptions.length === 0) {
      getCountries();
    }

    if (nationalityOptions.length === 0) {
      getNationalities();
    }

    if (stateOptions.length === 0) {
      getStates();
    }
    setLoading(false);
  });

  const value = {
    loading,
    countryOptions,
    stateOptions,
    nationalityOptions,
  };

  return <NationalityOptionsContext.Provider value={value}>{children}</NationalityOptionsContext.Provider>;
}
