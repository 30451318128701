import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const LearnerRegistrationHeading = styled(Container)`
  margin: 24px auto;
  max-width: 720px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LearnerRegistrationListBlock = styled(Container)`
  margin: 24px auto;
  max-width: 720px;

  h3 {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 8px;
    margin-bottom: 0;
  }

  > div {
    padding: 4px 0;
  }

  button.registration-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: 8px 8px 8px 34px;
    margin: 4px 0;
    border: none;

    svg {
      position: absolute;
      left: 8px;
      top: 12px;
    }
  }

  button.registration-item__current {
    color: #098b5b;
    > span:first-child {
      font-weight: bold;
    }
  }
`;

export const AdmissionDecisionBlock = styled(Container)`
  margin: 24px auto;
  max-width: 720px;

  > div.btn-group {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    input {
      margin-right: 4px;
    }

    label.btn {
      padding: 8px 16px;
    }

    label.active {
      background-color: #098b5b !important;
      border-color: #098b5b !important;
    }
  }
`;

export const LearnerRegistrationStatusBlock = styled(Container)`
  margin: 24px auto;
  max-width: 720px;

  h3 {
    color: black;
    border-bottom: 2px solid black;
    padding-bottom: 8px;
    margin-bottom: 0;
  }

  > div {
    padding: 0 0;
  }

  > span {
    display: block;
    padding: 30px 0;
    text-align: center;
    font-size: 13px;
    color: #383737;
  }

  p {
    margin: 8px;
    text-align: center;
  }
`;

export const LearnerRegistrationApplicantBlock = styled(Container)`
  margin: 24px auto 36px;
  max-width: 720px;

  &.full {
    max-width: 1080px;
  }

  h3 {
    color: black;
    border-bottom: 2px solid black;
    padding-bottom: 8px;
    margin-bottom: 0;
  }

  p {
    margin: 8px;
    text-align: center;
  }

  > span {
    display: block;
    padding: 30px 0;
    text-align: center;
    font-size: 13px;
    color: #383737;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid lightgrey;
    font-weight: bold;
    min-height: 43px;
    > span {
      display: inline-block;
      text-align: right;
      font-weight: normal;
      width: 175px;
      min-width: 175px;
      margin-right: 20px;
    }

    > div.row,
    > form,
    > form div.row {
      flex-grow: 1;
      align-items: center;
    }

    label.wrapper {
      margin-right: 25px;
    }

    &.status-line {
      justify-content: center;
      span {
        width: auto;
        margin-right: 0;
      }
    }
    &.status-line--success {
      color: #098b5b;
    }
    &.status-line--failed {
      color: red;
    }
    &.status-line--error {
      color: darkred;
    }
  }

  li.upload-row {
    > div {
      justify-content: space-between;
    }
  }
`;

export const TitleBarWithCta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  border-bottom: 2px solid black;
  padding-bottom: 8px;
  margin-bottom: 0;

  h3 {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;
