import { useQuery } from '@tanstack/react-query';
import Learner from '../../models/Learner';
import { AppError } from './app-error';
import { request } from './fetch';

const getLearner = async (learnerId: string) => request.get<Learner>(`/api/learner-profile/learners/${learnerId}`);

export const useLearner = (learnerId: string) =>
  useQuery({
    queryKey: ['learners', learnerId],
    queryFn: () => getLearner(learnerId),
    suspense: true,
    retry(failureCount, error) {
      if (failureCount >= 3) return false;
      // if learner does not found, do not call server again
      if (error instanceof AppError && error.data?.code === 'LEARNER_DOESNOT_EXIST') {
        return false;
      }
      return true;
    },
  });
