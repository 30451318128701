import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { LearnerRegistrationListBlock } from '../styled.components';

import { WithLoading } from '../../helper-components/loading.component';
import { RegistrationItem } from '../../../models/LearnerRegistration';

type RequestStateType = 'initial' | 'loading' | 'success' | 'error';

export interface RegistrationsListProps {
  activeRegistrationState: RequestStateType;
  activeRegistrations: Array<RegistrationItem> | undefined;
  selectedRegistrationIndex: number;
  setSelectedRegistrationIndex: (index: number) => void;
}

// Component to display list of registrations belonging to a learner
const RegistrationsListComponent = (props: RegistrationsListProps) => {
  const { activeRegistrationState, activeRegistrations, selectedRegistrationIndex, setSelectedRegistrationIndex } =
    props;

  return (
    <LearnerRegistrationListBlock>
      <WithLoading
        loading={activeRegistrationState === 'initial' || activeRegistrationState === 'loading'}
        loadingText="Loading registration list for applicant"
      >
        <div>
          {activeRegistrations?.map((item, index) => (
            <button
              type="button"
              className={
                selectedRegistrationIndex === index
                  ? 'registration-item registration-item__current'
                  : 'registration-item'
              }
              key={`reg-${item.registrationId}`}
              onClick={() => setSelectedRegistrationIndex(index)}
            >
              <span>
                {selectedRegistrationIndex === index && <FiSearch />}
                {!!item.supersededByRegistrationId && (
                  <del>
                    {item.productCode} {item.productType}
                  </del>
                )}
                {!item.supersededByRegistrationId && (
                  <>
                    {item.productCode} {item.productType}
                  </>
                )}
              </span>
              <span aria-label={`Last resumed on ${item.lastResumedOn}`}>
                {new Date(item.lastResumedOn).toLocaleString()}
              </span>
            </button>
          ))}
        </div>
      </WithLoading>
    </LearnerRegistrationListBlock>
  );
};

export default RegistrationsListComponent;
