import styled from 'styled-components';
import { Row } from 'react-bootstrap';

interface ICheckBoxProps {
  isReadOnly: boolean;
}

export const CourseRow = styled(Row)`
  padding: 0.5rem;
`;

export const StylesForCollapsePanel = styled.div`
  .Collapsible {
    background-color: #f7f7f7;
  }
  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid $lightGrey;
    border-top: 0;

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: $grey;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: $cyan;
    color: black;

    &:after {
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      color: #4e5861;
      content: '\f054';
      font-size: 30px;
      position: absolute;
      right: 10px;
      top: -2px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(90deg);
      }
    }

    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }

  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
  }

  .CustomTriggerCSS--open {
    background-color: darkslateblue;
  }

  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #cbb700;
    color: black;
  }
`;
export const StylesForCheckBox = styled.div<ICheckBoxProps>`
  .checkbox {
    display: inline-flex;
    position: relative;
    margin: 0.5rem;
    cursor: ${(props) => (props.isReadOnly ? 'default' : 'pointer')};
  }

  .checkbox > span {
    color: #34495e;
    padding: 0.1rem 0.25rem;
  }

  .checkbox > input {
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #34495e;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
    cursor: ${(props) => (props.isReadOnly ? 'default' : 'pointer')};
  }

  .checkbox > input:checked {
    border: 1px solid #41b883;
    opacity: ${(props) => (props.isReadOnly ? '0.5' : '1')};
  }

  .checkbox > input:checked + span::before {
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    color: #4e5861;
    font-size: 20px;
    content: '\f00c';
    top: -0.2rem;
    display: block;
    color: #41b883;
    opacity: ${(props) => (props.isReadOnly ? '0.5' : '1')};
    position: absolute;
    left: 0.1rem;
  }

  .checkbox > input:active {
    border: ${(props) => (props.isReadOnly ? '1px solid #41B883' : '2px solid #34495E')};
  }
`;
