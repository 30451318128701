export enum RequirementStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
}

export enum AdmittanceStatusEnum {
  Initiated = 'Initiated',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Superseded = 'Superseded',
}

export enum RequirementType {
  PersonalInfo = 'PersonalInfo',
  EnglishProficiency = 'EnglishProficiency',
  EducationInfo = 'EducationInfo',
  UnofficialTranscript = 'UnofficialTranscript',
  GovIdentity = 'GovIdentity',
  PhotoIdentity = 'PhotoIdentity',
  Agreement = 'Agreement',
  TermsAndConditions = 'TermsAndConditions',
  PreviousDegreeLevel = 'PreviousDegreeLevel',
  TuitionFee = 'TuitionFee',
  ApplicationFee = 'ApplicationFee',
  AdmissionDecision = 'AdmissionDecision',
  StartDate = 'StartDate',
}

export type RegistrationRequirement = {
  requirement: RequirementType;
  status: RequirementStatus;
};

export interface RegistrationItem {
  registrationId: string;
  productType: string;
  productCode: string;
  partnerId: string | null;
  status: string;
  lastUpdatedOn: string;
  lastResumedOn: string;
  requirements: RegistrationRequirement[];
  supersededByRegistrationId?: string;
  supersederRegistrationId?: string;
}

export interface PersonalDetailsResponse {
  Email: string;
  FirstName: string;
  LastName: string;
  BirthYear: number;
  PhoneCountryCode: string;
  PhoneNumber: string;
  State: string | null;
  City: string;
  Nationality: string;
  Country: string;
  Gender: string;
  HowFoundNexford: string;
  [key: string]: any;
}

// Object setting readable names for the personal details list
export const personalDetailsKeys: { [key: string]: string } = {
  FirstName: 'First name',
  LastName: 'Last name',
  Email: 'Email',
  BirthYear: 'Year of birth',
  PhoneNumber: 'Phone number',
  Nationality: 'Nationality',
  Country: 'Country',
  State: 'State',
  City: 'City',
  Gender: 'Gender',
  HowFoundNexford: 'Found Nexford on',
};

export interface EducationProfileResponse {
  HighestEducationLevel: string;
  GraduationYear: number;
  InstitutionName: string;
  InstitutionLanguage: string;
  InstitutionCountry: string;
  DegreeOrCertificateName: string;
  IsBusinessRelated: boolean;
  Accredited: boolean;
  [key: string]: any;
}

// Object setting the order and readable names for the education info list
export const educationInfoKeys: { [key: string]: string } = {
  InstitutionName: 'Institution Name',
  Accredited: 'Is an accredited institution',
  GraduationYear: 'Graduation year',
  HighestEducationLevel: 'Highest education level',
  DegreeOrCertificateName: 'Degree Or Certificate name',
  IsBusinessRelated: 'Is business related',
  InstitutionCountry: 'Institution Country',
};

export interface EnglishProficiency {
  IsEnglishPrimaryEducationLanguage: boolean;
  IsNativeEnglishSpeaker: boolean;
}

export interface AdmittanceLearnerDataResponse extends PersonalDetailsResponse, EducationProfileResponse {}

export interface AdmittanceResponse {
  decisionDate: string;
  decisionMadeBy: string;
  initiatedAt: string;
  productCode: string;
  productType: string;
  status: AdmittanceStatusEnum;
  decisionTicketUrl?: string;
  admittanceLearnerData?: AdmittanceLearnerDataResponse;
  missingAepDocuments?: Array<{
    isDefault: boolean;
    name: string;
    status: string;
  }>;
  comment?: string;
  aepDocumentUpdatedDate?: string;
  aepDocuments: Array<{
    isDefault: boolean;
    name: string;
    status: string;
  }>;
}

export interface ProductItem {
  FriendlyName: string;
  ProductCode: string;
  ProductType: string;
}
