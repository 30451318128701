import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { usePartners } from '../../../hooks/use-partners';
import styles from './partners-table.module.scss';
import { PartnerInfo } from 'models/Partner';
import { StripedTable } from 'components/ui/table';

type RowProps = {
  partner: PartnerInfo;
};

const Row = ({ partner }: RowProps) => (
  <tr>
    <td>{partner.partnerId}</td>
    <td>{partner.accountName}</td>
    <td>{partner.email}</td>
    <td>{partner.contactName?.trim() || 'N/A'}</td>
    <td>
      <Link to={`/partners/${partner.partnerId}`}>Manage</Link>
    </td>
  </tr>
);

const Placeholder = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <tr>
    <td colSpan={6} className={cn(styles.placeholder, className)}>
      {children}
    </td>
  </tr>
);

const Content = () => {
  const { data: partners, isLoading, error } = usePartners();

  if (isLoading) {
    return <Placeholder>Loading partners...</Placeholder>;
  }

  if (error) {
    return <Placeholder className={styles.error}>Failed to load partners. Please try again later.</Placeholder>;
  }

  if (!partners?.length) {
    return <Placeholder>There are no partners in the system</Placeholder>;
  }

  return (
    <tbody>
      {partners.map((partner) => (
        <Row key={partner.partnerId} partner={partner} />
      ))}
    </tbody>
  );
};

export const PartnersTable = () => (
  <StripedTable className={styles.table}>
    <thead>
      <tr>
        <th>Customer ID</th>
        <th>Account Name</th>
        <th>Email</th>
        <th>Contact Name</th>
        <th>{}</th>
      </tr>
    </thead>
    <Content />
  </StripedTable>
);
