import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { IReadmissionPermissionData, IReadmissionPermissionState } from '../reducers/readmission/readmission.model';

export const getReadmissionState = (state: IRootState): IReadmissionPermissionState => state.readmissionState;

export const getReadmissionPremissions = createSelector(
  [getReadmissionState],
  (readmissionState: IReadmissionPermissionState): IReadmissionPermissionData => readmissionState.permissionsData,
);
