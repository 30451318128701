import { useQuery } from '@tanstack/react-query';
import { usePartnerAgreementsParams } from './use-params';
import { getPropertyValues } from 'shared/api/partner';

export const usePropertyValues = () => {
  const { partnerId } = usePartnerAgreementsParams();

  return useQuery({
    queryKey: ['partner', partnerId, 'product-groups'],
    queryFn: () => getPropertyValues(),
    suspense: true,
  });
};
