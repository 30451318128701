export type PartnerCode = {
  id: number;
  generatedBy: string;
  created: string;
  partnerId: string;
  codesCount: number;
  codesType: CodesType;
};

export type CreatePartnerCodePayload = {
  partnerId: string;
  codesCount: number;
  codesType: CodesType;
};

export enum CodesType {
  Enrollment = 'enrollment',
  Payment = 'payment',
}

export const CODES_TYPE_LABELS = {
  [CodesType.Enrollment]: 'Enrollment',
  [CodesType.Payment]: 'Payment',
};
