import React from 'react';
import { Row, Col } from 'react-bootstrap';
import api from '../../../shared/api/adminUI.api';
import { ISelectedAdmissionOption } from '../../admissions/admissions.model';
import { ISelectOption } from '../../../models/SelectOptions';

import errorHandling from '../../helper-components/alert-component.component';
import { WithLoading } from '../../helper-components/loading.component';
import { capitalize } from '../../helper-components/form-components/form-filed-components/form-filed.components';

interface IAdmissionParametersData {
  acceptanceStatus: string;
  foundationCourses: string[];
}

interface IAdmissionParametersProps {
  readonly acceptanceStatus: string;
  readonly programEnrollmentId: string;
  learnerId: string;
}

interface IAdmissionParametersState {
  admissionParametersDataPending: boolean;
  admissionParametersData: IAdmissionParametersData;
}

export const createAdmissionOptions = (options: ISelectedAdmissionOption[]): ISelectOption[] =>
  (options || []).map(
    (type: ISelectedAdmissionOption): ISelectOption => ({
      label: type.Label,
      value: type.Value,
    }),
  );

class AdmissionParameters extends React.Component<IAdmissionParametersProps, IAdmissionParametersState> {
  constructor(props: IAdmissionParametersProps) {
    super(props);
    this.state = {
      admissionParametersDataPending: false,
      admissionParametersData: {
        foundationCourses: [],
        acceptanceStatus: props.acceptanceStatus,
      },
    };
  }

  componentDidMount() {
    const { programEnrollmentId, learnerId } = this.props;
    this.loadAdmissionParametersData(learnerId, programEnrollmentId);
  }

  private loadAdmissionParametersData = async (learnerId: string, programEnrollmentId: string): Promise<void> => {
    try {
      this.setState({ admissionParametersDataPending: true });
      const admissionDataResponse = await api.program.admissionParameters(learnerId, programEnrollmentId);
      if (!admissionDataResponse.ok) throw await admissionDataResponse.json();
      const admissionParametersData = await admissionDataResponse.json();
      const { foundationCourses, acceptanceStatus } = admissionParametersData;

      this.setState({
        admissionParametersData: { foundationCourses, acceptanceStatus },
        admissionParametersDataPending: false,
      });
    } catch (error: any) {
      errorHandling({ error });
      this.setState({ admissionParametersDataPending: false });
    }
  };

  public render(): JSX.Element {
    const { admissionParametersDataPending, admissionParametersData } = this.state;
    return (
      <>
        <WithLoading loading={admissionParametersDataPending} loadingText="Loading admission parameters...">
          <Row>
            <Col id="acceptanceStatus" xs={9} style={{ alignSelf: 'center' }}>
              <span>{capitalize(admissionParametersData.acceptanceStatus)} </span>
            </Col>
          </Row>
        </WithLoading>
      </>
    );
  }
}

export default AdmissionParameters;
