import React from 'react';
import { Spinner } from 'react-bootstrap';
import parse from 'html-react-parser';
import { RouteComponentProps } from 'react-router-dom';
import errorHandling from '../helper-components/alert-component.component';
import api from '../../shared/api/adminUI.api';
import { TranscriptContainer } from './transcript-styled-components';

interface MatchParams {
  learnerId: string;
}

interface ITranscriptState {
  transcriptHtml: string;
  transcriptPending: boolean;
}

class Transcript extends React.Component<RouteComponentProps<MatchParams>, ITranscriptState> {
  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    this.state = {
      transcriptHtml: '',
      transcriptPending: true,
    };
  }

  public componentDidMount() {
    const { match } = this.props;
    const { learnerId } = match.params;
    this.loadTranscript(learnerId);
  }

  private readonly loadTranscript = async (learnerId: string): Promise<void> => {
    try {
      const response = await api.transcript.getTranscriptHtml(learnerId);
      if (!response.ok) throw await response;

      const transcriptHtml = await response.text();
      this.setState({
        transcriptPending: false,
        transcriptHtml,
      });
    } catch (error: any) {
      errorHandling({ error });
      this.setState({
        transcriptPending: false,
      });
    }
  };

  render(): JSX.Element {
    const { transcriptHtml, transcriptPending } = this.state;

    return (
      <>
        <TranscriptContainer>
          {transcriptPending && <Spinner size="sm" animation="border" />}
          {!transcriptPending && parse(transcriptHtml)}
        </TranscriptContainer>
      </>
    );
  }
}

export default Transcript;
