import { Form, FormProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormField } from '../../../components/form-field/form-field';
import { ApplicationFee } from './application-fee/application-fee';
import { ProductsTableForm } from './products-table-form/products-table-form';
import { composeValidators, minLength, required } from '../../../../../utils/validate.utils';
import { AgreementDates } from './agreement-dates/agreement-dates';
import { ProductsForm } from './products-form/products-form';
import { PartnerAgreementFormValues } from '../../types';
import { HelperText } from '../../../../ui/helper-text/helper-text';
import { FormActions } from './form-actions';
import { AppError } from '../../../../../shared/api/app-error';
import styles from './form-content.module.scss';
import { EmailDomainsInput } from './email-domains-input/email-domains-input';

type Props = {
  error?: any;
} & FormProps<PartnerAgreementFormValues>;

export const FormContent = ({ error, ...props }: Props) => {
  const appError = error ? AppError.fromError(error) : null;

  return (
    <Form<PartnerAgreementFormValues>
      mutators={{
        ...arrayMutators,
      }}
      {...props}
      render={({ handleSubmit }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <AgreementDates />
            <FormField
              name="enrollmentAgreementId"
              label="Enrollment Agreement"
              validate={composeValidators(required, minLength(3))}
            />
          </div>
          <EmailDomainsInput />
          <ApplicationFee />
          <ProductsForm />
          <ProductsTableForm />
          {appError ? <HelperText variant="error">{appError.message}</HelperText> : null}
          <FormActions />
        </form>
      )}
    />
  );
};
