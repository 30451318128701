import { request } from './fetch';

export enum SuspensionRule {
  AEP = 'AEP',
  Financial = 'Financial',
  Manual = 'Manual',
}

export const getSuspensionRules = async (learnerId: string) =>
  request.get<SuspensionRule[]>(`/api/suspensions-api/v1/learners/${learnerId}/suspensions/rules`);

export type FinancialSuspension = {
  suspension: string;
  shouldBeSuspended: boolean;
  timestamp: string;
  possibleSuspensionDate: string;
};

export type FinancialSuspensionResponse = {
  status: string;
  currentSuspensions: FinancialSuspension[];
};

export const getFinancialSuspensions = async (learnerId: string) =>
  request.get<FinancialSuspensionResponse>(`/api/suspensions-api/v1/learners/${learnerId}/suspensions`);

export const updateFinancialSuspension = async (
  applicantId: string,
  payload: {
    isDisabled: boolean;
  },
) =>
  request.post(`/api/suspensions-api/v1/learners/${applicantId}/suspensions/disabled`, {
    body: JSON.stringify(payload),
  });
