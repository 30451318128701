import { IAEPDocument } from 'components/admissions/admissions.model';
import { request } from './fetch';
import { useQuery } from '@tanstack/react-query';

export const aepDocumentOptions = () => request.get<IAEPDocument[]>('/api/admission/aepdocuments');

export const useAepDocumentOptions = () =>
  useQuery({
    queryKey: ['aepDocumentOptions'],
    queryFn: aepDocumentOptions,
  });
