import { useQuery } from '@tanstack/react-query';
import {
  AdmittanceResponse,
  PersonalDetailsResponse,
  ProductItem,
  RegistrationItem,
} from '../../models/LearnerRegistration';
import { request } from './fetch';

export const getProductList = async () => request.get<ProductItem[]>('/api/requirements/versions/latest/products');

export type PartnerProduct = {
  code: string;
  type: string;
  level: string;
};

export const getPartnerProductList = async (partnerId: string, learnerId: string) =>
  request.get<PartnerProduct[]>(
    `/api/adminaggregator/v1/partners/${partnerId}/learners/${learnerId}/available-products`,
  );

export const getActiveRegistrations = async (applicantId: string) =>
  request.get<RegistrationItem[]>(`/api/registration/learners/${applicantId}/registrations`);

export const getPersonalDetails = async (applicantId: string) =>
  request.get<PersonalDetailsResponse>(`/api/learner-profile/v2/learners/${applicantId}`);

export const usePersonalDetails = (applicantId: string, { enabled }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: ['personal-details', applicantId],
    queryFn: () => getPersonalDetails(applicantId),
    enabled,
  });

export const rejectAdmittance = async (
  applicantId: string,
  registrationId: string,
  payload: {
    comment: string;
  },
) =>
  request.post(`/api/adminaggregator/learners/${applicantId}/admittance/${registrationId}/reject`, {
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
    },
  });

export const getAdmittanceStatus = async (applicantId: string, registrationId: string) =>
  request.get<AdmittanceResponse>(`/api/admittance-api/learners/${applicantId}/admittance/${registrationId}`);

export const useAdmittanceStatus = (applicantId: string, registrationId?: string) =>
  useQuery({
    queryKey: ['admittance-status', applicantId, registrationId],
    queryFn: async () => {
      const parsedResponse = await getAdmittanceStatus(applicantId, registrationId!);

      if (parsedResponse && parsedResponse.admittanceLearnerData) {
        // admittance returns the same keys as learner-profile API but with different capitalisation
        // Modifying the keys on admittance learner data to align with the other responses
        {
          Object.entries(parsedResponse.admittanceLearnerData).forEach(([key, value]) => {
            const newKey = key.charAt(0).toUpperCase() + key.slice(1);
            // @ts-ignore
            parsedResponse.admittanceLearnerData[newKey] = value;
            // @ts-ignore
            delete parsedResponse.admittanceLearnerData[key];
          });
        }
      }
      return parsedResponse;
    },
    enabled: !!registrationId,
  });

export const acceptAdmittance = async (
  applicantId: string,
  registrationId: string,
  payload?: {
    productType: string;
    productCode: string;
    comment: string;
    partnerId?: string;
  },
) =>
  request.post(`/api/adminaggregator/learners/${applicantId}/admittance/${registrationId}/accept`, {
    body: payload ? JSON.stringify(payload) : null,
    headers: {
      'content-type': 'application/json',
    },
  });
