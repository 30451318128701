import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../api/adminUI.api';
import IError from '../../models/Error';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  GET_AEP_DOCUMENTS_ERROR,
  GET_AEP_DOCUMENTS_PENDING,
  GET_AEP_DOCUMENTS_SUCCESS,
  UPDATE_AEP_DOCUMENTS,
} from '../reducers/aep-documents/aep-documents.constants';

import { IAEPLearnerDocument } from '../reducers/aep-documents/aep-documents.model';
import { IUpdateAepDocumentsRequest } from '../../components/learner-files/learner-files.model';

export const getAepDocumentsObjectPending = (): AnyAction => action(GET_AEP_DOCUMENTS_PENDING);
export const getAepDocumentsObjectSUCCES = (aepDocuments: IAEPLearnerDocument[]): AnyAction =>
  action(GET_AEP_DOCUMENTS_SUCCESS, aepDocuments);
export const getAepDocumentsObjectFailure = (error: IError): AnyAction => action(GET_AEP_DOCUMENTS_ERROR, error);

export const getLearnerAepDocumentsInfo = (learnerId: string) => async (dispatch: Dispatch) => {
  dispatch(getAepDocumentsObjectPending());
  try {
    const response = await api.aepDocuments.learnerAepDocuments(learnerId);
    if (!response.ok) throw await response;
    const data = (await response.json()) as IAEPLearnerDocument[];
    dispatch(getAepDocumentsObjectSUCCES(data));
  } catch (error: any) {
    dispatch(getAepDocumentsObjectFailure(error));
    errorHandling({ error });
  }
};

export const updateLearnerAepDocumentsInfo =
  (learnerId: string, aepDocumetsRequest: IUpdateAepDocumentsRequest) => async (dispatch: Dispatch) => {
    try {
      const response = await api.aepDocuments.updateLearnerAepDocuments(learnerId, aepDocumetsRequest);
      if (!response.ok) throw await response;
      dispatch({
        type: UPDATE_AEP_DOCUMENTS,
        payload: aepDocumetsRequest.aepDocuments,
      });
    } catch (error: any) {
      errorHandling({ error });
    }
  };
