import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { ILearnerProfileState } from '../reducers/learner-profile/learner-profile.reducer';
import IError from '../../models/Error';

export const getLearnerProfileState = (state: IRootState): ILearnerProfileState => state.learnerProfileState;

export const getLearnerProfilePermission = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): boolean => learnerProfileState.canReadLearnerProfile,
);

export const getLearnerProfileViewPermissionStatus = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): boolean => learnerProfileState.canReadLearnerProfilePending,
);

export const getLearnerProfileViewPermissionError = createSelector(
  [getLearnerProfileState],
  (learnerProfileState: ILearnerProfileState): IError | null => learnerProfileState.canReadLearnerProfileError,
);
