import { getPartnerProductList, PartnerProduct } from '../../../shared/api/learner-registration';
import { useField } from 'react-final-form';
import { PARTNER } from '../../admissions/admissions-form/admissions-form.constants';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProductList } from '../../../shared/api/learner-registration';
import { useMemo } from 'react';

export const useProducts = () => {
  const { applicantId } = useParams<{ applicantId: string }>();
  const { input: partnerInput } = useField<string>(PARTNER);
  const { data: products, isLoading: isProductsLoading } = useQuery({
    queryFn: () => getProductList(),
    queryKey: ['products'],
  });

  const partnerId = partnerInput.value;

  const { data: partnerProducts, isLoading: isPartnerProductsLoading } = useQuery({
    queryKey: ['partners', partnerId, 'products'],
    queryFn: () => getPartnerProductList(partnerId, applicantId),
    enabled: !!partnerId,
  });

  const filteredProducts = useMemo<PartnerProduct[]>(() => {
    if (partnerId) return partnerProducts ?? [];

    return (
      products?.map((p) => ({
        code: p.ProductCode,
        level: p.FriendlyName,
        type: p.ProductType,
      })) ?? []
    );
  }, [products, partnerProducts, partnerId]);

  return {
    data: filteredProducts,
    isLoading: partnerId ? isPartnerProductsLoading : isProductsLoading,
  };
};
