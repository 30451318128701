import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PersonalDetailsCard } from '../../styled-components';
import { BoldColumn } from '../../../learner-info/styled-components';
import { IEducationInfo } from '../../admissions.model';
import { StyledWrapper } from '../styled-components';

interface IApplicantPriorEducationProps {
  educationInfo: IEducationInfo | null;
}
const ApplicantPriorEducation: React.FC<IApplicantPriorEducationProps> = (
  props: IApplicantPriorEducationProps,
): JSX.Element => {
  const { educationInfo } = props;
  return (
    <StyledWrapper>
      <h3>Prior Education</h3>
      <PersonalDetailsCard>
        <Row>
          <Col xs={6}>
            <span>Institution Name:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{educationInfo && educationInfo.InstitutionName}</span>
          </BoldColumn>
        </Row>

        <Row>
          <Col xs={6}>
            <span>Institution Language:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{educationInfo && educationInfo.InstitutionLanguage}</span>
          </BoldColumn>
        </Row>

        <Row>
          <Col xs={6}>
            <span>Graduation Year:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{educationInfo && educationInfo.GraduationYear}</span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Country:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{educationInfo && educationInfo.InstitutionCountry}</span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Highest education level:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{educationInfo && educationInfo.HighestEducationLevel}</span>
          </BoldColumn>
        </Row>
        {educationInfo && educationInfo.DegreeOrCertificateName && (
          <Row>
            <Col xs={6}>
              <span>Degree Or Certificate Name:</span>
            </Col>
            <BoldColumn xs={6}>
              <span>{educationInfo.DegreeOrCertificateName}</span>
            </BoldColumn>
          </Row>
        )}
      </PersonalDetailsCard>
    </StyledWrapper>
  );
};

export default ApplicantPriorEducation;
