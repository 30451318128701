import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import { StepperAction, StepperContext, StepperController } from '../../ui/stepper';

interface ISubmitButtonProps {
  checkedItems: Map<string, boolean>;
  isDisabled: boolean;
  learnerId: string;
  programEnrollmentId: string;

  onSubmit: (
    e: React.FormEvent,
    checkedItems: Map<string, boolean>,
    resolve: (data: string) => void,
    learnerId: string,
    programEnrollmentId: string,
  ) => void;
  pendingSave: boolean;
}

const SubmitButton: React.FunctionComponent<ISubmitButtonProps> = (props: ISubmitButtonProps) => {
  const { checkedItems, learnerId, programEnrollmentId, isDisabled, onSubmit, pendingSave } = props;
  const { resolve } = React.useContext(StepperContext as any) as StepperController;

  return (
    <StepperAction
      align="right"
      onClick={(e) => onSubmit(e, checkedItems, resolve, learnerId, programEnrollmentId)}
      disabled={isDisabled}
    >
      Continue
      {pendingSave && <Spinner animation="border" size="sm" />}
    </StepperAction>
  );
};
export default SubmitButton;
