import React from 'react';
import { Table } from 'react-bootstrap';
import UploadedFileItem from './list-item';
import { ISelectOption } from '../../../models/SelectOptions';
import { IUploadedFile } from '../../../models/UploadedFile';
import { ILearnerFilePermissions } from '../learner-files.model';

interface IUploadedFilesTable {
  uploadedFilesList: IUploadedFile[];
  categoryOptions: ISelectOption[];
  learnerId: string;

  fileDeletedEvent: (fileName: string) => void;
  fileUpdatedEvent: (learnerId: string, file: IUploadedFile) => void;
  learnerFilePermissions: ILearnerFilePermissions;
  isLearnerRegistration?: boolean;
}

const header = ['Uploaded', 'Category', 'Author', 'Filename', 'File', 'Comment'];

const renderTableHeader = () => header.map((key) => <th key={key}>{key.toUpperCase()}</th>);

const renderTableData = (
  files: IUploadedFile[],
  categoryOptions: ISelectOption[],
  learnerId: string,

  fileDeletedEvent: (fileName: string) => void,
  fileUpdatedEvent: (learnerId: string, file: IUploadedFile) => void,
  learnerFilePermissions: ILearnerFilePermissions,
  isLearnerRegistration?: boolean,
): JSX.Element[] =>
  files.map((file) => (
    <UploadedFileItem
      key={file.fileName}
      id={file.fileName}
      file={file}
      categoryOptions={categoryOptions}
      learnerId={learnerId}
      fileDeletedEvent={fileDeletedEvent}
      fileUpdatedEvent={fileUpdatedEvent}
      learnerFilePermissions={learnerFilePermissions}
      isLearnerRegistration={isLearnerRegistration}
    />
  ));

const UploadedFilesTable: React.FunctionComponent<IUploadedFilesTable> = (props: IUploadedFilesTable) => {
  const {
    uploadedFilesList,
    categoryOptions,
    learnerId,
    fileDeletedEvent,
    fileUpdatedEvent,
    learnerFilePermissions,
    isLearnerRegistration,
  } = props;
  return (
    <>
      <Table>
        <thead>
          <tr>{renderTableHeader()}</tr>
        </thead>
        <tbody>
          {renderTableData(
            uploadedFilesList,
            categoryOptions,
            learnerId,
            fileDeletedEvent,
            fileUpdatedEvent,
            learnerFilePermissions,
            isLearnerRegistration,
          )}
        </tbody>
      </Table>
    </>
  );
};

export default UploadedFilesTable;
