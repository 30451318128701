import { Table } from '@tanstack/react-table';
import { createContext, useContext } from 'react';

export type Pagination = {
  currentPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
  pageSize: number;
};

type TableContextValue<D> = Table<D> & {
  loading?: boolean;
  pagination?: Pagination;
  emptyText?: string;
};

export const TableContext = createContext<TableContextValue<any> | null>(null);

export const useTableContext = <D = any,>() => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error('TableContext is not provided');
  }

  return context as TableContextValue<D>;
};
