import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faHistory, faCalendar, faParachuteBox } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import UniversityDate from '../university-date';
import { ILearnerAchievementArtifact } from '../../shared/actions/learner-profile.action';
import { Artifacts } from './artifacts';

const STATUS__VERIFIED = 'verified';

interface IProps {
  learnerId: string;

  category: string;
  name: string;
  productCode: string;
  productType: string;
  achievementType: 'credential' | 'non-credential' | null;
  date: Date | null | undefined;
  status: string | null;
  approvedBy?: string | null | undefined;
  canApproveAchievements?: boolean;
  approvalDate?: Date | null | undefined;
  artifacts?: ILearnerAchievementArtifact[];
  openModal?: () => void;
}

const AchievementRow: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    category,
    name,
    productCode,
    productType,
    achievementType,
    date,
    status,
    canApproveAchievements,
    approvedBy,
    approvalDate,
    openModal,
    artifacts,
    learnerId,
  } = props;

  return (
    <>
      <td>
        {category}
        <br />
        {name}
      </td>
      <td>{productCode}</td>
      <td>{productType}</td>
      <td>
        <UniversityDate value={date} />
      </td>
      {achievementType === 'non-credential' && (
        <td>
          <Artifacts learnerId={learnerId} artifacts={artifacts} />
        </td>
      )}
      {achievementType === 'credential' && (
        <td>
          <StatusBlock>
            <StatusCol>
              <IconBlock>
                <FontAwesomeIcon icon={faHistory} />
              </IconBlock>
              <DataTitle>Status:</DataTitle>
              {status && status !== STATUS__VERIFIED && canApproveAchievements && openModal ? (
                <StatusTitle onClick={() => openModal()}>{status} </StatusTitle>
              ) : (
                <StatusTitleVerified>{status} </StatusTitleVerified>
              )}
            </StatusCol>
            {status && status === STATUS__VERIFIED && (
              <div>
                <StatusCol>
                  <IconBlock>
                    <FontAwesomeIcon icon={faUserCheck} />
                  </IconBlock>
                  <DataTitle>Approved By:</DataTitle>
                  {approvedBy}
                </StatusCol>
                <StatusCol>
                  <IconBlock>
                    <FontAwesomeIcon icon={faCalendar} />
                  </IconBlock>
                  <DataTitle>Date:</DataTitle>
                  <UniversityDate value={approvalDate} />
                </StatusCol>
                {artifacts && (
                  <StatusCol>
                    <IconBlock>
                      <FontAwesomeIcon icon={faParachuteBox} />
                    </IconBlock>
                    <DataTitle>Artifacts:</DataTitle>
                  </StatusCol>
                )}
              </div>
            )}
          </StatusBlock>
          <Artifacts learnerId={learnerId} artifacts={artifacts} />
        </td>
      )}
    </>
  );
};

export default AchievementRow;

const IconBlock = styled.div`
  display: inline-block;
  width: 40px;
`;

const DataTitle = styled.span`
  font-size: 14px;
  font-weight: bold !important;
  padding-right: 5px;
  min-width: 100px;
  color: #030404;
`;

const StatusTitle = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const StatusTitleVerified = styled.span`
  text-decoration: underline;
`;

const StatusCol = styled.div`
  padding-bottom: 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  .svg-inline--fa {
    font-size: 21px;
    margin-right: 10px;
    &.fa-history {
      font-size: 24px;
    }
  }
`;

const StatusBlock = styled.div`
  padding: 10px 0;
  && {
    tr {
      font-size: 14px;
    }
  }
`;
