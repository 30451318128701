import React from 'react';
import { FormControl, Form } from 'react-bootstrap';
import handleValidation from './validation-functions';

interface ITextFieldProps {
  fieldValue: string;
  fieldType: string;
  propertyName: string;
  validationError: string | null;
  setNewFieldValue: (newValue: string) => void;
  setFormError: (error: string | null) => void;
  actions?: React.ReactNode;
}

const TextField: React.FunctionComponent<ITextFieldProps> = ({
  fieldValue,
  fieldType,
  propertyName,
  setNewFieldValue,
  setFormError,
  validationError,
  actions,
}: ITextFieldProps) => {
  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormError(handleValidation(newValue, fieldType, propertyName));
    setNewFieldValue(newValue);
  };
  return (
    <>
      <FormControl
        aria-describedby="basic-addon2"
        size="sm"
        required
        maxLength={200}
        type={fieldType}
        isInvalid={!!validationError}
        isValid={!validationError}
        style={{ fontSize: '1rem' }}
        placeholder={fieldValue}
        onChange={onTextChange}
        value={fieldValue}
      />
      {/** TODO Find a better way to handle this */}
      {actions}
      <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
    </>
  );
};
export default TextField;
