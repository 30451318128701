import { Dispatch } from 'redux';
import FilterValues from '../../components/learner-search/models/FilterValues';
import SearchResponse from '../../components/learner-search/models/SearchResponse';
import SearchResult from '../../components/learner-search/models/SearchResult';
import { request } from 'shared/api/fetch';

interface IGetFilterValuesAction {
  type: 'learnerSearch/GET_FILTER_VALUES_FULFILLED';
  payload: {
    filterValues: FilterValues;
  };
}

interface IGetFilterValuesPendingAction {
  type: 'learnerSearch/GET_FILTER_VALUES_PENDING';
}

interface IGetFilterValuesRejectedAction {
  type: 'learnerSearch/GET_FILTER_VALUES_REJECTED';
  payload: {
    error: string;
  };
}

interface IGetSearchResultsAction {
  type: 'learnerSearch/GET_SEARCH_RESULTS_FULFILLED';
  payload: {
    searchResponse: SearchResponse;
  };
}

interface IGetSearchResultsPendingAction {
  type: 'learnerSearch/GET_SEARCH_RESULTS_PENDING';
}

interface IGetSearchResultsRejectedAction {
  type: 'learnerSearch/GET_SEARCH_RESULTS_REJECTED';
  payload: {
    error: string;
  };
}

const initialState = {
  filterStatus: 'Loading' as 'Loading' | 'Errored' | 'Loaded',
  resultStatus: 'Empty' as 'Empty' | 'Loading' | 'Errored' | 'Loaded',
  filterValues: {} as FilterValues,
  searchResponse: {} as SearchResponse,
  error: '',
};

type KnownAction =
  | IGetFilterValuesAction
  | IGetFilterValuesPendingAction
  | IGetFilterValuesRejectedAction
  | IGetSearchResultsAction
  | IGetSearchResultsPendingAction
  | IGetSearchResultsRejectedAction;

export type LearnerSearchState = Readonly<typeof initialState>;

export default (state: LearnerSearchState = initialState, action: KnownAction): LearnerSearchState => {
  switch (action.type) {
    case 'learnerSearch/GET_FILTER_VALUES_PENDING':
      return {
        ...state,
        filterStatus: 'Loading',
      };
    case 'learnerSearch/GET_FILTER_VALUES_REJECTED':
      return {
        ...state,
        filterStatus: 'Errored',
        error: action.payload.error,
      };
    case 'learnerSearch/GET_FILTER_VALUES_FULFILLED':
      return {
        ...state,
        filterStatus: 'Loaded',
        filterValues: action.payload.filterValues,
        error: '',
      };
    case 'learnerSearch/GET_SEARCH_RESULTS_PENDING':
      return {
        ...state,
        resultStatus: 'Loading',
      };
    case 'learnerSearch/GET_SEARCH_RESULTS_REJECTED':
      return {
        ...state,
        resultStatus: 'Errored',
        error: action.payload.error,
      };
    case 'learnerSearch/GET_SEARCH_RESULTS_FULFILLED':
      return {
        ...state,
        resultStatus: 'Loaded',
        searchResponse: action.payload.searchResponse,
        error: '',
      };
    default:
      return state;
  }
};

export const loadFilterValues = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: 'learnerSearch/GET_FILTER_VALUES_PENDING' });

    const filterValues = await request.get<FilterValues>('/api/learner-search/filter-values');
    dispatch({
      type: 'learnerSearch/GET_FILTER_VALUES_FULFILLED',
      payload: { filterValues },
    });
  } catch (error: any) {
    dispatch({
      type: 'learnerSearch/GET_FILTER_VALUES_REJECTED',
      payload: { error },
    });
  }
};

export const getSearchResults = (searchString: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: 'learnerSearch/GET_SEARCH_RESULTS_PENDING' });

    const response = await request.get<any>(`/api/learner-search/${searchString}`);
    const searchResponse = {
      Count: response['@odata.count'],
      Results: response.value as SearchResult[],
    };
    dispatch({
      type: 'learnerSearch/GET_SEARCH_RESULTS_FULFILLED',
      payload: { searchResponse },
    });
  } catch (error: any) {
    dispatch({
      type: 'learnerSearch/GET_SEARCH_RESULTS_REJECTED',
      payload: { error },
    });
  }
};
