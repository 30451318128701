import { useQuery } from '@tanstack/react-query';
import { request } from './fetch';

export interface IFXRate {
  rateId: string;
  currencyCode: string;
  fxRate: number;
  effectiveFrom: string;
}

export const getFxRates = async () => request.get<IFXRate[]>('/api/learner-payments-api/fx-rates/current');

export const useFxRates = () =>
  useQuery({
    queryKey: ['fx-rates'],
    queryFn: getFxRates,
  });
