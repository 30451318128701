import { useMemo, useRef } from 'react';
import ReSelect, { ActionMeta, OnChangeValue, Props as ReProps } from 'react-select';
import { HelperText } from '../../helper-text/helper-text';
import { FormElement } from '../form-element/form-element';
import { useField, UseFieldConfig } from 'react-final-form';
import styles from './select.module.scss';
import { ISelectOption } from 'models/SelectOptions';
import cn from 'classnames';

type BaseProps<T, IsMulti extends boolean = false> = {
  error?: string;
  testId?: string;
  className?: string;
} & ReProps<T, IsMulti>;

export const BaseSelect = <T, IsMulti extends boolean>({
  error,
  testId,
  className,
  ...props
}: BaseProps<T, IsMulti>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onMenuOpen = () => {
    const { current } = containerRef;
    if (!current) return;

    setTimeout(() => {
      const selectedEl = current.getElementsByClassName('nxu__option--is-selected')[0];

      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 15);
  };

  return (
    <div className={className} ref={containerRef} data-testid={testId}>
      <ReSelect
        classNamePrefix="nxu"
        onMenuOpen={onMenuOpen}
        placeholder={props.isLoading ? 'Loading...' : 'Select...'}
        classNames={{
          control: ({ isFocused }) => cn(styles.select, error && styles.error, isFocused && styles.focused),
        }}
        {...props}
      />

      {error && <HelperText variant="error">{error}</HelperText>}
    </div>
  );
};

type Props = {
  label: string;
  name: string;
  fieldProps?: UseFieldConfig<string, string | undefined>;
  onChange?: (value: string | undefined, actionMeta: ActionMeta<ISelectOption>) => void;
} & Omit<BaseProps<ISelectOption, false>, 'onChange'>;

// TODO handle group selections
export const Select = ({ label, name, fieldProps = {}, onChange, className, ...props }: Props) => {
  const { input, meta } = useField(name, fieldProps);

  const handleChange = (value: OnChangeValue<ISelectOption, false>, actionMeta: ActionMeta<ISelectOption>) => {
    onChange?.(value?.value, actionMeta);
    input.onChange(value?.value);
  };

  const value = useMemo(() => {
    const newValue = props.options?.find(
      (option) => (option as ISelectOption).value === input.value,
    ) as ISelectOption | null;

    return newValue || null;
  }, [props.options, input.value]);

  return (
    <FormElement className={className}>
      <FormElement.Label>{label}</FormElement.Label>
      <BaseSelect<ISelectOption, false>
        className={styles.content}
        onChange={handleChange}
        value={value}
        error={meta.error}
        {...props}
      />
    </FormElement>
  );
};
