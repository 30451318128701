import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const FileLink = styled.span`
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
`;
export const SpinnerContainer = styled.div`
  text-align: center;
`;

export const FilesLoadingSpinner = styled(Spinner)`
  color: darkgray;
`;
