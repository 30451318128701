import React from 'react';
import { ICourseSelectObject, ICourseSelectState } from '../learner-info.model';
import { CoursesSearch } from '../styled-components';
import searchIcon from '../../../assets/images/search.svg';

class CourseSelect extends React.PureComponent<ICourseSelectObject, ICourseSelectState> {
  constructor(props: ICourseSelectObject) {
    super(props);

    this.state = {
      coursesSearchValue: '',
      isCollapsed: true,
    };
  }

  private handleSearchChange = (e: any): void => {
    this.setState({ coursesSearchValue: e.target.value });
  };

  private handleCollapse = (collapse: boolean): void => {
    this.setState({ isCollapsed: collapse });
  };

  render(): JSX.Element {
    const { coursesSearchValue, isCollapsed } = this.state;
    const { transferableCourses, fields, transferableCoursesPending, handleAddTransferredCourse } = this.props;

    let coursesList = transferableCourses;
    const searchString = coursesSearchValue.toLowerCase();

    if (searchString.length > 0) {
      coursesList = transferableCourses.filter(
        (l) => l.courseName.toLowerCase().match(searchString) || l.courseCode.toLowerCase().match(searchString),
      );
    }

    return (
      <>
        {transferableCourses &&
          transferableCourses.length > 0 &&
          (transferableCoursesPending ? (
            <div className="readmission-loader" />
          ) : (
            <CoursesSearch>
              <div className="search-input-wrapper">
                <img src={searchIcon} alt="Search" className="search-icon" />
                <input
                  className="search-input"
                  type="text"
                  value={coursesSearchValue}
                  onBlur={() => setTimeout(() => this.handleCollapse(true), 200)}
                  onFocus={() => this.handleCollapse(false)}
                  onChange={this.handleSearchChange}
                  placeholder="Search a Nexford University course"
                />
              </div>
              {!isCollapsed && (
                <ul className="search-list">
                  {coursesList.map((course: any, index) => (
                    <li
                      key={`course-item-${index}`}
                      onClick={() => {
                        handleAddTransferredCourse(fields, course);
                        this.handleCollapse(true);
                      }}
                    >
                      {course.courseName} - {course.courseCode}
                    </li>
                  ))}
                </ul>
              )}
            </CoursesSearch>
          ))}
      </>
    );
  }
}

export default CourseSelect;
