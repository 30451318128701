import React from 'react';
import { useParams } from 'react-router-dom';
import { useLearner } from '../../shared/api/learner';
import { SuspenseContainer } from '../suspense-container/suspense-container';

type Props = {
  children: React.ReactNode;
};

// it is moved out of learner container to trigger suspense
export const Content = ({ children }: Props) => {
  const { learnerId } = useParams<{ learnerId: string }>();

  useLearner(learnerId);

  return <>{children}</>;
};

export const LearnerContainer = ({ children }: Props) => (
  <SuspenseContainer
    loadingText="Loading Learner..."
    errorText={(error) => {
      if (error?.data?.code === 'LEARNER_DOESNOT_EXIST') {
        return 'The learner profile you’re looking for doesn’t exist. Please check the details and try again.';
      }
    }}
  >
    <Content>{children}</Content>
  </SuspenseContainer>
);
