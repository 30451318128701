import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventPropStartDate from './utils/event-prop-start-date';

export const startDateUpdatedSelector: (event: IEvent) => IEventDetails = (event: IEvent) => ({
  metadata: {
    type: {
      category: 'System Access',
      eventName: 'Tuition Payments Start Date',
    },
    date: event.updatedDateTime,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventPropStartDate value={event.startDate} primary />
    </Row>
  );
};
