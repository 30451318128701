import { getBearerToken } from 'components/auth/auth.utils';
import React from 'react';

interface WorQIFrameProps {
  readonly uiUrl: string;
  readonly answer: (answer: any, origin: string, answerText: string | null) => Promise<void>;
}

interface WorQIFrameState {
  readonly iFrameHeight: number;
}

class WorQIFrame extends React.Component<WorQIFrameProps, WorQIFrameState> {
  constructor(props: WorQIFrameProps) {
    super(props);

    this.state = { iFrameHeight: 1 };

    (window as any).worq = this;

    this.getBearerToken = this.getBearerToken.bind(this);
    this.onReceiveMessage = this.onReceiveMessage.bind(this);
  }

  public componentDidMount(): void {
    window.addEventListener('message', this.onReceiveMessage, false);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('message', this.onReceiveMessage, false);
  }

  public async onReceiveMessage(e: any): Promise<void> {
    const key = e.message ? 'message' : 'data';
    const data = e[key];
    const { command } = data;

    if (command === 'set-height') {
      this.setState({ iFrameHeight: data.payload });
      return;
    }

    if (command === 'answer') {
      const { answer } = this.props;
      await answer(data.payload, e.origin, null);
      return;
    }

    if (command === 'answerV2') {
      const { answer } = this.props;
      await answer(data.payload.answer, e.origin, data.payload.answerText);
      return;
    }

    throw new Error(`Don't know how to ${command}`);
  }

  /**
   * IMPORTANT: Please do not remove this function, it is
   * being used by the worq iframe to get the bearer token
   */
  public async getBearerToken(): Promise<string> {
    return getBearerToken();
  }

  render(): JSX.Element {
    const { uiUrl } = this.props;
    const { iFrameHeight } = this.state;
    const height = `${iFrameHeight}px`;

    return <iframe title="worq question" src={uiUrl} width="100%" scrolling="no" frameBorder="0" height={height} />;
  }
}

export default WorQIFrame;
