import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getActiveRegistrations } from '../../../shared/api/learner-registration';
import { useLearnerRegistrationParams } from './use-learner-registration-params';
import { SortRequirements } from '../../../utils/registration.utils';
import { RegistrationItem } from '../../../models/LearnerRegistration';
import moment from 'moment';

type Options = Omit<UseQueryOptions<RegistrationItem[]>, 'queryKey' | 'queryFn'>;

export const useActiveRegistrations = (options: Options = {}) => {
  const { applicantId } = useLearnerRegistrationParams();

  return useQuery({
    ...options,
    // @ts-ignore
    queryKey: ['activeRegistrations'],
    queryFn: async () => {
      const registrations = await getActiveRegistrations(applicantId);
      if (!registrations) return [];

      if (registrations?.length > 1) {
        registrations.sort((a, b) => (moment(b.lastResumedOn).isBefore(moment(a.lastResumedOn)) ? -1 : 1));
      }

      registrations.map((reg) => SortRequirements(reg.requirements));

      return registrations;
    },
  });
};
