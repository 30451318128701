import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAepStatusState, IAepStatusStateProps, IDispatchProps, IPropsMain } from './enrollment-modal-aep.model';
import { getAepDocumentsState } from '../../../shared/selectors/aep-documents.selector';
import { IRootState } from '../../../shared/reducers';
import { getLearnerAepDocumentsInfo } from '../../../shared/actions/aep-documents.action';
import AepEditor from './enrollment-modal-aep-editor';
import { IAEPFormValues } from '../aep-status/aep-status-editor-modal/aep-editor-modal.model';
import api from '../../../shared/api/adminUI.api';
import { LongText } from '../styled-components';
import StyledStatus from '../aep-status/styled-components';
import { OK } from '../../admissions/admissions-form/admissions-form.constants';

class AepStatusModal extends React.Component<IPropsMain, IAepStatusState> {
  constructor(props: IPropsMain) {
    super(props);
    this.state = {
      showModal: false,
      aepDocuments: [],
      aepDocumentsDataInternal: [],
    };
  }

  public componentDidMount(): void {
    const {
      getLearnerAEPDocumentsData,
      canViewAepStatus,
      canEditAepStatus,
      aepDocumentsData,
      currentAepStatus,
      handleAepStatusChange,
      learnerId,
    } = this.props;
    if (canViewAepStatus) {
      getLearnerAEPDocumentsData(learnerId);

      if (!currentAepStatus.length) {
        const aepDocsFixed = aepDocumentsData.aepDocuments.map(({ name: document, ...rest }) => ({
          ...rest,
          document,
        }));

        handleAepStatusChange(aepDocsFixed);
      }

      if (canEditAepStatus) {
        this.loadAepDocuments();
      }
    }
  }

  private handleAepSave = async (values: IAEPFormValues) => {
    const { handleAepStatusChange } = this.props;
    const aepDocs = values.learnerAepDocuments;
    this.setState({ aepDocumentsDataInternal: aepDocs });
    handleAepStatusChange(aepDocs);
    this.setState({ showModal: false });
  };

  private handleModal = async (show: boolean) => {
    this.setState({ showModal: show });
  };

  private loadAepDocuments = async (): Promise<void> => {
    const aepDocumentOptionsResponse = await api.aepDocuments.aepDocumentOptions();
    if (!aepDocumentOptionsResponse.ok) throw await aepDocumentOptionsResponse;
    const aepDocuments = await aepDocumentOptionsResponse.json();
    this.setState({ aepDocuments });
  };

  public render(): JSX.Element {
    const { aepDocumentsData, currentAepStatus, isFormEditable } = this.props;
    const { showModal, aepDocuments, aepDocumentsDataInternal } = this.state;
    const currentAepDocuments =
      currentAepStatus && currentAepStatus.length ? currentAepStatus : aepDocumentsDataInternal;
    const completedStatus = 'Completed';
    let currentAepData =
      currentAepDocuments && currentAepDocuments.length ? currentAepDocuments : aepDocumentsDataInternal;

    let missedDocuments = currentAepDocuments.filter((ad: { status: string }) => ad.status !== OK);

    if (currentAepData.length === 0) {
      currentAepData = aepDocumentsData.aepDocuments;
      missedDocuments = aepDocumentsData.aepDocuments.filter((ad: { status: string }) => ad.status !== OK);
    }

    const aepStatus = missedDocuments.length > 0;

    return (
      <>
        <Row>
          <Col xs={9}>
            {missedDocuments && missedDocuments.length === 0 ? (
              <div>{completedStatus}</div>
            ) : (
              <StyledStatus>Pending</StyledStatus>
            )}
          </Col>
          <Col xs={3}>
            <Row style={{ justifyContent: 'flex-end', margin: 0 }}>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  this.handleModal(true);
                }}
                disabled={!isFormEditable}
              >
                <FiEdit />
              </Button>
            </Row>
          </Col>
        </Row>
        {missedDocuments &&
          missedDocuments.map((doc: { document: string; name: string }) => (
            <LongText key={doc.document || doc.name}>{doc.document || doc.name}</LongText>
          ))}{' '}
        {showModal && (
          <AepEditor
            aepDocuments={aepDocuments}
            aepStatus={aepStatus}
            learnerAepDocuments={currentAepData}
            handleSave={this.handleAepSave}
            handleClose={this.handleModal}
            showModal={showModal}
            aepUpdatePending={false}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IRootState): IAepStatusStateProps => ({
  aepDocumentsData: getAepDocumentsState(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>): IDispatchProps => ({
  getLearnerAEPDocumentsData: (learnerId: string): void => {
    dispatch(getLearnerAepDocumentsInfo(learnerId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AepStatusModal);
