import React from 'react';
import { getEditorLabel } from '../../utils/format.utils';

const EventEditor: React.FunctionComponent<{
  editor?: string | null;
}> = (props: { editor?: string | null }) => {
  const { editor } = props;

  if (!editor) {
    return <></>;
  }

  const formattedEditor = getEditorLabel(editor);

  return <>{formattedEditor}</>;
};

export default EventEditor;
