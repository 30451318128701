import { RadioButtonCol } from 'components/admissions/admissions-form/styled-components';

import { Col } from 'react-bootstrap';

import { Row } from 'react-bootstrap';

import {
  ACCEPTANCE_PATH,
  ACCEPTED_EVALUATION_PENDING,
} from 'components/admissions/admissions-form/admissions-form.constants';
import { useField } from 'react-final-form';
import { RadioLabel } from 'components/admissions/admissions-form/styled-components';
import { Error } from 'components/helper-components/form-components/form-filed-components';
import { AepDocuments } from './aep-documents';

export const AcceptancePath = () => {
  const { input } = useField(ACCEPTANCE_PATH);

  if (!input.value) return null;

  return (
    <>
      <Row>
        <Col xs={4}>
          <span>Accepted Evaluation Pending</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={3} />
            <RadioButtonCol xs={1}>
              <RadioLabel>Ok</RadioLabel>
            </RadioButtonCol>
            <RadioButtonCol xs={2}>
              <RadioLabel>Missing</RadioLabel>
            </RadioButtonCol>
            <RadioButtonCol xs={3}>
              <RadioLabel>Incorrect/Insufficient</RadioLabel>
            </RadioButtonCol>
            <RadioButtonCol xs={2}>
              <RadioLabel>Unreadable</RadioLabel>
            </RadioButtonCol>
          </Row>
          <AepDocuments />
          <Error name={ACCEPTED_EVALUATION_PENDING} />
        </Col>
      </Row>
    </>
  );
};
