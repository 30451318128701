import { CreatePartnerCodePayload } from 'models/PartnerCode';
import { Agreement, AgreementPropertyValues } from 'models/Partner';
import { CreatePartnerAgreementRequest } from 'models/Partner';
import IPartnersPermission from 'models/PartnersPermissions';
import { request } from './fetch';
import { CreatePartnerPayload, PartnerInfo } from 'models/Partner';
import { PartnerCode } from 'models/PartnerCode';
import ProgramEnrollment from 'models/ProgramEnrollment';

export const getPartnerPermissions = () => request.get<IPartnersPermission>('/api/partners-api/v1/permissions');

export const getPartners = async () => request.get<PartnerInfo[], true>('/api/partners-api/v1/partners');

export const createPartner = async (payload: CreatePartnerPayload) => {
  const form = new FormData();
  form.append('accountName', payload.accountName);
  form.append('email', payload.email);
  if (payload.contactName) {
    form.append('contactName', payload.contactName);
  }
  if (payload.logo) {
    form.append('logo', payload.logo, payload.logo.name);
  }
  return await request.post('/api/adminaggregator/v1/partners', {
    body: form,
  });
};

export const updatePartner = async (partnerId: string, payload: CreatePartnerPayload) => {
  const form = new FormData();
  form.append('accountName', payload.accountName);
  form.append('email', payload.email);
  if (payload.contactName) {
    form.append('contactName', payload.contactName);
  }
  if (payload.logo) {
    form.append('logo', payload.logo, payload.logo.name);
  }

  return await request.put(`/api/adminaggregator/v1/partners/${partnerId}`, {
    body: form,
  });
};

export const getPartner = async (partnerId: string) =>
  request.get<PartnerInfo>(`/api/partners-api/v1/partners/${partnerId}`);

export const getLatestAgreement = async (partnerId: string) =>
  request.get<Agreement>(`/api/partners-api/v1/partners/${partnerId}/latest-agreement`);

export const createPartnerAgreement = async (partnerId: string, payload: CreatePartnerAgreementRequest) =>
  request.post<PartnerInfo>(`/api/partners-api/v1/partners/${partnerId}/agreements`, {
    body: JSON.stringify(payload),
  });

export const updatePartnerAgreement = async (
  partnerId: string,
  agreementId: string,
  payload: CreatePartnerAgreementRequest,
) =>
  request.put<PartnerInfo>(`/api/partners-api/v1/partners/${partnerId}/agreements/${agreementId}`, {
    body: JSON.stringify(payload),
  });

export const getPropertyValues = async () =>
  request.get<AgreementPropertyValues>('/api/adminaggregator/v1/partners/agreement-property-values');

export const getPartnerProgramEnrollments = async (partnerId: string) =>
  request.get<ProgramEnrollment[], true>(`/api/program/partners/${partnerId}/program-enrollments`);

export const getPartnerCodesBatches = async (partnerId: string) =>
  request.get<PartnerCode[]>(`/api/partners-codes-api/v1/partners/${partnerId}/batches`);

export const getPartnerCodesCSV = async (batchId: number) =>
  request.get<Blob>(`/api/partners-codes-api/v1/batches/${batchId}/codes/csv`);

export const createPartnerCodes = async (payload: CreatePartnerCodePayload) =>
  request.post<PartnerCode>('/api/partners-codes-api/v1/batches', {
    body: JSON.stringify(payload),
  });
