/* eslint no-param-reassign: ["error", { "props": false }] */

import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import moment from 'moment';
import CohortGradesAccordion from './components/cohort-grade/cohort-grade';
import GradeGPA from './components/grade-gpa';
import CumulativeGpaErrors from './components/cumulative-gpa-errors';
import api from '../../shared/api/adminUI.api';
import errorHandling from '../helper-components/alert-component.component';
import { WithPermissions } from '../helper-components/view-permission.component';
import { GPACalcIcon, LoadingP } from './styled-components';
import {
  IGradesState,
  IGradesProps,
  IGradesPermissions,
  ICourseStatusEditOptions,
  ICourseKey,
  DropRequestInfo,
  IGradesData,
  ILearnerGrades,
  IPerformanceCourse,
  IGradesObject,
} from './learner-grades.model';
import { getAepDocumentsState } from '../../shared/selectors/aep-documents.selector';
import { IRootState } from '../../shared/reducers';

class LearnerGrades extends React.Component<IGradesProps, IGradesState> {
  constructor(props: IGradesProps) {
    super(props);
    this.state = {
      status: 'Loading',
      subStatus: '',
      courseStatusEditOptions: [],
      gradesPermissions: {
        canViewLearnerGrades: false,
      },
      performance: {
        PossibleAttemptedCredits: null,
        PossibleEarnedCredits: null,
        cumulativeGPA: null,
        CumulativeGPAErrors: null,
        courses: [],
      },
      coursesData: [],
      error: null,
      currentProgramEnrollmentId: null,
    };
    const { learnerId, programEnrollmentId } = this.props;
    this.loadLearnerGrades(learnerId, programEnrollmentId);
  }

  componentDidUpdate() {
    const { learnerId, programEnrollmentId } = this.props;
    const { currentProgramEnrollmentId } = this.state;

    if (programEnrollmentId !== currentProgramEnrollmentId && currentProgramEnrollmentId !== null) {
      this.loadLearnerGrades(learnerId, programEnrollmentId);
    }
  }

  private calculateCumulativeGPAErrors = (
    learnerGrades: ILearnerGrades[],
    courseInfo: IGradesData[],
  ): ICourseKey[] | null => {
    const errors = (learnerGrades || [])
      .filter((lg) => lg.usedInGPA)
      .map((lg) =>
        find(courseInfo, { CourseCode: lg.courseCode, StartDate: lg.startDate })
          ? null
          : { CourseCode: lg.courseCode, StartDate: lg.startDate },
      )
      .filter((e) => e) as ICourseKey[];

    return errors.length ? errors : null;
  };

  private processGradesData = (performance: any): ILearnerGrades[] => {
    const learnerGrades = performance.courses.map((gradeObject: IGradesObject) => ({
      courseCode: gradeObject.courseCode,
      startDate: gradeObject.startDate,
      semester: gradeObject.startDate,
      grade: gradeObject.grade,
      usedInGPA: gradeObject.usedInGPA,
      attempt: gradeObject.attempt,
      courseName: gradeObject.courseName,
      status: gradeObject.status,
      subStatus: gradeObject.subStatus,
      dropRequestInfo: gradeObject.dropRequestInfo,
      creditType: gradeObject.creditType,
    }));
    return learnerGrades;
  };

  private returnUpdatedCourse = (
    course: IPerformanceCourse,
    status: string,
    subStatus: string | null,
    dropRequestInfo: DropRequestInfo,
  ) => ({
    ...course,
    status,
    subStatus,
    dropRequestInfo,
  });

  private changeCourseStatusToDeactivated = (
    courseCode: string,
    startDate: string,
    status: string,
    subStatus: string | null,
    dropRequestInfo: DropRequestInfo,
  ) => {
    const { performance } = this.state;
    const courseCohortGrades = [...performance.courses];
    const updatedCourses = courseCohortGrades.map((course) => {
      if (
        moment(course.startDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
        course.courseCode === courseCode
      ) {
        return this.returnUpdatedCourse(course, status, subStatus, dropRequestInfo);
      }
      return course;
    });
    this.setState({
      performance: { ...performance, courses: updatedCourses },
    });
  };

  private deleteCourse = (courseCode: string, startDate: string) => {
    const { performance } = this.state;
    const courseCohortGrades = [...performance.courses];
    const updatedCourses = courseCohortGrades.filter(
      (course) =>
        !(
          moment(course.startDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
          course.courseCode === courseCode
        ),
    );
    this.setState({
      performance: { ...performance, courses: updatedCourses },
    });
  };

  private getCourseStatusEditOptions = async (): Promise<ICourseStatusEditOptions[]> => {
    const courseStatusEditOptionsData = await api.academicPerformance.getCourseStatusEditOptions();
    if (!courseStatusEditOptionsData.ok) throw await courseStatusEditOptionsData;

    return (await courseStatusEditOptionsData.json()) as ICourseStatusEditOptions[];
  };

  private getGradesPermissions = async (): Promise<IGradesPermissions> => {
    const gradesPermissionsData = await api.academicPerformance.getLearnerGradesPermission();
    if (!gradesPermissionsData.ok) throw await gradesPermissionsData;

    const gradesPermissions = (await gradesPermissionsData.json()) as IGradesPermissions;

    this.setState({ gradesPermissions });
    return gradesPermissions;
  };

  private getPerformanceData = async (learnerId: string, programEnrollmentId: string): Promise<IGradesData> => {
    const defaultGradesData = {
      PossibleAttemptedCredits: null,
      PossibleEarnedCredits: null,
      cumulativeGPA: null,
      CourseRecords: [],
      courses: [],
    };
    const gradesPermissions = await this.getGradesPermissions();
    if (gradesPermissions.canViewLearnerGrades) {
      const grades = await api.academicPerformance.getLearnerGrades(learnerId, programEnrollmentId);
      if (!grades.ok) throw await grades;

      return (await grades.json()) as IGradesData;
    }
    return defaultGradesData;
  };

  private getCoursePerformance = async (
    learnerId: string,
    courseCode: string,
    startYear: number,
    startMonth: number,
    gradesPermissions: any,
  ): Promise<IGradesData> => {
    const defaultGradesData = {
      PossibleAttemptedCredits: null,
      PossibleEarnedCredits: null,
      cumulativeGPA: null,
      CourseRecords: [],
      courses: [],
    };
    if (gradesPermissions.canViewLearnerGrades) {
      const grades = await api.academicPerformance.getCoursePerformance(learnerId, courseCode, startYear, startMonth);
      if (grades.ok) {
        return (await grades.json()) as IGradesData;
      }
    }
    return defaultGradesData;
  };

  private readonly getPerformance = async (learnerId: string, programEnrollmentId: string): Promise<any> => {
    const gradesDataAsync = this.getPerformanceData(learnerId, programEnrollmentId);
    const gradesData = await gradesDataAsync;
    const grades = this.processGradesData(gradesData);

    const performanceData = {
      PossibleAttemptedCredits: gradesData.PossibleAttemptedCredits,
      PossibleEarnedCredits: gradesData.PossibleEarnedCredits,
      cumulativeGPA: gradesData.cumulativeGPA,
      courses: grades,
    };

    if (!performanceData || !performanceData.courses) return performanceData;
    return performanceData;
  };

  private readonly loadLearnerGrades = async (learnerId: string, programEnrollmentId: string): Promise<void> => {
    try {
      const performanceAsync = this.getPerformance(learnerId, programEnrollmentId);
      const courseStatusEditOptionsAsync = this.getCourseStatusEditOptions();

      const performance = await performanceAsync;
      const courseStatusEditOptions = await courseStatusEditOptionsAsync;

      this.setState((state) => ({
        ...state,
        status: 'Loaded',
        courseStatusEditOptions,
        currentProgramEnrollmentId: programEnrollmentId,
        performance,
      }));

      const { courses } = performance;
      const coursesArr = [];

      if (courses) {
        this.setState({
          status: 'Loaded',
        });
        const gradesPermissions = await this.getGradesPermissions();
        if (gradesPermissions.canViewLearnerGrades) {
          for (const singleCourse of courses) {
            const { courseCode, startDate } = singleCourse;
            const startDateObj = new Date(startDate);
            const year = startDateObj.getFullYear();
            const month = startDateObj.getMonth() + 1;

            const coursesData = await this.getCoursePerformance(learnerId, courseCode, year, month, gradesPermissions);
            coursesArr.push(coursesData);
          }
        }

        this.setState({ coursesData: coursesArr });
      }
      performance.CumulativeGPAErrors = this.calculateCumulativeGPAErrors(performance.courses, coursesArr);
      this.setState({
        performance,
      });
    } catch (error: any) {
      errorHandling({ error });
      this.setState((state) => ({
        ...state,
        status: 'Errored',
        error: error.message,
      }));
    }
  };

  render(): JSX.Element {
    const {
      status,
      error,
      performance,
      courseStatusEditOptions,
      gradesPermissions,
      coursesData,
      currentProgramEnrollmentId,
    } = this.state;
    const {
      learnerId,
      programEnrollmentId,
      canEditCompletedCourse,
      canUpdateAcademicPerformance,
      canDeleteLearnerCourses,
      canApproveDropRequest,
    } = this.props;
    const { canViewLearnerGrades } = gradesPermissions;
    let currentStatus = status;

    if (programEnrollmentId !== currentProgramEnrollmentId && currentProgramEnrollmentId !== null) {
      currentStatus = 'Loading';
    }

    switch (currentStatus) {
      case 'Errored':
        return (
          <div>
            <h3>Error!</h3>
            <pre>{error}</pre>
          </div>
        );
      case 'Loaded':
        if (!performance || performance!.courses.length === 0) {
          return <LoadingP>No performance data found for this learner yet</LoadingP>;
        }
        return (
          <>
            <div className="d-flex justify-content-between align-items-middle w-100">
              <div>
                <p>
                  <>
                    <GPACalcIcon /> Cum GPA{' '}
                  </>
                  <WithPermissions permission={canViewLearnerGrades} noAccesText="Access Denied">
                    <GradeGPA gpa={performance.cumulativeGPA} />
                  </WithPermissions>
                  <CumulativeGpaErrors errors={performance.CumulativeGPAErrors} />
                </p>
              </div>
            </div>
            <CohortGradesAccordion
              deactivateCourse={this.changeCourseStatusToDeactivated}
              deleteCourse={this.deleteCourse}
              performance={performance}
              coursesData={coursesData}
              canApproveDropRequest={canApproveDropRequest}
              canUpdateAcademicPerformance={canUpdateAcademicPerformance}
              canEditCompletedCourse={canEditCompletedCourse}
              canDeleteLearnerCourses={canDeleteLearnerCourses}
              gradesViewPermission={canViewLearnerGrades}
              learnerId={learnerId}
              programEnrollmentId={programEnrollmentId}
              courseStatusEditOptions={courseStatusEditOptions}
            />
          </>
        );
      default:
        return <LoadingP>Loading learner performance...</LoadingP>;
    }
  }
}
const mapStateToProps = (state: IRootState, ownProps: IGradesProps): IGradesProps => ({
  ...ownProps,
  aepDocumetsData: getAepDocumentsState(state),
});
export default connect(mapStateToProps)(LearnerGrades);
