// @ts-ignore
import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import {
  IApplicantEditableDataStateTypes,
  IApplicantEditableData,
} from '../reducers/applicant-editable-info/applicant-editable-info.model';

export const getApplicantEditableInfoState = (state: IRootState): IApplicantEditableDataStateTypes =>
  state.applicantEditableDataState;

export const getApplicantEditableInfo = createSelector(
  [getApplicantEditableInfoState],
  (applicantEditableDataState: IApplicantEditableDataStateTypes): IApplicantEditableData => applicantEditableDataState,
);
