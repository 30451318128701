import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const QuickSearchHeader = styled.h2`
  font-variant: small-caps;
  text-align: left;
`;

export const QuickSearchDiv = styled.div`
  font-family: 'Open Sans';
  text-align: left;
`;

export const QuickSearchItemList = styled.ul`
  padding: 0;
  max-height: 200px;
  overflow-y: scroll;
  text-align: left;
  display: inline-block;
`;

export const QuickSearchSpinner = styled(Spinner)`
  margin-left: -20px;
  margin-right: 5px;
`;

export const AdvancedSearchLink = styled(Link)`
  margin-left: 10px;
`;
