import React from 'react';
import { CommunicationItem, CommunicationsList } from './communications-styled-component';
import errorHandling from '../helper-components/alert-component.component';
import { EmailBodyDialog, LearnerEmail } from './communications-common';
import UniversityDate from '../university-date';
import { request } from 'shared/api/fetch';

interface ICommunicationsRecentState {
  emails: LearnerEmail[] | null;
  showEmail: LearnerEmail | null;
}

class CommunicationsRecent extends React.Component<any, ICommunicationsRecentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      emails: null,
      showEmail: null,
    };
  }

  componentDidMount(): void {
    this.loadEmails();
  }

  private readonly loadEmails = async (): Promise<void> => {
    try {
      const emails = await request.get<LearnerEmail[]>('/api/email-sync/emails/recent');
      this.setState({ emails });
    } catch (error: any) {
      errorHandling({ error });
    }
  };

  render(): JSX.Element {
    const { emails, showEmail } = this.state;
    if (emails == null) return <>Loading...</>;

    return (
      <>
        {showEmail && <EmailBodyDialog email={showEmail} close={() => this.setState({ showEmail: null })} />}
        <CommunicationsList>
          {emails.map((email) => (
            <EmailRow showEmail={() => this.setState({ showEmail: email })} email={email} key={email.emailId} />
          ))}
        </CommunicationsList>
      </>
    );
  }
}

interface IEmailRowProps {
  email: LearnerEmail;
  showEmail: () => void;
}
const EmailRow = ({ email, showEmail }: IEmailRowProps): JSX.Element => (
  <CommunicationItem onClick={showEmail}>
    <div className="table-cell" style={{ width: '25%' }} title={`From ${email.from} to ${email.to}`}>
      {email.fromNexford ? email.from : email.to}
    </div>
    <div className="table-cell" style={{ width: '50%' }}>
      <h5>{email.subject}</h5>
    </div>
    <div className="table-cell" style={{ width: '25%' }}>
      <UniversityDate value={email.timestamp} withTime />
    </div>
    <div className="table-call" style={{ width: '100%' }}>
      <span>{email.preview}</span>
    </div>
  </CommunicationItem>
);

export default CommunicationsRecent;
