import React, { useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import styles from './pagination.module.scss';
import { useTableContext } from '../context';

export const TablePagination = () => {
  const { pagination } = useTableContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const { pageCount, currentPage, onPageChange } = pagination!;

  const updatePage = (value: number) => {
    if (value >= 0 && value < pageCount) {
      onPageChange(value);
    } else if (inputRef.current) {
      inputRef.current.value = (currentPage + 1).toString();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updatePage(Number(e.currentTarget.value) - 1);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    updatePage(Number(e.currentTarget.value) - 1);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = (currentPage + 1).toString();
    }
  }, [currentPage]);

  return (
    <div className={styles.container}>
      <Button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 0}>
        Previous
      </Button>
      <div className={styles.pageNumber}>
        Page{' '}
        <Form.Control
          type="number"
          ref={inputRef}
          defaultValue={currentPage + 1}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />{' '}
        of {Math.max(pageCount, 1)}
      </div>
      <Button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage >= pageCount - 1}>
        Next
      </Button>
    </div>
  );
};
