import { ISelectOption } from '../../../models/SelectOptions';

const validateNumberValue = (value: string, propertyName: string) => {
  const numberValue = parseInt(value, 10);

  switch (propertyName) {
    case 'BirthYear': {
      const minYearOfBirth = new Date().getFullYear() - 14;

      if (!numberValue) {
        return 'Please, provide value';
      }

      if (numberValue < 1900 || numberValue > minYearOfBirth) {
        return `Birth Year should be between 1900 and ${minYearOfBirth}`;
      }
      return null;
    }

    case 'GraduationYear': {
      const maxGraduationYear = new Date().getFullYear();
      if (!numberValue) {
        return 'Please, provide value';
      }

      if (numberValue < 1900 || numberValue > maxGraduationYear) {
        return `Graduation Year should be between 1900 and ${maxGraduationYear}`;
      }
      return null;
    }
    default:
      return null;
  }
};

const validateTextInput = (fieldValue: string) => {
  if (!fieldValue) {
    return 'Please, provide value';
  }
  return null;
};

const validateEmail = (fieldValue: string) => {
  // eslint-disable-next-line
  const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!fieldValue) {
    return 'Please, provide value';
  }
  if (!emailRegExp.test(String(fieldValue).toLowerCase())) {
    return 'Please, provide valid email';
  }
  return null;
};

const handleValidation = (fieldValue: string, fieldType: string, propertyName: string) => {
  switch (fieldType) {
    case 'email':
      return validateEmail(fieldValue);
    case 'input':
      return validateTextInput(fieldValue);
    case 'number':
      return validateNumberValue(fieldValue, propertyName);
    default:
      return null;
  }
};

export const handleSelectValidation = (option: ISelectOption | undefined): string | null => {
  if (!option) return 'Please, select an option';
  return null;
};

export default handleValidation;
