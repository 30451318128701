import React, { useRef } from 'react';
import moment from 'moment';
import { IDatePickerProps } from './form-filed.models';
import { formatDate, isCurrentDate } from './form-filed.components';
import { InputStyles, RedLabel } from '../styled-components';
import RDatePicker from 'react-datepicker';

export const DatePicker = ({
  input,
  deferred,
  maxDate,
  minDate,
  showMonthYearPicker,
  dateFormat,
  timeFormat,
  locale,
  showCurrentTime,
  disabled,
  testID,
}: IDatePickerProps) => {
  const ref = useRef<RDatePicker>(null);

  const preventTypingInDataSelectInput = (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined,
  ) => {
    e?.preventDefault();
  };

  const dateValue = input.value ? moment(`${formatDate(input.value, 'YYYY-MM-DD')}`).toDate() : null;

  const currentTime = `${new Date().getHours()}:${new Date().getMinutes().toString().padStart(2, '0')}`;
  const dateTimeFormat = dateValue && isCurrentDate(dateValue) ? `${dateFormat} ${currentTime}` : dateFormat;

  const handleChange = (date: Date | null) => {
    input.onChange(date);
    ref.current?.setOpen(false, true);
  };

  return (
    <span data-testid={testID}>
      <InputStyles>
        <RDatePicker
          ref={ref}
          placeholderText="Click to select a date"
          selected={dateValue}
          onChange={handleChange}
          onChangeRaw={preventTypingInDataSelectInput}
          dateFormat={showCurrentTime ? dateTimeFormat : dateFormat}
          timeFormat={timeFormat}
          locale={locale}
          minDate={new Date(minDate)}
          maxDate={new Date(maxDate)}
          showMonthYearPicker={showMonthYearPicker}
          disabled={disabled}
          shouldCloseOnSelect={false}
        />
        {deferred && <RedLabel> Deferred</RedLabel>}
      </InputStyles>
    </span>
  );
};
