import React from 'react';
import { Row, Button, Modal, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FaTrashAlt } from 'react-icons/fa';
import { MISSING, OK, INCORRECT, UNREADBLE } from '../../../admissions/admissions-form/admissions-form.constants';
import { RADIO, SAVE, CLOSE } from '../../../constants/common-constants';
import { renderCheckbox } from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import { RadioButtonCol, RadioLabel, TrashButton } from '../../../admissions/admissions-form/styled-components';
import { IAEPDocument } from '../../../admissions/admissions.model';
import { IAepEditorState, IAEPFormValues, IProps } from './aep-editor-modal.model';
import { ISelectOption } from '../../../../models/SelectOptions';
import { ButtonColumn, CenteredRow, FormWarning, LongTextColumn } from '../../styled-components';
import InputableSelect from '../../../../shared/inputable-select';

class AepEditor extends React.PureComponent<IProps, IAepEditorState> {
  constructor(props: IProps) {
    super(props);
    const { learnerAepDocuments } = this.props;
    this.state = {
      aepStatusChanged: false,
      aepFormValues: {
        learnerAepDocuments,
      },
    };
  }

  private handleDocumentChange = (fields: any, inputValue: any): void => {
    if (inputValue) {
      fields.push({
        name: inputValue.value,
        status: MISSING,
        isDefault: false,
      });
    }
  };

  private handleAepStatusChange = (values: IAEPFormValues): void => {
    const { aepStatus } = this.props;
    const currentAepStatus = values.learnerAepDocuments.filter((ad) => ad.status !== OK).length > 0;
    const aepStatusChanged = aepStatus !== currentAepStatus;
    this.setState({ aepStatusChanged });
  };

  public render(): JSX.Element {
    const { showModal, handleSave, handleClose, aepUpdatePending, aepDocuments } = this.props;

    const { aepFormValues, aepStatusChanged } = this.state;

    const createOptions = (options: IAEPDocument[]): ISelectOption[] =>
      (options || []).map(
        (type: IAEPDocument): ISelectOption => ({
          label: type.Name,
          value: type.Name,
        }),
      );

    const aepDocumentsOptions = createOptions(aepDocuments);
    return (
      <Modal show={showModal} backdrop="static" size="lg">
        <Modal.Header>
          <Modal.Title>Accepted Evaluation Pending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSave}
            initialValues={aepFormValues}
            mutators={{
              ...arrayMutators,
            }}
            validate={(values) => {
              const errors: { [key: string]: string | string[] } = {};
              this.handleAepStatusChange(values);
              return errors;
            }}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <>
                  <Row>
                    <Col xs={3} />
                    <RadioButtonCol xs={1}>
                      <RadioLabel>Ok</RadioLabel>
                    </RadioButtonCol>
                    <RadioButtonCol xs={2}>
                      <RadioLabel>Missing</RadioLabel>
                    </RadioButtonCol>
                    <RadioButtonCol xs={3}>
                      <RadioLabel>Incorrect/Insufficient</RadioLabel>
                    </RadioButtonCol>
                    <RadioButtonCol xs={2}>
                      <RadioLabel>Unreadable</RadioLabel>
                    </RadioButtonCol>
                  </Row>
                  <FieldArray name="learnerAepDocuments">
                    {({ fields }) => (
                      <div>
                        {fields.map((document, index) => (
                          <div key={document}>
                            <CenteredRow>
                              <LongTextColumn xs={3}>
                                <span>{values.learnerAepDocuments[index].name}</span>
                              </LongTextColumn>
                              <RadioButtonCol xs={1}>
                                <Field
                                  fieldType="RADIO"
                                  name={`${document}.status`}
                                  render={renderCheckbox}
                                  type={RADIO}
                                  value={OK}
                                />
                              </RadioButtonCol>
                              <RadioButtonCol xs={2}>
                                <Field
                                  fieldType="RADIO"
                                  name={`${document}.status`}
                                  render={renderCheckbox}
                                  type={RADIO}
                                  value={MISSING}
                                />
                              </RadioButtonCol>
                              <RadioButtonCol xs={3}>
                                <Field
                                  fieldType="RADIO"
                                  name={`${document}.status`}
                                  render={renderCheckbox}
                                  type={RADIO}
                                  value={INCORRECT}
                                />
                              </RadioButtonCol>
                              <RadioButtonCol xs={2}>
                                <Field
                                  fieldType="RADIO"
                                  name={`${document}.status`}
                                  render={renderCheckbox}
                                  type={RADIO}
                                  value={UNREADBLE}
                                />
                              </RadioButtonCol>
                              <ButtonColumn xs={1}>
                                {!values.learnerAepDocuments[index].isDefault && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Remove</Tooltip>}
                                  >
                                    <TrashButton onClick={() => fields.remove(index)}>
                                      <FaTrashAlt style={{ color: 'grey' }} />
                                    </TrashButton>
                                  </OverlayTrigger>
                                )}
                              </ButtonColumn>
                            </CenteredRow>
                          </div>
                        ))}
                        {values.learnerAepDocuments.length < 6 && (
                          <div>
                            <InputableSelect
                              maxLength={40}
                              minLength={4}
                              onChange={(value: any) => {
                                this.handleDocumentChange(fields, value);
                              }}
                              options={aepDocumentsOptions.filter(
                                (ad) => !values.learnerAepDocuments.find((add) => add.name === ad.value),
                              )}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </FieldArray>
                  {aepStatusChanged && (
                    <FormWarning>Submitting this will update the AEP Status. Are you sure?</FormWarning>
                  )}
                </>
                <Modal.Footer>
                  <Button
                    disabled={pristine || invalid || aepUpdatePending}
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {SAVE}
                    {aepUpdatePending && <Spinner size="sm" animation="border" />}
                  </Button>
                  <Button disabled={aepUpdatePending} variant="secondary" onClick={() => handleClose(false)}>
                    {CLOSE}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default AepEditor;
