import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import { FaEye, FaTrashAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { AnyObject } from 'react-final-form';
import { FileEditingButton } from '../styled-component';
import errorHandling from '../../helper-components/alert-component.component';
import api, { LEARNER_FILE_TYPE, ADMISSION_FILE_TYPE, APPLICANT_FILE_TYPE } from '../../../shared/api/adminUI.api';
import { ISelectOption } from '../../../models/SelectOptions';
import DeleteDialog from '../file-delete-modal/file-delete-modal';
import EditingDialog from '../file-edit-modal/file-edit-modal';
import { IUploadedFile } from '../../../models/UploadedFile';
import { ILearnerFilePermissions } from '../learner-files.model';
import LearnerFilesService from '../learner-files-service';
import UniversityDate from '../../university-date';

interface IUploadedFileItem {
  id: string;
  learnerId: string;
  file: IUploadedFile;
  categoryOptions: ISelectOption[];

  fileDeletedEvent: (fileName: string) => void;
  fileUpdatedEvent: (learnerId: string, file: IUploadedFile) => void;
  learnerFilePermissions: ILearnerFilePermissions;
  isLearnerRegistration?: boolean;
}

const getCategoryName = (value: string, categoryOptions: ISelectOption[]): string => {
  const option = categoryOptions.find((category: ISelectOption) => category.value === value);
  return option ? option.label : value;
};

const createPreviewFileURL = (fileType: string, learnerId: string, fileName: string): string => {
  switch (fileType) {
    case LEARNER_FILE_TYPE:
      return `/api/learner-files/learners/${learnerId}/files/${fileName}`;
    case ADMISSION_FILE_TYPE:
    case APPLICANT_FILE_TYPE:
      return `/api/admission/learner-files/${learnerId}/files/${fileName}`;
    default:
      return `/api/learner-files/learners/${learnerId}/files/${fileName}`;
  }
};

const previewFile = async (
  file: IUploadedFile,
  learnerId: string,

  setPending: (pending: boolean) => void,
) => {
  try {
    setPending(true);

    const { fileName, fileType = LEARNER_FILE_TYPE } = file;
    const previewFileRequestUrl = createPreviewFileURL(fileType, learnerId, fileName);
    const response = await api.documentUpload.previewFile(previewFileRequestUrl);
    if (!response.ok) throw response;

    const url = await response.json();

    window.open(url, '_blank');
    setPending(false);
  } catch (error: any) {
    errorHandling({ error });
    setPending(false);
  }
};

const editFileProperties = async (
  values: AnyObject,
  file: IUploadedFile,
  learnerId: string,

  fileUpdatedEvent: (learnerId: string, file: IUploadedFile) => void,
  setEditingModalWindowVisible: (visible: boolean) => void,
  isLearnerRegistration?: boolean,
) => {
  try {
    const updatedFile: IUploadedFile = {
      ...file,
      displayFileName: values.fileName,
      category: values.categorySelect.value,
      subCategory: values.subcategory,
      comment: values.comment,
    };

    const editedFile = await LearnerFilesService.editLearnerFileProperties(
      learnerId,
      updatedFile,
      isLearnerRegistration,
    );

    if (!editedFile.ok) throw await editedFile;

    setEditingModalWindowVisible(false);
    fileUpdatedEvent(learnerId, updatedFile);
  } catch (error: any) {
    setEditingModalWindowVisible(false);
    errorHandling({ error });
  }
};

const deleteFile = async (
  learnerId: string,
  file: IUploadedFile,

  fileDeletedEvent: (fileName: string) => void,
  setDeleteModalWindowVisible: (visible: boolean) => void,
  isLearnerRegistration?: boolean,
) => {
  try {
    const deleteFileResult = await LearnerFilesService.deleteLearnerFile(learnerId, file, isLearnerRegistration);

    if (!deleteFileResult.ok) throw await deleteFileResult;

    setDeleteModalWindowVisible(false);
    fileDeletedEvent(file.fileName);
  } catch (error: any) {
    setDeleteModalWindowVisible(false);
    errorHandling({ error });
  }
};

const UploadedFileItem: React.FunctionComponent<IUploadedFileItem> = (props: IUploadedFileItem): JSX.Element => {
  const {
    id,
    file,
    categoryOptions,
    learnerId,
    fileDeletedEvent,
    fileUpdatedEvent,
    learnerFilePermissions,
    isLearnerRegistration,
  } = props;
  const { uploadDate, category, author, displayFileName, comment, canEdit } = file;
  const [pending, setPending] = useState(false);
  const [isDeleteModalWindowVisible, setDeleteModalWindowVisible] = useState(false);
  const [isEditingModalWindowVisible, setEditingModalWindowVisible] = useState(false);
  const isEditor = learnerFilePermissions.canEditLearnerFile;
  return (
    <tr key={id}>
      <CommonTd>
        <UniversityDate value={uploadDate} withTime />
      </CommonTd>
      <CommonTd>{category && getCategoryName(category, categoryOptions)}</CommonTd>
      <CommonTd>{author}</CommonTd>
      <CommonTd>{displayFileName}</CommonTd>
      <CenteredTd>
        {pending ? (
          <Spinner animation="border" />
        ) : (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Preview File</Tooltip>}>
              <FileEditingButton onClick={() => previewFile(file, learnerId, setPending)} size="sm">
                <FaEye style={{ color: '#6c757d' }} />
              </FileEditingButton>
            </OverlayTrigger>
            {isEditor && (
              <>
                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Edit File</Tooltip>}>
                  <FileEditingButton onClick={() => setEditingModalWindowVisible(true)} size="sm">
                    <FiEdit style={{ color: '#6c757d' }} />
                  </FileEditingButton>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Delete File</Tooltip>}>
                  <FileEditingButton
                    onClick={() => setDeleteModalWindowVisible(true)}
                    size="sm"
                    disabled={canEdit === false}
                  >
                    <FaTrashAlt style={{ color: 'red' }} />
                  </FileEditingButton>
                </OverlayTrigger>
              </>
            )}
          </>
        )}
      </CenteredTd>
      <CommentsTd>{comment}</CommentsTd>

      {isDeleteModalWindowVisible && (
        <DeleteDialog
          deleteFile={() =>
            deleteFile(learnerId, file, fileDeletedEvent, setDeleteModalWindowVisible, isLearnerRegistration)
          }
          setDeleteModalWindowVisible={setDeleteModalWindowVisible}
          fileName={displayFileName}
        />
      )}
      {isEditingModalWindowVisible && (
        <EditingDialog
          handleSave={(values) =>
            editFileProperties(
              values,
              file,
              learnerId,
              fileUpdatedEvent,
              setEditingModalWindowVisible,
              isLearnerRegistration,
            )
          }
          handleClose={setEditingModalWindowVisible}
          categoryOptions={categoryOptions}
          file={file}
        />
      )}
    </tr>
  );
};

export default UploadedFileItem;

const CommonTd = styled.td``;
const CenteredTd = styled.td`
  text-align: center;
`;

const CommentsTd = styled.td`
  &&& {
    word-break: break-word;
    font-size: 14px;
    padding: 10px 5px !important;
  }
`;
