import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { Button, Table } from 'react-bootstrap';

import { ISelectOption } from '../../models/SelectOptions';
import api, { AvailabilityPermissionResponse, AvailabilityResult } from '../../shared/api/adminUI.api';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import IError from '../../models/Error';
import errorHandling from '../helper-components/alert-component.component';

import { SeminarHeader, SeminarActionsRow, SeminarTableWrapper } from './styled.components';

const tableRowsByMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const SeminarAvailability = () => {
  const currentYear = String(new Date().getFullYear());
  const currentMonth = new Date().getUTCMonth();
  const nextYear = String(new Date().getFullYear() + 1);
  const yearOptions = [
    { label: currentYear, value: currentYear },
    { label: nextYear, value: nextYear },
  ];

  const [status, setStatus] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');
  const [selectedYear, setSelectedYear] = useState<string>(currentYear);
  const [availability, setAvailability] = useState<Array<AvailabilityResult>>();
  const [responseError, setResponseError] = useState<IError | null>(null);

  const [modifiedAvailability, setModifiedAvailability] = useState<Array<AvailabilityResult>>();
  const [canEdit, setCanEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchSeminarAvailability = async (year: string) => {
    try {
      setStatus('loading');
      const response = await api.seminars.getSeminarAvailabilityByYear(year);
      if (!response.ok) throw await response;
      setAvailability((await response.json()) as Array<AvailabilityResult>);
      setStatus('success');
    } catch (err) {
      setStatus('error');
      setResponseError(err as IError);
    }
  };

  const fetchSeminarPermissions = async () => {
    try {
      const response = await api.seminars.getPermissions();
      if (!response.ok) throw await response;
      const responseJson = (await response.json()) as AvailabilityPermissionResponse;
      setCanEdit(responseJson.canEditAvaialbilities);
    } catch (err) {
      console.warn(err);
    }
  };

  const handleYearChange = async (year?: string) => {
    if (!year) return;
    setSelectedYear(year);
    await fetchSeminarAvailability(year);
  };

  const startEditing = () => {
    if (!canEdit) return;
    const deepCopy = JSON.parse(JSON.stringify(availability));
    setModifiedAvailability(deepCopy);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    const deepCopy = JSON.parse(JSON.stringify(availability));
    setModifiedAvailability(deepCopy);
    setIsModified(false);
    setIsEditing(false);
  };

  // Selected month should be '1-12' and NOT '0-11'
  const handleChange = (productIndex: number, selectedMonth: number) => {
    if (!modifiedAvailability) return;
    const availabilityMonths = modifiedAvailability[productIndex].availableMonths;
    if (availabilityMonths.find((el) => el === selectedMonth)) {
      const index = availabilityMonths.indexOf(selectedMonth);
      if (index > -1) availabilityMonths.splice(index, 1);
    } else {
      availabilityMonths.push(selectedMonth);
    }
    setIsModified(true);
  };

  const handleSave = async () => {
    if (!isModified || !modifiedAvailability || isSubmitting || !canEdit) return;

    try {
      setIsSubmitting(true);

      const response = await api.seminars.updateSeminarAvailabilityByYear(selectedYear, modifiedAvailability);
      if (!response.ok) throw await response;

      setAvailability(modifiedAvailability);
      setIsSubmitting(false);
      setIsModified(false);
      setIsEditing(false);
    } catch (err) {
      errorHandling({ error: err as IError });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const loadInitialAvailability = async (): Promise<void> => {
      if (selectedYear && status === 'initial') {
        await fetchSeminarPermissions();
        await fetchSeminarAvailability(selectedYear);
      }
    };
    loadInitialAvailability();
  }, [selectedYear, status, fetchSeminarAvailability]);

  const staticTableBody = useMemo(() => {
    if (!availability) return null;
    return (
      <tbody>
        {tableRowsByMonth.map((mon, i) => (
          <tr key={mon}>
            <td>{`${mon} ${selectedYear}`}</td>
            {availability.map((value) => (
              <td key={`${value.productCode}-${mon}`}>
                {value.availableMonths.find((el) => el === i + 1) && <>&#10004;</>}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }, [availability, tableRowsByMonth, currentYear, isModified]);

  const editableTableBody = useMemo(() => {
    if (!modifiedAvailability) return null;
    return (
      <tbody>
        {tableRowsByMonth.map((mon, monthIndex) => (
          <tr key={mon}>
            <td>{`${mon} ${selectedYear}`}</td>
            {modifiedAvailability.map((value, productIndex) => (
              <td key={`${value.productCode}-${mon}`}>
                <input
                  aria-label={`Set seminar availability for ${value.productCode} on ${mon} ${selectedYear}`}
                  disabled={selectedYear === currentYear && monthIndex <= currentMonth}
                  type="checkbox"
                  name={`${value.productCode}-${mon}`}
                  defaultChecked={!!value.availableMonths.find((el) => el === monthIndex + 1)}
                  onChange={() => handleChange(productIndex, monthIndex + 1)}
                  className="form-check-input"
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }, [modifiedAvailability, tableRowsByMonth, currentYear, isModified]);

  return (
    <div>
      <SeminarHeader>
        <h1>Seminar availability</h1>
      </SeminarHeader>

      <SeminarActionsRow>
        <div>
          <span aria-hidden="true">Select year</span>
          <Select
            isDisabled={isEditing}
            aria-label="Select year"
            id="year-select"
            options={yearOptions}
            defaultValue={{ label: selectedYear, value: selectedYear }}
            // @ts-ignore
            onChange={(option: any): ISelectOption => handleYearChange(option?.value)}
          />
        </div>
        {canEdit && (
          <div>
            {!isEditing && (
              <Button variant="dark" onClick={() => startEditing()}>
                Edit
              </Button>
            )}
            {isEditing && (
              <Button variant="danger" onClick={() => cancelEditing()}>
                Cancel
              </Button>
            )}
            {isEditing && (
              <Button disabled={!isModified} onClick={() => handleSave()}>
                Save
              </Button>
            )}
          </div>
        )}
      </SeminarActionsRow>

      <SeminarTableWrapper>
        <WithLoading
          loading={status === 'initial' || status === 'loading'}
          loadingText={`Loading seminar availability for ${selectedYear}`}
        >
          <WithErrorHandling error={responseError}>
            {availability?.length && (
              <form>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Month</th>
                      {availability.map((value) => (
                        <th key={value.productCode}>{value.productCode}</th>
                      ))}
                    </tr>
                  </thead>
                  {!isEditing ? staticTableBody : editableTableBody}
                </Table>
              </form>
            )}
          </WithErrorHandling>
        </WithLoading>
      </SeminarTableWrapper>
    </div>
  );
};

export default SeminarAvailability;
