import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import errorHandling from '../../components/helper-components/alert-component.component';

import api from '../api/adminUI.api';
import IError from '../../models/Error';
import {
  GET_READMISSION_PERMISSIONS_ERROR,
  GET_READMISSION_PERMISSIONS_PENDING,
  GET_READMISSION_PERMISSIONS_SUCCESS,
} from '../reducers/readmission/readmission.constants';
import { IReadmissionPermissionData } from '../reducers/readmission/readmission.model';

export const getReadmissionPending = (): AnyAction => action(GET_READMISSION_PERMISSIONS_PENDING);
export const getReadmissionSuccess = (readmissionData: IReadmissionPermissionData): AnyAction =>
  action(GET_READMISSION_PERMISSIONS_SUCCESS, readmissionData);
export const getReadmissionFailure = (error: IError): AnyAction => action(GET_READMISSION_PERMISSIONS_ERROR, error);

export const getReadmissionPermissions = () => async (dispatch: Dispatch) => {
  dispatch(getReadmissionPending());

  try {
    const response = await api.readmission.getPermissions();

    if (!response.ok) throw await response;

    const permissionData = (await response.json()) as IReadmissionPermissionData;
    dispatch(getReadmissionSuccess(permissionData));
  } catch (error: any) {
    dispatch(getReadmissionFailure(error));
    errorHandling({ error });
  }
};
