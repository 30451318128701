import styled from 'styled-components';

export const RedLabel = styled.span`
  color: red;
  padding: 0.2em;
  display: block;
`;

export const InputStyles = styled.div`
  input {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 4px 8px;
    cursor: default;
    width: 100%;
    &:focus {
      outline: blue;
    }
  }
`;
