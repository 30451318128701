import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import {
  AcademicStandingModalTypes,
  LearnerAcademicStanding,
} from '../reducers/academic-standing/academic-standing.model';

export const getAcademicStandingState = (state: IRootState): AcademicStandingModalTypes => state.sapModelState;

export const getAcademicStanding = createSelector(
  [getAcademicStandingState],
  (sapModalState: AcademicStandingModalTypes): LearnerAcademicStanding => sapModalState.academicStanding,
);
