import {} from 'react-bootstrap';
import styled from 'styled-components';

export const CommunicationsList = styled.div``;

export const CommunicationsWrapper = styled.div`
  padding: 25px 0;
`;

export const CommunicationItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0 1em 0;
  padding: 7px 5px;
  align-items: center;
  background-color: #f7f7f7;
  cursor: pointer;
  .table-cell {
  }
  && {
    h5 {
      font-size: 19px;
    }
    span {
      font-size: 14px;
    }
  }

  &.table-cell {
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%; // Default to full width
    padding: 0.8em 1.2em;
    overflow: hidden; // Or flex might break
    list-style: none;
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      margin: 0;
    }
  }
`;
