import { AnyAction } from 'redux';
import { IStartDateModalTypes } from './start-date.model';
import {
  SET_START_DATE_ERROR,
  SET_START_DATE_PENDING,
  SET_START_DATE_SUCCESS,
  SET_DEFERRED_STATUS,
} from './start-date.constants';

const initialState: IStartDateModalTypes = {
  learnerDetailsObject: {
    acceptanceStatus: '',
    startDate: null,
    enrollmentDate: null,
    maxDeferralMonths: 0,
    daysBeforeNextPeriod: 0,
    disableStartDateAfterXDays: 0,
    disableScheduleLoaAfterXDays: 0,
    loaMaxMonths: 0,
    comment: '',
  },
  optionsPending: false,
  deferred: false,
  error: null,
};

const startDateModalState = (
  state: IStartDateModalTypes = initialState,
  { type, payload }: AnyAction,
): IStartDateModalTypes => {
  switch (type) {
    case SET_START_DATE_PENDING:
      return {
        ...state,
        optionsPending: true,
      };
    case SET_START_DATE_SUCCESS:
      return {
        ...state,
        learnerDetailsObject: {
          ...state.learnerDetailsObject,
          startDate: payload.startDate,
          comment: payload.comment,
        },
        optionsPending: false,
      };
    case SET_START_DATE_ERROR:
      return {
        ...state,
        error: payload,
        learnerDetailsObject: {
          acceptanceStatus: '',
          enrollmentDate: null,
          startDate: null,
          maxDeferralMonths: 0,
          daysBeforeNextPeriod: 0,
          disableStartDateAfterXDays: 0,
          disableScheduleLoaAfterXDays: 0,
          loaMaxMonths: 0,
          comment: '',
        },
        deferred: false,
        optionsPending: false,
      };
    case SET_DEFERRED_STATUS:
      return {
        ...state,
        deferred: payload,
      };
    default:
      return state;
  }
};

export default startDateModalState;
