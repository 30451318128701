import React, { useEffect, useRef } from 'react';
import { FieldRenderProps, Field } from 'react-final-form';

type Props = { name: string; children: (value: any, previous: any) => void };

export function OnChange({ name, children }: Props) {
  return <Field name={name}>{({ input }) => <InnerOnChange input={input}>{children}</InnerOnChange>}</Field>;
}

function InnerOnChange({ input, children }: Pick<FieldRenderProps<any>, 'input'> & Pick<Props, 'children'>) {
  const previousValue = useRef(input.value);

  useEffect(() => {
    if (input.value !== previousValue.current) {
      children(input.value, previousValue.current);
      previousValue.current = input.value;
    }
  }, [input.value, children]);

  return null;
}
