import React from 'react';
import { PropContainer, PropValue } from './event-prop';

const EventPropProductReEnrollment: React.FunctionComponent<{
  name?: string | null;
  productType: string;
  productCode: string;
  primary?: boolean;
}> = (props: { productType: string; productCode: string }) => {
  const { productType, productCode } = props;

  let product = null;
  if ((productType || '').toLowerCase() === 'degree') {
    product = productCode || productType;
  } else {
    product = `${productType || ''} ${productCode || ''}`.trim();
  }

  if (!product) {
    return <></>;
  }

  return (
    <PropContainer>
      <PropValue value={`${product} Re-Enrollment`} primary />
    </PropContainer>
  );
};

export default EventPropProductReEnrollment;
