import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { StripedTable } from '../ui/table/table';

export const RowTitle = styled.h2`
  font-weight: 500;
  font-size: 1.2em;
  text-align: left;
  padding: 15px;
  flex: 1 0 100%;
`;

export const StripedTableWithCenteredFirstColumn = styled(StripedTable as any)`
  td:nth-of-type(1) {
    text-align: center;
  }
`;

export const UpdateFXRateContainer = styled(Container)`
  div {
    margin-bottom: 5px;

    span:not(.error_lable) {
      display: inline-block;
      padding-right: 5px;
      width: 150px;
      font-weight: bold;
    }

    button {
      display: inline-block;
      margin: 15px 15px 15px 0px;
    }

    span.error_lable {
      color: red;
    }
  }

  .datePicker {
    display: inline-block;
  }
`;

export const SmallTextContainer = styled.div`
  font-size: 0.8em;
  font-weight: bold;
`;
