import { FormControl } from 'react-bootstrap';
import { FormElement } from '../form-element/form-element';
import { HelperText } from 'components/ui/helper-text/helper-text';
import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import cn from 'classnames';
import styles from './text-area.module.scss';

type Props = {
  label: string;
  name: string;
  testId?: string;
  className?: string;
  fieldProps?: UseFieldConfig<string>;
} & React.HTMLAttributes<HTMLTextAreaElement>;

export const TextArea = ({ label, name, className, testId, fieldProps = {}, ...props }: Props) => {
  const { input, meta } = useField(name, fieldProps);

  return (
    <FormElement className={className} data-testid={testId}>
      <FormElement.Label>{label}</FormElement.Label>
      <FormElement.Content>
        <FormControl
          {...props}
          className={cn(meta.error && styles.error)}
          as="textarea"
          value={input.value}
          onChange={input.onChange}
        />
        {meta.error && <HelperText variant="error">{meta.error}</HelperText>}
      </FormElement.Content>
    </FormElement>
  );
};
