import React from 'react';

interface WorQHtmlProps {
  readonly html: string;
  readonly answer: (answer: any, origin: string | null, answerText: string | null) => Promise<void>;
}

class WorQHtml extends React.Component<WorQHtmlProps> {
  constructor(props: WorQHtmlProps) {
    super(props);

    (window as any).worq = this;

    this.answer = this.answer.bind(this);
  }

  public async answer(a: any, answerText: string | null): Promise<void> {
    const { answer } = this.props;
    await answer(a, null, answerText);
  }

  private createMarkup() {
    const { html } = this.props;
    return { __html: html };
  }

  render(): JSX.Element {
    return <div id="worqContent" dangerouslySetInnerHTML={this.createMarkup()} />;
  }
}

export default WorQHtml;
