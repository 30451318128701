import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { PartnerAgreementFormValues } from '../../types';
import { usePartnerAgreementsParams } from '../../hooks/use-params';
import { useAgreementInitialValues } from './use-initial-values';
import { useLatestAgreement } from '../../hooks/use-latest-agreement';
import { FormContent } from './form-content';
import { createPartnerAgreement, updatePartnerAgreement } from 'shared/api/partner';
import { AgreementTuition, CreatePartnerAgreementRequest } from 'models/Partner';

export const PartnerAgreementsForm = () => {
  const history = useHistory();
  const { partnerId } = usePartnerAgreementsParams();
  const { data: latestAgreement } = useLatestAgreement();
  const queryClient = useQueryClient();

  const initialValues = useAgreementInitialValues();

  const { mutateAsync: createAgreement, error: createError } = useMutation({
    mutationFn: (payload: CreatePartnerAgreementRequest) => createPartnerAgreement(partnerId, payload),
  });
  const { mutateAsync: updateAgreement, error: updateError } = useMutation({
    mutationFn: (payload: CreatePartnerAgreementRequest) =>
      updatePartnerAgreement(partnerId, latestAgreement?.agreementId || '', payload),
  });

  const handleSubmit = async (data: PartnerAgreementFormValues) => {
    try {
      const agreementTuitions = data.agreementTuitions
        .filter((agreement) => agreement.enabled)
        .map((agreement) =>
          agreement.tierTypeIds
            .filter((tier) => Boolean(tier.value))
            .map<AgreementTuition>((tier) => ({
              productGroup: agreement.productId,
              tier: tier.id,
              planId: tier.value,
            })),
        )
        .flat();

      const fn = latestAgreement ? updateAgreement : createAgreement;
      const result = await fn({
        ...data,
        applicationFeeAmount: +data.applicationFeeAmount,
        startDate: moment(data.startDate).toISOString(),
        endDate: data.endDate ? moment(data.endDate).toISOString() : undefined,
        agreementTuitions,
      });

      toast.success(latestAgreement ? 'Agreement updated successfully' : 'Agreement created successfully');
      queryClient.setQueryData(['partner', partnerId, 'latest-agreement'], result);
      history.push(`/partners/${partnerId}`);
    } catch (err) {
      console.error('createAgreement error', err);
    }
  };

  const error = createError || updateError;

  return <FormContent error={error} onSubmit={handleSubmit} initialValues={initialValues} />;
};
