import IError from '../../../../models/Error';
import { ISelectOption } from '../../../../models/SelectOptions';
import { StepperController } from '../../../ui/stepper';

export enum StatusID {
  Completed = 'completed',
  Dismissed = 'dismissed',
  Enrolled = 'enrolled',
  Withdrawn = 'withdrawn',
  PreviewingStatus = 'previewing',
}
export enum SubstatusID {
  Passed = 'passed',
  Failed = 'failed',
  PreviewingSubStatus = 'previewing',
}

export interface CourseListDnDProps {
  summaryCoursesData: ICourse[];
  elementIdsWithWrongPositions: IWrongElement[];

  setRetakeCourse: (course: ICourse) => void;
  handleSelectChange: (newValue: ISelectOption, course: ICourse, courseId: string) => ISelectOption;
  setList: (courses: ICourse[]) => void;
  retakenCoursesIds: string[];
  learnerId: string;
  programEnrollmentId: string;
  stepperContext?: any;
}

export interface IWrongElement {
  courseId: string;
}

export interface ICoreCoursesOrderElement {
  id: string;
  order: number;
  group?: string | null;
  isGroupSelection?: boolean;
}

export interface ICoursesWithPrereuisites {
  courseId: string;
  inProgress: boolean;
  index: number;
  prerquisitesPositions: number[];
}

export type EnrollmentBehaviour = 'Standalone' | 'NoRestrictions';

export interface ICourse {
  id: string;
  retaken: number;
  name: string;
  statusId: StatusID | null;
  subStatusId: SubstatusID | null;
  order: number;
  group: null | string;
  fixed: boolean;
  defaultFixed: boolean;
  inProgress: boolean;
  isGroupSelection?: boolean;
  isSelectDropdown?: boolean;
  prerequisites: string[];
  section: string;
  courses?: ICourse[];
  isCourseAvailable: boolean;
  externalTransferred: boolean;
  enrollmentBehaviour: EnrollmentBehaviour;
}

export interface ICoursesSummaryProps {
  readonly vertical?: boolean;
  readonly isIndividualCourseProgram: boolean;
  readonly stepperContext?: StepperController;
  readonly submitButtonPermission: boolean;
  readonly learnerId: string;
  readonly programEnrollmentId: string;
}

export interface ICoursesSummaryState {
  summaryCoursesData: ICourse[];
  summaryCoursesDataPending: boolean;
  isRetakeWindowVisible: boolean;
  courseForRetake: ICourse | null;
  error: IError | null;
  elementIdsWithWrongPositions: IWrongElement[];
  pendingSave: boolean;
}

export type IProps = ICoursesSummaryProps;
