import { PROGRAM_STATUS, SUB_STATUS, REASON, COMMENT, RETURN_DATE } from './program-status-editing-modal.constants';
import { ISelectOption, IOption } from '../../../../models/SelectOptions';
import { IProgramStatusOption } from '../../../../shared/reducers/learner-info-editing-modal';

export interface ILearnerInfoModalWindowProps {
  learnerId: string;
  show: boolean;
  programEnrollmentId: string;
  handleClose: () => void;
}
export interface ILearnerInfoEditStateProps {
  programStatusSelectOptions: IOption[];
  reasonSelectOptions: IOption[];
  selectedOptionsList: IDropdownSelectedOptions;
  restrictions: IProgramStatusOption[];
}

export interface IDispacthProps {
  getDropDownSelectedOptionsList: (learnerId: string, programEnrollmentId: string) => void;

  setSelectedDropDownOptions: (
    learnerId: string,
    programEnrollmentId: string,
    optionObject: ISelectedDropdownOptions,
  ) => void;
}
export interface ILearnerInfoModalWindowState {
  selectedOptions: IDropdownSelectedOptions;
  isChangedForm: boolean;
  saveChangesDialogVisible: boolean;
  subStatusesOptionList: IOption[];
  reasonOptionList: IOption[];
}

export interface ISelectedDropdownOptions {
  programStatusId: string | null;
  subStatusId: string | null;
  reasonId: string | null;
  [COMMENT]: string;
  [RETURN_DATE]: string | null;
}
export interface IDropdownSelectedOptions {
  [PROGRAM_STATUS]: ISelectOption;
  [SUB_STATUS]: ISelectOption;
  [REASON]: ISelectOption;
  [COMMENT]: string;
  [RETURN_DATE]: ISelectOption;
}

export type ICommonLearnerInfoEditStateProps = IDispacthProps &
  ILearnerInfoModalWindowProps &
  ILearnerInfoEditStateProps;

export enum ProgramStatusValue {
  OnLeaveOfAbsense = 'onLeaveOfAbsense',
  Dismissed = 'dismissed',
  Withdrawn = 'withdrawn',
  Deferred = 'deferred',
  Enrolled = 'enrolled',
  Graduated = 'graduated',
  Other = 'other',
}
