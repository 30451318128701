import React from 'react';
import ReviewIcon from './application-review-icon';
import { AdminSubmitIdentityId, AdminSubmitIdentityIdV2, DocumentInfo } from '../admissions.model';
import WaitButton from '../../../shared/wait-button';
import AdmissionsFileUpload from './file-upload';
import { INxuFileUploadResult } from '../../../shared/models/file-upload-result';

export interface IdentityIdProps {
  readonly applicantId: string;
  readonly applicationStatus: string;
  readonly officialName: string;
  readonly idDocument: DocumentInfo;
  readonly url: string | null;
  readonly status: string;
  readonly reviewer: string;
  readonly saveIdentityIdV2: (applicantId: string, identityData: AdminSubmitIdentityIdV2) => Promise<void>;
  readonly saveIdentityId: (applicantId: string, foo: AdminSubmitIdentityId) => Promise<void>;
}

interface IdentityIdState {
  readonly officialName: string;
  readonly officialNameChanged: boolean;
  readonly idDocument: DocumentInfo;
  readonly idDocumentChanged: boolean;
  readonly saving: boolean;
  readonly valid: boolean;
}

class IdentityId extends React.Component<IdentityIdProps, IdentityIdState> {
  constructor(props: IdentityIdProps) {
    super(props);
    this.state = {
      officialName: props.officialName,
      idDocument: props.idDocument,
      officialNameChanged: false,
      idDocumentChanged: false,
      saving: false,
      valid: true,
    };
  }

  readonly isLatinOnly = (name: string): boolean => (!name ? true : !!name.match('^[\u0000-\u00FF]{1,200}$'));

  validate(): boolean {
    const { officialName } = this.state;
    const valid = this.isLatinOnly(officialName);
    this.setState({ valid });
    return valid;
  }

  updateOfficialName(evt: any) {
    const { officialName } = this.props;
    this.setState({
      officialName: evt.target.value,
      officialNameChanged: officialName !== evt.target.value,
    });
    this.validate();
  }

  async save(): Promise<boolean> {
    if (!this.validate()) {
      return false;
    }

    const { officialName, idDocument } = this.state;
    const { saveIdentityId, saveIdentityIdV2, applicantId } = this.props;

    this.setState({ saving: true });
    if (process.env.REACT_APP_USE_IDENTITY_SERVICE === 'true') {
      await saveIdentityIdV2(applicantId, {
        OfficialName: officialName,
        Documents: [
          {
            originalFileName: idDocument.originalFileName,
            uploadedFileName: idDocument.uploadedFileName,
            id: 'id',
          },
        ],
      });
    } else {
      await saveIdentityId(applicantId, {
        OfficialName: officialName,
        IdDocument: idDocument,
      });
    }
    this.setState({
      saving: false,
      officialNameChanged: false,
      idDocumentChanged: false,
    });
    return true;
  }

  idUploadStarted(): void {
    this.setState({
      saving: true,
      idDocumentChanged: true,
    });
  }

  async handleIdUploaded(result: INxuFileUploadResult): Promise<void> {
    this.setState({
      idDocument: {
        originalFileName: result.originalFileName,
        uploadedFileName: result.uploadedFileName,
      },
      idDocumentChanged: true,
    });
    await this.save();
  }

  render() {
    const { url, status, reviewer, applicantId, applicationStatus } = this.props;
    const { officialName, officialNameChanged, idDocumentChanged, saving, valid, idDocument } = this.state;
    const showSave =
      (officialNameChanged || idDocumentChanged) && officialName && idDocument && idDocument.uploadedFileName;
    const editable =
      (status === 'Failed' || status === 'Passed') &&
      (applicationStatus === 'Submitted' || applicationStatus === 'Enrolling');

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ReviewIcon status={status} />
        </div>
        <div
          style={{
            background: '#F3F3F3',
            paddingLeft: 5,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 6,
          }}
        >
          <div>
            {url && (
              <a href={url} target="_blank" rel="noopener noreferrer">
                Government ID
              </a>
            )}
            {!url && <span>No government ID</span>}
            {editable && (
              <span style={{ fontSize: 'small', paddingLeft: 3 }}>
                <AdmissionsFileUpload
                  learnerId={applicantId}
                  type="government-id"
                  uploadStarted={() => this.idUploadStarted()}
                  onFileUploaded={(p) => this.handleIdUploaded(p)}
                >
                  change
                </AdmissionsFileUpload>
              </span>
            )}
          </div>
          <div>
            <span>Official Name: </span>
            {editable && (
              <input
                type="text"
                value={officialName}
                onChange={(evt) => this.updateOfficialName(evt)}
                style={{ minWidth: 300 }}
              />
            )}
            {!editable && officialName}
          </div>
          {reviewer && <div>Verified By: {reviewer}</div>}
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 10,
          }}
        >
          {showSave && (
            <WaitButton
              size="sm"
              variant="outline-primary"
              onClick={() => this.save()}
              disabled={!valid}
              waiting={saving}
            >
              Save
            </WaitButton>
          )}
        </div>
      </div>
    );
  }
}

export default IdentityId;
