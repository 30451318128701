import React, { useState } from 'react';
import { IProgramEnrollmentFilesProps } from './program-enrollment-files.model';
import api from '../../../shared/api/adminUI.api';
import { FileLink, FilesLoadingSpinner, SpinnerContainer } from './styled-components';
import errorHandling from '../../helper-components/alert-component.component';

const ProgramEnrollmentFiles: React.FunctionComponent<IProgramEnrollmentFilesProps> = (
  props: IProgramEnrollmentFilesProps,
) => {
  const { enrollmentAgreementFile, catalogUrl, catalogFileName, catalogAddendumFile, catalogVersion, learnerId } =
    props;

  const [pending, setPending] = useState(false);
  const emptyStyle = {
    color: 'black',
    textDecoration: 'none',
    cursor: 'default',
  };

  const downloadFile = async (fileName: string) => {
    if (fileName) {
      try {
        setPending(true);

        const requestUrl = `/api/learner-files/learners/${learnerId}/files/${fileName}`;
        const response = await api.documentUpload.previewFile(requestUrl);
        if (!response.ok) throw response;

        const url = await response.json();

        window.open(url, '_blank');
        setPending(false);
      } catch (error: any) {
        errorHandling(error);
        setPending(false);
      }
    }
  };

  const downloadCatalogFile = async (catalogUrl: string, fileName: string) => {
    if (catalogUrl) {
      window.open(catalogUrl, '_blank');
    } else if (fileName) {
      downloadFile(fileName);
    }
  };

  return (
    <>
      {' '}
      {pending ? (
        <SpinnerContainer>
          <FilesLoadingSpinner animation="border" />
        </SpinnerContainer>
      ) : (
        <span>
          <FileLink
            style={enrollmentAgreementFile ? {} : emptyStyle}
            onClick={() => downloadFile(enrollmentAgreementFile)}
          >
            Agreement
          </FileLink>
          <span> / </span>
          <FileLink
            title={catalogVersion}
            style={catalogUrl || catalogFileName ? {} : emptyStyle}
            onClick={() => (catalogUrl || catalogFileName ? downloadCatalogFile(catalogUrl, catalogFileName) : {})}
          >
            Catalog ({(catalogVersion || '').split('.').slice(0, 2).join('.')})
          </FileLink>
          {catalogAddendumFile && (
            <span>
              <span> / </span>
              <FileLink onClick={() => downloadFile(catalogAddendumFile)}>Addendum</FileLink>
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default ProgramEnrollmentFiles;
