import React, { useState } from 'react';
import { Row, Button, Modal, Col, Spinner } from 'react-bootstrap';
import { Form, FormRenderProps, AnyObject, Field } from 'react-final-form';
import { FormState } from 'final-form';
import { CLOSE, SAVE } from '../../constants/common-constants';
import { ILearnerAEPDocument } from '../learner-files.model';
import { FormWarning } from '../styled-component';
import { AEP_CHANGE_WARNING } from './aep-files-modal.constants';
import { LongTextColumn } from '../../learner-info/styled-components';

interface IAEPOptionsDialog {
  handleSave: (values: AnyObject) => void;
  handleClose: (showAepOptionsModal: boolean) => void;
  showModal: boolean;
  aepDocuments: ILearnerAEPDocument[];
  aepUpdatePending: boolean;
}

const AEPOptionsDialog: React.FunctionComponent<IAEPOptionsDialog> = (props: IAEPOptionsDialog) => {
  const [allSellected, setAllSellected] = useState(false);
  const { handleSave, handleClose, showModal, aepDocuments, aepUpdatePending } = props;

  return (
    <>
      <Modal show={showModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>Does this file satisfy any of the outstanding application requirements?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSave}
            keepDirtyOnReinitialize
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => {
              const errors: { [key: string]: string | string[] } = {};
              const allItemsSellected =
                values.acceptedDocuments && aepDocuments.length === (values.acceptedDocuments as string[]).length;

              setAllSellected(allItemsSellected);
              return errors;
            }}
            render={<T extends object>({
              handleSubmit,
              pristine,
              invalid,
            }: FormRenderProps<any> & FormState<T>): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <>
                  {aepDocuments.map((option) => (
                    <Row key={option.name}>
                      <LongTextColumn xs={6} key={option.name}>
                        {option.name}
                      </LongTextColumn>
                      <Col xs={3}>
                        <Field
                          key={option.name}
                          name="acceptedDocuments"
                          component="input"
                          type="checkbox"
                          value={option.name}
                        />
                      </Col>
                    </Row>
                  ))}
                  {allSellected && <FormWarning>{AEP_CHANGE_WARNING}</FormWarning>}
                </>
                <Modal.Footer>
                  <Button
                    disabled={pristine || invalid || aepUpdatePending}
                    variant="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {SAVE}
                    {aepUpdatePending && <Spinner size="sm" animation="border" />}
                  </Button>
                  <Button disabled={aepUpdatePending} variant="secondary" onClick={() => handleClose(false)}>
                    {CLOSE}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AEPOptionsDialog;
