import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { WARNING, CANCEL, YES } from '../../../constants/common-constants';
import { ICourse } from './courses-summary.model';

interface ICourseRetakeWarning {
  show: boolean;
  course: ICourse | null;
  closeDialog: (isRetakeWindowVisible: boolean) => void;
  retakeCourse: (course: ICourse | null) => void;
}

const CourseRetakeWarning: React.FunctionComponent<ICourseRetakeWarning> = (props: ICourseRetakeWarning) => {
  const { show, course, retakeCourse, closeDialog } = props;

  const courseId = course ? course.id : '';
  const courseName = course ? course.name : '';

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{WARNING}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{`Are you sure, you want to retake ${courseId} ${courseName}?`}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDialog(false)}>
          {CANCEL}
        </Button>
        <Button variant="primary" onClick={() => retakeCourse(course)}>
          {YES}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CourseRetakeWarning;
