import { toast } from 'react-toastify';
import IError from '../../models/Error';
import { AppError } from 'shared/api/app-error';

interface errorAlertProps {
  error: IError;
}

const errorAlert = (props: errorAlertProps): void => {
  const { error } = props;

  if (error) {
    const appError = AppError.fromError(error);
    toast.error(`${appError.data?.status || 400}: ${appError.message}`, {
      position: 'bottom-right',
      closeButton: false,
      autoClose: 5000,
      hideProgressBar: true,
    });
  }
};
export default errorAlert;
