import React, { Suspense } from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
import styles from './suspense-container.module.scss';
import { HelperText } from '../ui/helper-text/helper-text';
import { AppError } from '../../shared/api/app-error';

const getErrorStr = (errorText: Props['errorText'], error: AppError | null) => {
  if (!errorText) return error?.message;
  if (typeof errorText === 'string') return errorText;

  return errorText(error);
};

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  loadingText?: string;
  errorText?: string | ((error: AppError | null) => string | undefined);
  container?: React.ElementType;
};

// TODO move error boundary into a new container
export const SuspenseContainer = ({
  fallback,
  loadingText = 'Loading...',
  children,
  errorText,
  container: Container = 'div',
}: Props) => (
  <Suspense
    fallback={
      <Container className={styles.container}>
        {fallback || <HelperText className={styles.loading}>{loadingText}</HelperText>}
      </Container>
    }
  >
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary, error }) => {
            const appError = error ? AppError.fromError(error) : null;

            return (
              <Container className={styles.container}>
                <div className={styles.error}>
                  <HelperText variant="error">{getErrorStr(errorText, appError) || 'An error occurred'}</HelperText>
                  <Button size="sm" onClick={() => resetErrorBoundary()}>
                    Try again
                  </Button>
                </div>
              </Container>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  </Suspense>
);
