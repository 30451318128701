import { useMutation, useQuery } from '@tanstack/react-query';
import { usePartnerCodesParams } from '../../hooks/use-partner-codes-params';
import { Table } from 'components/ui/table/table';
import moment from 'moment';
import { CODES_TYPE_LABELS, PartnerCode } from 'models/PartnerCode';
import styles from './partner-codes-table.module.scss';
import { CellContext } from '@tanstack/react-table';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getCsvFileName } from '../../utils/get-csv-file-name';
import { downloadBlobFile } from 'utils/download';
import { FaDownload } from 'react-icons/fa';
import { getPartnerCodesBatches, getPartnerCodesCSV } from 'shared/api/partner';

type Props = {
  accountName: string | undefined;
};

const DownloadCell = ({ row, accountName }: CellContext<PartnerCode, any> & { accountName?: string }) => {
  const { mutateAsync: getCSV, isLoading } = useMutation({
    mutationFn: () => getPartnerCodesCSV(row.original.id),
  });

  const handleDownload = async () => {
    try {
      const file = await getCSV();
      const fileName = getCsvFileName(row.original.id, accountName, row.original.codesType);
      downloadBlobFile(file, fileName);
    } catch (error: any) {
      console.error(error);
      toast.error('Failed to download codes');
    }
  };
  return (
    <Button onClick={handleDownload} disabled={isLoading}>
      {isLoading ? <Spinner animation="border" size="sm" /> : <FaDownload />} Download
    </Button>
  );
};

export const PartnerCodesTable = ({ accountName }: Props) => {
  const { partnerId } = usePartnerCodesParams();

  const { data: codes, isLoading } = useQuery({
    queryKey: ['partner', partnerId, 'codes'],
    queryFn: () => getPartnerCodesBatches(partnerId),
  });

  return (
    <Table
      emptyText="There are no codes"
      data={codes ?? []}
      columns={[
        {
          header: 'Date',
          cell: ({ row }) => moment(row.original.created).format('yyyy-MM-DD hh:mm'),
        },
        { accessorKey: 'generatedBy', header: 'Generated By' },
        {
          header: 'Type',
          cell: ({ row }) => CODES_TYPE_LABELS[row.original.codesType] || row.original.codesType,
        },
        {
          header: '# of Codes',
          cell: ({ row }) => <span className={styles.cellCodesCount}>{row.original.codesCount}</span>,
        },
        {
          header: ' ',
          cell: (props) => <DownloadCell accountName={accountName} {...props} />,
        },
      ]}
      loading={isLoading}
    />
  );
};
