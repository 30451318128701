const validateBirthYear = (dataValue: string, paramsValidation: string | any) => {
  const data = parseInt(dataValue, 10);
  const minBirthYear = new Date().getFullYear() - 14;
  if (!data) {
    return 'Please, provide value';
  }
  if (data.toString().length > 4 || data.toString().length < 4) {
    return 'Please, enter year format';
  }
  if (paramsValidation === 'yearOfBirth' && (data < 1900 || data > minBirthYear)) {
    return `Birth Year should be between 1900 and ${minBirthYear}`;
  }
  return null;
};

const validateTextInput = (fieldValue: string) => {
  if (!fieldValue) {
    return 'Please, provide value';
  }

  return null;
};

const handleValidation = (fieldValue: string, fieldType: string, paramsValidation?: string | any) => {
  switch (fieldType) {
    case 'input':
      return validateTextInput(fieldValue);
    case 'number':
      return validateBirthYear(fieldValue, paramsValidation);
    default:
      return null;
  }
};
export default handleValidation;
