import { Container, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const AdminContentContainer = styled(Container)`
  font-family: 'Open Sans';
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 15px;
  margin-right: auto;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  min-height: 100%;
  @media (min-width: 420px) {
    padding-left: 50px;
    padding-right: 20px;
  }
  @media (min-width: 1200px) {
    max-width: 700px;
  }
`;

export const OptionCard = styled(Card)`
  background: #535454;
  color: yellow;
  margin: 15px;
  flex: 0 0 45%;
  text-align: center;
  &:hover {
    background: yellow;
    color: #535454;
  }
`;

export const CardNavLink = styled(NavLink)`
  text-decoration: none;
  color: yellow;
  font-weight: 800;
  &:hover {
    color: #535454;
    text-decoration: none;
  }
`;

export const CardLink = styled.a`
  text-decoration: none;
  color: yellow;
  font-weight: 800;
  &:hover {
    color: #535454;
    text-decoration: none;
  }
`;

export const FirstRowTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.2em;
  text-align: left;
  padding: 15px;
  flex: 1 0 40%;
`;

export const WelcomeRow = styled.h1`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5em;
  text-align: left;
  padding: 15px;
  flex: 1 0 100%;
`;

export const RowTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.2em;
  text-align: left;
  padding: 15px;
  flex: 1 0 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const TransparentPlaceholderCard = styled.div`
  flex: 0 0 45%;
`;
