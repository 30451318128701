import moment from 'moment';

interface StartDateRange {
  readonly earliestAllowed: Date;
  readonly latestAllowed: Date;
  readonly nextAvailable: Date;
}

const calculateStartDateRange = (
  enrollmentDate: Date | string,
  daysBeforeNextMonthCutOff: number,
  maxDeferralMonths: number,
  now?: Date | string | moment.Moment,
): StartDateRange => {
  const today = moment.utc(now).startOf('day');
  const nextMonth = moment.utc(today).startOf('month').add(1, 'months');
  const enrollmentDateOnly = moment.utc(enrollmentDate).startOf('day');

  const earliestAllowed = moment
    .utc(enrollmentDateOnly)
    .add(daysBeforeNextMonthCutOff, 'days')
    .startOf('month')
    .add(1, 'months');
  const latestAllowed = moment.utc(earliestAllowed).add(maxDeferralMonths, 'months');

  const daysBeforeNextMonth = nextMonth.diff(today, 'days');
  const nextMonthIsNotAvailable = daysBeforeNextMonth <= daysBeforeNextMonthCutOff;
  const nextAvailable = nextMonthIsNotAvailable ? nextMonth.add(1, 'months') : nextMonth;

  return {
    earliestAllowed: earliestAllowed.toDate(),
    latestAllowed: latestAllowed.toDate(),
    nextAvailable: nextAvailable.toDate(),
  };
};

export default calculateStartDateRange;
