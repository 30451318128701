import React from 'react';
import styles from './form-field.module.scss';

export type PartnerLogoImagePreviewProps = {
  image: string;
};

export const PartnerLogoImagePreview = ({ image }: PartnerLogoImagePreviewProps) => (
  <>
    <span className={styles.label} />
    <img src={image} alt="logo" className={styles.partnerlogopreview} />
  </>
);
