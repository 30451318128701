import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { IVoucherCodeStateTypes } from '../reducers/voucher-code/voucher-code.model';
import IError from '../../models/Error';
import { IVoucherData } from '../../components/admissions/admissions.model';

export const getVoucherCodeState = (state: IRootState): IVoucherCodeStateTypes => state.voucherCodeState;

export const getVoucherData = createSelector(
  [getVoucherCodeState],
  (voucherDataObj: IVoucherCodeStateTypes): IVoucherData => voucherDataObj.voucherData,
);

export const getVoucherDataError = createSelector(
  [getVoucherCodeState],
  (voucherDataObj: IVoucherCodeStateTypes): IError | null => voucherDataObj.error,
);
