import React from 'react';
import moment from 'moment';
import { PropContainer, PropName, PropValue } from './event-prop';

interface IProps {
  name?: string;
  value?: string | Date | null;
  month?: number;
  year?: number;
  primary?: boolean;
}

const EventPropStartDate: React.FunctionComponent<IProps> = (props: IProps) => {
  const { name, value, month, year, primary } = props;

  let startDate = '-';
  if (!(value || (month && year))) {
    return <></>;
  }
  if (value) {
    startDate = moment.utc(value).format('YYYY-MM-DD');
  } else if (month && year) {
    startDate = `${year}-${month}-01`;
  }

  return (
    <PropContainer>
      <PropName>{name || 'Start Date:'}</PropName>
      <PropValue value={startDate} primary={primary} />
    </PropContainer>
  );
};

export default EventPropStartDate;
