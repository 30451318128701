import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ISelectOption } from '../models/SelectOptions';
import { StyledWrapperCreatableSelect } from './styled-components';

interface InputableSelectProps {
  minLength: number;
  maxLength: number;
  onChange?: (inputValue: any, actionMeta: any) => void;
  options: ISelectOption[];
}

interface InputableSelectState {
  inputValue: string;
}

class InputableSelect extends React.Component<InputableSelectProps, InputableSelectState> {
  constructor(props: InputableSelectProps) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  onInputChange(value: string): void {
    const { maxLength } = this.props;
    if (value.length <= maxLength) {
      this.setState({ inputValue: value });
    }
  }

  validateNewOption(inputValue: string): boolean {
    const { minLength, maxLength } = this.props;
    return inputValue.length <= maxLength && inputValue.length >= minLength;
  }

  render(): JSX.Element {
    const { options, onChange } = this.props;
    const { inputValue } = this.state;

    return (
      <StyledWrapperCreatableSelect>
        <CreatableSelect
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable
          value={null}
          inputValue={inputValue}
          onInputChange={(value: string) => this.onInputChange(value)}
          onChange={onChange}
          options={options}
          isValidNewOption={(optionValue: string) => this.validateNewOption(optionValue)}
        />
      </StyledWrapperCreatableSelect>
    );
  }
}

export default InputableSelect;
