import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
// @ts-ignore
import Select from 'react-select';
import { ISelectOption } from '../../../../models/SelectOptions';
import languages from '../../../../shared/data/languages.json';
import highestEducationLevelOptions from '../../../../shared/data/highestEducationOptions.json';

const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: 34,
    minHeight: 34,
    fontWeight: 200,
    padding: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided: any) => ({
    ...provided,
    padding: '.25rem .5rem',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    padding: '.25rem .5rem',
  }),
};

interface ISelectFieldProps {
  fieldValue: string;
  selectOptions?: ISelectOption[] | null;
  setNewFieldValue: (newValue: string) => void;
  propertyName: string;
}

const SelectFieldEditor: React.FunctionComponent<ISelectFieldProps> = ({
  fieldValue,
  selectOptions,
  setNewFieldValue,
  propertyName,
}: ISelectFieldProps) => {
  const [options, setOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (selectOptions && selectOptions.length > 0) {
      setOptions(selectOptions);
    } else {
      switch (propertyName) {
        default:
        case 'Gender':
          setOptions(GENDER_OPTIONS);
          break;
        case 'Language':
        case 'InstitutionLanguage':
          setOptions(languages.languages);
          break;
        case 'HighestEducationLevel':
          setOptions(highestEducationLevelOptions.highestEducationLevelOptions);
          break;
      }
    }

    setNewFieldValue(fieldValue);
  }, []);

  const onSelectChange = (option: ISelectOption, fieldName: string) => {
    switch (fieldName) {
      case 'Country':
      case 'InstitutionCountry':
      case 'Nationality':
      case 'State':
        setNewFieldValue(option.value);
        break;
      default:
        setNewFieldValue(option.label);
        break;
    }
    return option;
  };

  const setValueForSelect = (oldValue: any, dropdownOptions: ISelectOption[]): any =>
    dropdownOptions.find((option: ISelectOption) => option.value === oldValue);

  // @ts-ignore
  return options.length === 0 ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <Select
      styles={customStyles}
      options={options}
      defaultValue={setValueForSelect(fieldValue, options)}
      onChange={(option: any): ISelectOption => onSelectChange(option, propertyName)}
    />
  );
};

export default SelectFieldEditor;
