/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { InternalProvider } from '../../constants/common-constants';

interface IProps {
  readonly productType: string;
  readonly productCode: string;
  readonly productProvider: string;
  readonly productVersion: string;
}

const ProductName: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { productType, productCode, productProvider, productVersion } = props;

  const productName =
    productType === 'Course' || productType === 'Certificate' ? `${productType} (${productCode})` : productCode;

  const hash = `${productType}${productCode}v${productVersion}`;
  const productVersionUrl =
    productType === 'Degree' || (productType === 'Certificate' && productCode === 'FND5000')
      ? `https://nexford.atlassian.net/wiki/spaces/TEC/pages/2936569899/Program+Versions#${hash}`
      : null;

  const isExternalProvider = productProvider !== InternalProvider;

  return (
    <>
      {productName} {isExternalProvider && `(${productProvider})`}{' '}
      {productVersionUrl && (
        <a href={productVersionUrl} target="_blank">
          v{productVersion}
        </a>
      )}
    </>
  );
};

export default ProductName;
