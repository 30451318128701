import { get } from 'lodash';
import { ExpandableData } from './types';
import { DeepKeys } from '@tanstack/react-table';

export const createExpandableData = <Data, Key extends DeepKeys<Data>>(
  data: Data[],
  key: Key,
): ExpandableData<Data>[] => {
  return data.reduce((acc, curr) => {
    const id = get(curr, key);
    // if there is already an item with same id, then add it into its subRows
    const index = acc.findIndex((item) => get(item, key) === id);
    if (index < 0) {
      return [...acc, curr as ExpandableData<Data>];
    }

    acc[index].subRows = [...(acc[index].subRows ?? []), curr];
    return acc;
  }, [] as ExpandableData<Data>[]);
};
