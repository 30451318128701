import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import {
  GET_DROPDOWN_OPTIONS_PENDING,
  GET_DROPDOWN_OPTIONS_SUCCESS,
  GET_DROPDOWN_OPTIONS_ERROR,
  SET_DROPDOWN_OPTIONS_ERROR,
  SET_DROPDOWN_OPTIONS_PENDING,
  SET_DROPDOWN_OPTIONS_SUCCESS,
} from '../reducers/learner-info-editing-modal/learner-info-edit-modal.constants';
import errorHandling from '../../components/helper-components/alert-component.component';
import IError from '../../models/Error';
import {
  ISelectedDropdownOptions,
  IDropdownSelectedOptions,
} from '../../components/learner-info/program-status/program-status-editing-modal/programs-status-editing-modal.model';
import { IOption } from '../../models/SelectOptions';
import { SET_LEARNER_PROGRAM_ENROLLMENT_PROGRAM_STATUS } from '../reducers/learner-profile/learner-profile.constants';
import { request } from 'shared/api/fetch';

export interface ISelectOptionsObject {
  programStatusSelectOptions: IOption[];
  subStatusSelectOptions: IOption[];
  reasonSelectOptions: IOption[];
}

export interface IProgramStatusData {
  restrictions: {
    programStatus: IOption[];
    subStatuses: IOption[];
  };
  reasons: IOption[];
}

export interface IUpdatedProgramStatusData {
  programEnrollmentId: string;
  programStatusId: string;
  programStatusName: string;
}

export const getDropDownOptionsArrayPending = (): AnyAction => action(GET_DROPDOWN_OPTIONS_PENDING);
export const getDropDownOptionsArraySuccess = (optionsObject: IProgramStatusData): AnyAction =>
  action(GET_DROPDOWN_OPTIONS_SUCCESS, optionsObject);
export const getDropDownOptionsArrayFailure = (error: IError): AnyAction => action(GET_DROPDOWN_OPTIONS_ERROR, error);

export const getDropDownOptionsArray = () => async (dispatch: Dispatch) => {
  dispatch(getDropDownOptionsArrayPending());

  try {
    const options = await request.get<IProgramStatusData>('/api/program/program-statuses');

    dispatch(getDropDownOptionsArraySuccess(options));
  } catch (error: any) {
    dispatch(getDropDownOptionsArrayFailure(error));
    errorHandling({ error });
  }
};

export const setDropDownOptionsArrayPending = (): AnyAction => action(SET_DROPDOWN_OPTIONS_PENDING);
export const setDropDownOptionsArraySuccess = (selectedDropdownOptions: IDropdownSelectedOptions): AnyAction =>
  action(SET_DROPDOWN_OPTIONS_SUCCESS, selectedDropdownOptions);
export const setDropDownOptionsArrayFailure = (error: IError): AnyAction => action(SET_DROPDOWN_OPTIONS_ERROR, error);

export const setProgramEnrollmentProgramStatus = (updatedProgramStatusPayload: IUpdatedProgramStatusData): AnyAction =>
  action(SET_LEARNER_PROGRAM_ENROLLMENT_PROGRAM_STATUS, updatedProgramStatusPayload);

export const setDropDownOptions =
  (learnerId: string, programEnrollmentId: string, selectedDropdownOptions: ISelectedDropdownOptions) =>
  async (dispatch: Dispatch) => {
    dispatch(setDropDownOptionsArrayPending());

    try {
      const selectedOptions = await request.put<any>(
        `/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/program-status`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(selectedDropdownOptions),
        },
      );

      dispatch(setDropDownOptionsArraySuccess(selectedOptions));
      const updatedProgramStatusData: IUpdatedProgramStatusData = {
        programEnrollmentId,
        programStatusId: selectedOptions.programStatus.id,
        programStatusName: selectedOptions.programStatus.name,
      };

      dispatch(setProgramEnrollmentProgramStatus(updatedProgramStatusData));
    } catch (error: any) {
      dispatch(setDropDownOptionsArrayFailure(error));
      errorHandling({ error });
    }
  };

export const getDropDownOptions = (learnerId: string, programEnrollmentId: string) => async (dispatch: Dispatch) => {
  dispatch(setDropDownOptionsArrayPending());
  try {
    const selectedOptions = await request.get<IDropdownSelectedOptions>(
      `/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/program-status`,
    );

    dispatch(setDropDownOptionsArraySuccess(selectedOptions));
  } catch (error: any) {
    dispatch(setDropDownOptionsArrayFailure(error));
    errorHandling({ error });
  }
};
