import React from 'react';
import AuthInProgress from './auth-in-progress';
import AuthErrored from './auth-errored';
import AppProps from './app-props';
import { msal } from './auth.utils';

interface AuthState {
  readonly status: 'Loading' | 'RenewIFrame' | 'Authenticated' | 'Errored';
  readonly errorMessage?: string;
  readonly userName: string;
}

const withAuth = (WrappedComponent: React.ComponentType<AppProps>): React.ComponentType =>
  class extends React.Component<any, AuthState> {
    constructor(props: any) {
      super(props);
      this.state = {
        status: 'Loading',
        userName: '',
      };
    }

    componentDidMount() {
      this.handleAuth();
    }

    private readonly handleAuth = (): void => {
      const accounts = msal.getAllAccounts();
      if (accounts.length > 0) {
        msal.setActiveAccount(accounts[0]);
        this.setState((state) => ({
          ...state,
          userName: accounts[0].name!,
          status: 'Authenticated',
        }));
      }

      // handle auth redired/do all initial setup for msal
      msal
        .handleRedirectPromise()
        .then(() => {
          // Check if user signed in
          const account = msal.getActiveAccount();
          if (!account) {
            // redirect anonymous user to login page
            msal.loginRedirect({
              scopes: ['User.Read'],
            });
            this.setState((state) => ({
              ...state,
              status: 'Authenticated',
            }));
          }
        })
        .catch((err) => {
          this.setState((state) => ({
            ...state,
            status: 'Errored',
            errorMessage: err.errorMessage,
          }));
        });
    };

    private readonly logout = async (): Promise<string> => {
      await msal!.logoutRedirect({
        postLogoutRedirectUri: '/',
      });

      throw new Error('Not Authenticated');
    };

    render(): JSX.Element {
      const { status, errorMessage, userName } = this.state;

      switch (status) {
        case 'Loading':
          return <AuthInProgress />;
        case 'RenewIFrame':
          return <div>hidden renew iframe - not visible</div>;
        case 'Errored':
          return <AuthErrored message={errorMessage} />;
        case 'Authenticated':
          return <WrappedComponent logOut={this.logout} SAName={userName} />;
        default:
          return <div />;
      }
    }
  };

export default withAuth;
