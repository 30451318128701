export const switchInvalidCouponCodeReason = (errorCode: string): string => {
  switch (errorCode) {
    case 'NotFound':
      return 'Coupon code does not exist';
    case 'NotApplicable':
      return 'Coupon code is not valid for the product selection';
    case 'Expired':
      return 'Coupon code has expired';
    default:
      return 'Coupon code is invalid';
  }
};
