import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { INCORRECT, MISSING, OK, UNREADBLE } from 'components/admissions/admissions-form/admissions-form.constants';

import { AEP_DOCUMENTS } from 'components/admissions/admissions-form/admissions-form.constants';
import { RadioButtonCol, TrashButton } from 'components/admissions/admissions-form/styled-components';
import { renderCheckbox } from 'components/helper-components/form-components/form-filed-components';
import { LongTextColumn } from 'components/learner-info/styled-components';
import { Row } from 'react-bootstrap';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useAepDocumentOptionsField } from './use-aep-document-options-field';
import { FaTrashAlt } from 'react-icons/fa';
import InputableSelect from 'shared/inputable-select';
import { ApproveAdmissionFieldValues } from '../types';

export const AepDocuments = () => {
  const { values } = useFormState<ApproveAdmissionFieldValues>();
  const { aepOptionsList, defaultAepDocuments } = useAepDocumentOptionsField();

  const handleAepDocumentsChange = (fields: any, inputValue: any): void => {
    if (inputValue) {
      fields.push({ name: inputValue.value, status: MISSING });
    }
  };

  return (
    <FieldArray name={AEP_DOCUMENTS}>
      {({ fields }) => (
        <div className="d-flex flex-column row-gap-1">
          {fields.map((document, index) => (
            <div key={document}>
              <Row>
                <LongTextColumn xs={3}>{values.aepDocuments[index].name}</LongTextColumn>
                <RadioButtonCol xs={1}>
                  <Field
                    fieldType="RADIO"
                    name={`${document}.status`}
                    render={renderCheckbox}
                    type="radio"
                    value={OK}
                  />
                </RadioButtonCol>
                <RadioButtonCol xs={2}>
                  <Field
                    fieldType="RADIO"
                    name={`${document}.status`}
                    render={renderCheckbox}
                    type="radio"
                    value={MISSING}
                  />
                </RadioButtonCol>
                <RadioButtonCol xs={3}>
                  <Field
                    fieldType="RADIO"
                    name={`${document}.status`}
                    render={renderCheckbox}
                    type="radio"
                    value={INCORRECT}
                  />
                </RadioButtonCol>
                <RadioButtonCol xs={2}>
                  <Field
                    fieldType="RADIO"
                    name={`${document}.status`}
                    render={renderCheckbox}
                    type="radio"
                    value={UNREADBLE}
                  />
                </RadioButtonCol>

                <Col xs={1}>
                  {!defaultAepDocuments?.find((ad) => ad.Name === values.aepDocuments[index].name) && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Remove</Tooltip>}>
                      <TrashButton onClick={() => fields.remove(index)}>
                        <FaTrashAlt
                          style={{
                            color: 'grey',
                          }}
                        />
                      </TrashButton>
                    </OverlayTrigger>
                  )}
                </Col>
              </Row>
            </div>
          ))}
          {values.aepDocuments.length < 6 && (
            <div>
              <InputableSelect
                minLength={4}
                maxLength={40}
                onChange={(inputValue: any) => {
                  handleAepDocumentsChange(fields, inputValue);
                }}
                options={aepOptionsList.filter(
                  (ad) => !values.aepDocuments.find((add: { name: string }) => add.name === ad.value),
                )}
              />
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
};
