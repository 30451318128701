export const toLowerCaseProps = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => toLowerCaseProps(item));
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((prop: any, key) => {
      const camelKey = key.charAt(0).toLocaleLowerCase() + key.substring(1, key.length);
      prop[camelKey] = toLowerCaseProps(obj[key]);
      return prop;
    }, {});
  }
  return obj;
};

export default toLowerCaseProps;
