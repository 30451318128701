import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const LearnerDashboardContainer = styled(Container)`
  font-family: 'Open Sans';
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 50px;
  padding-top: 15px;
  text-align: left;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
  .enrollment-name {
    color: grey;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    text-align: center;
  }
`;
export const LearnerNameIdContainer = styled(Container)`
  font-family: 'Open Sans';
  margin-bottom: 20px;
  text-align: left;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

export const TabsContainer = styled(Container)`
  font-family: 'Open Sans';
  margin-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
`;

export const CenteredRow = styled(Row)`
  align-items: center;
  display: flex;
`;

export const ButtonRowWrapper = styled.div`
  text-align: right;
`;

export const ButtonRow = styled.span`
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: right;
  width: 150px;
  &:first-child {
    margin-right: 10px;
  }
`;

export const LearnerName = styled.h3`
  a {
    font-weight: bold;
    color: black;
    text-decoration: none; /* no underline */
    cursor: default;
  }

  font-weight: bold;
  text-align: left;
`;

export const LearnerId = styled.h3`
  font-size: larger;
  margin-bottom: 20px;
  text-align: left;
`;
