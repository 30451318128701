import { IAdmissionDataObject } from '../../components/admissions/admissions-form/admissions-form.model';
import { ICoreCoursesOrderElement } from '../../components/learning-path/steps/courses-summary/courses-summary.model';
import { ILoaDataObject } from '../../components/learner-info/loa-scheduler/loa-scheduler-modal';
import { AdminSubmitIdentityId, AdminSubmitIdentityIdV2 } from '../../components/admissions/admissions.model';
import { IUploadedFile } from '../../models/UploadedFile';
import { IUpdateAepDocumentsRequest } from '../../components/learner-files/learner-files.model';
import { IExtendAepDeadlineRequest } from '../../components/learner-info/aep-deadline/aep-deadline.model';
import { TransferableCoursesFormValues } from '../../components/learner-info/learner-info.model';
import { DocumentInfo } from '../models/identity-info';
import { EducationProfileResponse, PersonalDetailsResponse } from '../../models/LearnerRegistration';
import { getBearerToken } from 'components/auth/auth.utils';
import { request } from './fetch';

export const LEARNER_FILE_TYPE = 'learnerFile';
export const ADMISSION_FILE_TYPE = 'admissionFile';
export const APPLICANT_FILE_TYPE = 'applicantFile';

export interface IUploadFormData {
  fileName: string;
  uploadDate: string;
  category: string;
  subcategory: string;
  comment: string;
}

export interface IUploadData extends IUploadFormData {
  uploadedFileName: string;
}

export interface IProgramStatusData {
  program: string;
  acceptanceStatus: string;
  foundationCourses: string[];
  comment: string;
}

interface IUnenrollDataObject {
  status: string;
  subStatus: string | null;
  comment: string;
  dropRequested: boolean;
}

interface IEnrollmentFormData {
  AcceptanceStatus: string;
  IsSapPlanRequired: boolean;
  LearnerId: string;
  ProductCode: string;
  ProductType: string;
  StartDate: string;
  AEPDocuments: string;
}

export interface AvailabilityPermissionResponse {
  canEditAvaialbilities: boolean;
}

export interface AvailabilityResult {
  productCode: string;
  productType: string;
  availableMonths: Array<number>;
  year: number;
}

export interface CredentialLookup {
  AchievementId: string;
  Reference: string;
  LearnerId: string;
  ConfermentStatus: string;
}

const getFileSubmitUrlbyFileType = (fileType: string, learnerId: string): string => {
  switch (fileType) {
    case APPLICANT_FILE_TYPE:
      return `/api/admission/applicant-files/${learnerId}/files`;
    case LEARNER_FILE_TYPE:
    default:
      return `/api/learner-files/learners/${learnerId}/files`;
  }
};

const getIdentityUrlByFlag = (learnerId: string): string => {
  const useIdentityService: boolean = process.env.REACT_APP_USE_IDENTITY_SERVICE === 'true';
  if (useIdentityService === true) {
    return `/api/learner-identity-api/learners/${learnerId}/identity-info`;
  }
  return `/api/admission/applicants/${learnerId}/application/identity`;
};

export default {
  admissions: {
    getAdmissionPermission: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getFXRatePermission: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-payments-api/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getCountries: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/countries', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getStates: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/states', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getNationalities: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/nationalities', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getApplicantVoucherCodeData: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/voucher-codes`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getAdmissionData: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application-decision-options`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getApplicantInfo: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    setAdmissionData: async (
      applicantId: string,

      admissionData: IAdmissionDataObject,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application-decision`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(admissionData),
      });
      if (!response.ok) throw await response;

      return response;
    },
    getEducationInfo: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/education-info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },
    getNationalityInfo: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/nationality-info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },
    getPersonalInfo: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/personal-info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    getPersonalInfoEditableApplicant: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/info`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getIdentityInfo: async (applicantId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/identity`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    SubmitIdentityId: async (
      applicantId: string,

      identityId: AdminSubmitIdentityId,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${applicantId}/application/identity/id`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(identityId),
      });
      if (!response.ok) throw await response;
      return response;
    },
    deleteLearnerFile: async (learnerId: string, fileName: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/learner-files/${learnerId}/files/${fileName}`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    editLearnerFileProperties: async (learnerId: string, updatedFile: IUploadedFile) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/learner-files/${learnerId}/files`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(updatedFile),
      });
      return response;
    },

    getProgramFrequencies: async (productType: string, productCode: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/tuition-fee/frequency/${productType}/${productCode}`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  graduation: {
    getCredentialsByRereference: (referenceId: string) =>
      request.get<CredentialLookup>(`/api/graduation/credentials/${referenceId}`),
  },
  learnerDetails: {
    getLearnerDetails: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/enrollment/learners/${learnerId}/details`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  learningPathPlanner: {
    userProgressInfo: {
      getUserProgressInfo: async (learnerId: string, programEnrollmentId: string) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/status`,
          {
            method: 'GET',
            headers: [['Authorization', `Bearer ${token}`]],
          },
        );
        return response;
      },
    },
    coursesSummary: {
      getCoursesSummaryData: async (learnerId: string, programEnrollmentId: string) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path`,
          {
            method: 'GET',
            headers: [['Authorization', `Bearer ${token}`]],
          },
        );
        return response;
      },
      setSelectedCoursesSummaryData: async (
        learnerId: string,
        programEnrollmentId: string,

        courseOrder: ICoreCoursesOrderElement[],
      ) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path`,
          {
            method: 'PUT',
            headers: [
              ['Authorization', `Bearer ${token}`],
              ['content-type', 'application/json'],
            ],
            body: JSON.stringify(courseOrder),
          },
        );
        return response;
      },
    },
    specializtions: {
      getSpecializtionsData: async (learnerId: string, programEnrollmentId: string) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/specialisations`,
          {
            method: 'GET',
            headers: [['Authorization', `Bearer ${token}`]],
          },
        );
        return response;
      },
      setSelectedSpecializtions: async (
        learnerId: string,
        programEnrollmentId: string,

        specialisationsData: string[],
      ) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/specialisations`,
          {
            method: 'PUT',
            headers: [
              ['Authorization', `Bearer ${token}`],
              ['content-type', 'application/json'],
            ],
            body: JSON.stringify(specialisationsData),
          },
        );
        return response;
      },
    },
    electives: {
      getElectivesData: async (learnerId: string, programEnrollmentId: string) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/electives`,
          {
            method: 'GET',
            headers: [['Authorization', `Bearer ${token}`]],
          },
        );
        return response;
      },
      setSelectedElectives: async (
        learnerId: string,
        programEnrollmentId: string,

        electivesData: string[],
      ) => {
        const token = await getBearerToken();
        const response = await fetch(
          `/api/lpp/learners/${learnerId}/program-enrollments/${programEnrollmentId}/learning-path/electives`,
          {
            method: 'PUT',
            headers: [
              ['Authorization', `Bearer ${token}`],
              ['content-type', 'application/json'],
            ],
            body: JSON.stringify(electivesData),
          },
        );
        return response;
      },
    },
  },
  billing: {
    getLearnerPaymentStatus: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/billing/customers/${learnerId}/status`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getBillingPermissions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/billing/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  transcript: {
    getTranscriptHtml: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/transcript/learners/${learnerId}/transcript/html`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  loaScheduler: {
    getLoaStatus: async (learnerId: string, programEnrollmentId: string) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/schedule`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
    updateLoaStatus: async (
      learnerId: string,
      programEnrollmentId: string,

      loaData: ILoaDataObject,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/schedule`,
        {
          method: 'PUT',
          headers: [
            ['Authorization', `Bearer ${token}`],
            ['content-type', 'application/json'],
          ],
          body: JSON.stringify(loaData),
        },
      );
      return response;
    },
    cancelLoaStatus: async (
      learnerId: string,
      programEnrollmentId: string,

      scheduleId?: null | string,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/schedule/${scheduleId}`,
        {
          method: 'DELETE',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
    getOptionsList: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/program/schedule/options', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  transferCredits: {
    getExternalTransferPermissions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/external-transfer/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getListOfProviders: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/external-transfer/providers', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getListOfTransferableProducts: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/external-transfer/transferable-products', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getListOfProductsTransferableCourses: async (learnerId: string, productType: string, productCode: string) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/external-transfer/learners/${learnerId}/products/${productType}/${productCode}/transferable-courses`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
    getLearnerExternalTransfers: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/transfered-courses`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    saveLearnerPendingExternalTransfers: async (
      learnerId: string,

      transferedCourses: TransferableCoursesFormValues,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/transfered-courses`, {
        method: 'POST',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify(transferedCourses),
      });
      return response;
    },
    approveLearnerPendingExternalTransfers: async (
      learnerId: string,

      transferedCourses: TransferableCoursesFormValues,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/external-transfer/learners/${learnerId}/transfered-courses/approved`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify(transferedCourses),
      });
      return response;
    },
  },
  academicPerformance: {
    getLearnerGradesPermission: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/performance/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getLearnerGrades: async (learnerId: string, programEnrollmentId: string) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/performance/learners/${learnerId}/program-enrollments/${programEnrollmentId}/performance`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
    getCoursePerformance: async (learnerId: string, courseCode: string, startYear: number, startMonth: number) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/grades/learners/${learnerId}/courses/${courseCode}/${startYear}-${startMonth}/performance`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
    getCourseStatusEditOptions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/enrollment/course-enrollment/restrictions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    unenrollLearnerFromCourse: async (
      learnerId: string,
      programEnrollmentId: string,

      courseCode: string,
      startDate: string,
      unenrollData: IUnenrollDataObject,
    ) => {
      const token = await getBearerToken();
      const baseUrl = `/api/enrollment/course-enrollment/${learnerId}/program-enrollments`;
      const response = await fetch(`${baseUrl}/${programEnrollmentId}/courses/${courseCode}/${startDate}`, {
        method: 'PUT',
        body: JSON.stringify(unenrollData),
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
      });
      return response;
    },
    updateDropRequestStatus: async (
      learnerId: string,
      programEnrollmentId: string,

      courseCode: string,
      startDate: string,
      dropRequestDecision: string,
    ) => {
      const token = await getBearerToken();
      const baseUrl = `/api/enrollment/course-enrollment/${learnerId}/program-enrollments`;
      const response = await fetch(`${baseUrl}/${programEnrollmentId}/courses/${courseCode}/${startDate}/dropRequest`, {
        method: 'PUT',
        body: JSON.stringify({ status: dropRequestDecision }),
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
      });
      return response;
    },
    deleteLearnerCourse: async (
      learnerId: string,
      programEnrollmentId: string,

      courseCode: string,
      startDate: string,
      comment: string,
    ) => {
      const token = await getBearerToken();
      const baseUrl = `/api/enrollment/course-enrollment/${learnerId}/program-enrollments`;
      const response = await fetch(`${baseUrl}/${programEnrollmentId}/courses/${courseCode}/${startDate}`, {
        method: 'DELETE',
        body: JSON.stringify({ comment }),
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
      });
      return response;
    },
  },
  history: {
    getLearnerHistories: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/history/learners/${learnerId}`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getLearnerPrivacySettingsHistory: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/history/learners/${learnerId}/privacy-settings`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  documentUpload: {
    previewFile: async (url: string) => {
      const token = await getBearerToken();
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getLearnerFilesCategoryOptions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-files/categories', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getApplicantFilesCategoryOptions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/admission/applicant-files/categories', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    getAdmissionsFiles: async (learnerId: string) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/admission/learner-files/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      return response;
    },
    getApplicantFiles: async (learnerId: string) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/applicant-files/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      return response;
    },
    getLearnerFiles: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    deleteLearnerFile: async (learnerId: string, fileName: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files/${fileName}`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    editLearnerFileProperties: async (learnerId: string, updatedFile: IUploadedFile) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(updatedFile),
      });
      return response;
    },
    getLearnerFilePermission: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-files/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    submitUploadForm: async (
      learnerId: string,

      fileType: string,
      uploadFormData: IUploadData,
    ) => {
      const token = await getBearerToken();
      const url = getFileSubmitUrlbyFileType(fileType, learnerId);
      const response = await fetch(url, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(uploadFormData),
      });
      return response;
    },
  },
  learnerProfile: {
    getResidenceCountryOptions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-profile/residence-country', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },

    getSuccessAdvisorOptions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/learner-profile/success-advisor', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;

      return response;
    },
  },
  aepDocuments: {
    aepDocumentOptions: async () => {
      const token = await getBearerToken();

      const response = await fetch('/api/admission/aepdocuments', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    learnerAepDocuments: async (learnerId: string) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/admission/applicants/${learnerId}/aepdocuments`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    updateLearnerAepDocuments: async (
      learnerId: string,

      updatedDocuments: IUpdateAepDocumentsRequest,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${learnerId}/aepdocuments`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(updatedDocuments),
      });
      return response;
    },
  },
  aepDeadline: {
    learnerAepDeadline: async (learnerId: string) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/admission/applicants/${learnerId}/aep-deadline`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    extendLearnerAepDeadline: async (
      learnerId: string,

      updatedDocuments: IExtendAepDeadlineRequest,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/admission/applicants/${learnerId}/aep-deadline`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(updatedDocuments),
      });
      return response;
    },
  },
  academicStanding: {
    learnerAcademicStanding: async (learnerId: string) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/sap/learners/${learnerId}/sap-status`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  privacySettings: {
    current: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-profile/learners/${learnerId}/privacy-setting`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },
  program: {
    configuration: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/program/configuration', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    getFileForReadmission: async (learnerId: string, updatedFile: any, ext: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files/temp/${ext}`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: updatedFile,
      });
      return response;
    },

    learnerLatestProgram: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/program/learners/${learnerId}/latest-program`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
    admissionParameters: async (learnerId: string, programEnrollmentId: string) => {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/program/learners/${learnerId}/program-enrollments/${programEnrollmentId}/admission-parameters`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      return response;
    },
  },
  readmission: {
    getReadmissionEligibility: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-profile/learners/${learnerId}/flag-settings`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw response;
      return response;
    },
    putReadmissionEligibility: async (learnerId: string, payload: { Value: boolean }) => {
      const token = await getBearerToken();
      return fetch(`/api/learner-profile/learners/${learnerId}/flag-settings/readmission-disabled`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(payload),
      });
    },
    createLearnerReadmissionRequest: async (learnerId: string, enrollmentFormData: IEnrollmentFormData) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(enrollmentFormData),
      });
      return response;
    },

    approveLearnerReadmissionRequest: async (learnerId: string, enrollmentFormData: IEnrollmentFormData) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active/approved`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(enrollmentFormData),
      });
      return response;
    },

    cancelLearnerReadmissionRequest: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active/canceled`, {
        method: 'DELETE',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    updateLearnerReadmissionRequest: async (learnerId: string, enrollmentFormData: IEnrollmentFormData) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(enrollmentFormData),
      });
      return response;
    },

    getLearnerActiveReadmissionRequest: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/readmission/learners/${learnerId}/readmission-request/active`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    getPermissions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/readmission/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },

    learnerProducts: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/readmission/products', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      return response;
    },
  },

  learnerIdentity: {
    SubmitIdentityInfo: async (
      learnerId: string,

      identityInfo: AdminSubmitIdentityIdV2,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-identity-api/learners/${learnerId}/identity-info`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(identityInfo),
      });
      if (!response.ok) throw await response;
      return response;
    },

    SubmitIdentityPhoto: async (
      learnerId: string,

      photoDocument: DocumentInfo,
    ) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-identity-api/learners/${learnerId}/documents/photo`, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(photoDocument),
      });
      if (!response.ok) throw await response;
      return response;
    },

    getIdentityInfo: async (learnerId: string) => {
      const token = await getBearerToken();
      const url = getIdentityUrlByFlag(learnerId);
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
    updateOfficialName: async (
      learnerId: string,

      officialName: string,
    ) => {
      const token = await getBearerToken();

      const response = await fetch(`/api/learner-identity-api/learners/${learnerId}/official-name/by-admin`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify({ officialName }),
      });
      return response;
    },
  },

  seminars: {
    getPermissions: async () => {
      const token = await getBearerToken();
      const response = await fetch('/api/product-availability-api/permissions', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getSeminarAvailabilityByYear: async (year: string) => {
      const token = await getBearerToken();
      const url = `/api/product-availability-api/product-availability/${year}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updateSeminarAvailabilityByYear: async (year: string, payload: Array<AvailabilityResult>) => {
      const token = await getBearerToken();
      const url = `/api/product-availability-api/product-availability/${year}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(payload),
      });
      if (!response.ok) throw await response;
      return response;
    },
  },

  learnerRegistration: {
    getActiveRegistrations: async (applicantId: string) => {
      const token = await getBearerToken();
      const url = `/api/registration/learners/${applicantId}/registrations`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getAdmittanceStatus: async (applicantId: string, registrationId: string) => {
      const token = await getBearerToken();
      const url = `/api/admittance-api/learners/${applicantId}/admittance/${registrationId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getProductList: async () => {
      const token = await getBearerToken();
      const url = '/api/requirements/versions/latest/products';
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    undoAdmittance: async (applicantId: string, registrationId: string) => {
      const token = await getBearerToken();
      const url = `/api/admittance-api/learners/${applicantId}/admittance/${registrationId}/undo`;
      return fetch(url, {
        method: 'POST',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
      });
    },

    getPersonalDetails: async (applicantId: string) => {
      const token = await getBearerToken();
      const url = `/api/learner-profile/v2/learners/${applicantId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updatePersonalDetails: async (applicantId: string, payload: PersonalDetailsResponse) => {
      const token = await getBearerToken();
      return fetch(`/api/learner-profile/learners/${applicantId}`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(payload),
      });
    },

    getIdentityInfo: async (learnerId: string) => {
      const token = await getBearerToken();

      const url = `/api/learner-identity-api/learners/${learnerId}/identity-info`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getEducationInfo: async (applicantId: string) => {
      const token = await getBearerToken();
      const url = `/api/learner-profile/v2/learners/${applicantId}/education-info`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updateEducationInfo: async (applicantId: string, payload: EducationProfileResponse) => {
      const token = await getBearerToken();
      return fetch(`/api/learner-profile/v2/learners/${applicantId}/education-info`, {
        method: 'PUT',
        headers: [
          ['Authorization', `Bearer ${token}`],
          ['content-type', 'application/json'],
        ],
        body: JSON.stringify(payload),
      });
    },

    getEnglishProficiency: async (applicantId: string) => {
      const token = await getBearerToken();
      const url = `/api/learner-profile/learners/${applicantId}/english-proficiency`;
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    getLearnerFiles: async (learnerId: string) => {
      const token = await getBearerToken();
      const response = await fetch(`/api/learner-files/learners/${learnerId}/files`, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },
  },

  fxRates: {
    getRateWithHistory: async (currency: string) => {
      const url = `/api/learner-payments-api/fx-rates/${currency}`;
      const token = await getBearerToken();
      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) throw await response;
      return response;
    },

    updateFXRate: async (currency: string, newRate: number, effectiveFrom: string) => {
      const url = `/api/learner-payments-api/fx-rates/${currency}`;
      const token = await getBearerToken();
      const response = await fetch(url, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`]],
        body: JSON.stringify({ newRate, effectiveFrom }),
      });
      if (!response.ok) throw await response;
      return response;
    },
  },
};
