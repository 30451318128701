import React from 'react';

interface ViewPermissionProps {
  permission: boolean;
  noAccesText: string;
  styleNoAccesText?: React.CSSProperties;
}

const withPermission =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P & ViewPermissionProps> =>
  ({ permission, styleNoAccesText, noAccesText, ...props }: ViewPermissionProps) =>
    permission ? <Component {...(props as P)} /> : <span style={styleNoAccesText}>{noAccesText}</span>;

export default withPermission;

export const WithPermissions = withPermission(({ children }: { children: React.ReactNode }) => <>{children}</>);
