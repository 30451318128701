import React from 'react';
import moment from 'moment-timezone';

interface IProps {
  value: Date | string | number | null | undefined;
  withTime?: boolean;
  skipTimezoneConversion?: boolean;
}

const CURRENT_YEAR = new Date().getFullYear();

const UniversityDate: React.FunctionComponent<IProps> = ({ value, withTime, skipTimezoneConversion }: IProps) => {
  if (value === null || value === undefined) {
    return <></>;
  }

  if (withTime && skipTimezoneConversion) {
    return <>You can not skip the timezone coversion when withTime is selected</>;
  }

  const date = skipTimezoneConversion ? moment.utc(value) : moment.utc(value).tz('America/New_York');

  let dateFormat = CURRENT_YEAR === date.year() ? 'MMM DD ' : 'MMM DD, YYYY ';

  if (withTime) {
    dateFormat += 'hh:mm ';
  }

  return <span>{date.format(dateFormat)}</span>;
};

export default UniversityDate;
