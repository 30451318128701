import * as azureStroageBlob from '../../app/scripts/azure-storage.blob.min';

interface ISpeedSummary {
  on(eventName: 'progress', callback: () => void): void;
  getCompletePercent(): number;
}

interface IBlobService {
  createBlockBlobFromBrowserFile(
    container: string,
    blob: string,
    browserFile: File,
    callback: (error: any, result: any, response: any) => void,
  ): ISpeedSummary;
}

export interface IAzureBlobUploadService {
  uploadBlob(
    container: string,
    blob: string,
    browserFile: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<any>;
}

class AzureBlobUploadService implements IAzureBlobUploadService {
  constructor(private readonly blobService: IBlobService) {}

  uploadBlob(
    container: string,
    blob: string,
    browserFile: File,
    progressCallback?: (completePercent: number) => void,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const speedSummary = this.blobService.createBlockBlobFromBrowserFile(
        container,
        blob,
        browserFile,
        (error: Error, result: any) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        },
      );
      if (progressCallback) {
        speedSummary.on('progress', () => progressCallback(speedSummary.getCompletePercent()));
      }
    });
  }
}

export const createBlobServiceWithSas = (accountName: string, sasToken: string): IAzureBlobUploadService => {
  const host = `https://${accountName}.blob.core.windows.net`;
  const blobService = azureStroageBlob.createBlobServiceWithSas(host, sasToken);
  return new AzureBlobUploadService(blobService);
};
