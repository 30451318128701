import React from 'react';
import styled from 'styled-components';

const EventProp: React.FunctionComponent<{
  name: string;
  value: string | null;
  primary?: boolean;
}> = (props: { name: string; value: string | null; primary?: boolean }) => {
  const { name, value, primary } = props;

  if (value === null || value === undefined) {
    return <></>;
  }

  return (
    <PropContainer>
      <PropName>{name}:</PropName>
      <PropValue value={value} primary={primary} />
    </PropContainer>
  );
};

export const PropValue: React.FunctionComponent<{
  value?: string;
  primary?: boolean;
  children?: JSX.Element;
}> = (props: { value?: string | null; primary?: boolean; children?: any }) => {
  const { value, primary, children } = props;

  return primary ? (
    <PrimaryPropValue>
      {value}
      {children}
    </PrimaryPropValue>
  ) : (
    <NonPrimaryPropValue>
      {value}
      {children}
    </NonPrimaryPropValue>
  );
};

export default EventProp;

export const PropContainer = styled.div`
  display: inline;
  margin-right: 10px;
`;

export const PropName = styled.span`
  font-weight: 700;
  font-size: 14px;
  padding-right: 3px;
  color: gray;
`;

const NonPrimaryPropValue = styled.span`
  font-size: 14px;
`;

const PrimaryPropValue = styled.span`
  font-weight: 700;
  font-size: 15px;
`;
