import * as React from 'react';
import PropTypes from 'prop-types';

interface ErrorProps {
  message?: string;
}

const AuthErrored = (props: ErrorProps) => {
  const { message } = props;
  return (
    <div>
      <pre>
        <code>{message}</code>
      </pre>
    </div>
  );
};

AuthErrored.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AuthErrored;
