import React from 'react';
import { PropContainer, PropName, PropValue } from './event-prop';

const EventPropProduct: React.FunctionComponent<{
  name?: string | null;
  productType: string;
  productCode: string;
  primary?: boolean;
}> = (props: { name?: string | null; productType: string; productCode: string; primary?: boolean }) => {
  const { name, productType, productCode, primary } = props;

  let product = null;
  if ((productType || '').toLowerCase() === 'degree') {
    product = productCode || productType;
  } else {
    product = `${productType || ''} ${productCode || ''}`.trim();
  }

  if (!product) {
    return <></>;
  }

  return (
    <PropContainer>
      {name && <PropName>{name}</PropName>}
      <PropValue value={product} primary={primary} />
    </PropContainer>
  );
};

export default EventPropProduct;
