import React from 'react';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import { RowTitle } from './styled.components';
import { Breadcrumb } from '../breadcrumb/breadcrumb';
import { useFxRates } from 'shared/api/fxrates';
import { Table } from 'components/ui/table';
import { Link } from 'react-router-dom';

export const FXRatesLandingPage = () => {
  const { data: fxRates, isLoading } = useFxRates();

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>FX Rates</Breadcrumb.Item>
      </Breadcrumb>
      <RowTitle>FX Rates</RowTitle>
      <Table
        loading={isLoading}
        data={fxRates ?? []}
        columns={[
          {
            header: 'Currency Code',
            accessorKey: 'currencyCode',
            cell: ({ cell }) => <span className="text-center w-100">{cell.getValue().toUpperCase()}</span>,
          },
          {
            header: 'FX Rate (per $1.00)',
            accessorKey: 'fxRate',
          },
          {
            header: 'Effective From',
            accessorKey: 'effectiveFrom',
            cell: ({ cell }) => moment(cell.getValue()).format('YYYY-MM-DD'),
          },
          {
            header: ' ',
            cell: ({ row }) => <Link to={`/fxrates/${row.original.currencyCode}`}>Manage</Link>,
          },
        ]}
      />
    </Container>
  );
};
