import React from 'react';
import { Container } from 'react-bootstrap';
import { PermissionContainer } from '../../permissions/permission-container';
import { SuspenseContainer } from '../../suspense-container/suspense-container';
import { PartnerCodesBreadcrumb } from './components/partner-codes-breadcrumb';
import { Permission } from '../../../shared/models/permission';
import styles from './partner-details.module.scss';
import { PartnerCodesHeader } from './components/partner-codes-header/partner-codes-header';
import { PartnerCodesTable } from './components/partner-codes-table/partner-codes-table';
import { usePartnerCodesParams } from './hooks/use-partner-codes-params';
import { usePartner } from '../hooks/use-partner';

const PartnerCodesContent = () => {
  const { partnerId } = usePartnerCodesParams();

  const { data: partner } = usePartner(partnerId, { suspense: true });

  return (
    <>
      <PartnerCodesBreadcrumb accountName={partner?.accountName} />

      <div className={styles.container}>
        <PartnerCodesHeader />
        <PartnerCodesTable accountName={partner?.accountName} />
      </div>
    </>
  );
};

export const PartnerCodes = () => (
  <Container>
    <PermissionContainer permissions={[Permission.PartnersViewer]}>
      <SuspenseContainer loadingText="Loading Partner..." errorText="You don't have permission to view this page">
        <PartnerCodesContent />
      </SuspenseContainer>
    </PermissionContainer>
  </Container>
);
