import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import {
  ILoaSchedulerStateTypes,
  ILoaSchedulerData,
  ILoaOptionObject,
} from '../reducers/loa-scheduler/loa-scheduler.modal';
import IError from '../../models/Error';

export const getLoaSchedulerState = (state: IRootState): ILoaSchedulerStateTypes => state.loaSchedulerState;

export const getLoaSchedulerData = createSelector(
  [getLoaSchedulerState],
  (loaSchedulerState: ILoaSchedulerStateTypes): ILoaSchedulerData => loaSchedulerState.loaSchedulerData,
);

export const getLoaSchedulerDataPending = createSelector(
  [getLoaSchedulerState],
  (loaSchedulerState: ILoaSchedulerStateTypes): boolean => loaSchedulerState.pending,
);

export const getLoaSchedulerDataError = createSelector(
  [getLoaSchedulerState],
  (loaSchedulerState: ILoaSchedulerStateTypes): IError | null => loaSchedulerState.error,
);

export const getLoaSchedulerOptions = createSelector(
  [getLoaSchedulerState],
  (loaSchedulerState: ILoaSchedulerStateTypes): ILoaOptionObject => loaSchedulerState.optionsData,
);

export const getLoaSchedulerOptionsPending = createSelector(
  [getLoaSchedulerState],
  (loaSchedulerState: ILoaSchedulerStateTypes): boolean => loaSchedulerState.optionPending,
);

export const getLoaSchedulerUpdatePending = createSelector(
  [getLoaSchedulerState],
  (loaSchedulerState: ILoaSchedulerStateTypes): boolean => loaSchedulerState.pendingDataSave,
);
