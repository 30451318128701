import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { orderBy } from 'lodash';
import api from '../../../shared/api/adminUI.api';
import errorHandling from '../../helper-components/alert-component.component';
import { ISelectOption } from '../../../models/SelectOptions';
import languages from '../../../shared/data/languages.json';
import highestEducationLevelOptions from '../../../shared/data/highestEducationOptions.json';
import { handleSelectValidation } from './validation-functions';
import { ISAOption } from '../learner-info.model';

export const createSAModel = (option: ISelectOption): ISAOption => {
  const [FirstName, LastName] = option.label.split(' ');
  return {
    FirstName,
    LastName,
    Email: option.value,
  };
};

const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];
const READMISSION_ELIGIBILITY_OPTIONS = [
  { label: 'Not Eligible', value: 'true' },
  { label: 'Eligible', value: 'false' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: 34,
    minHeight: 34,
    fontWeight: 200,
    padding: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided: any) => ({
    ...provided,
    padding: '.25rem .5rem',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    padding: '.25rem .5rem',
  }),
};

interface ISelectFieldProps {
  fieldValue: string;
  setNewFieldValue: (newValue: string) => void;
  setSAFieldValue: (newValue: ISAOption) => void;
  setFormError: (error: string | null) => void;
  propertyName: string;
}

const proccessCountryOptions = (options: string[]): ISelectOption[] =>
  orderBy(
    options.map((option) => ({ value: option, label: option })),
    ['value'],
    ['asc'],
  );

const proccessSAOptions = (options: ISAOption[]): ISelectOption[] =>
  options.map((option) => ({
    value: option.Email,
    label: `${option.FirstName} ${option.LastName}`,
  }));

const getCountryOptions = async (
  setOptions: (options: ISelectOption[]) => void,
  setPending: (pending: boolean) => void,
): Promise<void> => {
  setPending(true);
  try {
    const response = await api.learnerProfile.getResidenceCountryOptions();
    const residenceCountryOptions = (await response.json()) as string[];

    setOptions(proccessCountryOptions(residenceCountryOptions));
    setPending(false);
  } catch (error: any) {
    setPending(false);
    errorHandling({ error });
  }
};

const getSuccessAdvisorOptions = async (
  setOptions: (options: ISelectOption[]) => void,
  setPending: (pending: boolean) => void,
): Promise<void> => {
  setPending(true);
  try {
    const response = await api.learnerProfile.getSuccessAdvisorOptions();
    const successAdvisorOptions = (await response.json()) as ISAOption[];
    setOptions(proccessSAOptions(successAdvisorOptions));
    setPending(false);
  } catch (error: any) {
    setPending(false);
    errorHandling({ error });
  }
};

const SelectFieldEditor: React.FunctionComponent<ISelectFieldProps> = ({
  fieldValue,
  setNewFieldValue,
  setSAFieldValue,
  setFormError,
  propertyName,
}: ISelectFieldProps) => {
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const [optionsPending, setPending] = useState<boolean>(false);

  const setError = (option: ISelectOption | undefined) => {
    setFormError(handleSelectValidation(option));
  };

  useEffect(() => {
    setError(undefined);
    switch (propertyName) {
      default:
      case 'Gender':
        setOptions(GENDER_OPTIONS);
        break;
      case 'readmissionEligibility':
        setOptions(READMISSION_ELIGIBILITY_OPTIONS);
        break;
      case 'Country':
      case 'InstitutionCountry':
        getCountryOptions(setOptions, setPending);
        break;
      case 'SuccessAdvisorName':
        getSuccessAdvisorOptions(setOptions, setPending);
        break;
      case 'Language':
      case 'InstitutionLanguage':
        setOptions(languages.languages);
        break;
      case 'HighestEducationLevel':
        setOptions(highestEducationLevelOptions.highestEducationLevelOptions);
        break;
    }
  }, []);

  const onSelectChange = (option: ISelectOption, fieldName: string) => {
    setError(option);
    switch (fieldName) {
      case 'readmissionEligibility':
        setNewFieldValue(option.value);
        break;
      case 'SuccessAdvisorName':
        setNewFieldValue(option.label);
        setSAFieldValue(createSAModel(option));
        break;
      default:
        setNewFieldValue(option.label);
        break;
    }

    return option;
  };

  const setValueForSelect = (oldValue: string, selectOptions: ISelectOption[]): ISelectOption | undefined =>
    selectOptions.find((option: ISelectOption) => option.label === oldValue);

  return optionsPending || options.length === 0 ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <Select
      styles={customStyles}
      options={options}
      defaultValue={setValueForSelect(fieldValue, options)}
      onChange={(option: any): ISelectOption => onSelectChange(option, propertyName)}
    />
  );
};

export default SelectFieldEditor;
