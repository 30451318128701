import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import styles from './breadcrumb.module.scss';

type ItemActiveProps = {
  active: true;
};

type ItemLinkProps = {
  active?: false;
  to: string;
};

type ItemProps = {
  children: React.ReactNode;
} & (ItemActiveProps | ItemLinkProps);

const isLink = (props: ItemActiveProps | ItemLinkProps): props is ItemLinkProps => !props.active;

const Item = ({ children, ...props }: ItemProps) => {
  if (!isLink(props)) {
    return <div className={styles.item}>{children}</div>;
  }

  const { to } = props;

  return (
    <Link className={styles.item} to={to}>
      {children}
    </Link>
  );
};

type Props = {
  children: React.ReactNode;
};

export const Breadcrumb = ({ children }: Props) => {
  const items = React.Children.toArray(children);

  return (
    <nav className={styles.container}>
      {items.map((item, index) => (
        <>
          {item}
          {index !== items.length - 1 && <FaChevronRight size={10} />}
        </>
      ))}
    </nav>
  );
};

Breadcrumb.Item = Item;
