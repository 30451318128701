import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import api from '../../../shared/api/adminUI.api';
import { educationInfoKeys, EducationProfileResponse } from '../../../models/LearnerRegistration';
import IError from '../../../models/Error';
import { WithLoading } from '../../helper-components/loading.component';
import { WithErrorHandling } from '../../helper-components/error-handling.component';

import { LearnerRegistrationApplicantBlock, TitleBarWithCta } from '../styled.components';
import FieldEditor from '../../admissions/applicant-editable-info/editing-component/editing-component';
import { APPLICANT_STATUS } from '../../constants/common-constants';
import { useNationalityOptionsContext } from '../../../shared/providers/nationalityOptionsProvider';

type RequestStateType = 'initial' | 'loading' | 'success' | 'error';

export interface PreviousEducationProps {
  canEditEducation: boolean;
}

const PreviousEducationComponent = (props: PreviousEducationProps) => {
  const { canEditEducation } = props;
  const { applicantId } = useParams<{ applicantId: string }>();

  const [educationInfoState, setEducationInfoState] = useState<RequestStateType>('initial');
  const [educationInfo, setEducationInfo] = useState<EducationProfileResponse>();
  const [educationInfoError, setEducationInfoError] = useState<IError | null>(null);

  const [editInProgress, setEditInProgress] = useState(false);
  const [editError, setEditError] = useState<IError>();

  const { countryOptions } = useNationalityOptionsContext();

  const fetchApplicantEducationInfo = async (id: string) => {
    try {
      setEducationInfoState('loading');
      const response = await api.learnerRegistration.getEducationInfo(id);
      if (!response.ok) throw await response;
      if (response.status === 204) setEducationInfo(undefined);
      else setEducationInfo((await response.json()) as EducationProfileResponse);
      setEducationInfoState('success');
    } catch (err) {
      setEducationInfoState('error');
      setEducationInfoError(err as IError);
    }
  };

  useEffect(() => {
    const loadInitialEducationDetails = async (): Promise<void> => {
      if (applicantId && educationInfoState === 'initial') await fetchApplicantEducationInfo(applicantId);
    };
    loadInitialEducationDetails();
  }, [applicantId, educationInfoState]);

  const submitEducationInfo = async (newValue: any): Promise<void> => {
    if (!educationInfo || !applicantId) return;
    setEditInProgress(true);
    const payload = { ...educationInfo, ...newValue };

    try {
      const response = await api.learnerRegistration.updateEducationInfo(applicantId, payload);
      if (!response.ok) throw await response;
      else setEducationInfo((await response.json()) as EducationProfileResponse);
      setEditInProgress(false);
    } catch (err) {
      setEditInProgress(false);
      setEditError(err as IError);
    }
  };

  const EducationInfoBlock = () => (
    <LearnerRegistrationApplicantBlock>
      <TitleBarWithCta>
        <h3>Previous Education</h3>
        <div>
          {editInProgress && (
            <span>
              Updating <FontAwesomeIcon icon={faSpinner} />
            </span>
          )}
        </div>
      </TitleBarWithCta>

      <WithLoading
        loading={educationInfoState === 'initial' || educationInfoState === 'loading'}
        loadingText="Loading previous education for applicant"
      >
        <WithErrorHandling small error={educationInfoError}>
          {!educationInfo && <p>Applicant hasn&apos;t submitted their previous education yet</p>}
          {educationInfo && (
            <ul>
              {editError && (
                <li className="status-line status-line--error">
                  <span>{editError.message || 'There was an error on the prior education updates'}</span>
                </li>
              )}
              <li>
                <span>{educationInfoKeys.InstitutionName}:</span>
                <FieldEditor
                  oldValue={educationInfo.InstitutionName}
                  propertyName="InstitutionName"
                  changedFieldUrl="InstitutionName"
                  fieldType="input"
                  maxSymbols={200}
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
              <li>
                <span>{educationInfoKeys.InstitutionCountry}:</span>
                <FieldEditor
                  oldValue={educationInfo.InstitutionCountry}
                  propertyName="InstitutionCountry"
                  changedFieldUrl="InstitutionCountry"
                  fieldType="select"
                  selectOptions={countryOptions}
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
              <li>
                <span>{educationInfoKeys.Accredited}:</span>
                <FieldEditor
                  oldValue={educationInfo.Accredited}
                  propertyName="Accredited"
                  changedFieldUrl="Accredited"
                  fieldType="checkbox"
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
              <li>
                <span>{educationInfoKeys.GraduationYear}:</span>
                <FieldEditor
                  oldValue={educationInfo.GraduationYear}
                  propertyName="GraduationYear"
                  changedFieldUrl="graduationYear"
                  fieldType="number"
                  maxSymbols={4}
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
              <li>
                <span>{educationInfoKeys.HighestEducationLevel}:</span>
                <FieldEditor
                  oldValue={educationInfo.HighestEducationLevel}
                  propertyName="HighestEducationLevel"
                  changedFieldUrl="HighestEducationLevel"
                  fieldType="select"
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
              <li>
                <span>{educationInfoKeys.DegreeOrCertificateName}:</span>
                <FieldEditor
                  oldValue={educationInfo.DegreeOrCertificateName}
                  propertyName="DegreeOrCertificateName"
                  changedFieldUrl="DegreeOrCertificateName"
                  fieldType="input"
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
              <li>
                <span>{educationInfoKeys.IsBusinessRelated}:</span>
                <FieldEditor
                  oldValue={educationInfo.IsBusinessRelated}
                  propertyName="IsBusinessRelated"
                  changedFieldUrl="IsBusinessRelated"
                  fieldType="checkbox"
                  handleNewValue={submitEducationInfo}
                  applicationStatus={
                    canEditEducation ? APPLICANT_STATUS.REGISTRATION : APPLICANT_STATUS.REGISTRATION_INITIATED
                  }
                />
              </li>
            </ul>
          )}
        </WithErrorHandling>
      </WithLoading>
    </LearnerRegistrationApplicantBlock>
  );

  return <EducationInfoBlock />;
};

export default PreviousEducationComponent;
