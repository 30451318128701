export interface PartnerInfo {
  partnerId: string;
  accountName: string;
  email: string;
  contactName?: string;
  logoUploadedFileName: string | null;
}

export type CreatePartnerPayload = {
  accountName: string;
  email: string;
  contactName: string | null;
  logo: File | null;
};

export type IdNameType = {
  id: string;
  name: string;
};

export type AgreementTuition = {
  productGroup: string;
  tier: string;
  planId: string;
};

export enum TuitionFeeInvoiceType {
  Learner = 'Learner',
  PartnerSponsorsLearner = 'PartnerSponsorsLearner',
  PartnerBillsLearner = 'PartnerBillsLearner',
}

export type CreatePartnerAgreementRequest = {
  applicationFeePaidBy: string;
  applicationFeeAmount: number;
  tuitionFeeInvoiceType: TuitionFeeInvoiceType;
  tuitionFeePaymentUrl?: string;
  startDate: string | number;
  endDate?: string | number;
  agreementTuitions: AgreementTuition[];
  enrollmentAgreementId: string;
  emailDomainsWhitelist: string[] | null;
};

export type AgreementPropertyValues = Record<
  'applicationInvoiceTypes' | 'productGroups' | 'tierTypes' | 'tuitionInvoiceTypes',
  IdNameType[]
>;

export type Agreement = CreatePartnerAgreementRequest & {
  agreementId: string;
  editor: string;
  timestamp: string;
};
