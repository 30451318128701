import * as React from 'react';
import cn from 'classnames';
import { Button, ButtonProps } from 'react-bootstrap';
import { Context as StepperContext } from '../context';

type Align = 'left' | 'right';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps & {
    raised?: boolean;
    align?: Align;
  };

const StepperAction = ({ disabled = false, type, align = 'left', className, children, ...props }: Props) => {
  const { isLoading } = React.useContext(StepperContext);

  return (
    <Button
      {...props}
      className={cn(className, 'stepper__action', {
        'stepper__action--right': align === 'right',
      })}
      type={type}
      disabled={isLoading() || disabled}
    >
      {children}
    </Button>
  );
};

export default StepperAction;
