import React from 'react';
import { Container } from 'react-bootstrap';
import { PermissionContainer } from '../../permissions/permission-container';
import { Permission } from '../../../shared/models/permission';
import { SuspenseContainer } from '../../suspense-container/suspense-container';
import { PartnerAgreementsBreadcrumbs } from './components/breadcrumbs';
import styles from './partner-agreements.module.scss';
import { PartnerAgreementsHeader } from './components/header';
import { PartnerAgreementsForm } from './components/form/form';

export const PartnerAgreements = () => (
  <Container>
    <PermissionContainer permissions={[Permission.PartnersViewer]}>
      <SuspenseContainer loadingText="Loading Partner..." errorText="Failed to load partner information">
        <PartnerAgreementsBreadcrumbs />

        <div className={styles.container}>
          <PartnerAgreementsHeader />
          <PartnerAgreementsForm />
        </div>
      </SuspenseContainer>
    </PermissionContainer>
  </Container>
);
