import React from 'react';
import { Redirect } from 'react-router-dom';
import api, { CredentialLookup } from '../../shared/api/adminUI.api';
import errorHandling from '../helper-components/alert-component.component';
import './learner-reference.scss';

interface StateProps {
  credential: CredentialLookup | null;
  loading: boolean;
  searchValue: string;
  isNoData: boolean;
}

class LearnerReference extends React.Component<any, StateProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      credential: null,
      loading: false,
      searchValue: '',
      isNoData: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt: any) {
    const {
      target: { value },
    } = evt;
    this.setState({ searchValue: value });
  }

  private readonly getCredentials = async (referenceId: string): Promise<void> => {
    if (referenceId) {
      try {
        this.setState({ loading: true, credential: null, isNoData: false });
        const credentialLookup = await api.graduation.getCredentialsByRereference(referenceId);
        this.setState({
          loading: false,
          credential: credentialLookup,
          isNoData: !credentialLookup,
        });
      } catch (error: any) {
        this.setState({ loading: false, credential: null });
        errorHandling({ error });
      }
    }
  };

  render() {
    const { credential, searchValue, loading, isNoData } = this.state;

    if (credential) {
      /* tslint:disable:max-line-length */
      const url = `/learners/${credential.LearnerId}/credentials?achievementId=${credential.AchievementId}`;
      return <Redirect push to={url} />;
    }
    return (
      <div className="learner-reference-wrapper">
        <p className="text">Look up learner/credentials by reference</p>
        <div className="learner-reference-inner">
          <div className={`search-block${loading ? ' loading' : ''}`}>
            <input
              type="text"
              name="text"
              placeholder="enter reference code..."
              value={searchValue}
              onChange={this.onChange}
              className="search-input"
            />
            <button type="submit" className="search-button" onClick={() => this.getCredentials(searchValue)}>
              Search
            </button>
          </div>
          {isNoData && <p className="no-data-label">Not found</p>}
        </div>
      </div>
    );
  }
}

export default LearnerReference;
