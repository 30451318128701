import { combineReducers } from 'redux';
import learnerProfileState, { ILearnerProfileState } from './learner-profile/learner-profile.reducer';
import learnerSearchState, { LearnerSearchState } from './learner-search.reducer';
import learnerInfoEditModalState from './learner-info-editing-modal/learner-info-edit-modal.reducer';
import { learnerInfoEditModalTypes } from './learner-info-editing-modal/learning-info-editing-modal.model';
import startDateModalState from './start-date/start-date.reducer';
import { IStartDateModalTypes } from './start-date/start-date.model';
import sapModelState from './academic-standing/academic-standing.reducer';
import { AcademicStandingModalTypes } from './academic-standing/academic-standing.model';
import loaSchedulerState from './loa-scheduler/loa-sheduler.reducer';
import { ILoaSchedulerStateTypes } from './loa-scheduler/loa-scheduler.modal';
import { IVoucherCodeStateTypes } from './voucher-code/voucher-code.model';
import { IApplicantEditableDataStateTypes } from './applicant-editable-info/applicant-editable-info.model';
import applicantEditableDataState from './applicant-editable-info/applicant-editable-info.reducer';
import voucherCodeState from './voucher-code/voucher-code.reducer';
import { ILearnerAEPDocumentsState } from './aep-documents/aep-documents.model';
import aepDocumentsState from './aep-documents/aep-documents.reducer';
import aepDeadlineState from './aep-deadline/aep-deadline.reducer';
import { ILearnerAEPDeadlineState } from './aep-deadline/aep-deadline.model';
import { IConfigurationState } from './configuration/configuration.model';
import configurationState from './configuration/configuration.reducer';
import readmissionState from './readmission/readmission.reducer';
import { IReadmissionPermissionState } from './readmission/readmission.model';

export interface IRootState {
  readonly learnerProfileState: ILearnerProfileState;
  readonly learnerSearchState: LearnerSearchState;
  readonly learnerInfoEditModalState: learnerInfoEditModalTypes;
  readonly startDateModalState: IStartDateModalTypes;
  readonly sapModelState: AcademicStandingModalTypes;
  readonly loaSchedulerState: ILoaSchedulerStateTypes;
  readonly voucherCodeState: IVoucherCodeStateTypes;
  readonly applicantEditableDataState: IApplicantEditableDataStateTypes;
  readonly aepDocumentsState: ILearnerAEPDocumentsState;
  readonly aepDeadlineState: ILearnerAEPDeadlineState;
  readonly configurationState: IConfigurationState;
  readonly readmissionState: IReadmissionPermissionState;
}

const appReducer = combineReducers({
  learnerProfileState,
  learnerSearchState,
  learnerInfoEditModalState,
  startDateModalState,
  sapModelState,
  loaSchedulerState,
  voucherCodeState,
  applicantEditableDataState,
  aepDocumentsState,
  aepDeadlineState,
  configurationState,
  readmissionState,
});

export default appReducer;
