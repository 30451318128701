import { FormField } from 'components/manage-partners/components/form-field/form-field';
import { KeyboardEventHandler, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import CreatableSelect from 'react-select/creatable';
import styles from './email-domains-input.module.scss';
import isValidDomain from 'is-valid-domain';
import { InputActionMeta, SelectComponentsConfig } from 'react-select';
import { ISelectOption } from 'models/SelectOptions';
import { OverlayTooltip, OverlayTooltipRef } from '../../tooltip/tooltip';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

const SEPERATOR = /[, ]/;

const components: SelectComponentsConfig<any, any, any> = {
  DropdownIndicator: null,
  ClearIndicator: undefined,
};

const createOption = (label: string): ISelectOption => ({
  label,
  value: label,
});

export const EmailDomainsInput = () => {
  const [inputValue, setInputValue] = useState('');
  const { input } = useField<string[]>('emailDomainsWhitelist');
  const tooltipRef = useRef<OverlayTooltipRef | null>(null);

  const handleChange = (newValue: ISelectOption[]) => input.onChange(newValue.map((option) => option.value));

  const handleAddValue = () => {
    const trimmedValue = inputValue.trim();

    if (!trimmedValue) return;

    const items = [
      ...new Set(
        trimmedValue
          .split(SEPERATOR)
          .map((value) => value.trim())
          .filter((value) => !!value && isValidDomain(value) && !input.value.includes(value)),
      ),
    ];

    if (!items.length) {
      if (!isValidDomain(trimmedValue)) {
        tooltipRef.current?.show('Invalid domain', { variant: 'danger' });
      } else if (input.value.includes(trimmedValue)) {
        tooltipRef.current?.show('Domain already exists', { variant: 'danger' });
      }
      return;
    }

    input.onChange([...input.value, ...items]);
    setInputValue('');
  };

  const handleInputChange = (value: string, actionMeta: InputActionMeta) => {
    if (!['menu-close', 'input-blur'].includes(actionMeta.action)) {
      setInputValue(value);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        event.preventDefault();
        handleAddValue();
    }
  };

  const labeledValue = input.value.map((value) => createOption(value));

  return (
    <div className={styles.container}>
      <FormField<ISelectOption[]>
        name="emailDomainsWhitelist"
        label="Enrollment Email Domains"
        className={styles.content}
        contentClassName={styles.inputWrapper}
        render={() => (
          <OverlayTooltip ref={tooltipRef}>
            <CreatableSelect
              className={styles.input}
              components={components}
              value={labeledValue}
              onChange={handleChange}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={handleAddValue}
              placeholder="Type something and press enter..."
              backspaceRemovesValue={false}
              menuIsOpen={false}
              isMulti
            />
          </OverlayTooltip>
        )}
      />
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Enter list of email domains that can enroll without an enrollment code</Tooltip>}
      >
        <div>
          <FaInfoCircle size={20} />
        </div>
      </OverlayTrigger>
    </div>
  );
};
