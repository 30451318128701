import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import IPartnersPermission from 'models/PartnersPermissions';
import { getPartnerPermissions } from 'shared/api/partner';

type Options = {} & Omit<UseQueryOptions<IPartnersPermission>, 'queryKey' | 'queryFn'>;

export const usePermissions = (options: Options = {}) =>
  useQuery<IPartnersPermission>({
    queryKey: ['permissions'],
    queryFn: () => getPartnerPermissions(),
    ...options,
  });
