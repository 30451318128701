import { AnyAction } from 'redux';
import moment from 'moment';
import {
  PROGRAM_STATUS,
  SUB_STATUS,
  REASON,
  COMMENT,
  RETURN_DATE,
} from '../../../components/learner-info/program-status/program-status-editing-modal';
import {
  learnerInfoEditModalTypes,
  ILearnerInfoOption,
  IProgramStatusOption,
} from './learning-info-editing-modal.model';
import { ISelectOption } from '../../../models/SelectOptions';
import {
  GET_DROPDOWN_OPTIONS_PENDING,
  GET_DROPDOWN_OPTIONS_ERROR,
  GET_DROPDOWN_OPTIONS_SUCCESS,
  SET_DROPDOWN_OPTIONS_ERROR,
  SET_DROPDOWN_OPTIONS_PENDING,
  SET_DROPDOWN_OPTIONS_SUCCESS,
} from './learner-info-edit-modal.constants';
import { emptySelectOption } from '../../../components/helper-components/form-components/form-filed-components';

const initialState: learnerInfoEditModalTypes = {
  programStatusOptions: [],
  restrictions: [],
  reasons: [],
  dropdownSelectedOptions: {
    [PROGRAM_STATUS]: emptySelectOption,
    [SUB_STATUS]: emptySelectOption,
    [REASON]: emptySelectOption,
    [RETURN_DATE]: emptySelectOption,
    [COMMENT]: '',
  },
  optionsPending: false,
  error: null,
};
const createOption = (option: ILearnerInfoOption): ISelectOption => ({
  label: option.name,
  value: option.id,
});

const retrieveProgramStatusOptions = (restrictions: IProgramStatusOption[]): ILearnerInfoOption[] =>
  restrictions.map((restriction: IProgramStatusOption) => restriction.programStatus);

const learnerInfoEditModalState = (
  state: learnerInfoEditModalTypes = initialState,
  { type, payload }: AnyAction,
): learnerInfoEditModalTypes => {
  switch (type) {
    case SET_DROPDOWN_OPTIONS_PENDING:
    case GET_DROPDOWN_OPTIONS_PENDING:
      return {
        ...state,
        optionsPending: true,
      };
    case GET_DROPDOWN_OPTIONS_SUCCESS:
      return {
        ...state,
        programStatusOptions: retrieveProgramStatusOptions(payload.restrictions).filter((option) => option.visible),
        restrictions: payload.restrictions,
        reasons: payload.reasons,
        optionsPending: false,
      };
    case SET_DROPDOWN_OPTIONS_SUCCESS:
      return {
        ...state,
        dropdownSelectedOptions: {
          ...payload,
          [PROGRAM_STATUS]: createOption(payload[PROGRAM_STATUS]),
          [SUB_STATUS]: Object.prototype.hasOwnProperty.call(payload, SUB_STATUS)
            ? createOption(payload[SUB_STATUS])
            : emptySelectOption,
          [REASON]: Object.prototype.hasOwnProperty.call(payload, REASON)
            ? createOption(payload[REASON])
            : emptySelectOption,
          [RETURN_DATE]: payload[RETURN_DATE]
            ? {
                label: moment(payload[RETURN_DATE]).format('DD MMM YYYY').toString(),
                value: moment(payload[RETURN_DATE]).format('DD MMM YYYY').toString(),
              }
            : emptySelectOption,
        },
        optionsPending: false,
      };

    case SET_DROPDOWN_OPTIONS_ERROR:
    case GET_DROPDOWN_OPTIONS_ERROR:
      return {
        ...state,
        error: payload,
        optionsPending: false,
      };
    default:
      return state;
  }
};

export default learnerInfoEditModalState;
