import React, { useEffect, useState } from 'react';
import Stepper, { Step, StepperContext, StepperController } from '../ui/stepper';
import api from '../../shared/api/adminUI.api';
import { LearningPathContainer, MaxCourseContainer } from './styled-components';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import errorHandling from '../helper-components/alert-component.component';
import { LearnerProgramTypesStepper } from './learning-path.model';
import { LOADING_TEXT, SPECIALISATIONS, ELECTIVE_COURSES, COURSES_ORDERING } from './learning-path.constants';
import ElectiveCourses, { STEP2 } from './steps/elective-courses/elective-courses';
import CoreCourses, { STEP3 } from './steps/courses-summary/courses-summary';
import SpecializationsCourses, { STEP1 } from './steps/specializations-courses/specialization-course';
import MaxCourseNumber from './max-course-number/max-course-number';
import IError from '../../models/Error';

interface ILearningPathProps {
  readonly canSaveLearningPathChanges: boolean;
  readonly learnerProgramType: string;
  readonly learnerProductCode: string;
  readonly learnerId: string;
  readonly programEnrollmentId: string;
}

export interface IUserProgressInfo {
  meetRequirements: boolean;
}

const isStepperVisible = (learnerProgramType: string): boolean =>
  Object.values(LearnerProgramTypesStepper).some((program) => program === learnerProgramType);

const checkIfIndividualCourse = (learnerProgramType: string): boolean =>
  learnerProgramType === LearnerProgramTypesStepper.INDIVIDUAL_COURSE;

const checkIfCertificate = (learnerProgramType: string): boolean =>
  learnerProgramType === LearnerProgramTypesStepper.CERTIFICATE;

const getInitialStep = (learnerProgramType: string, meetRequirements: boolean): string => {
  if (checkIfIndividualCourse(learnerProgramType) || checkIfCertificate(learnerProgramType)) {
    return STEP2;
  }
  return meetRequirements ? STEP3 : STEP1;
};

const getLPPStepsBasedOnLearnerProgram = (
  learnerId: string,
  programEnrollmentId: string,
  programType: string,
  learnerProductCode: string,
  canSaveLearningPathChanges: boolean,
  stepperContext: any,
  setIsElectivesEmpty: (value: boolean) => void,
): JSX.Element => {
  switch (programType) {
    case LearnerProgramTypesStepper.MBA:
    case LearnerProgramTypesStepper.BBA:
    default:
      return (
        <>
          <Step stepId={STEP1} title={SPECIALISATIONS} disabled={!canSaveLearningPathChanges}>
            <SpecializationsCourses
              stepperContext={stepperContext}
              submitButtonPermission={canSaveLearningPathChanges}
              learnerProductCode={learnerProductCode}
              learnerId={learnerId}
              programEnrollmentId={programEnrollmentId}
            />
          </Step>
          <Step stepId={STEP2} title={ELECTIVE_COURSES} disabled={!canSaveLearningPathChanges}>
            <ElectiveCourses
              stepperContext={stepperContext}
              learnerProductCode={learnerProductCode}
              learnerId={learnerId}
              programEnrollmentId={programEnrollmentId}
              setIsElectivesEmpty={setIsElectivesEmpty}
            />
          </Step>
          <Step stepId={STEP3} title={COURSES_ORDERING} disabled={!canSaveLearningPathChanges}>
            <CoreCourses
              stepperContext={stepperContext}
              submitButtonPermission={canSaveLearningPathChanges}
              learnerId={learnerId}
              programEnrollmentId={programEnrollmentId}
              isIndividualCourseProgram={false}
            />
          </Step>
        </>
      );
    case LearnerProgramTypesStepper.CERTIFICATE:
    case LearnerProgramTypesStepper.INDIVIDUAL_COURSE:
      return (
        <>
          <Step stepId={STEP1} title={ELECTIVE_COURSES} disabled={!canSaveLearningPathChanges}>
            <ElectiveCourses
              stepperContext={stepperContext}
              learnerProductCode={learnerProductCode}
              learnerId={learnerId}
              programEnrollmentId={programEnrollmentId}
              isIndividualCourseProgram
              setIsElectivesEmpty={setIsElectivesEmpty}
            />
          </Step>
          <Step stepId={STEP2} title={COURSES_ORDERING} disabled={!canSaveLearningPathChanges}>
            <CoreCourses
              stepperContext={stepperContext}
              submitButtonPermission={canSaveLearningPathChanges}
              learnerId={learnerId}
              programEnrollmentId={programEnrollmentId}
              isIndividualCourseProgram
            />
          </Step>
        </>
      );
  }
};

export const LearningPath: React.FC<ILearningPathProps> = (props: ILearningPathProps): JSX.Element => {
  const [userProgressInfoPending, setUserProgressInfoPending] = useState<boolean>(false);
  const [userProgressInfo, setUserProgressInfo] = useState<IUserProgressInfo>({
    meetRequirements: false,
  });
  const [error, setError] = useState<IError | null>(null);
  const [isElectivesEmpty, setIsElectivesEmpty] = useState<boolean>(false);

  useEffect(() => {
    const loadUserProgressInfo = async (
      learnerId: string,
      programEnrollmentId: string,

      learnerProgramType: string,
    ): Promise<void> => {
      try {
        if (isStepperVisible(learnerProgramType)) {
          setUserProgressInfoPending(true);

          const response = await api.learningPathPlanner.userProgressInfo.getUserProgressInfo(
            learnerId,
            programEnrollmentId,
          );
          if (!response.ok) throw await response;

          setUserProgressInfo((await response.json()) as IUserProgressInfo);
          setUserProgressInfoPending(false);
        } else {
          setUserProgressInfoPending(false);
        }
      } catch (err) {
        setUserProgressInfoPending(false);
        setError(err as IError);
        errorHandling({ error: err as IError });
      }
    };

    loadUserProgressInfo(props.learnerId, props.programEnrollmentId, props.learnerProgramType);
  }, [props.learnerId, props.programEnrollmentId, props.learnerProgramType]);

  const { canSaveLearningPathChanges, learnerProgramType, learnerProductCode, learnerId, programEnrollmentId } = props;

  const disableStepper = () => isElectivesEmpty && learnerProgramType === LearnerProgramTypesStepper.CERTIFICATE;

  const { meetRequirements } = userProgressInfo;

  return (
    <>
      <MaxCourseContainer>
        <MaxCourseNumber learnerId={learnerId} />
      </MaxCourseContainer>
      <WithLoading loading={userProgressInfoPending} loadingText={LOADING_TEXT}>
        <WithErrorHandling error={error}>
          <LearningPathContainer>
            {isStepperVisible(learnerProgramType) && !disableStepper() ? (
              <Stepper className="custom-theme" initialStep={getInitialStep(learnerProgramType, meetRequirements)}>
                <StepperContext.Consumer>
                  {(stepperContext: StepperController) =>
                    learnerProgramType &&
                    getLPPStepsBasedOnLearnerProgram(
                      learnerId,
                      programEnrollmentId,
                      learnerProgramType,
                      learnerProductCode,
                      canSaveLearningPathChanges,
                      stepperContext,
                      setIsElectivesEmpty,
                    )
                  }
                </StepperContext.Consumer>
              </Stepper>
            ) : (
              <CoreCourses
                submitButtonPermission={canSaveLearningPathChanges}
                learnerId={learnerId}
                programEnrollmentId={programEnrollmentId}
                isIndividualCourseProgram={false}
              />
            )}
          </LearningPathContainer>
        </WithErrorHandling>
      </WithLoading>
    </>
  );
};
