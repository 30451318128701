import React from 'react';
import { Col, Row, Button, Modal, Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import { AnyObject } from 'final-form';
import { FaTrashAlt } from 'react-icons/fa';
import api from '../../../shared/api/adminUI.api';
import errorHandling from '../../helper-components/alert-component.component';
import withPermission from '../../helper-components/view-permission.component';
import withLoading from '../../helper-components/loading.component';
import withErrorHandling from '../../helper-components/error-handling.component';
import {
  renderSelectField,
  renderCheckbox,
  renderTextField,
  emptySelectOption,
  Error,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { IProductTypeSelectOption, ISelectOption } from '../../../models/SelectOptions';
import {
  ACCEPTANCE_TYPE,
  ADMISSION_DECISION,
  ACCEPT,
  REJECT,
  ACCEPTANCE_PATH,
  FOUNDATION_COURSES,
  ACCEPTED_EVALUATION_PENDING,
  OK,
  PROVISIONAL,
  INCORRECT,
  MISSING,
  UNREADBLE,
  ADMISSION_DECISION_ERROR,
  FOUNDATION_COURSES_ERROR,
  AEP_ERROR_WRONG_SELECTION,
  WARNING_TEXT,
  COMMENT_WARNING,
  AEP_DOCUMENTS,
  FULL,
  PRODUCT_CODE,
  PRODUCT_TYPE,
  PRODUCT_CODE_ERROR,
  UNITED_STATES,
  UNITED_STATES_ISO,
} from './admissions-form.constants';
import { MBA, COMMENT_ID, COMMENT, SUBMIT, CLOSE, WARNING } from '../../constants/common-constants';
import Styles, {
  PaddingRow,
  RadioLabel,
  RadioButtonCol,
  StyledResult,
  StyledCard,
  TrashButton,
  CountryMessage,
} from './styled-components';
import {
  IAdmissionsFormState,
  IAdmissionsFormProps,
  IAdmissionFormValues,
  IAdmissionDataObject,
  IFormErrors,
} from './admissions-form.model';
import { ISelectedAdmissionOption, IAEPDocument } from '../admissions.model';
import { RedLabel } from '../../helper-components/form-components/styled-components';
import { ILearnerAEPDocument } from '../../learner-files/learner-files.model';
import InputableSelect from '../../../shared/inputable-select';
import { LongTextColumn } from '../../learner-info/styled-components';
import { OnChange } from '../../form/on-change';

const CHECKBOX = 'checkbox';
const RADIO = 'radio';
const SUBMITTED = 'Submitted';
interface IAdmissionsWarningModal {
  show: boolean;
  pendingSave: boolean;
  programFieldObject: {
    previousStatusLabel: string;
    currentStatusLabel: string;
    programFieldChanged: boolean;
  };
  handleSubmit: () => void;
  handleModal: (show: boolean) => void;
}

const AdmissionsWarningModal: React.FunctionComponent<IAdmissionsWarningModal> = (props: IAdmissionsWarningModal) => {
  const { show, pendingSave, programFieldObject, handleModal, handleSubmit } = props;
  return (
    <Modal show={show} onHide={() => handleModal(false)} backdrop="static" animation={false}>
      <Modal.Header>
        <Modal.Title>{WARNING}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaddingRow>
          <p>{WARNING_TEXT}</p>
          {programFieldObject.programFieldChanged && (
            <RedLabel>
              You are changing the program from {programFieldObject.previousStatusLabel} to <br />
              {programFieldObject.currentStatusLabel}, please ensure this is correct
            </RedLabel>
          )}
        </PaddingRow>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={pendingSave} onClick={() => handleSubmit()}>
          <span>{SUBMIT}</span>
          {pendingSave && <Spinner animation="border" size="sm" />}
        </Button>
        <Button variant="secondary" onClick={() => handleModal(false)}>
          {CLOSE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const createOptions = (options: ISelectedAdmissionOption[]): ISelectOption[] =>
  (options || []).map(
    (type: ISelectedAdmissionOption): ISelectOption => ({
      label: type.Label,
      value: type.Value,
    }),
  );

export const createProductTypeOptions = (options: ISelectedAdmissionOption[]): IProductTypeSelectOption[] =>
  (options || []).map(
    (type: ISelectedAdmissionOption): IProductTypeSelectOption => ({
      label: type.Label,
      value: type.Value,
      products: createOptions(type.Products),
    }),
  );

const createAepDocumentOptions = (options: IAEPDocument[]): ISelectOption[] =>
  (options || []).map(
    (type: IAEPDocument): ISelectOption => ({
      label: type.Name,
      value: type.Name,
    }),
  );

class AdmissionsForm extends React.Component<IAdmissionsFormProps, IAdmissionsFormState> {
  constructor(props: IAdmissionsFormProps) {
    super(props);
    const { applicantInfo, admissionData, aepDocuments: aepDocumentsList } = this.props;
    const emptyProductTypeSelectOption = {
      label: emptySelectOption.label,
      value: emptySelectOption.value,
      products: [],
    };
    const initialProductType =
      createProductTypeOptions(admissionData.ProductList).find(
        (option: IProductTypeSelectOption) => option.value === applicantInfo.ProductType,
      ) || emptyProductTypeSelectOption;
    const initialProductCode =
      initialProductType.products.find((option: ISelectOption) => option.value === applicantInfo.ProductCode) ||
      emptySelectOption;
    this.state = {
      programFieldObject: {
        previousStatusLabel: emptySelectOption.label,
        currentStatusLabel: emptySelectOption.label,
        programFieldChanged: false,
      },
      selectedProductTypeOption: initialProductType,
      isFormSubmitted: false,
      status: applicantInfo.Status,
      showModal: false,
      pendingSave: false,
      admissionFormValues: {
        [ADMISSION_DECISION]: '',
        [ACCEPTANCE_TYPE]: FULL,
        [PRODUCT_TYPE]: initialProductType || emptySelectOption,
        [PRODUCT_CODE]: initialProductCode || emptySelectOption,
        [FOUNDATION_COURSES]: [...props.initialFoundationCoursesValues],
        [ACCEPTANCE_PATH]: false,
        [COMMENT_ID]: '',
        [AEP_DOCUMENTS]: aepDocumentsList
          .filter((ad) => ad.IsDefault)
          .map((ad) => ({ name: ad.Name, status: OK }) as ILearnerAEPDocument),
      },
    };
  }

  private proccesFormDataBeforeSave = (values: IAdmissionFormValues): IAdmissionDataObject => {
    const { aepDocuments: aepDocumentsOptions } = this.props;
    const {
      acceptancePath,
      acceptanceType,
      admissionDecision,
      productType,
      productCode,
      foundationCourses,
      comment,
      aepDocuments,
    } = values;

    return {
      Accepted: admissionDecision === ACCEPT,
      ProductType: productType.value,
      ProductCode: productCode.value,
      Provisional: acceptanceType === PROVISIONAL,
      FoundationCourses: acceptanceType === PROVISIONAL ? foundationCourses : null,
      AEP: acceptancePath,
      Comments: comment,
      AEPDocuments: acceptancePath
        ? aepDocuments
        : aepDocumentsOptions
            .filter((ad) => ad.IsDefault)
            .map((ad) => ({ name: ad.Name, status: OK }) as ILearnerAEPDocument),
    };
  };

  private handleAepDocumentsChange = (fields: any, inputValue: any): void => {
    if (inputValue) {
      fields.push({ name: inputValue.value, status: MISSING });
    }
  };

  private handleProgramChange = (
    value: ISelectOption,
    previousValue: ISelectOption,
    values: IAdmissionFormValues,
  ): void => {
    if (value.value !== MBA) {
      values.acceptanceType = FULL;
    }
  };

  private handleSave = async (values: IAdmissionFormValues) => {
    try {
      this.setState({ pendingSave: true });
      const { applicantId } = this.props;

      const admissionData: IAdmissionDataObject = this.proccesFormDataBeforeSave(values);
      const response = await api.admissions.setAdmissionData(applicantId, admissionData);
      if (!response.ok) throw await response;

      this.setState({ isFormSubmitted: true, pendingSave: false });
    } catch (error: any) {
      errorHandling({ error });
      this.setState({ pendingSave: false });
    }
    this.handleModal(false);
  };

  private comparePreviusAndCurrentProgramValue = (form: AnyObject) => {
    const formValues = form.getState().values;
    const { applicantInfo } = this.props;
    const currentType = formValues[PRODUCT_TYPE].value;
    const currentCode = formValues[PRODUCT_CODE].value;
    const previousType = applicantInfo.ProductType;
    const previousCode = applicantInfo.ProductCode;
    if (currentType !== previousType || currentCode !== previousCode) {
      this.setState({
        programFieldObject: {
          programFieldChanged: true,
          previousStatusLabel: `${previousType} ${previousCode}`,
          currentStatusLabel: `${currentCode} ${currentType}`,
        },
      });
    } else {
      const { programFieldObject } = this.state;
      this.setState({
        programFieldObject: {
          ...programFieldObject,
          programFieldChanged: false,
        },
      });
    }
  };

  private openWarningModal = (form: AnyObject) => {
    this.comparePreviusAndCurrentProgramValue(form);
    this.handleModal(true);
  };

  private handleModal = (showModal: boolean) => {
    this.setState({ showModal });
  };

  render(): JSX.Element {
    const {
      programFieldObject,
      admissionFormValues,
      showModal,
      status,
      isFormSubmitted,
      pendingSave,
      selectedProductTypeOption,
    } = this.state;
    const {
      admissionData,
      makeApplicationDecisions,
      applicantInfo,
      identityInfo,
      applicantId,
      aepDocuments: aepDocumentsList,
      country,
      state,
    } = this.props;
    const aepDocumentsOptions = createAepDocumentOptions(aepDocumentsList);
    const defaultAepDocuments = aepDocumentsList.filter((ad) => ad.IsDefault);
    const initialProductTypeValue = createProductTypeOptions(admissionData.ProductList).find(
      (option: IProductTypeSelectOption) => option.value === applicantInfo.ProductType,
    );
    const initialProductCodeValue = !initialProductTypeValue
      ? undefined
      : initialProductTypeValue.products.find((option: ISelectOption) => option.value === applicantInfo.ProductCode);
    const identityProcessComplete = identityInfo && identityInfo.process && identityInfo.process.processComplete;
    const showForm = makeApplicationDecisions && !isFormSubmitted && status === SUBMITTED && identityProcessComplete;

    return showForm ? (
      <Styles>
        <Form
          onSubmit={this.handleSave}
          keepDirtyOnReinitialize
          initialValues={admissionFormValues}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            const errors: IFormErrors = {};
            if (!values[ADMISSION_DECISION]) {
              errors[ADMISSION_DECISION] = ADMISSION_DECISION_ERROR;
            }
            if (
              values[ADMISSION_DECISION] === ACCEPT &&
              (!values[PRODUCT_CODE] || values[PRODUCT_CODE].value === emptySelectOption.value)
            ) {
              errors[PRODUCT_CODE] = PRODUCT_CODE_ERROR;
            }
            if (values[ACCEPTANCE_TYPE] === PROVISIONAL && values[FOUNDATION_COURSES].length === 0) {
              errors[FOUNDATION_COURSES] = FOUNDATION_COURSES_ERROR;
            }
            if (values[ACCEPTANCE_PATH] && values[AEP_DOCUMENTS].filter((ad) => ad.status !== OK).length === 0) {
              errors[ACCEPTED_EVALUATION_PENDING] = AEP_ERROR_WRONG_SELECTION;
            }
            return errors;
          }}
          render={({ form, handleSubmit, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={4}>
                  <span>Applied Product</span>
                </Col>
                <Col xs={8}>
                  <span>
                    {applicantInfo.ProductCode} {applicantInfo.ProductType}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xs={4}>
                  <span>Admissions decision</span>
                </Col>

                <Col xs={4}>
                  <Field
                    name={ADMISSION_DECISION}
                    required
                    render={renderCheckbox}
                    type={RADIO}
                    value={ACCEPT}
                    label={ACCEPT}
                  />
                  <p>
                    <Error name={ADMISSION_DECISION} />
                  </p>
                </Col>
                <Col xs={4}>
                  <Field name={ADMISSION_DECISION} render={renderCheckbox} type={RADIO} value={REJECT} label={REJECT} />
                </Col>
              </Row>
              {values[ADMISSION_DECISION] === ACCEPT && (
                <>
                  <Row>
                    <Col xs={4}>
                      <span>Product Type</span>
                    </Col>
                    <Col xs={8}>
                      <Field
                        fieldType="admissionForm"
                        name={PRODUCT_TYPE}
                        emptyValue={initialProductTypeValue}
                        options={createProductTypeOptions(admissionData.ProductList)}
                        render={renderSelectField}
                      />
                      <OnChange name={PRODUCT_TYPE}>
                        {(value) => {
                          let selectedProductCode = initialProductCodeValue || emptySelectOption;
                          if (initialProductTypeValue && value.value !== initialProductTypeValue.value) {
                            selectedProductCode = emptySelectOption;
                          }
                          values[PRODUCT_CODE] = selectedProductCode;
                          this.setState({ selectedProductTypeOption: value });
                        }}
                      </OnChange>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <span>Product Code</span>
                    </Col>
                    <Col xs={8}>
                      <Field
                        fieldType="admissionForm"
                        name={PRODUCT_CODE}
                        emptyValue={initialProductCodeValue}
                        options={selectedProductTypeOption.products}
                        render={renderSelectField}
                      />
                      <OnChange name={PRODUCT_CODE}>
                        {(value, previous) => {
                          this.handleProgramChange(value, previous, values);
                        }}
                      </OnChange>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <span>Acceptance path</span>
                    </Col>
                    <Col xs={8}>
                      <Field
                        fieldType={CHECKBOX}
                        name={ACCEPTANCE_PATH}
                        render={renderCheckbox}
                        type={CHECKBOX}
                        label="Accepted Evaluation Pending"
                      />
                    </Col>
                  </Row>
                  {values[ACCEPTANCE_PATH] && (
                    <Row>
                      <Col xs={4}>
                        <span>Accepted Evaluation Pending</span>
                      </Col>
                      <Col xs={8}>
                        <Row>
                          <Col xs={3} />
                          <RadioButtonCol xs={1}>
                            <RadioLabel>Ok</RadioLabel>
                          </RadioButtonCol>
                          <RadioButtonCol xs={2}>
                            <RadioLabel>Missing</RadioLabel>
                          </RadioButtonCol>
                          <RadioButtonCol xs={3}>
                            <RadioLabel>Incorrect/Insufficient</RadioLabel>
                          </RadioButtonCol>
                          <RadioButtonCol xs={2}>
                            <RadioLabel>Unreadable</RadioLabel>
                          </RadioButtonCol>
                        </Row>
                        <FieldArray name={AEP_DOCUMENTS}>
                          {({ fields }) => (
                            <div>
                              {fields.map((document, index) => (
                                <div key={document}>
                                  <Row>
                                    <LongTextColumn xs={3}>{values.aepDocuments[index].name}</LongTextColumn>
                                    <RadioButtonCol xs={1}>
                                      <Field
                                        fieldType="RADIO"
                                        name={`${document}.status`}
                                        render={renderCheckbox}
                                        type={RADIO}
                                        value={OK}
                                      />
                                    </RadioButtonCol>
                                    <RadioButtonCol xs={2}>
                                      <Field
                                        fieldType="RADIO"
                                        name={`${document}.status`}
                                        render={renderCheckbox}
                                        type={RADIO}
                                        value={MISSING}
                                      />
                                    </RadioButtonCol>
                                    <RadioButtonCol xs={3}>
                                      <Field
                                        fieldType="RADIO"
                                        name={`${document}.status`}
                                        render={renderCheckbox}
                                        type={RADIO}
                                        value={INCORRECT}
                                      />
                                    </RadioButtonCol>
                                    <RadioButtonCol xs={2}>
                                      <Field
                                        fieldType="RADIO"
                                        name={`${document}.status`}
                                        render={renderCheckbox}
                                        type={RADIO}
                                        value={UNREADBLE}
                                      />
                                    </RadioButtonCol>

                                    <Col xs={1}>
                                      {!defaultAepDocuments.find(
                                        (ad) => ad.Name === values.aepDocuments[index].name,
                                      ) && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id="button-tooltip">Remove</Tooltip>}
                                        >
                                          <TrashButton onClick={() => fields.remove(index)}>
                                            <FaTrashAlt style={{ color: 'grey' }} />
                                          </TrashButton>
                                        </OverlayTrigger>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              {values.aepDocuments.length < 6 && (
                                <div>
                                  <InputableSelect
                                    minLength={4}
                                    maxLength={40}
                                    onChange={(inputValue: any) => {
                                      this.handleAepDocumentsChange(fields, inputValue);
                                    }}
                                    options={aepDocumentsOptions.filter(
                                      (ad) => !values.aepDocuments.find((add) => add.name === ad.value),
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <Error name={ACCEPTED_EVALUATION_PENDING} />
                      </Col>
                    </Row>
                  )}
                </>
              )}

              {(values[ADMISSION_DECISION] === REJECT || values[ADMISSION_DECISION] === ACCEPT) && (
                <Row>
                  <Col xs={4}>
                    <span>{COMMENT}</span>
                  </Col>
                  <Col xs={8}>
                    <p>{COMMENT_WARNING}</p>
                    <Field name={COMMENT_ID} displayError render={renderTextField} maxLength={250} />
                  </Col>
                </Row>
              )}
              {(country === UNITED_STATES || country === UNITED_STATES_ISO) && (
                <CountryMessage data-testid="country-message">
                  This is a US applicant from {state} state.
                </CountryMessage>
              )}

              <div className="buttons">
                <Button
                  disabled={invalid || pristine}
                  onClick={() => {
                    this.openWarningModal(form);
                  }}
                >
                  {SUBMIT}
                </Button>
              </div>
              <AdmissionsWarningModal
                show={showModal}
                pendingSave={pendingSave}
                programFieldObject={programFieldObject}
                handleModal={this.handleModal}
                handleSubmit={handleSubmit}
              />
            </form>
          )}
        />
      </Styles>
    ) : (
      <StyledCard>
        <Card.Title>
          <StyledResult>
            <span>
              Form is in {status} status and identity checks are
              {identityProcessComplete ? ' complete' : ' incomplete'}
            </span>
            {status === 'Enrolled' && (
              <Button style={{ marginLeft: 20 }} as="a" variant="success" href={`/learners/${applicantId}`}>
                Learner Record
              </Button>
            )}
          </StyledResult>
        </Card.Title>
      </StyledCard>
    );
  }
}
export default withLoading(withErrorHandling(withPermission(AdmissionsForm)));
