import { IEventDetails, IEvent } from './learner-history-model';
import { genericEventSelector } from './events/generic-event';
import { SAPLearnerSAPUpdatedEventSelector } from './events/sap-learner-sap-updated-event';
import { achievementApprovedSelector } from './events/achievement-learner-achievement-approved-event';
import { programStatusSelector } from './events/program-status';
import { programEnrollmentDetailsSelector } from './events/program-enrollment-details';
import { azureADUserCreatedEventSelector } from './events/azuread-user-created-event';
import { neoLearnerCreatedEventSelector } from './events/neo-learner-created-event';
import { startDateUpdatedSelector } from './events/billing-tuition-payments-start-date-updated-event';
import { azureADUserDepartmentUpdatedEventSelector } from './events/azuread-user-department-updated-event';
import { billingTuitionPaymentsResumedEventSelector } from './events/billing-tuition-payments-resumed-event';
import { billingTuitionPaymentsCanceledEventSelector } from './events/billing-tuition-payments-canceled-event';
import { billingTuitionPaymentsChangedEventSelector } from './events/billing-tuition-payments-changed-event';
import { billingTuitionPaymentsPausedEventSelector } from './events/billing-tuition-payments-paused-event';
import { neoLearnerDeactivatedEventSelector } from './events/neo-learner-deactivated-event';
import { neoLearnerReactivatedEventSelector } from './events/neo-learner-reactivated-event';
import { enrollmentLearnerEnrolledToCourseEventSelector } from './events/enrollment-learner-enrolled-to-course-event';
import { unenrolledFromCourseSelector } from './events/enrollment-learner-unenrolled-from-course-event';
import { enrollmentLearnerCourseDeletedEventSelector } from './events/enrollment-learner-course-deleted-event';
import { maxSimultaneousCoursesUpdatedSelector } from './events/learner-max-simultaneous-courses-updated-event';
import { programLearnerEnrolledToProgramEventSelector } from './events/program-learner-product-updated-event';
import { admissionAEPStatusChangedEventSelector } from './events/admission-aep-status-changed-event';
import { leaveOfAbsenceCreatedSelector } from './events/program-scheduled-leave-of-absence-created-event';
import { leaveOfAbsenceUpdatedSelector } from './events/program-scheduled-leave-of-absence-updated-event';
import { leaveOfAbsenceCancelledSelector } from './events/program-scheduled-leave-of-absence-cancelled-event';
import { successAdvisorAssignedSelector } from './events/learner-profile-success-advisor-assigned-event';
import { learnerOfficialNameUpdatedEventSelector } from './events/learner-profile-offical-name-updated-event';
import { learnerProfileLearnerEmailUpdatedEventSelector } from './events/learner-profile-email-updated-event';
import { learnerProfileLearnerBirthYearUpdatedEventSelector } from './events/learner-profile-birth-year-updated-event';
import { learnerCountryOfResidenceUpdatedSelector } from './events/learner-profile-country-of-residence-updated-event';
import { dropRequestStatusUpdatedEventSelector } from './events/drop-request-status-updated-event';
import { admissionAEPExtendedEventSelector } from './events/admission-aep-extended-event';
import { canvasLearnerCreatedEventSelector } from './events/canvas-learner-created-event';
import { courseMaxSimultaneousCoursesUpdatedSelector } from './events/courseload-max-simultaneous-courses-updated';
import { readmissioRequestApprovedEvent } from './events/readmission-request-approved-event';
import { readmissioRequestCanceledEvent } from './events/readmission-request-canceled-event';
import { readmissionAgreementExpiredEvent } from './events/readmission-agreement-expired-event';
import { readmissionAgreementSignedEvent } from './events/readmission-agreement-signed-event';
import { readmissionAgreementRejectedEvent } from './events/readmission-agreement-rejected-event';
import { learnerPrivacySettingUpdatedEventSelector } from './events/learner-profile-privacy-setting-updated-event';

const selectEventElement = (eventKey: string): ((event: IEvent) => IEventDetails | null) | null => {
  const eventKeyComponentMap: {
    [key: string]: ((event: IEvent) => IEventDetails | null) | null;
  } = {
    // Ignored:
    'Achievement.LearnerAchievementEvent': null,
    AcademicStanding: null,
    'LearnerProfile.LearnerGenderUpdatedEvent': null,
    'CourseEnrollment.LearnerUnenrolledEvent': null,
    'Enrollment.LearnerAdmissionParametersUpdatedEvent': null,
    'Program.LearnerAdmissionParametersUpdatedEvent': null,
    'LearnerFiles.LearnerFileDeletedEvent': null,
    'LearnerFiles.LearnerFileUpdatedEvent': null,
    'LearnerProfile.AcceptanceStatusChangedEvent': null,
    'LearnerProfile.LearnerNameUpdatedEvent': null,

    // SAP
    'SAP.LearnerSAPUpdatedEvent': SAPLearnerSAPUpdatedEventSelector,

    // Credentials
    'Achievement.LearnerAchievementApprovedEvent': achievementApprovedSelector,

    // Program Enrollment
    EnrollmentDetails: programEnrollmentDetailsSelector,
    'Program.LearnerEnrolledToProgramEvent': programLearnerEnrolledToProgramEventSelector,
    'Admission.AEPStatusChangedEvent': admissionAEPStatusChangedEventSelector,
    'Admission.AEPExtendedEvent': admissionAEPExtendedEventSelector,

    // Program Status
    ProgramStatus: programStatusSelector,
    'Program.ScheduledLeaveOfAbsenceCreatedEvent': leaveOfAbsenceCreatedSelector,
    'Program.ScheduledLeaveOfAbsenceUpdatedEvent': leaveOfAbsenceUpdatedSelector,
    'Program.ScheduledLeaveOfAbsenceCancelledEvent': leaveOfAbsenceCancelledSelector,

    // System Access
    'AzureAD.UserCreatedEvent': azureADUserCreatedEventSelector,
    'AzureAD.UserDepartmentUpdatedEvent': azureADUserDepartmentUpdatedEventSelector,
    'Billing.TuitionPaymentsStartDateUpdatedEvent': startDateUpdatedSelector,
    'Billing.TuitionPaymentsResumedEvent': billingTuitionPaymentsResumedEventSelector,
    'Billing.TuitionPaymentsCancelledEvent': billingTuitionPaymentsCanceledEventSelector,
    'Billing.TuitionPaymentChangedEvent': billingTuitionPaymentsChangedEventSelector,
    'Billing.TuitionPaymentsPausedEvent': billingTuitionPaymentsPausedEventSelector,
    'Neo.LearnerCreatedEvent': neoLearnerCreatedEventSelector,
    'Neo.LearnerDeactivatedEvent': neoLearnerDeactivatedEventSelector,
    'Neo.LearnerReactivatedEvent': neoLearnerReactivatedEventSelector,
    'Canvas.LearnerCreatedEvent': canvasLearnerCreatedEventSelector,

    // Course Enrollment
    'Enrollment.LearnerEnrolledToCourseEvent': enrollmentLearnerEnrolledToCourseEventSelector,
    'Enrollment.LearnerUnenrolledFromCourseEvent': unenrolledFromCourseSelector,
    'Enrollment.LearnerCourseDeletedEvent': enrollmentLearnerCourseDeletedEventSelector,
    'Enrollment.LearnerMaxSimultaneousCoursesUpdatedEvent': maxSimultaneousCoursesUpdatedSelector,
    'CourseLoad.LearnerMaxSimultaneousCoursesUpdatedEvent': courseMaxSimultaneousCoursesUpdatedSelector,
    'Enrollment.DropRequestStatusUpdatedEvent': dropRequestStatusUpdatedEventSelector,

    // Learner Profile
    'LearnerProfile.SuccessAdvisorAssignedEvent': successAdvisorAssignedSelector,
    'LearnerProfile.LearnerOfficialNameUpdatedEvent': learnerOfficialNameUpdatedEventSelector,
    'LearnerProfile.LearnerEmailUpdatedEvent': learnerProfileLearnerEmailUpdatedEventSelector,
    'LearnerProfile.LearnerBirthYearUpdatedEvent': learnerProfileLearnerBirthYearUpdatedEventSelector,
    'LearnerProfile.LearnerCountryOfResidenceUpdatedEvent': learnerCountryOfResidenceUpdatedSelector,
    'LearnerProfile.LearnerPrivacySettingUpdatedEvent': learnerPrivacySettingUpdatedEventSelector,

    // Readmission
    'Readmission.ReadmissionRequestApprovedEvent': readmissioRequestApprovedEvent,
    'Readmission.ReadmissionRequestCanceledEvent': readmissioRequestCanceledEvent,
    'Readmission.ReadmissionEnrollmentAgreementExpiredEvent': readmissionAgreementExpiredEvent,
    'Readmission.ReadmissionEnrollmentAgreementSignedEvent': readmissionAgreementSignedEvent,
    'Readmission.ReadmissionEnrollmentAgreementRejectedEvent': readmissionAgreementRejectedEvent,

    // Learner Identity
    'LearnerIdentity.LearnerOfficialNameUpdatedEvent': learnerOfficialNameUpdatedEventSelector,
  };

  const selector = eventKeyComponentMap[eventKey];

  return selector === undefined ? genericEventSelector : selector;
};

export default selectEventElement;
