import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import {
  faUser,
  faPollH,
  faHandshake,
  faSearch,
  faCreditCard,
  faBolt,
  faEnvelope,
  faGraduationCap,
  faCalendarDay,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.styles.scss';
import { getPartnerPermissions } from 'shared/api/partner';

interface ISidebarProps {
  width: number;
}

export default function Sidebar(props: ISidebarProps) {
  const { width } = props;
  const [partnerViewerPermission, setPartnerViewerPermission] = useState<boolean>(false);

  const getPermission = async () => {
    try {
      const perm = await getPartnerPermissions();

      setPartnerViewerPermission(perm!.PartnersViewer);
    } catch (err) {
      setPartnerViewerPermission(false);
    }
  };

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <Menu disableAutoFocus width={width} bodyClassName="body-class" pageWrapId="page-wrap" className="sidemenu">
      <NavLink to="/">
        <FontAwesomeIcon icon={faUser} />
        <span>My Profile</span>
      </NavLink>
      <h2>
        <span>Teaching</span>
      </h2>
      <NavLink to="/seminar-availability">
        <FontAwesomeIcon icon={faCalendarDay} />
        <span>Seminar Availability</span>
      </NavLink>
      <h2>
        <span>Learner Records</span>
      </h2>
      <NavLink to="/learners">
        <FontAwesomeIcon icon={faSearch} />
        <span>Learner Search</span>
      </NavLink>
      <NavLink to="/credentials">
        <FontAwesomeIcon icon={faGraduationCap} />
        <span>Credential Search</span>
      </NavLink>
      <h2>
        <span>Reports</span>
      </h2>
      <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_EOC_SURVEY_URL}>
        <FontAwesomeIcon icon={faPollH} />
        <span>End of course survey</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_PARTNER_LEARNERS_URL}>
        <FontAwesomeIcon icon={faHandshake} />
        <span>Partner learners report</span>
      </a>
      <NavLink to="/communications/recent">
        <FontAwesomeIcon icon={faEnvelope} />
        <span>Recent Communications</span>
      </NavLink>
      <h2>
        <span>Services</span>
      </h2>
      <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_CHARGEBEE_URL}>
        <FontAwesomeIcon icon={faCreditCard} />
        <span>Chargebee</span>
      </a>
      <NavLink to="/worq">
        <FontAwesomeIcon icon={faBolt} />
        <span>WorQ Queue</span>
      </NavLink>
      <h2>
        <span>System Configuration</span>
      </h2>
      <NavLink to="/fxrates">
        <FontAwesomeIcon icon={faMoneyBill} />
        <span>FX Rates</span>
      </NavLink>
      {partnerViewerPermission && (
        <NavLink to="/partners">
          <FontAwesomeIcon icon={faHandshake} />
          <span>Manage Partners</span>
        </NavLink>
      )}
    </Menu>
  );
}
