import { useQuery } from '@tanstack/react-query';
import { getPartner } from 'shared/api/partner';

type Options = {
  enabled?: boolean;
  suspense?: boolean;
};

export const usePartner = (id: string, options: Options = {}) =>
  useQuery({
    queryKey: ['partners', id],
    queryFn: () => getPartner(id),
    ...options,
  });
