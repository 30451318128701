import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import IError from '../../models/Error';
import Learner, { LearnerFlags } from '../../models/Learner';
import errorHandling from '../../components/helper-components/alert-component.component';
import api from '../api/adminUI.api';

import {
  GET_LEARNER_PROFILE_PENDING,
  GET_LEARNER_PROFILE_FULFILLED,
  GET_LEARNER_PROFILE_REJECTED,
  GET_LEARNER_INFO_PERMISSIONS_PENDING,
  GET_LEARNER_INFO_PERMISSIONS_SUCCESS,
  GET_LEARNER_INFO_PERMISSIONS_ERROR,
  GET_LEARNER_PROFILE_VIEW_PERMISSION_PENDING,
  GET_LEARNER_PROFILE_VIEW_PERMISSION_SUCCESS,
  GET_LEARNER_PROFILE_VIEW_PERMISSION_ERROR,
  GET_LEARNER_PAYMENT_STATUS_FULFILLED,
  GET_LEARNER_PAYMENT_STATUS_REJECTED,
  GET_LEARNER_PAYMENT_STATUS_PENDING,
  UPDATE_FIELD_VALUE,
  GET_LEARNER_ACHIEVEMENTS_PENDING,
  GET_LEARNER_ACHIEVEMENTS_SUCCESS,
  GET_LEARNER_ACHIEVEMENTS_REJECTED,
  UPDATE_LEARNER_ACHIEVEMENTS_STATUS,
  GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_PENDING,
  GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_SUCCESS,
  GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_ERROR,
  GET_LPP_PERMISSIONS_PENDING,
  GET_LPP_PERMISSIONS_SUCCESS,
  GET_LPP_PERMISSIONS_ERROR,
  UPDATE_LEARNER_ACHIEVEMENTS_PENDING,
  UPDATE_LEARNER_ACHIEVEMENTS_REJECTED,
  GET_LEARNER_PROGRAM_ENROLLMENTS_PENDING,
  GET_LEARNER_PROGRAM_ENROLLMENTS_FULFILLED,
  GET_LEARNER_PROGRAM_ENROLLMENTS_ERROR,
  UPDATE_ACTIVE_PROGRAM_ENROLLMENT,
  GET_LEARNER_FLAGS_PENDING,
  GET_LEARNER_FLAGS_REJECTED,
  GET_LEARNER_FLAGS_FULFILLED,
  UPDATE_LEARNER_READMISSION_FLAG_VALUE,
  GET_LEARNER_APPLICANT_INFO_SUCCESS,
  GET_LEARNER_APPLICANT_INFO_PENDING,
  GET_LEARNER_APPLICANT_INFO_REJECTED,
} from '../reducers/learner-profile/learner-profile.constants';
import ProgramEnrollment from '../../models/ProgramEnrollment';
import { INewFieldValue, ISAOption } from '../../components/learner-info/learner-info.model';
import { IApplicantInfo } from '../../components/admissions/admissions.model';
import { request } from 'shared/api/fetch';

export interface IlearnerInfoEditPermissionObject {
  canEditProgramStatus: boolean;
  canViewEnrollmentData: boolean;
  canUpdateAcademicStanding: boolean;
  canDeleteLearnerCourses: boolean;
  canEditStartDate: boolean;
  canEditLearnerInfo: boolean;
  canEditAdmissionParameters: boolean;
  canEditSuccessAdvisor: boolean;
  canEditAepStatus: boolean;
  canApproveDropRequest: boolean;
  canViewAepStatus: boolean;
  canEditCompletedCourse: boolean;
}

interface IlearnerInfoViewPermissionObject {
  canReadLearnerProfile: boolean;
}

export interface IlearnerAchievementsPermissionObject {
  canReadLearnerAchievements: boolean;
  canApproveLearnerAchievements: boolean;
}

export interface ILPPPermissionObject {
  canViewLearningPathPlan: boolean;
  canEditLearningPathPlan: boolean;
}

interface IBillingPermission {
  canViewBillingData: boolean;
}

export interface ILearnerAchievementDetails {
  achievements: ILearnerAchievement[];
  progress: ILearnerAchievementProgress[];
  subscriptionCode: string;
}

interface ILearnerAchievement {
  id: string;
  category: string;
  name: string;
  productCode: string;
  productType: string;
  achievementType: 'credential' | 'non-credential' | null;
  editor?: string | null | undefined;
  date?: null | Date | undefined;
  status: string;
  approvalDate?: null | Date | undefined;
  approvedBy?: string | null | undefined;
  artifacts?: ILearnerAchievementArtifact[];
}

export interface ILearnerAchievementArtifact {
  artifactName: string;
  artifactType: string;
  artifactService: string;
  data: string;
  timestamp: Date;
}

export interface ILearnerAchievementProgress {
  name: string;
  code: string;
  productCode: string;
  productType: string;
  achievementType: 'credential' | 'non-credential';
  timestamp: Date;
  progress: number;
  passedCoursesCount: number;
  requiredCoursesCount: number;
}

export const loadLearnerEnrollments = (learnerId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LEARNER_PROGRAM_ENROLLMENTS_PENDING });
    const programEnrollments = await request.get<ProgramEnrollment[]>(
      `/api/adminaggregator/learners/${learnerId}/program-enrollments`,
    );

    dispatch({
      type: GET_LEARNER_PROGRAM_ENROLLMENTS_FULFILLED,
      payload: { programEnrollments },
    });
  } catch (error: any) {
    const errorMessage = await error.json();
    const errorObject = { statusCode: error.status, ...errorMessage };
    dispatch({
      type: GET_LEARNER_PROGRAM_ENROLLMENTS_ERROR,
      payload: errorObject,
    });
    errorHandling({ error: errorObject });
  }
};

export const loadLearnerInfo = (learnerId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LEARNER_PROFILE_PENDING });
    const learner = await request.get<Learner>(`/api/learner-profile/learners/${learnerId}`);

    dispatch({
      type: GET_LEARNER_PROFILE_FULFILLED,
      payload: { learner },
    });
  } catch (error: any) {
    const errorMessage = await error.json();
    const errorObject = { statusCode: error.status, ...errorMessage };
    dispatch({
      type: GET_LEARNER_PROFILE_REJECTED,
      payload: errorObject,
    });
    errorHandling({ error: errorObject });
  }
};

export const loadLearnerFlags = (learnerId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LEARNER_FLAGS_PENDING });
    const response = await api.readmission.getReadmissionEligibility(learnerId);
    if (!response.ok) throw await response;

    const learnerFlagsResp = (await response.json()) as LearnerFlags;

    dispatch({
      type: GET_LEARNER_FLAGS_FULFILLED,
      payload: { learnerFlagsResp },
    });
  } catch (error: any) {
    const errorMessage = await error.json();
    const errorObject = { statusCode: error.status, ...errorMessage };

    dispatch({
      type: GET_LEARNER_FLAGS_REJECTED,
      payload: errorObject,
    });

    errorHandling({ error: errorObject });
  }
};

export const updateLearnerReadmissionFlag =
  (learnerId: string, payload: { Value: boolean }) => async (dispatch: Dispatch) => {
    try {
      const response = await api.readmission.putReadmissionEligibility(learnerId, payload);

      if (!response.ok) throw await response;
      dispatch({
        type: UPDATE_LEARNER_READMISSION_FLAG_VALUE,
        payload: payload.Value,
      });
    } catch (error: any) {
      errorHandling({ error });
    }
  };

export const loadLearnerPaymentStatus = (learnerId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LEARNER_PAYMENT_STATUS_PENDING });

    const permissionResponse = await api.billing.getBillingPermissions();
    if (!permissionResponse.ok) throw await permissionResponse.json();
    const billingPermission = (await permissionResponse.json()) as IBillingPermission;

    if (billingPermission.canViewBillingData) {
      const response = await api.billing.getLearnerPaymentStatus(learnerId);
      if (!response.ok) throw await response;
      const learnerPaymentStatus = (await response.json()) as Learner;
      dispatch({
        type: GET_LEARNER_PAYMENT_STATUS_FULFILLED,
        payload: learnerPaymentStatus,
      });
    } else {
      throw await { message: 'Access restricted', notShowErrorMessage: true };
    }
  } catch (error: any) {
    dispatch({
      type: GET_LEARNER_PAYMENT_STATUS_REJECTED,
      payload: error,
    });
    if (!error.notShowErrorMessage) {
      errorHandling({ error });
    }
  }
};

export const getEditPermissionPending = (): AnyAction => action(GET_LEARNER_INFO_PERMISSIONS_PENDING);
export const getEditingPermissionSuccess = (permissionObject: IlearnerInfoEditPermissionObject): AnyAction =>
  action(GET_LEARNER_INFO_PERMISSIONS_SUCCESS, permissionObject);
export const getEditingPermissionFailure = (error: IError): AnyAction =>
  action(GET_LEARNER_INFO_PERMISSIONS_ERROR, error);

export const getEnrollmentEditingPermissions = () => async (dispatch: Dispatch) => {
  dispatch(getEditPermissionPending());

  try {
    const permissionObject = await request.get<IlearnerInfoEditPermissionObject>('/api/enrollment/permissions');
    dispatch(getEditingPermissionSuccess(permissionObject));
  } catch (error: any) {
    dispatch(getEditingPermissionFailure(error));
    errorHandling({ error });
  }
};

export const getAdmissionEditingPermissions = () => async (dispatch: Dispatch) => {
  dispatch(getEditPermissionPending());

  try {
    const permissionObject = await request.get<IlearnerInfoEditPermissionObject>('/api/admission/permissions');
    dispatch(getEditingPermissionSuccess(permissionObject));
  } catch (error: any) {
    dispatch(getEditingPermissionFailure(error));
    errorHandling({ error });
  }
};

export const getLearnerInfoViewingPermissionPending = (): AnyAction =>
  action(GET_LEARNER_PROFILE_VIEW_PERMISSION_PENDING);
export const getLearnerInfoViewingPermissionSuccess = (permissionObject: IlearnerInfoViewPermissionObject): AnyAction =>
  action(GET_LEARNER_PROFILE_VIEW_PERMISSION_SUCCESS, permissionObject);
export const getLearnerInfoViewingPermissionFailure = (error: IError): AnyAction =>
  action(GET_LEARNER_PROFILE_VIEW_PERMISSION_ERROR, error);

export const getLearnerProfileViewPermission = () => async (dispatch: Dispatch) => {
  dispatch(getLearnerInfoViewingPermissionPending());

  try {
    const permissionObject = await request.get<IlearnerInfoViewPermissionObject>('/api/learner-profile/permissions');
    dispatch(getLearnerInfoViewingPermissionSuccess(permissionObject));
  } catch (error: any) {
    dispatch(getLearnerInfoViewingPermissionFailure(error));
    errorHandling({ error });
  }
};

export const updateLearnerProfileFieldValue =
  (learnerId: string, newFieldValue: INewFieldValue | ISAOption, changedFieldUrl: string) =>
  async (dispatch: Dispatch) => {
    try {
      await request.put(`/api/learner-profile/learners/${learnerId}/${changedFieldUrl}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(newFieldValue),
      });
      dispatch({ type: UPDATE_FIELD_VALUE, payload: newFieldValue });
    } catch (error: any) {
      errorHandling({ error });
    }
  };

export const getLearnerAchievementsPermissionPending = (): AnyAction =>
  action(GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_PENDING);
export const getLearnerAchievementsPermissionSuccess = (
  permissionObject: IlearnerAchievementsPermissionObject,
): AnyAction => action(GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_SUCCESS, permissionObject);
export const getLearnerAchievementsPermissionFailure = (error: IError): AnyAction =>
  action(GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_ERROR, error);

export const getLearnerAchievementsPermission = () => async (dispatch: Dispatch) => {
  dispatch(getLearnerAchievementsPermissionPending());

  try {
    const permissionObject = await request.get<IlearnerAchievementsPermissionObject>('/api/achievements/permissions');

    dispatch(getLearnerAchievementsPermissionSuccess(permissionObject));
  } catch (error: any) {
    dispatch(getLearnerAchievementsPermissionFailure(error));
    errorHandling({ error });
  }
};

export const getLPPPermissionPending = (): AnyAction => action(GET_LPP_PERMISSIONS_PENDING);
export const getLPPPermissionSuccess = (permissionObject: ILPPPermissionObject): AnyAction =>
  action(GET_LPP_PERMISSIONS_SUCCESS, permissionObject);
export const getLPPPermissionFailure = (error: IError): AnyAction => action(GET_LPP_PERMISSIONS_ERROR, error);

export const getLPPPermission = () => async (dispatch: Dispatch) => {
  dispatch(getLPPPermissionPending());

  try {
    const permissionObject = await request.get<ILPPPermissionObject>('/api/lpp/permissions');

    dispatch(getLPPPermissionSuccess(permissionObject));
  } catch (error: any) {
    dispatch(getLPPPermissionFailure(error));
    errorHandling({ error });
  }
};

export const getLearnerApplicantInfoObjectPending = (): AnyAction => action(GET_LEARNER_APPLICANT_INFO_PENDING);
export const getLearnerApplicantInfoObjectSuccess = (applicantInfo: IApplicantInfo): AnyAction =>
  action(GET_LEARNER_APPLICANT_INFO_SUCCESS, applicantInfo);
export const loadLearnerApplicantInfo = (learnerId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(getLearnerApplicantInfoObjectPending());

    const response = await api.admissions.getApplicantInfo(learnerId);

    if (!response.ok) throw await response;

    const applicantInfo = (await response.json()) as IApplicantInfo;

    dispatch(getLearnerApplicantInfoObjectSuccess(applicantInfo));
  } catch (error: any) {
    dispatch({
      type: GET_LEARNER_APPLICANT_INFO_REJECTED,
      payload: error,
    });
    errorHandling(error);
  }
};

export const getLearnerAchievementsObjectPending = (): AnyAction => action(GET_LEARNER_ACHIEVEMENTS_PENDING);
export const getLearnerAchievementsObjectSuccess = (achievementDetails: ILearnerAchievementDetails): AnyAction =>
  action(GET_LEARNER_ACHIEVEMENTS_SUCCESS, achievementDetails);
export const getLearnerAchievementsObjectFailure = (error: IError): AnyAction =>
  action(GET_LEARNER_ACHIEVEMENTS_REJECTED, error);

export const loadLearnerAchievements = (learnerId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(getLearnerAchievementsObjectPending());

    const achievements = await request.get<ILearnerAchievement[]>(
      `/api/achievements/learners/${learnerId}/achievements`,
    );
    const progressData = await request.get<{
      progress: ILearnerAchievementProgress[];
      subscriptionCode: string;
    }>(`/api/achievements/learners/${learnerId}/achievements/progress`);

    dispatch(
      getLearnerAchievementsObjectSuccess({
        achievements,
        progress: progressData.progress,
        subscriptionCode: progressData.subscriptionCode,
      }),
    );
  } catch (error: any) {
    // const errorMessage = await error.json();
    // const errorObject = { statusCode: error.status, ...errorMessage };
    dispatch({
      type: GET_LEARNER_ACHIEVEMENTS_REJECTED,
      payload: error,
    });
    errorHandling(error);
  }
};

export const getUpdateLearnerAchievementStatusPending = (): AnyAction => action(UPDATE_LEARNER_ACHIEVEMENTS_PENDING);
export const getUpdateLearnerAchievementStatusFailure = (error: IError): AnyAction =>
  action(UPDATE_LEARNER_ACHIEVEMENTS_REJECTED, error);

export const updateLearnerAchievementStatus = (achievementId: string) => async (dispatch: Dispatch) => {
  dispatch(getUpdateLearnerAchievementStatusPending());
  try {
    const data = await request.put<ILearnerAchievement>(`/api/achievements/${achievementId}/approved`);
    dispatch({ type: UPDATE_LEARNER_ACHIEVEMENTS_STATUS, payload: data });
  } catch (error: any) {
    const errorMessage = await error.json();
    const errorObject = { statusCode: error.status, ...errorMessage };
    dispatch(getUpdateLearnerAchievementStatusFailure(error));
    errorHandling({ error: errorObject });
  }
};

export const updateActiveProgramEnrollment = (programEnrollmentId: string): AnyAction =>
  action(UPDATE_ACTIVE_PROGRAM_ENROLLMENT, programEnrollmentId);
