import { UNITED_STATES_ISO } from 'components/admissions/admissions-form/admissions-form.constants';
import { CountryMessage } from 'components/admissions/admissions-form/styled-components';
import { COMMENT } from 'components/constants/common-constants';
import { TextArea } from 'components/ui/form';
import { RegistrationItem } from 'models/LearnerRegistration';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { AppError } from 'shared/api/app-error';
import { rejectAdmittance, usePersonalDetails } from 'shared/api/learner-registration';

type FieldValues = {
  [COMMENT]: string;
};

type Props = {
  onHide: () => void;
  show: boolean;
  applicantId: string;
  activeRegistration: RegistrationItem;
  onSuccess: () => Promise<any>;
};

export const RejectAdmissionDecisionModal = ({ applicantId, activeRegistration, onHide, show, onSuccess }: Props) => {
  const { data: personalDetails } = usePersonalDetails(applicantId, {
    enabled: show,
  });

  const handleSubmit = async (values: FieldValues): Promise<void> => {
    try {
      await rejectAdmittance(applicantId, activeRegistration.registrationId, {
        comment: values[COMMENT],
      });

      await onSuccess();
    } catch (err) {
      toast.error(
        err instanceof AppError && err.data?.code !== 'UNKNOWN' ? err.message : 'Failed to reject admission decision',
      );
    }
  };

  return (
    <Modal show={show} animation={false} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Rejection Reason</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {personalDetails?.country === UNITED_STATES_ISO && (
                <div className="d-flex justify-content-center align-items-center">
                  <CountryMessage data-testid="country-message">
                    This is a US applicant from {personalDetails?.state} state.
                  </CountryMessage>
                </div>
              )}

              <TextArea name={COMMENT} label="Comment" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="d-flex gap-1 align-items-center"
                variant="danger"
                disabled={invalid || submitting}
                type="submit"
              >
                {submitting && <Spinner size="sm" />}
                <span>Reject</span>
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};
