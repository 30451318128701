import React from 'react';
import { Form } from 'react-bootstrap';

interface ICheckFieldProps {
  fieldValue: boolean | any;
  setNewFieldValue: (newValue: boolean) => void;
}

const CheckboxField: React.FunctionComponent<ICheckFieldProps> = ({
  fieldValue,
  setNewFieldValue,
}: ICheckFieldProps) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setNewFieldValue(newValue);
  };

  return (
    <>
      <Form.Check type="checkbox" onChange={onValueChange} checked={fieldValue} />
    </>
  );
};
export default CheckboxField;
