import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../api/adminUI.api';
import IError from '../../models/Error';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  GET_VOUCHER_DATA_PENDING,
  GET_VOUCHER_DATA_SUCCESS,
  GET_VOUCHER_DATA_ERROR,
  UPDATE_APPLICATION_FIELD_VALUE,
  UPDATE_TUITION_FIELD_VALUE,
} from '../reducers/voucher-code/voucher-code.constants';

import { IVoucherData } from '../reducers/voucher-code/voucher-code.model';
import { INewFieldValue } from '../../components/admissions/admissions.model';
import { switchInvalidCouponCodeReason } from '../../components/helper-components/payment-utility';
import { request } from 'shared/api/fetch';
import { AppError } from 'shared/api/app-error';

export const getVoucherCodeObjectPending = (): AnyAction => action(GET_VOUCHER_DATA_PENDING);
export const getStartDateObjectSuccess = (voucherData: IVoucherData): AnyAction =>
  action(GET_VOUCHER_DATA_SUCCESS, voucherData);
export const getVoucherCodeObjectFailure = (error: IError): AnyAction => action(GET_VOUCHER_DATA_ERROR, error);

export const getVoucherCodeInfo = (applicantId: string) => async (dispatch: Dispatch) => {
  dispatch(getVoucherCodeObjectPending());
  try {
    const response = await api.admissions.getApplicantVoucherCodeData(applicantId);
    if (!response.ok) throw await response;
    const data = (await response.json()) as IVoucherData;
    dispatch(getStartDateObjectSuccess(data));
  } catch (error: any) {
    dispatch(getVoucherCodeObjectFailure(error));
    errorHandling({ error });
  }
};

export const updateApplicationVoucherCodeFieldValue =
  (applicantId: string, newFieldValue: INewFieldValue) => async (dispatch: Dispatch) => {
    try {
      await request.put(`/api/admission/applicants/${applicantId}/application-fee/coupon`, {
        body: JSON.stringify(newFieldValue),
        headers: {
          'content-type': 'application/json',
        },
      });

      dispatch({
        type: UPDATE_APPLICATION_FIELD_VALUE,
        payload: newFieldValue,
      });
    } catch (error: any) {
      if (error instanceof AppError && error.data?.code !== 'UNKNOWN') {
        error.message = switchInvalidCouponCodeReason(error.data?.code || '');
      }
      errorHandling({ error });
    }
  };

export const updateTuitionVoucherCodeFieldValue =
  (applicantId: string, newFieldValue: INewFieldValue) => async (dispatch: Dispatch) => {
    try {
      await request.put(`/api/admission/applicants/${applicantId}/tuition-fee/coupon`, {
        body: JSON.stringify(newFieldValue),
        headers: {
          'content-type': 'application/json',
        },
      });

      dispatch({ type: UPDATE_TUITION_FIELD_VALUE, payload: newFieldValue });
    } catch (error: any) {
      if (error instanceof AppError && error.data?.code !== 'UNKNOWN') {
        error.message = switchInvalidCouponCodeReason(error.data?.code || '');
      }
      errorHandling({ error });
    }
  };
