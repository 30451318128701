import { getBearerToken } from 'components/auth/auth.utils';
import { AppError } from './app-error';

const getResponseValue = (response: Response) => {
  const contentType = response.headers.get('content-type');
  if (contentType?.includes('application/json')) {
    return response.json();
  }

  if (contentType?.includes('text/csv')) {
    return response.blob();
  }

  return response.text();
};

/**
 * TODO add new features to the request method
 * like handling processing, error handling, etc.
 */
const createRequest =
  (method: string) =>
  async <T, Nullable = false>(path: string, params?: RequestInit): Promise<Nullable extends true ? T | null : T> => {
    const authToken = await getBearerToken();
    const response = await fetch(path, {
      ...params,
      method,
      headers: {
        ...params?.headers,
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw AppError.fromError(await getResponseValue(response), response.status);
    }

    if (response.status === 204) return null as T;

    return getResponseValue(response);
  };

// TODO: Add more request methods if needed
export const request = {
  get: createRequest('get'),
  post: createRequest('post'),
  put: createRequest('put'),
};
