import { useQuery } from '@tanstack/react-query';
import { getPartners } from 'shared/api/partner';

export const PARTNERS_QUERY_KEY = ['partners'];

export const usePartners = () =>
  useQuery({
    queryKey: PARTNERS_QUERY_KEY,
    queryFn: async () => getPartners(),
  });
