import { Row, Col, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  margin-bottom: 45px;
`;

export const StyledIdentityBlock = styled.div`
  margin-bottom: 15px;
`;

export const StyledResult = styled.div`
  font-size: 17px;
  padding: 15px 0 7px;
`;
export const StyledCard = styled(Card)`
  margin-bottom: 25px;
  text-align: center;
`;
export const PaddingRow = styled(Row)`
  padding: 10px;
`;
export const RadioLabel = styled.span`
  text-align: center;
  font-size: 15px;
  text-transform: capitalize;
`;
export const IncorrectLabel = styled.span`
  text-align: center;
  font-size: 15px;
`;
export const RadioButtonCol = styled(Col)`
  text-align: center;
  padding-left: 10px
  padding-right: 10px
`;
export const MissingDocLabel = styled.span`
  text-align: center;
`;
export const EnglishProficiencyRow = styled(Row)`
  margin-top: 30px;
  padding-left: 15px;
`;
export const TranscriptRow = styled(Row)`
  margin-top: 10px;
  padding-left: 15px;
`;
export default styled.div`
  form {
    max-width: 1200px;
    margin: 10px auto;
    border: 1px solid #ccc;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    
    & > div {
      display: flex;
      flex-flow: row nowrap;
      padding: 10px;
      line-height: 2em;
      margin: 5px;
      & > label {
        color: #333;
        width: 110px;
        font-size: 1em;
        line-height: 32px;
      }
      & > input,
      & > select,
      & > textarea {
        flex: 1;
        padding: 3px 5px;
        font-size: 1em;
        margin-left: 15px;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      & > input[type="checkbox"] {
        margin-top: 7px;
      }
      & > div {
        margin-left: 16px;
        & > label {
          display: block;
          & > input {
            margin-right: 3px;
          }
        }
      }
    }
    & > .buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 15px;
    }

`;

export const TrashButton = styled(Button)`
  border-color: white;
  background-color: white;
  margin: 0 1px 0 1px;
  &:hover {
    background-color: transparent;
    border-color: #6c757d;
  }
  &:active {
    background-color: transparent !important;
    border-color: #6c757d !important;
  }
  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    opacity: 0.3 !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
`;

export const CountryMessage = styled.div`
  border-radius: 10px;
  background: #f4cccc;
  padding: 4px 8px;
  place-content: center;
  border: solid 1px black;
`;
