import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const CourseListContainer = styled(Container)`
  font-family: 'Open Sans';
  text-align: left;
  margin-bottom: 20px;
  margin: 0 auto;
`;

export const WelcomeRow = styled.h1`
  font-weight: 500;
  font-size: 1.5em;
  text-align: left;
  margin: auto;
  padding: 5px;
  flex: 1 0 100%;
`;
