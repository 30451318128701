import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './status-indicator.module.scss';
import { useTableContext } from '../../context';

export const TableStatusIndicator = () => {
  const { loading, getRowCount, emptyText } = useTableContext();

  if (loading) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  if (getRowCount() === 0) {
    return <div className={styles.container}>{emptyText || 'No results found'}</div>;
  }

  return null;
};
